export const getCookieValue = (name: string): string | undefined => {
  const cookies = document.cookie.split(';')
  const cookie = cookies.find((cookie) => cookie.trim().startsWith(`${name}=`))
  if (cookie === undefined) {
    return undefined
  }

  const cookieValue = cookie.split('=')[1]
  return cookieValue
}

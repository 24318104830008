import React, { useState, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { fetchCustomers, fetchSites } from 'App/app-state'
import { type Profile } from 'App/Profiles/profiles-types'
import { copyProfile } from 'App/Profiles/profiles-state'
import ActionDialog from 'common/components/ActionDialog'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import useApp from 'common/hooks/useApp'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()(() => ({
  FormField: {
    marginTop: '32px',
  },
  FormSelect: {
    marginTop: '32px',
  },
}))

interface CopyProfileActionDialogOptions {
  profile: Profile
  open: boolean
  onClose: () => void
}

const CopyProfileActionDialog = ({
  profile,
  open,
  onClose,
}: CopyProfileActionDialogOptions) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const [profileName, setProfileName] = useState<string>('')
  const [closeOnAction, setCloseOnAction] = useState<boolean>(true)
  const [nameError, setNameError] = useState<string | undefined>()

  const [isSameSite, setIsSameSite] = useState<boolean>(true)

  const [distributorId, setDistributorId] = useState<number | undefined>()
  const [distributorError, setDistributorError] = useState<string | undefined>()

  const [organizationId, setOrganizationId] = useState<number | undefined>()
  const [organizatioError, setOrganizatioError] = useState<string | undefined>()

  const [siteId, setSiteId] = useState<number | undefined>()
  const [siteError, setSiteError] = useState<string | undefined>()

  useEffect(() => {
    if (
      profileName.trim().length > 0 &&
      (isSameSite ||
        (distributorId !== undefined &&
          organizationId !== undefined &&
          siteId !== undefined))
    ) {
      setCloseOnAction(true)
    } else {
      setCloseOnAction(false)
    }
  }, [profileName, isSameSite, distributorId, organizationId, siteId])

  const currentAccount = useCurrentAccount()
  const { adminMode } = useApp()

  const distributors = useAppSelector((state) => state.app.distributors)
  const organizations = useAppSelector((state) => state.app.customers)
  const sites = useAppSelector((state) => state.app.sites)

  useEffect(() => {
    if (distributorId !== undefined) {
      dispatch(fetchCustomers(distributorId))
    }
  }, [dispatch, distributorId])

  useEffect(() => {
    if (organizationId !== undefined) {
      dispatch(fetchSites(organizationId))
    }
  }, [dispatch, organizationId])

  const onAction = () => {
    if (profileName.trim().length === 0) {
      setNameError(t('profiles.addProfile.errors.emptyField'))
    } else if (!isSameSite && !distributorId) {
      setDistributorError(t('profiles.addProfile.errors.emptyField'))
    } else if (!isSameSite && !organizationId) {
      setOrganizatioError(t('profiles.addProfile.errors.emptyField'))
    } else if (!isSameSite && !siteId) {
      setSiteError(t('profiles.addProfile.errors.emptyField'))
    } else {
      let toOrganizationId, toSiteId
      if (isSameSite) {
        toOrganizationId = currentAccount.organizationId
        toSiteId = currentAccount.siteId
      } else {
        toOrganizationId = organizationId!
        toSiteId = siteId!
      }

      const props = {
        profileId: profile.id,
        fromOrganizationId: profile.organizationId,
        fromSiteId: profile.siteId,
        profileName,
        toOrganizationId,
        toSiteId,
      }

      dispatch(copyProfile(props))
    }
  }

  const locationOptions = useMemo(
    () => [
      { label: t('profiles.copyProfile.licationOption.same'), value: 1 },
      { label: t('profiles.copyProfile.licationOption.another'), value: 0 },
    ],
    [t]
  )

  const distributorOptions = useMemo(
    () =>
      distributors.map((distributor) => ({
        label: distributor.name,
        value: distributor.id,
      })),
    [distributors]
  )

  const organizationOptions = useMemo(
    () =>
      organizations.map((org) => ({
        label: org.name,
        value: org.id,
      })),
    [organizations]
  )

  const siteOptions = useMemo(
    () =>
      sites.map((site) => ({
        label: site.name,
        value: site.id,
      })),
    [sites]
  )

  return (
    <ActionDialog
      open={open}
      title={t('profiles.copyProfile.title')}
      actionText={t('profiles.copyProfile.action')}
      onAction={onAction}
      onClose={onClose}
      closeOnAction={closeOnAction}
    >
      <FormField
        className={classes.FormField}
        label={t('profiles.copyProfile.profileNameField.label')}
        placeholder={t('profiles.copyProfile.profileNameField.placeholder')}
        value={profileName}
        onChange={(e) => setProfileName(e.target.value)}
        autoFocus
        error={nameError}
      />
      {adminMode && (
        <>
          <FormSelect
            className={classes.FormSelect}
            label={t('profiles.copyProfile.locationSelect.label')}
            options={locationOptions}
            value={Number(isSameSite)}
            onChange={(_, value) => setIsSameSite(Boolean(value))}
          />
          {!isSameSite && (
            <>
              <FormSelect
                className={classes.FormSelect}
                label={t('profiles.copyProfile.distributor.label')}
                options={distributorOptions}
                value={distributorId}
                onChange={(_, value) => setDistributorId(value as number)}
                error={distributorError}
              />
              <FormSelect
                className={classes.FormSelect}
                label={t('profiles.copyProfile.orgnization.label')}
                options={organizationOptions}
                value={organizationId}
                onChange={(_, value) => setOrganizationId(value as number)}
                error={organizatioError}
              />
              <FormSelect
                className={classes.FormSelect}
                label={t('profiles.copyProfile.site.label')}
                options={siteOptions}
                value={siteId}
                onChange={(_, value) => setSiteId(value as number)}
                error={siteError}
              />
            </>
          )}
        </>
      )}
    </ActionDialog>
  )
}

export default CopyProfileActionDialog

import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import {
  useDoImportCodesMutation,
  useTryImportCodesMutation,
} from 'App/Codes/codes-rtk-api'
import { fetchCodes, resetAddCodes, setAddCodes } from 'App/Codes/codes-state'
import { type ImportedCode, PermissionType } from 'App/Codes/codes-types'
import GroupCodesTable from 'App/Codes/GroupCodesTable'
import Toast from 'common/components/Toast'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import useProfiles from 'common/hooks/useProfiles'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'
import { getTomorrow } from 'common/utils/date-utils'
import { type FieldErrors } from 'App/Codes/Codes.types'

const ImportCodesForm = () => {
  const [name, setName] = useState('')
  const [file, setFile] = useState<File>()
  const [expiration, setExpiration] = useState<Date | null>(getTomorrow())
  const [profileId, setProfileId] = useState(0)
  const [permissionType, setPermissionType] = useState<PermissionType>(
    PermissionType.Single
  )
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})
  const [processingFile, setProcessingFile] = useState(false)
  const [isImporting, setIsImporting] = useState(false)
  const [preImportCodes, setPreImportCodes] = useState<ImportedCode[]>()
  const [overwrite, setOverwrite] = useState(true)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  // TODO: check useProfiles keep fetching profiles everytime
  const profiles = useProfiles()
  const [tryImportCodes] = useTryImportCodesMutation()
  const [doImportCodes] = useDoImportCodesMutation()
  const { organizationId, siteId } = useCurrentAccount()
  const codes = useAppSelector((state) => state.codes.codes)
  const addedCodes = useAppSelector((state) => state.codes.addedCodes)
  const groupNames = Array.from(
    new Set(codes.map((c) => c.groupName).filter(Boolean))
  )

  const isValidInput = () => {
    setIsFormDirty(true)

    let hasError = false
    const errors: FieldErrors = {}

    if (!name) {
      hasError = true
      errors.name = t('codes.addCode.errors.emptyField')
    }

    if (groupNames.includes(name)) {
      hasError = true
      errors.name = t('codes.addCode.errors.duplicateGroupName')
    }

    if (!expiration || expiration.getTime() < Date.now()) {
      hasError = true
      errors.expiration = t('codes.addCode.errors.invalidField')
    }

    if (!profileId) {
      hasError = true
      errors.profileId = t('codes.addCode.errors.neglectedSelect')
    }

    if (!file) {
      hasError = true
      errors.file = t('codes.addCode.errors.fileNeeded')
    }

    setFieldErrors(errors)
    return !hasError
  }

  const resetForm = () => {
    setIsFormDirty(false)
    setFile(undefined)
    setName('')
    setExpiration(getTomorrow())
    setProfileId(0)
    setPermissionType(PermissionType.Single)
    setFieldErrors({})
    setPreImportCodes(undefined)
    setOverwrite(true)
  }

  const handleSubmit = () => {
    if (!isValidInput()) {
      return
    }

    const codesToImport = preImportCodes
      ?.filter((code) => !code.invalid && (overwrite || !code.exists))
      .map((code) => code.value)

    if (codesToImport) {
      setIsImporting(true)
      doImportCodes({
        organizationId,
        siteId,
        codes: codesToImport,
        profileId,
        expiration: expiration as Date | undefined, // TODO: improving type here
        permissionType,
        name,
      })
        .unwrap()
        .then(() => {
          dispatch(setAddCodes())
          dispatch(fetchCodes({ organizationId, siteId }))
          resetForm()
          setIsImporting(false)
        })
        .catch((err) => console.log('Failed while importing codes', err))
        .finally(() => setIsImporting(false))
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    setFile(file)

    if (file) {
      setFieldErrors((prev) => ({ ...prev, file: '' }))
      const reader = new FileReader()
      reader.onload = async (e) => {
        setProcessingFile(true)
        const content = e.target?.result as string
        const fileData = content.split('\n').filter((code) => code !== '')

        if (fileData.length > 0) {
          const codesToImport = fileData.map((code) => code.trim())
          tryImportCodes({
            organizationId,
            siteId,
            codes: codesToImport,
          })
            .unwrap()
            .then((res) => setPreImportCodes(res))
            .catch((err) =>
              console.log('Failed while try to import codes', err)
            )
            .finally(() => setProcessingFile(false))
        } else {
          setProcessingFile(false)
        }
      }
      reader.readAsText(file)
    } else {
      setPreImportCodes(undefined)
    }
  }

  const hasExistingCodes = preImportCodes?.some((code) => code.exists)

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <TextField
        variant="outlined"
        label={t('codes.addCode.groupNameField.label')}
        placeholder={t('codes.addCode.groupNameField.placeholder')}
        fullWidth
        value={name}
        onChange={(e) => {
          setFieldErrors((prev) => ({ ...prev, name: '' }))
          setName(e.target.value)
        }}
        error={Boolean(isFormDirty && fieldErrors.name)}
        helperText={fieldErrors.name}
      />

      <DatePicker
        label={t('codes.addCode.expirationField.label')}
        sx={{ width: '100%' }}
        value={expiration}
        onChange={(newValue) => setExpiration(newValue)}
        disablePast
      />

      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        error={Boolean(isFormDirty && fieldErrors.profileId)}
      >
        <InputLabel>{t('codes.addCode.profileField.label')}</InputLabel>
        <Select
          value={profileId}
          onChange={(e) => {
            setFieldErrors((prev) => ({ ...prev, profileId: '' }))
            setProfileId(Number(e.target.value))
          }}
        >
          {profiles.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {Boolean(isFormDirty && fieldErrors.profileId) && (
          <FormHelperText>{fieldErrors.profileId}</FormHelperText>
        )}
      </FormControl>

      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel>{t('codes.addCode.permissionTypeField.label')}</InputLabel>
        <Select
          value={permissionType}
          onChange={(e) => setPermissionType(e.target.value as PermissionType)}
        >
          <MenuItem value={PermissionType.Single}>
            {t('codes.addCode.permissionTypeField.options.single')}
          </MenuItem>
          <MenuItem value={PermissionType.Multi}>
            {t('codes.addCode.permissionTypeField.options.multi')}
          </MenuItem>
          <MenuItem value={PermissionType.Reusable}>
            {t('codes.addCode.permissionTypeField.options.reusable')}
          </MenuItem>
        </Select>
      </FormControl>

      <TextField
        label={t('codes.importCodes.selectFile')}
        variant="outlined"
        type="file"
        InputProps={{
          inputProps: {
            accept: '.txt,.csv',
          },
        }}
        onChange={handleFileChange}
        fullWidth
        error={Boolean(isFormDirty && fieldErrors.file)}
        helperText={
          fieldErrors.file ?? t('codes.importCodes.fileImportInstruction')
        }
      />

      {processingFile ? (
        <CircularProgress />
      ) : preImportCodes ? (
        <>
          <GroupCodesTable codes={preImportCodes} importing />
          {hasExistingCodes && (
            <FormControlLabel
              label={t('codes.importCodes.overwriteOldCodes')}
              control={
                <Checkbox
                  checked={overwrite}
                  onChange={() => setOverwrite(!overwrite)}
                />
              }
            />
          )}
        </>
      ) : null}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        marginTop={3}
      >
        <Button variant="contained" onClick={handleSubmit}>
          {isImporting
            ? t('codes.importCodes.importing')
            : t('codes.importCodes.import')}
        </Button>
      </Box>

      {/* TODO: update Toast to support failed cases */}
      <Toast
        open={addedCodes}
        onClose={() => dispatch(resetAddCodes())}
        message={t('codes.importCodes.success')}
      />
    </Box>
  )
}

export default ImportCodesForm

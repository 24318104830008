import React, { useEffect, useMemo, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import PageActions from 'common/components/PageActions'
import { useTranslation } from 'react-i18next'
import Button from 'common/components/Button'
import { Box, List, ListItem, Alert, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import AddProfileDrawer from 'App/Profiles/components/AddProfileDrawer'

import EditIcon from '@mui/icons-material/EditOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import IconMenu from 'common/components/IconMenu'
import { type Profile } from 'App/Profiles/profiles-types'
import { renderIf, renderItems } from 'common/utils/render-utils'
import {
  deleteProfile,
  fetchProfiles,
  fetchProfilesWithCodes,
  resetDeleteProfile,
  resetCopyProfile,
} from 'App/Profiles/profiles-state'
import CopyProfileActionDialog from 'App/Profiles/components/CopyProfileActionDialog'
import ActionDialog from 'common/components/ActionDialog'
import Toast from 'common/components/Toast'
import { Link, useNavigate } from 'react-router-dom'
import PageEmpty from 'common/components/PageEmpty'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import PageRequirement from 'common/components/PageRequirement'
import useSiteEnrolled from 'common/hooks/useSiteEnrolled'
import Dialog from 'common/components/Dialog'
import useDocks from 'common/hooks/useDocks'
import { fetchSite } from 'App/Sites/sites-state'
import {
  useLoadCategoriesBySiteQuery,
  useLazyLoadIntegrationParamsCountByProfileIdQuery,
} from 'App/Profiles/profiles-rtk-api'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  ProfileList: {
    marginRight: '-25px',
    marginBottom: '-25px',
  },
  ProfileListItem: {
    float: 'left',
    width: '338px',
    height: '299px',
    marginRight: '25px',
    marginBottom: '25px',
    padding: 0,
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
  },
  ProfileListItemLink: {
    display: 'block',
    width: '100%',
    height: '100%',
    textDecoration: 'none',
    color: '#000',
  },
  ProfileListItemImage: {
    position: 'relative',
    width: '100%',
    height: '190px',
    padding: '15px 16px',
    backgroundColor: '#EFEFEF',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  ProfileListItemInfo: {
    width: '100%',
    padding: '24px',
  },
  ProfileListItemName: {
    marginBottom: '4px',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '33px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  ProfileListItemDescription: {
    fontSize: '14px',
    lineHeight: '19px',
  },
}))

interface ProfileListItemActionsProps {
  profile: Profile
  onClickEdit: (profile: Profile) => void
  onClickCopy: (profile: Profile) => void
  onClickDelete: (profile: Profile) => void
}

const ProfileListItemActions = ({
  profile,
  onClickEdit,
  onClickCopy,
  onClickDelete,
}: ProfileListItemActionsProps) => {
  const actions = [
    {
      local: 'profiles.listProfiles.actions.edit',
      fn: () => onClickEdit(profile),
      icon: EditIcon,
    },
    {
      local: 'profiles.listProfiles.actions.copy',
      fn: () => onClickCopy(profile),
      icon: ContentCopyIcon,
    },
    {
      local: 'profiles.listProfiles.actions.delete',
      fn: () => onClickDelete(profile),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu variant="white" actions={actions} />
}

const Profiles = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [copyProfileValue, setCopyProfileValue] = useState<
    Profile | undefined
  >()

  const [openDrawer, setOpenDrawer] = useState(false)
  const [deleteProfileValue, setDeleteProfileValue] = useState<
    Profile | undefined
  >()

  const [showTip, setShowTip] = useState(false)

  const profiles = useAppSelector((state) => state.profiles.profiles)
  const fetchedProfiles = useAppSelector(
    (state) => state.profiles.fetchedProfiles
  )
  const addedProfile = useAppSelector((state) => state.profiles.addedProfile)
  const deletedProfile = useAppSelector(
    (state) => state.profiles.deletedProfile
  )
  const { organizationId, siteId } = useCurrentAccount()
  const updatedProfile = useAppSelector(
    (state) => state.profiles.updatedProfile
  )
  const copiedProfile = useAppSelector((state) => state.profiles.copiedProfile)

  const siteEnrolled = useSiteEnrolled()

  const profilesWithCodes = useAppSelector(
    (state) => state.profiles.profilesWithCodes
  )
  const deleteProfileHasCodes = useMemo(
    () =>
      deleteProfileValue !== undefined &&
      profilesWithCodes.includes(deleteProfileValue.id),
    [profilesWithCodes, deleteProfileValue]
  )

  const site = useAppSelector((state) => state.sites.site)
  const siteUsingDeleteProfile =
    site != null && site.profileId === deleteProfileValue?.id

  const docks = useDocks('site', false)
  const docksUsingDeleteProfile = docks.some(
    (dock) => dock.profileId === deleteProfileValue?.id
  )

  const [
    loadIntegrationParamsCountByProfileId,
    { data: integrationParamsUsingDeleteProfile },
  ] = useLazyLoadIntegrationParamsCountByProfileIdQuery()

  const { data: categories } = useLoadCategoriesBySiteQuery({
    organizationId,
    siteId,
  })

  const foundCategory = categories?.some((category) =>
    profiles.some((profile) => profile.id === category.profileId)
  )

  useEffect(() => {
    setShowTip(!foundCategory)
  }, [foundCategory, setShowTip])

  useEffect(() => {
    if (deleteProfileValue !== undefined) {
      loadIntegrationParamsCountByProfileId({
        organizationId,
        siteId,
        profileId: deleteProfileValue.id,
      })
    }
  }, [
    dispatch,
    deleteProfileValue,
    loadIntegrationParamsCountByProfileId,
    organizationId,
    siteId,
  ])

  useEffect(() => {
    dispatch(fetchSite({ organizationId, siteId }))
    dispatch(fetchProfiles({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  // this is probably not correct all the time since user may delete site that does not belong to his current org
  useEffect(() => {
    if (deletedProfile) {
      dispatch(resetDeleteProfile())
      dispatch(fetchProfiles({ organizationId, siteId }))
    }
  }, [dispatch, deletedProfile, organizationId, siteId])

  useEffect(() => {
    if (copiedProfile) {
      dispatch(resetCopyProfile())
      dispatch(fetchProfiles({ organizationId, siteId }))
    }
  }, [dispatch, copiedProfile, organizationId, siteId])

  useEffect(() => {
    dispatch(fetchProfilesWithCodes({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  if (!fetchedProfiles) {
    return <Page />
  }

  const onClickAddProfile = () => setOpenDrawer(true)
  const onCloseDrawer = () => setOpenDrawer(false)

  const onClickEdit = (profile: Profile) => navigate(`/profiles/${profile.id}`)
  const onClickCopy = (profile: Profile) => setCopyProfileValue(profile)
  const onClickDelete = (profile: Profile) => setDeleteProfileValue(profile)

  const onCloseCopyDialog = () => setCopyProfileValue(undefined)
  const onCloseDeleteDialog = () => setDeleteProfileValue(undefined)

  const onActionDelete = () => {
    if (deleteProfileValue != null) {
      const context = { organizationId, siteId }
      dispatch(deleteProfile({ context, profileId: deleteProfileValue.id }))
      setDeleteProfileValue(undefined)
    }
  }

  return (
    <>
      <Page>
        <PageRequirement
          criteria={siteEnrolled}
          title={t('profiles.requirement.title')}
          description={t('profiles.requirement.description')}
          actionText={t('profiles.requirement.action')}
          actionTo="/site-settings"
        >
          {renderIf(profiles.length === 0, () => (
            <PageEmpty
              title={t('profiles.empty.title')}
              description={t('profiles.empty.description')}
              actionText={t('profiles.empty.title')}
              onClickAction={onClickAddProfile}
            />
          ))}
          {renderIf(profiles.length > 0, () => (
            <>
              <PageHeader>
                <PageTitle title={t('profiles.title')} />
                <PageActions>
                  <Button onClick={onClickAddProfile} small>
                    {t('profiles.actions.addProfile')}
                  </Button>
                </PageActions>
              </PageHeader>
              <PageBody>
                {showTip && (
                  <Box mb={3}>
                    <Alert variant="filled" severity="info">
                      {t('profiles.tips.missingCategory')}
                    </Alert>
                  </Box>
                )}
                <List className={classes.ProfileList}>
                  {renderItems(profiles, (profile: Profile) => (
                    <ListItem className={classes.ProfileListItem}>
                      <Link
                        className={classes.ProfileListItemLink}
                        to={`/profiles/${profile.id}`}
                      >
                        <Box
                          className={classes.ProfileListItemImage}
                          style={{
                            backgroundImage:
                              profile.imageThumb === null
                                ? 'none'
                                : `url('${profile.imageThumb}')`,
                          }}
                        >
                          <ProfileListItemActions
                            profile={profile}
                            onClickEdit={onClickEdit}
                            onClickCopy={onClickCopy}
                            onClickDelete={onClickDelete}
                          />
                        </Box>
                        <Box className={classes.ProfileListItemInfo}>
                          <Box className={classes.ProfileListItemName}>
                            {profile.name}
                          </Box>
                          <Box className={classes.ProfileListItemDescription}>
                            {profile.description}
                          </Box>
                        </Box>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </PageBody>
            </>
          ))}
        </PageRequirement>
      </Page>

      <AddProfileDrawer open={openDrawer} onClose={onCloseDrawer} />

      <Toast
        open={addedProfile}
        message={t('profiles.addProfile.toast.success')}
      />
      <Toast
        open={updatedProfile}
        message={t('profiles.editProfile.toast.success')}
      />
      <Toast
        open={deletedProfile}
        message={t('profiles.deleteProfile.toast.success')}
      />
      <Toast
        open={copiedProfile}
        message={t('profiles.copyProfile.toast.success')}
      />

      {copyProfileValue != null && (
        <CopyProfileActionDialog
          profile={copyProfileValue}
          open={copyProfileValue !== undefined}
          onClose={onCloseCopyDialog}
        />
      )}

      {deleteProfileValue != null &&
        profiles.length > 1 &&
        !deleteProfileHasCodes && (
          <ActionDialog
            open={deleteProfileValue !== undefined}
            title={t('profiles.deleteProfile.title')}
            description={t('profiles.deleteProfile.description', {
              profile: deleteProfileValue.name,
            })}
            actionText={t('profiles.deleteProfile.action')}
            onAction={onActionDelete}
            onClose={onCloseDeleteDialog}
          />
        )}

      {deleteProfileValue != null && profiles.length === 1 && (
        <Dialog
          open={deleteProfileValue !== undefined}
          title={t('profiles.deleteProfile.title')}
          onClose={onCloseDeleteDialog}
        >
          {t('profiles.deleteProfile.rejectReason.lastProfile')}
        </Dialog>
      )}

      {deleteProfileValue != null &&
        profiles.length > 1 &&
        deleteProfileHasCodes && (
          <Dialog
            open={deleteProfileValue !== undefined}
            title={t('profiles.deleteProfile.title')}
            onClose={onCloseDeleteDialog}
          >
            {t('profiles.deleteProfile.rejectReason.codeUsingProfile')}
          </Dialog>
        )}

      {deleteProfileValue != null && siteUsingDeleteProfile && (
        <Dialog
          open={deleteProfileValue !== undefined}
          title={t('profiles.deleteProfile.title')}
          onClose={onCloseDeleteDialog}
        >
          {t('profiles.deleteProfile.rejectReason.siteUsingProfile')}
        </Dialog>
      )}

      {deleteProfileValue != null && docksUsingDeleteProfile && (
        <Dialog
          open={deleteProfileValue !== undefined}
          title={t('profiles.deleteProfile.title')}
          onClose={onCloseDeleteDialog}
        >
          {t('profiles.deleteProfile.rejectReason.dockUsingProfile')}
        </Dialog>
      )}

      {deleteProfileValue != null &&
        integrationParamsUsingDeleteProfile !== undefined &&
        integrationParamsUsingDeleteProfile.count > 0 && (
          <Dialog
            open={deleteProfileValue !== undefined}
            title={t('profiles.deleteProfile.title')}
            onClose={onCloseDeleteDialog}
          >
            {t('profiles.deleteProfile.rejectReason.integrationUsingProfile')}
          </Dialog>
        )}
    </>
  )
}

export default Profiles

import { useEffect, useRef, EffectCallback, DependencyList } from 'react'
import isDeepEqual from 'fast-deep-equal'

export const useDeepEffect = (
  callback: EffectCallback,
  deps: DependencyList
) => {
  const ref = useRef<DependencyList | undefined>()

  if (!ref.current || !isDeepEqual(deps, ref.current)) {
    ref.current = deps
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [ref.current])
}

import React, { useCallback, useMemo, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import { Stack } from '@mui/system'
import { Alert, LinearProgress, Typography } from '@mui/material'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import Table, {
  TableColumnFilter,
  type TableColumn,
} from 'common/components/TableNew'
import TableActionsBar from 'common/components/TableActionsBar'
import { useGetTabletsDataForReviewQuery } from 'App/SuperAdmin/super-admin-rtk-api'
import FormSelect from 'common/components/FormSelect'
import {
  ViewWeekOutlined as ViewWeekIcon,
  FactCheckOutlined as FactCheckIcon,
} from '@mui/icons-material'
import { type TabletDataForAdminReview } from 'App/SuperAdmin/super-admin-types'
import IconMenu from 'common/components/IconMenu'
import DataReviewTabletDrawer from 'App/SuperAdmin/components/DataReviewTabletDrawer'
import Toast from 'common/components/Toast'

interface TabletsTableActionsBarProps {
  tabletsTotalCount: number
  tabletsReviewedCount: number

  columns: TableColumn[]
  selectedColumns: string[]
  onChangeSelectedColumns: (selectedColumns: string[]) => void
}

const TabletsTableActionsBar = ({
  tabletsTotalCount,
  tabletsReviewedCount,
  columns,
  selectedColumns,
  onChangeSelectedColumns,
}: TabletsTableActionsBarProps) => {
  const severity = useMemo(() => {
    if (tabletsReviewedCount === 0) {
      return 'info'
    }
    if (tabletsReviewedCount < tabletsTotalCount) {
      return 'warning'
    }
    return 'success'
  }, [tabletsReviewedCount, tabletsTotalCount])

  const columnOptions = useMemo(
    () =>
      columns
        .filter((column) => column.field != null)
        .map((column) => ({
          value: String(column.field),
          label: String(column.title),
        })),
    [columns]
  )

  return (
    <TableActionsBar>
      <Stack direction="row" gap={2} alignItems="flex-end">
        <Alert severity={severity} sx={{ flexGrow: 1 }}>
          Reviewed {tabletsReviewedCount} / {tabletsTotalCount} tablets
        </Alert>
        <FormSelect
          options={columnOptions}
          renderValue={() => <ViewWeekIcon />}
          multiple
          checkmarks
          value={selectedColumns}
          onChange={(e) => onChangeSelectedColumns(e.target.value)}
          small
        />
      </Stack>
    </TableActionsBar>
  )
}

interface TabletRowActionsProps {
  tablet: TabletDataForAdminReview
  onClickEdit: (tabletId: number) => void
}

const TabletRowActions = ({ tablet, onClickEdit }: TabletRowActionsProps) => {
  const actions = [
    {
      local: 'Review',
      fn: () => onClickEdit(tablet.id),
      icon: FactCheckIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

const TabletsTable = () => {
  const columns: TableColumn[] = useMemo(
    () => [
      {
        title: 'Distributor',
        field: 'distributorName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Organization',
        field: 'organizationName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Site',
        field: 'siteName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Country',
        field: 'country',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Serial number',
        field: 'tabletSerialNumber',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Last active',
        field: 'tabletActiveAt',
        filter: TableColumnFilter.DateRange,
        canSort: true,
      },
      {
        title: 'Mac Address',
        field: 'tabletMacAddress',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'OS version',
        field: 'tabletOsVersion',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'App version',
        field: 'tabletAppVersion',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Location',
        field: 'tabletLocation',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Status',
        field: 'tabletStockStatus',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Tags',
        field: 'tabletTags',
        filter: TableColumnFilter.Text,
        valueFn: (model: TabletDataForAdminReview) => model.tags.join(', '),
        canSort: true,
      },
      {
        title: 'Is reviewed',
        field: 'isReviewed',
        filter: TableColumnFilter.Boolean,
        render: (tablet: TabletDataForAdminReview) =>
          tablet.isReviewed ? 'Yes' : 'No',
        canSort: true,
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (tablet: TabletDataForAdminReview) => (
          <TabletRowActions
            tablet={tablet}
            onClickEdit={(tabletId: number) => setToEditTabletId(tabletId)}
          />
        ),
      },
    ],
    []
  )

  const { data, refetch: refetchTabletsData } =
    useGetTabletsDataForReviewQuery()

  const [toEditTabletId, setToEditTabletId] = useState<number | null>(null)
  const toEditTablet = useMemo(() => {
    if (toEditTabletId == null) {
      return null
    }

    return data?.find((tablet) => tablet.id === toEditTabletId)
  }, [data, toEditTabletId])

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'tabletSerialNumber',
    'tabletMacAddress',
    'tabletOsVersion',
    'tabletAppVersion',
    'tabletLocation',
    'tabletStockStatus',
    'tabletActiveAt',
    'tags',
    'isReviewed',
  ])
  const visibleColumns = useMemo(
    () =>
      columns.filter(
        (column) =>
          selectedColumns.includes(String(column.field)) ||
          column.id === 'actions'
      ),
    [columns, selectedColumns]
  )

  const [isSaveSuccess, setIsSaveSuccess] = useState(false)
  const handleSaved = useCallback(() => {
    refetchTabletsData()
    setToEditTabletId(null)
    setIsSaveSuccess(true)
  }, [refetchTabletsData])

  const tabletsTotalCount = data?.length ?? 0
  const tabletsReviewedCount = useMemo(() => {
    return data?.filter((tablet) => tablet.isReviewed).length ?? 0
  }, [data])

  if (data == null) {
    return <LinearProgress />
  }

  return (
    <>
      <Stack>
        <TabletsTableActionsBar
          tabletsTotalCount={tabletsTotalCount}
          tabletsReviewedCount={tabletsReviewedCount}
          columns={columns}
          selectedColumns={selectedColumns}
          onChangeSelectedColumns={setSelectedColumns}
        />
        <Table data={data} columns={visibleColumns} />
      </Stack>
      {toEditTablet != null && (
        <DataReviewTabletDrawer
          open={toEditTablet != null}
          onClose={() => setToEditTabletId(null)}
          onSaved={handleSaved}
          data={toEditTablet}
        />
      )}
      <Toast
        message="Tablet data saved"
        open={isSaveSuccess}
        onClose={() => setIsSaveSuccess(false)}
      />
    </>
  )
}

const DataReviewTablets = () => {
  return (
    <Page>
      <PageHeader>
        <Stack>
          <Typography
            sx={(theme) => ({
              fontWeight: 600,
              color: theme.palette.secondary.main,
            })}
          >
            Super-admin
          </Typography>
          <PageTitle title="Data Review: Tablets" />
        </Stack>
      </PageHeader>
      <PageBody>
        <Stack gap={2}>
          <Typography variant="h2">Tablets</Typography>
          <TabletsTable />
        </Stack>
      </PageBody>
    </Page>
  )
}

export default DataReviewTablets

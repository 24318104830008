import React, { useEffect, useMemo, useState } from 'react'
import { type OrganizationDataForAdminReview } from 'App/SuperAdmin/super-admin-types'
import FormField from 'common/components/FormField'
import FormSwitch from 'common/components/FormSwitch'
import DrawerForm from 'common/components/DrawerForm'
import { Stack } from '@mui/material'
import { useGetDistributorsQuery } from 'App/Distributors/distributors-rtk-api'
import FormSelect from 'common/components/FormSelect'
import { useReviewOrganizationDataMutation } from 'App/SuperAdmin/super-admin-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import { timezoneOptions } from 'common/timezones'
import { useCountryOptions } from 'common/countries'
import DataTagsField from 'common/components/DataTagsField'

interface DataReviewOrganizationDrawerProps {
  open: boolean
  onClose: () => void
  onSaved: (data: OrganizationDataForAdminReview) => void
  organizationData: OrganizationDataForAdminReview
}

const DataReviewOrganizationDrawer = ({
  open,
  onClose,
  onSaved,
  organizationData,
}: DataReviewOrganizationDrawerProps) => {
  const [name, setName] = useState(organizationData.name)
  const [timezone, setTimezone] = useState(organizationData.timezone)
  const [isDistributor, setIsDistributor] = useState(
    organizationData.isDistributor
  )
  const [distributorId, setDistributorId] = useState(
    String(organizationData.distributorId ?? '')
  )
  const [isManufacturer, setIsManufacturer] = useState(
    organizationData.isManufacturer
  )
  const [hubspotId, setHubspotId] = useState(organizationData.hubspotId)
  const [vatIdNumber, setVatIdNumber] = useState(organizationData.vatIdNumber)
  const [vatIdName, setVatIdName] = useState(organizationData.vatIdName)
  const [billingAddressStreet1, setBillingAddressStreet1] = useState(
    organizationData.billingAddressStreet1
  )
  const [billingAddressStreet2, setBillingAddressStreet2] = useState(
    organizationData.billingAddressStreet2
  )
  const [billingAddressZipcode, setBillingAddressZipcode] = useState(
    organizationData.billingAddressZipcode
  )
  const [billingAddressCity, setBillingAddressCity] = useState(
    organizationData.billingAddressCity
  )
  const [billingAddressRegion, setBillingAddressRegion] = useState(
    organizationData.billingAddressRegion
  )
  const [billingAddressCountry, setBillingAddressCountry] = useState(
    organizationData.billingAddressCountry
  )

  const [tags, setTags] = useState<string[]>(organizationData.tags)

  const distributors = useGetDistributorsQuery()
  const distributorOptions = useMemo(
    () =>
      distributors.data?.map((d) => ({ label: d.name, value: String(d.id) })) ??
      [],
    [distributors.data]
  )

  const [
    reviewOrganizationData,
    {
      isLoading: isReviewingOrganizationData,
      error: reviewOrganizationDataError,
      isSuccess: isOrganizationDataReviewed,
      data: reviewedOrganizationData,
    },
  ] = useReviewOrganizationDataMutation()

  useEffect(() => {
    if (isOrganizationDataReviewed && reviewedOrganizationData) {
      onSaved(reviewedOrganizationData)
    }
  }, [isOrganizationDataReviewed, onSaved, reviewedOrganizationData])

  const [hasApiError, apiError] = useApiErrors([reviewOrganizationDataError])

  const handleSubmit = () => {
    const updatedOrganizationData: OrganizationDataForAdminReview = {
      id: organizationData.id,
      name,
      timezone,
      isDistributor,
      distributorId: distributorId === '' ? undefined : Number(distributorId),
      isManufacturer,
      hubspotId,
      vatIdNumber,
      vatIdName,
      billingAddressStreet1,
      billingAddressStreet2,
      billingAddressZipcode,
      billingAddressCity,
      billingAddressRegion,
      billingAddressCountry,
      tags,
      isReviewed: true,
    }

    reviewOrganizationData(updatedOrganizationData)
  }

  const countryOptions = useCountryOptions()

  return (
    <DrawerForm
      title="Review organization data"
      open={open}
      onClose={onClose}
      submitText="Save"
      onSubmit={handleSubmit}
      disabledButton={isReviewingOrganizationData}
    >
      <Stack spacing={4}>
        {hasApiError && <ErrorBox>{apiError}</ErrorBox>}
        <FormSwitch
          title="Is distributor"
          checked={isDistributor}
          onChange={(e, checked) => setIsDistributor(checked)}
          variant="drawer"
          disabled={true}
        />
        <FormSelect
          label="Distributor"
          value={distributorId}
          onChange={(e) => setDistributorId(e.target.value)}
          options={distributorOptions}
          emptyLabel="-"
          placeholder="Select distributor"
          disabled={true}
        />
        <FormSwitch
          title="Is manufacturer"
          checked={isManufacturer}
          onChange={(e, checked) => setIsManufacturer(checked)}
          variant="drawer"
          disabled={true}
        />
        <FormField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter name"
          disabled={isReviewingOrganizationData}
        />
        <FormSelect
          label="Timezone"
          options={timezoneOptions}
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          placeholder="Select timezone"
          disabled={isReviewingOrganizationData}
        />
        <FormField
          label="Hubspot ID"
          value={hubspotId}
          onChange={(e) => setHubspotId(e.target.value)}
          placeholder="Enter Hubspot ID"
          disabled={isReviewingOrganizationData}
        />
        <FormField
          label="VAT ID Number"
          value={vatIdNumber}
          onChange={(e) => setVatIdNumber(e.target.value)}
          placeholder="Enter VAT ID Number"
          disabled={isReviewingOrganizationData}
        />
        <FormField
          label="VAT ID Name"
          value={vatIdName}
          onChange={(e) => setVatIdName(e.target.value)}
          placeholder="Enter VAT ID Name"
          disabled={isReviewingOrganizationData}
        />
        <FormField
          label="Billing address street 1"
          value={billingAddressStreet1}
          onChange={(e) => setBillingAddressStreet1(e.target.value)}
          placeholder="Enter billing address street 1"
          disabled={isReviewingOrganizationData}
        />
        <FormField
          label="Billing address street 2"
          value={billingAddressStreet2}
          onChange={(e) => setBillingAddressStreet2(e.target.value)}
          placeholder="Enter billing address street 2"
          disabled={isReviewingOrganizationData}
        />
        <FormField
          label="Billing address zipcode"
          value={billingAddressZipcode}
          onChange={(e) => setBillingAddressZipcode(e.target.value)}
          placeholder="Enter billing address zipcode"
          disabled={isReviewingOrganizationData}
        />
        <FormField
          label="Billing address city"
          value={billingAddressCity}
          onChange={(e) => setBillingAddressCity(e.target.value)}
          placeholder="Enter billing address city"
          disabled={isReviewingOrganizationData}
        />
        <FormField
          label="Billing address region"
          value={billingAddressRegion}
          onChange={(e) => setBillingAddressRegion(e.target.value)}
          placeholder="Enter billing address region"
          disabled={isReviewingOrganizationData}
        />
        <FormSelect
          label="Billing address country"
          options={countryOptions}
          value={billingAddressCountry}
          onChange={(e) => setBillingAddressCountry(e.target.value)}
          placeholder="Select billing address country"
          disabled={isReviewingOrganizationData}
        />

        <DataTagsField
          dataType="organization"
          tags={tags}
          onChange={(tags) => setTags(tags)}
          disabled={isReviewingOrganizationData}
        />
      </Stack>
    </DrawerForm>
  )
}

export default DataReviewOrganizationDrawer

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Tablet, ProtectBattery } from 'App/Tablets/tablets-types'
import DrawerWithTabs from 'common/components/DrawerWithTabs'
import Panel, { EditState } from 'common/components/NewPanel'
import Button from 'common/components/Button'
import { CircularProgress, Divider } from '@mui/material'
import PanelField from 'common/components/PanelField'
import SettingsEntry from 'App/Profiles/ProfilesView/SettingsEntry'
import ManagedConfigurationsTable from 'App/Tablets/components/ManagedConfigurationsTable'
import useApp from 'common/hooks/useApp'
import { fetchEmmInfo, fetchTablets } from 'App/Tablets/tablets-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
// import AppInstallTable from 'App/Tablets/components/AppInstallTable'
import useDocks from 'common/hooks/useDocks'
import FormSelect, { type FormSelectOption } from 'common/components/FormSelect'
import {
  useUpdateTabletDockDetailsMutation,
  useUpdateTabletLMSDetailsMutation,
} from 'App/Tablets/tablets-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import ViewTabletLoansTab from 'App/Tablets/components/ViewTabletLoansTab'
import FormField from 'common/components/FormField'
import TabletTest from 'App/Tablets/components/TabletTest'
import { useAppDispatch, useAppSelector } from 'store'
import TabletLogs from 'App/Tablets/components/TabletLogs'
import JsonView from 'common/components/JsonView'
import Dialog from 'common/components/Dialog'

interface TabletLMSPanelProps {
  tablet: Tablet
}

const TabletLMSPanel = ({ tablet }: TabletLMSPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { adminMode } = useApp()
  const { organizationId, siteId } = useCurrentAccount()

  const [editState, setEditState] = useState<EditState>(EditState.Default)

  const [numberInLms, setNumberInLms] = useState(tablet.numberInLms ?? '')

  const [
    updateTabletLMSDetails,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateTabletLMSDetailsMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchTablets({ organizationId, siteId }))
    }
  }, [isUpdateSuccess, hasApiMutationErrors, dispatch, organizationId, siteId])

  const handleSave = () => {
    setEditState(EditState.Pending)
    updateTabletLMSDetails({
      organizationId,
      siteId,
      tabletId: tablet.id,
      numberInLms: numberInLms.trim() === '' ? undefined : numberInLms,
    })
  }

  const renderEditMode = () => (
    <>
      <FormField
        label={t('tablets.viewTablet.general.fields.numberInLms.label')}
        placeholder={t(
          'tablets.viewTablet.general.fields.numberInLms.placeholder'
        )}
        value={numberInLms}
        onChange={(e) => setNumberInLms(e.target.value)}
        disabled={editState === EditState.Pending}
      />
    </>
  )

  return (
    <>
      <Panel
        title={t('tablets.viewTablet.general.lms.title')}
        editable={adminMode}
        editState={editState}
        onEdit={() => setEditState(EditState.Edit)}
        onCancel={() => setEditState(EditState.Default)}
        onSave={handleSave}
        onSuccess={() => setEditState(EditState.Default)}
        renderEditMode={renderEditMode}
        error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
      >
        <PanelField
          title={t('tablets.viewTablet.general.fields.numberInLms.label')}
          value={tablet.numberInLms}
        />
      </Panel>
    </>
  )
}

interface TabletHubletAppPanelProps {
  tablet: Tablet
}

const TabletHubletAppPanel = ({ tablet }: TabletHubletAppPanelProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Panel title={t('tablets.viewTablet.general.hubletApp.title')}>
        <PanelField
          title={t('tablets.viewTablet.general.hubletApp.version')}
          value={tablet.hubletVersionCode}
        />
        <PanelField
          title={t('tablets.viewTablet.general.hubletApp.versionName')}
          value={tablet.hubletVersionName}
        />
      </Panel>
    </>
  )
}

interface TabletOSPanelProps {
  tablet: Tablet
}

const TabletOSPanel = ({ tablet }: TabletOSPanelProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Panel title={t('tablets.viewTablet.general.os.title')}>
        <PanelField
          title={t('tablets.viewTablet.general.sdk')}
          value={tablet.osBuildSdkApiLevel}
        />
        <PanelField
          title={t('tablets.viewTablet.general.release')}
          value={tablet.osBuildRelease}
        />
      </Panel>
    </>
  )
}

interface TabletDockPanelProps {
  tablet: Tablet
}

const TabletDockPanel = ({ tablet }: TabletDockPanelProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { adminMode } = useApp()
  const { organizationId, siteId } = useCurrentAccount()

  const docks = useDocks('site', false)
  const dock = useMemo(() => {
    return docks.find((dock) => dock.id === tablet.dockId)
  }, [docks, tablet.dockId])

  const [editState, setEditState] = useState<EditState>(EditState.Default)

  const [dockId, setDockId] = useState(tablet.dockId ?? -1)
  const [dockSlot, setDockSlot] = useState(tablet.dockSlot ?? -1)

  const [
    updateTabletDockDetails,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateTabletDockDetailsMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchTablets({ organizationId, siteId }))
    }
  }, [isUpdateSuccess, hasApiMutationErrors, dispatch, organizationId, siteId])

  const dockOptions = useMemo(() => {
    const opts: FormSelectOption[] = [
      { label: t('tablets.viewTablet.general.fields.dock.empty'), value: -1 },
    ]

    docks.forEach((dock) => {
      opts.push({
        label: `${dock.name} / ${dock.serialNumber ?? '-'}`,
        value: dock.id,
      })
    })

    return opts
  }, [t, docks])

  const dockSlotOptions = useMemo(() => {
    const PHYSICAL_SLOTS_START = 1
    const VIRTUAL_SLOTS_START = 7

    const opts: FormSelectOption[] = [
      {
        label: t('tablets.viewTablet.general.fields.dockSlot.empty'),
        value: -1,
      },
    ]

    for (let i = 1; i <= 10; i += 1) {
      const opt: FormSelectOption = {
        value: i,
        label: i.toString(),
      }

      if (i === PHYSICAL_SLOTS_START) {
        opt.startGroup = t(
          'tablets.viewTablet.general.fields.dockSlot.physical'
        )
      } else if (i === VIRTUAL_SLOTS_START) {
        opt.startGroup = t('tablets.viewTablet.general.fields.dockSlot.virtual')
      }

      opts.push(opt)
    }

    return opts
  }, [t])

  const handleSave = () => {
    setEditState(EditState.Pending)
    updateTabletDockDetails({
      organizationId,
      siteId,
      tabletId: tablet.id,
      dockId: dockId === -1 ? undefined : dockId,
      dockSlot: dockId === -1 || dockSlot === -1 ? undefined : dockSlot,
    })
  }

  const renderEditMode = () => (
    <>
      <FormSelect
        label={t('tablets.viewTablet.general.fields.dock.label')}
        options={dockOptions}
        value={dockId}
        onChange={(_e, v) => setDockId(v as number)}
        disabled={editState === EditState.Pending}
      />
      {dockId !== null && (
        <FormSelect
          label={t('tablets.viewTablet.general.dockSlot')}
          options={dockSlotOptions}
          value={dockSlot}
          onChange={(_e, v) => setDockSlot(v as number)}
          disabled={editState === EditState.Pending}
        />
      )}
    </>
  )

  return (
    <Panel
      title={t('tablets.viewTablet.general.dock.title')}
      editable={adminMode}
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(EditState.Default)}
      onSave={handleSave}
      onSuccess={() => setEditState(EditState.Default)}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('tablets.viewTablet.general.fields.dock.label')}
        value={`${dock?.name ?? '-'} / ${dock?.serialNumber ?? '-'}`}
      />
      {dock !== undefined && (
        <PanelField
          title={t('tablets.viewTablet.general.dockSlot')}
          value={tablet.dockSlot ?? '-'}
        />
      )}
    </Panel>
  )
}

interface ExtraDetailsPanelProps {
  tablet: Tablet
}

const ExtraDetailsPanel = ({ tablet }: ExtraDetailsPanelProps) => {
  const { t } = useTranslation()
  const { emmInfo, fetchingEmmInfo } = useAppSelector((state) => ({
    emmInfo: state.tablets.emmInfo,
    fetchingEmmInfo: state.tablets.fetchingEmmInfo,
  }))

  const [openDevicePolicyDialog, setOpenDevicePolicyDialog] = useState(false)

  return (
    <>
      <Panel title={t('tablets.viewTablet.general.adminInfo.title')}>
        <PanelField
          title={t('tablets.viewTablet.general.emm')}
          value={
            fetchingEmmInfo ? (
              <CircularProgress size={24} />
            ) : (
              t(emmInfo?.status ?? 'Unknown')
            )
          }
        />
        <PanelField
          title={t('tablets.viewTablet.general.googleUserId')}
          value={tablet.googleUserId ?? t('tablets.viewTablet.NA')}
        />
        <PanelField
          title={t('tablets.viewTablet.general.googleDeviceId')}
          value={tablet.googleDeviceId ?? t('tablets.viewTablet.NA')}
        />
        <PanelField
          title={t('tablets.viewTablet.general.emmDevicePolicyUpdatedAt')}
          value={
            tablet.emmDevicePolicyUpdatedAt != null ? (
              <DateWithRelativeTime
                datetime={new Date(tablet.emmDevicePolicyUpdatedAt)}
              />
            ) : (
              '-'
            )
          }
        />
        <PanelField
          title={t('tablets.viewTablet.general.emmDevicePolicy')}
          value={
            <Button
              textOnly
              onClick={() => {
                setOpenDevicePolicyDialog(true)
              }}
            >
              {t('common.table.actions.viewDetails')}
            </Button>
          }
        />
        <PanelField
          title={t('tablets.viewTablet.general.lockRequested')}
          value={String(tablet.lockRequested ?? false)}
        />
        <PanelField
          title={t('tablets.viewTablet.general.resetRequested')}
          value={String(tablet.resetRequested ?? false)}
        />
        <PanelField
          title={t('tablets.viewTablet.general.loanStatus')}
          value={tablet.loanStatus}
        />
        <PanelField
          title={t('tablets.viewTablet.general.loanKey')}
          value={tablet.loanKey ?? t('tablets.viewTablet.NA')}
        />
      </Panel>
      <Dialog
        title={t('tablets.viewTablet.general.emmDevicePolicy')}
        open={openDevicePolicyDialog}
        onClose={() => setOpenDevicePolicyDialog(false)}
      >
        <JsonView object={emmInfo?.devicePolicy} />
      </Dialog>
    </>
  )
}

interface ViewTabletDrawerProps {
  tablet: Tablet
  open: boolean
  onClose: () => void
  onClickReboot: (tablet: Tablet) => void
  onClickReset: (tablet: Tablet) => void
  onClickRemove: (tablet: Tablet) => void
}

const ViewTabletDrawer = ({
  tablet,
  open,
  onClose,
  onClickReboot,
  onClickReset,
  onClickRemove,
}: ViewTabletDrawerProps) => {
  const { t } = useTranslation()
  const { adminMode } = useApp()
  const { organizationId, siteId } = useCurrentAccount()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (tablet.id > 0) {
      dispatch(fetchEmmInfo({ organizationId, siteId, tabletId: tablet.id }))
    }
  }, [dispatch, organizationId, siteId, tablet])

  const tabs = [
    {
      title: t('tablets.viewTablet.tabs.general'),
      component: (
        <>
          <Panel title={t('tablets.viewTablet.general.title')}>
            <PanelField
              title={t('tablets.viewTablet.general.serialNumber')}
              value={tablet.serialNumber}
            />
            <PanelField
              title={t('tablets.viewTablet.general.macAddress')}
              value={tablet.macAddress}
            />
            <PanelField
              title={t('tablets.viewTablet.general.model')}
              value={tablet.modelCode}
            />
            <PanelField
              title={t('tablets.viewTablet.general.cover')}
              value={
                tablet.onewireId ??
                t('tablets.viewTablet.general.cover.notPaired')
              }
            />
            <PanelField
              title={t('tablets.viewTablet.general.createdAt')}
              value={<DateWithRelativeTime datetime={tablet.createdAt} />}
            />
            <PanelField
              title={t('tablets.viewTablet.general.lastActive')}
              value={
                tablet.activeAt !== undefined ? (
                  <DateWithRelativeTime datetime={new Date(tablet.activeAt)} />
                ) : (
                  '-'
                )
              }
            />
            <PanelField
              title={t('tablets.viewTablet.general.batteryLevel')}
              value={
                tablet.batteryLevelFromDeviceApi !== undefined
                  ? `${tablet.batteryLevelFromDeviceApi}%`
                  : undefined
              }
            />
            <PanelField
              title={t('tablets.viewTablet.general.batterySaver')}
              value={
                tablet.batterySaverFromDeviceApi
                  ? t('tablet.batterySaverFromDeviceApi.on')
                  : t('tablet.batterySaverFromDeviceApi.off')
              }
            />
            <PanelField
              title={t('tablets.viewTablet.general.protectBattery')}
              value={t(
                `tablet.protectBatteryFromDeviceApi.${
                  tablet.protectBatteryFromDeviceApi ?? ProtectBattery.UNKNOWN
                }`
              )}
            />
          </Panel>
          <TabletOSPanel tablet={tablet} />
          <TabletHubletAppPanel tablet={tablet} />
          <TabletDockPanel tablet={tablet} />
          <TabletLMSPanel tablet={tablet} />
          {adminMode && <ExtraDetailsPanel tablet={tablet} />}
        </>
      ),
    },
    {
      title: t('tablets.viewTablet.tabs.loans'),
      component: <ViewTabletLoansTab tablet={tablet} />,
    },
    {
      title: t('tablets.viewTablet.tabs.settings'),
      component: (
        <Panel title={t('tablets.viewTablet.settings.title')}>
          <SettingsEntry property="tablets.viewTablet.settings.reset">
            <Button small flat onClick={() => onClickReset(tablet)}>
              {t('tablets.viewTablet.settings.reset.resetButton')}
            </Button>
          </SettingsEntry>

          <Divider light />

          <SettingsEntry property="tablets.viewTablet.settings.reboot">
            <Button small flat onClick={() => onClickReboot(tablet)}>
              {t('tablets.viewTablet.settings.reboot.rebootButton')}
            </Button>
          </SettingsEntry>

          <Divider light />

          <SettingsEntry property="tablets.viewTablet.settings.remove">
            <Button small flat negative onClick={() => onClickRemove(tablet)}>
              {t('tablets.viewTablet.settings.remove.removeButton')}
            </Button>
          </SettingsEntry>
        </Panel>
      ),
    },
    {
      title: t('tablets.viewTablet.tabs.managedConfiguration'),
      component: <ManagedConfigurationsTable tabletId={tablet.id} />,
    },
    // HUB-1087: Temporarily hide this until we use EMM API to fetched installed apps
    // {
    //   title: t('tablets.viewTablet.tabs.apps'),
    //   component: <AppInstallTable tablet={tablet} />,
    // },
  ]

  if (adminMode) {
    tabs.push({
      title: t('tablets.viewTablet.tabs.test'),
      component: <TabletTest tablet={tablet} />,
    })

    tabs.push({
      title: t('tablets.viewTablet.tabs.logs'),
      component: <TabletLogs tablet={tablet} />,
    })
  }

  return (
    <DrawerWithTabs
      open={open}
      onClose={onClose}
      title={t('tablets.viewTablet.title')}
      tabs={tabs}
    />
  )
}

export default ViewTabletDrawer

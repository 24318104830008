import React, { useMemo, useState } from 'react'

import FormField from 'common/components/FormField'

import { UserRole } from 'App/app-state'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import { inviteOrganizationUser, inviteSiteUser } from 'App/Users/users-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import FormSelect from 'common/components/FormSelect'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useApp from 'common/hooks/useApp'
import { renderIf } from 'common/utils/render-utils'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  InviteUserFormFields: {
    '& > .MuiFormControl-root': {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}))

interface InviteUserDrawerProps {
  open: boolean
  onClose: () => void
}

interface FieldErrors {
  email?: string
}

interface InviteUserForm {
  email: string
  role: UserRole
  language: string
}

const inviteUserForm = (language: string, role: UserRole): InviteUserForm => ({
  email: '',
  role,
  language, // uses current user's language by default
})

const InviteUserDrawer = ({ open, onClose }: InviteUserDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const { classes } = useStyles()

  const languages = useAppSelector((state) => state.app.languages)

  const { organizationId, siteId } = useCurrentAccount()
  const { adminMode } = useApp()

  const isSiteUser = siteId !== -1 && siteId !== undefined
  const defaultRole = isSiteUser ? UserRole.SiteAdmin : UserRole.OrgAdmin

  const [userForm, setUserForm] = useState<InviteUserForm>(
    inviteUserForm(i18n.language, defaultRole)
  )
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  const languageOptions = useMemo(() => {
    return languages.map((lng) => ({
      label: t(`languages.${lng}`),
      value: lng,
    }))
  }, [languages, t])

  const roleOptions = useMemo(() => {
    const opts = []

    if (isSiteUser) {
      opts.push({ value: UserRole.SiteAdmin, label: 'Site admin' })
    } else {
      opts.push({ value: UserRole.OrgAdmin, label: 'Organization admin' })
    }

    opts.push({ value: UserRole.Translator, label: 'Translator' })

    return opts
  }, [isSiteUser])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserForm({ ...userForm, [key]: e.target.value })
    }

  const validateForm = () => {
    let hasError = false
    const errors: FieldErrors = {}

    if (userForm.email === '') {
      hasError = true
      errors.email = t('users.inviteUser.errors.requiredField')
    }

    return { hasError, errors }
  }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    const { hasError, errors } = validateForm()

    if (!hasError) {
      if (!isSiteUser) {
        dispatch(
          inviteOrganizationUser({
            organizationId,
            email: userForm.email,
            language: userForm.language,
            role: userForm.role,
          })
        )
      } else {
        dispatch(
          inviteSiteUser({
            organizationId,
            siteId,
            email: userForm.email,
            language: userForm.language,
            role: userForm.role,
          })
        )
      }
    }

    setFieldErrors(errors)
  }

  const { hasError } = validateForm()

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      title={t('users.inviteUser.title')}
      submitText={t('users.inviteUser.buttons.submit')}
      disabledButton={hasError}
    >
      <div className={classes.InviteUserFormFields}>
        <FormField
          type="email"
          label={t('users.inviteUser.emailField.label')}
          placeholder={t('users.inviteUser.emailField.placeholder')}
          value={userForm.email}
          onChange={onChangeField('email')}
          autoFocus
          error={fieldErrors.email}
          helperText={t('users.inviteUser.emailField.helperText')}
        />
        <FormSelect
          label={t('users.inviteUser.languageField.label')}
          value={userForm.language}
          options={languageOptions}
          onChange={onChangeField('language')}
        />
        {renderIf(adminMode, () => (
          <FormSelect
            label="Role"
            value={userForm.role}
            options={roleOptions}
            onChange={onChangeField('role')}
          />
        ))}
      </div>
    </DrawerForm>
  )
}

export default InviteUserDrawer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  type Profile,
  type ProfileForm,
  type ProfilesContext,
} from 'App/Profiles/profiles-types'
import ProfilesApi from 'api/profiles-api'
import { trackEvent } from 'common/hooks/useTracking'
import { TrackRequestProps } from 'common/hooks/useTracking.types'
import { getCommonTrackingData } from 'common/utils/tracking-utils'
import { RootState } from 'store'

interface ProfilesState {
  profiles: Profile[]
  fetchingProfiles: boolean
  fetchedProfiles: boolean
  addingProfile: boolean
  addedProfile: boolean
  updatingProfile: boolean
  updatedProfile: boolean
  deletingProfile: boolean
  deletedProfile: boolean
  copyingProfile: boolean
  copiedProfile: boolean
  uploadingProfileImage: boolean
  uploadedProfileImage: boolean
  uploadedProfileImageFile: string | null
  uploadProfileImageError: boolean
  profilesWithCodes: number[]
  fetchedProfilesWithCodes: boolean
}

const initialState: ProfilesState = {
  profiles: [],
  fetchingProfiles: false,
  fetchedProfiles: false,
  addingProfile: false,
  addedProfile: false,
  updatingProfile: false,
  updatedProfile: false,
  deletingProfile: false,
  deletedProfile: false,
  copyingProfile: false,
  copiedProfile: false,
  uploadingProfileImage: false,
  uploadedProfileImage: false,
  uploadedProfileImageFile: null,
  uploadProfileImageError: false,
  profilesWithCodes: [],
  fetchedProfilesWithCodes: false,
}

export const fetchProfiles = createAsyncThunk(
  'profiles/fetchProfiles',
  async (context: ProfilesContext) => ProfilesApi.fetchProfiles(context)
)

export const addProfile = createAsyncThunk(
  'profiles/addProfile',
  async (
    props: { context: ProfilesContext; form: ProfileForm },
    { getState }
  ) => {
    const res = await ProfilesApi.createProfile(props)

    const state = getState() as RootState
    const commonTrackingData = getCommonTrackingData(state)
    const requestBody: TrackRequestProps = {
      ...commonTrackingData,
      feature: 'Profiles',
      action: 'Profile created',
      payload: {
        name: props.form.name ?? '',
        description: props.form.description ?? '',
      },
    }
    trackEvent(requestBody)

    return res
  }
)

export const updateProfile = createAsyncThunk(
  'profiles/updateProfile',
  async (props: {
    context: ProfilesContext
    form: ProfileForm
    profileId: number
  }) => ProfilesApi.updateProfile(props)
)

export const deleteProfile = createAsyncThunk(
  'profiles/deleteProfile',
  async (
    props: { context: ProfilesContext; profileId: number },
    { getState }
  ) => {
    const res = await ProfilesApi.deleteProfile(props)

    const state = getState() as RootState
    const commonTrackingData = getCommonTrackingData(state)
    const requestBody: TrackRequestProps = {
      ...commonTrackingData,
      feature: 'Profiles',
      action: 'Profile deleted',
      payload: { profileId: props.profileId },
    }
    trackEvent(requestBody)
    return res
  }
)

export const copyProfile = createAsyncThunk(
  'profiles/copyProfile',
  async (props: {
    profileId: number
    fromOrganizationId: number
    fromSiteId: number
    profileName: string
    toOrganizationId: number
    toSiteId: number
  }) => ProfilesApi.copyProfile(props)
)

export const uploadProfileImage = createAsyncThunk(
  'profiles/uploadProfileImage',
  async ({
    context,
    imageFile,
    profileId,
  }: {
    context: ProfilesContext
    imageFile: File
    profileId: number
  }) => ProfilesApi.uploadProfileImage(context, imageFile, profileId)
)

export const fetchProfilesWithCodes = createAsyncThunk(
  'profiles/fetchProfilesWithCodes',
  async (context: ProfilesContext) =>
    ProfilesApi.fetchProfilesWithCodes(context)
)

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    resetAddProfile: (state) => {
      state.addingProfile = false
      state.addedProfile = false
      return state
    },
    resetUpdateProfile: (state) => {
      state.updatingProfile = false
      state.updatedProfile = false
      return state
    },
    resetDeleteProfile: (state) => {
      state.deletingProfile = false
      state.deletedProfile = false
      return state
    },
    resetCopyProfile: (state) => {
      state.copyingProfile = false
      state.copiedProfile = false
      return state
    },
    resetUploadProfileImage: (state) => {
      state.uploadedProfileImage = false
      state.uploadingProfileImage = false
      state.uploadedProfileImageFile = null
      state.uploadProfileImageError = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfiles.pending, (state) => {
      state.fetchingProfiles = true
      return state
    })

    builder.addCase(fetchProfiles.fulfilled, (state, { payload }) => {
      state.fetchingProfiles = false
      state.fetchedProfiles = true
      state.profiles = payload
      return state
    })

    builder.addCase(addProfile.pending, (state) => {
      state.addingProfile = true
      state.addedProfile = false
      return state
    })

    builder.addCase(addProfile.fulfilled, (state) => {
      state.addingProfile = false
      state.addedProfile = true
      return state
    })

    builder.addCase(updateProfile.pending, (state) => {
      state.updatingProfile = true
      state.updatedProfile = false
      return state
    })

    builder.addCase(updateProfile.fulfilled, (state) => {
      state.updatingProfile = false
      state.updatedProfile = true
      return state
    })

    builder.addCase(deleteProfile.pending, (state) => {
      state.deletingProfile = true
      state.deletedProfile = false
      return state
    })

    builder.addCase(deleteProfile.fulfilled, (state) => {
      state.deletingProfile = false
      state.deletedProfile = true
      return state
    })

    builder.addCase(copyProfile.pending, (state) => {
      state.copyingProfile = true
      state.copiedProfile = false
      return state
    })

    builder.addCase(copyProfile.fulfilled, (state) => {
      state.copyingProfile = false
      state.copiedProfile = true
      return state
    })

    builder.addCase(uploadProfileImage.pending, (state) => {
      state.uploadingProfileImage = true
      state.uploadedProfileImage = false
      state.uploadProfileImageError = false
      return state
    })

    builder.addCase(uploadProfileImage.fulfilled, (state, { payload }) => {
      state.uploadingProfileImage = false
      state.uploadedProfileImage = true
      state.uploadedProfileImageFile = payload
      state.uploadProfileImageError = false
      return state
    })

    builder.addCase(uploadProfileImage.rejected, (state) => {
      state.uploadingProfileImage = false
      state.uploadedProfileImage = false
      state.uploadedProfileImageFile = null
      state.uploadProfileImageError = true
      return state
    })

    builder.addCase(fetchProfilesWithCodes.fulfilled, (state, { payload }) => {
      state.fetchedProfilesWithCodes = true
      state.profilesWithCodes = payload
      return state
    })
  },
})

export const {
  resetAddProfile,
  resetUpdateProfile,
  resetDeleteProfile,
  resetCopyProfile,
  resetUploadProfileImage,
} = profilesSlice.actions

export default profilesSlice.reducer

interface AddressProps {
  street?: string
  street2?: string
  zipcode?: string
  city?: string
  region?: string
}

export const printAddress = ({
  street,
  street2,
  zipcode,
  city,
  region,
}: AddressProps): string => {
  const parts = [street, street2, zipcode, city, region].filter(
    (x) => x !== null && x !== undefined && x !== ''
  )
  return parts.join(', ')
}

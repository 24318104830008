import { Autocomplete, Box, TextField } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useMemo, useState } from 'react'
import {
  allTrackActions,
  allTrackingFeatures,
} from 'common/hooks/useTracking.constants'
import { TrackingAction } from 'common/hooks/useTracking.types'
import { useTrackingData } from 'App/TrackingData/TrackingData.hooks'
import { columns } from './columns'

export const TrackingDataComponent = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0, // first page
  })
  const [selectedFeature, setSelectedFeature] = useState('')
  const [selectedActions, setSelectedActions] = useState<TrackingAction[]>([])

  const handleActionsChange = (event: any, newValue: TrackingAction[]) => {
    setSelectedActions(newValue)
  }

  const handleFeatureChange = (event: any, newValue: string | null) => {
    setSelectedFeature(newValue ?? '')
    setSelectedActions([])
  }

  const { rows } = useTrackingData({
    features: selectedFeature ? [selectedFeature] : [],
    actions: selectedActions,
  })

  const trackingActions = useMemo(() => {
    if (!selectedFeature) {
      return allTrackActions
    }
    // @ts-expect-error improve typing
    return allTrackingFeatures[selectedFeature] as TrackingAction[]
  }, [selectedFeature])

  return (
    <Box flexDirection="column" sx={{ width: '100%' }}>
      <Box>Select a feature and/or action(s) to narrow down the data</Box>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        justifyContent="flex-start"
        mt={2}
        mb={2}
      >
        <Autocomplete
          options={Object.keys(allTrackingFeatures)}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Select a feature" />
          )}
          value={selectedFeature}
          onChange={handleFeatureChange}
          sx={{ flex: 1, maxWidth: '250px' }}
        />
        <Autocomplete
          options={trackingActions}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Select action(s)" />
          )}
          multiple
          sx={{ flex: 1, maxWidth: '250px' }}
          value={selectedActions}
          onChange={handleActionsChange}
        />
      </Box>
      <Box sx={{ height: 628, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </Box>
    </Box>
  )
}

// Define the array with all possible values
export const trackLoginActions = [
  'Success',
  'Failed',
  'Password requested',
  'Password updated',
] as const

export const trackUsersActions = [
  'New user invited',
  'User deleted',
  'User re-invited',
] as const

export const trackProfilesActions = [
  'Profile created',
  'Profile deleted',
  'Category added',
  'Category deleted',
] as const

export const allTrackActions = [
  ...trackLoginActions,
  ...trackUsersActions,
  ...trackProfilesActions,
]

export const allTrackingFeatures = {
  Login: trackLoginActions,
  Users: trackUsersActions,
  Profiles: trackProfilesActions,
}

import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Tablet,
  type TabletAppSettings,
  type TabletEmmInfo,
  type TabletPackage,
  type UpdateManagedConfiguration,
  ProtectBattery,
} from 'App/Tablets/tablets-types'
import QueryString from 'qs'

export const toModel = (remote: {
  id: number
  siteId: number
  organizationId: number
  dockId?: number
  dockSlot?: number
  macAddress?: string
  activeAt?: number
  serialNumber: string
  modelCode: string
  deviceId?: string
  batteryStatus?: string
  googleConfigIsLocked?: boolean
  googleConfigRemoteFactoryReset?: boolean
  googleDeviceId?: string
  googleUserId?: string
  loanStatus?: string
  loanKey?: string
  batteryLevelFromDeviceApi?: number
  batterySaverFromDeviceApi?: boolean
  protectBatteryFromDeviceApi?: ProtectBattery
  createdAt: string
  deletedAt?: string
  numberInLms?: string
  emmDevicePolicyUpdatedAt?: number
  osBuildRelease?: string
  osBuildFingerprint?: string
  osBuildSdkApiLevel?: number
  hubletVersionCode?: string
  hubletVersionName?: string
}): Tablet => ({
  id: remote.id,
  siteId: remote.siteId,
  organizationId: remote.organizationId,
  dockId: remote.dockId,
  dockSlot: remote.dockSlot,
  macAddress: remote.macAddress?.substring(0, 17),
  activeAt: remote.activeAt,
  serialNumber: remote.serialNumber,
  modelCode: remote.modelCode,
  onewireId: remote.deviceId ?? undefined,
  batteryStatus: remote.batteryStatus,
  lockRequested: remote.googleConfigIsLocked,
  resetRequested: remote.googleConfigRemoteFactoryReset,
  googleDeviceId: remote.googleDeviceId,
  googleUserId: remote.googleUserId,
  loanStatus: remote.loanStatus,
  loanKey: remote.loanKey,
  batteryLevelFromDeviceApi: remote.batteryLevelFromDeviceApi ?? undefined,
  batterySaverFromDeviceApi: remote.batterySaverFromDeviceApi ?? undefined,
  protectBatteryFromDeviceApi:
    remote.protectBatteryFromDeviceApi ?? ProtectBattery.UNKNOWN,
  createdAt: new Date(remote.createdAt),
  deletedAt: remote.deletedAt ? new Date(remote.deletedAt) : undefined,
  numberInLms: remote.numberInLms ?? undefined,
  emmDevicePolicyUpdatedAt: remote.emmDevicePolicyUpdatedAt,
  osBuildRelease: remote.osBuildRelease,
  osBuildFingerprint: remote.osBuildFingerprint,
  osBuildSdkApiLevel: remote.osBuildSdkApiLevel,
  hubletVersionCode: remote.hubletVersionCode,
  hubletVersionName: remote.hubletVersionName,
})

class TabletsApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchTablets(
    siteId: number,
    organizationId: number,
    dockId?: number,
    withDeleted = true
  ): Promise<Tablet[]> {
    let url = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets`

    const queryParams = []
    if (dockId) {
      queryParams.push(`dockId=${dockId}`)
    }

    if (withDeleted) {
      queryParams.push(`withDeleted=${String(withDeleted)}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    const { data } = await axios.get(url)

    const tablets: Tablet[] = data.rows.map(toModel)

    return tablets
  }

  static async removeTablet(
    tabletId: number,
    siteId: number,
    organizationId: number
  ): Promise<void> {
    await axios.delete(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}`
    )
  }

  /**
   * @deprecated We don't provide lockTablet functionality in UI anymore
   */
  static async lockTablet(
    tabletId: number,
    siteId: number,
    organizationId: number
  ): Promise<void> {
    await axios.post(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/lock`
    )
  }

  static async rebootTablet(
    tabletId: number,
    siteId: number,
    organizationId: number
  ): Promise<void> {
    await axios.post(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/reboot`
    )
  }

  static async fetchInstallQrcode(
    siteId: number,
    organizationId: number,
    networkId?: number,
    tabletPackage?: TabletPackage
  ): Promise<string> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/qr-code`
    const params = {
      networkId,
      tabletPackage,
    }

    const { data } = await axios.get(apiUrl, {
      params,
      paramsSerializer: (params) =>
        // Transforms nested objects into proper query param format
        QueryString.stringify(params, {
          arrayFormat: 'brackets',
          encode: false,
        }),
    })
    return data.data
  }

  static async fetchManagedConfiguration(
    organizationId: number,
    siteId: number,
    tabletId: number
  ): Promise<TabletAppSettings[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/managed-configuration`
    const { data } = await axios.get(apiUrl)

    return data as TabletAppSettings[]
  }

  static async insertAppSettings(
    organizationId: number,
    siteId: number,
    tabletId: number
  ): Promise<TabletAppSettings> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/managed-configuration`
    const { data } = await axios.post(apiUrl)
    return data as TabletAppSettings
  }

  static async updateManagedConfiguration(
    organizationId: number,
    siteId: number,
    tabletId: number,
    updateData: UpdateManagedConfiguration
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/managed-configuration`
    await axios.put(apiUrl, updateData)
  }

  static async updateAppSettings(
    organizationId: number,
    siteId: number,
    tabletId: number,
    appSettingsId: number,
    data: Partial<TabletAppSettings>
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/managed-configuration/${appSettingsId}`
    await axios.put(apiUrl, data)
  }

  static async deleteAppSettings(
    organizationId: number,
    siteId: number,
    tabletId: number,
    appSettingsId: number
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/managed-configuration/${appSettingsId}`
    await axios.delete(apiUrl)
  }

  static async fetchEmmInfo(
    organizationId: number,
    siteId: number,
    tabletId: number
  ): Promise<TabletEmmInfo> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/emm-info`
    const { data } = await axios.get(apiUrl)

    return data as TabletEmmInfo
  }
}

export default TabletsApi

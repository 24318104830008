import React, { useState, useEffect, useCallback } from 'react'
import { Grid, type Theme } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchTwoTone'
import RemoveIcon from '@mui/icons-material/DeleteOutlined'
import ReturnIcon from '@mui/icons-material/UndoOutlined'
import { makeStyles } from 'tss-react/mui'
import {
  type StockTablet,
  StockState,
} from 'App/StockTablets/stock-tablets-types'
import {
  setRemoveTablet,
  setReturnTablet,
  setReturnState,
  resetReturnState,
} from 'App/StockTablets/stock-tablets-state'
import {
  usePostRemoveTabletsMutation,
  usePostReturnTabletsMutation,
} from 'App/StockTablets/stock-tablets-rtk-api'
import FormField from 'common/components/FormField'
import ActionDialog from 'common/components/ActionDialog'
import Toast from 'common/components/Toast'
import { SelectedRowsActions } from 'common/components/TableActionsBar'
import useItemListToString from 'common/hooks/useItemListToString'
import ReaturnStateFormSelect from 'App/StockTablets/components/ReaturnStateFormSelect'
import { useAppDispatch, useAppSelector } from 'store'
import { useTranslation } from 'react-i18next'

enum SelectedTabletsAction {
  Remove = 'Remove',
  Return = 'Return',
}

const useStyles = makeStyles()((theme: Theme) => ({
  TableActionsBar: {
    marginBottom: theme.spacing(4),
  },
  Filters: {
    flexGrow: 1,
  },
  SelectedRowsText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  TableSearchField: {
    maxWidth: 300,
    height: 39,
    verticalAlign: 'middle',
    '& .MuiInput-root': {
      height: '100%',
      borderColor: theme.palette.primary.light,
    },
    '& .MuiInputAdornment-root': {
      color: theme.palette.primary.main,
    },
    '& > label': {
      height: '100%',
    },
  },
  TableShowDeleted: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    '& > span': {
      verticalAlign: 'middle',
    },
  },
  TableShowDeletedText: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
}))

interface TabletSearchProps {
  placeholder: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onSearch: () => void
}

const TableSearchForm = ({
  placeholder,
  onChange,
  onSearch,
}: TabletSearchProps) => {
  const { classes } = useStyles()

  return (
    <FormField
      className={classes.TableSearchField}
      placeholder={placeholder}
      onChange={onChange}
      onClickEndIcon={onSearch}
      endIcon={<SearchIcon />}
      rounded
      dense
    />
  )
}

interface SelectedTabletsRemoveActionDialogProps {
  open: boolean
  tabletsToRemove: StockTablet[]
  onClose: () => void
}

export const SelectedTabletsRemoveActionDialog = ({
  open,
  tabletsToRemove,
  onClose,
}: SelectedTabletsRemoveActionDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const tabletListToString = useItemListToString<StockTablet>(
    (t) => t.serialNumber,
    'tablets.remaining'
  )

  const [removeTablets, { isSuccess: isRemoveTabletsSuccess }] =
    usePostRemoveTabletsMutation()
  const [removingTabletsCount, setRemovingTabletsCount] = useState(0)

  useEffect(() => {
    if (isRemoveTabletsSuccess) {
      dispatch(setRemoveTablet())
      onClose()
    }
  }, [dispatch, isRemoveTabletsSuccess, onClose])

  const handleActionRemoveSelectedTablets = () => {
    const serialNumbers = tabletsToRemove
      .filter((tablet) => tablet.state === StockState.ASSIGNED)
      .map((t) => t.serialNumber)
    removeTablets({ serialNumbers })
    setRemovingTabletsCount(serialNumbers.length)
  }

  return (
    <>
      <ActionDialog
        open={open}
        title={t('tablets.removeTablets.title', {
          count: tabletsToRemove.length,
        })}
        description={t('tablets.removeTablets.description', {
          tablets: tabletListToString(
            tabletsToRemove.filter(
              (tablet) => tablet.state === StockState.ASSIGNED
            )
          ),
        })}
        actionText={t('tablets.listTablets.actions.remove')}
        onAction={handleActionRemoveSelectedTablets}
        onClose={onClose}
        closeOnAction={false}
      ></ActionDialog>
      <Toast
        message={t('tablets.removeTablets.toast', {
          count: removingTabletsCount,
        })}
        open={isRemoveTabletsSuccess}
      />
    </>
  )
}

interface SelectedTabletsReturnActionDialogProps {
  open: boolean
  tabletsToReturn: StockTablet[]
  onClose: () => void
}

export const SelectedTabletsReturnActionDialog = ({
  open,
  tabletsToReturn,
  onClose,
}: SelectedTabletsReturnActionDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const returnState = useAppSelector((state) => state.stockTablets.returnState)

  const tabletListToString = useItemListToString<StockTablet>(
    (t) => t.serialNumber,
    'tablets.remaining'
  )

  const [returnTablets, { isSuccess: isReturnTabletsSuccess }] =
    usePostReturnTabletsMutation()
  const [returningTabletsCount, setReturningTabletsCount] = useState(0)

  useEffect(() => {
    if (isReturnTabletsSuccess) {
      dispatch(setReturnTablet())
      onClose()
    }
  }, [dispatch, isReturnTabletsSuccess, onClose])

  const handleActionReturnSelectedTablets = () => {
    const serialNumbers = tabletsToReturn
      .filter(
        (tablet) =>
          tablet.state === StockState.ASSIGNED ||
          tablet.state === StockState.REMOVED
      )
      .map((t) => t.serialNumber)
    returnTablets({ serialNumbers, returnState })
    setReturningTabletsCount(serialNumbers.length)
  }

  return (
    <>
      <ActionDialog
        open={open}
        title={t('tablets.returnTablets.title', {
          count: tabletsToReturn.length,
        })}
        description={t('tablets.returnTablets.description', {
          tablets: tabletListToString(
            tabletsToReturn.filter(
              (tablet) =>
                tablet.state === StockState.ASSIGNED ||
                tablet.state === StockState.REMOVED
            )
          ),
        })}
        actionText={t('tablets.listTablets.actions.return')}
        onAction={handleActionReturnSelectedTablets}
        onClose={onClose}
        closeOnAction={false}
      >
        {t('tablets.returnStockTablet.action.select')}
        <ReaturnStateFormSelect
          state={returnState}
          onChange={(v) => dispatch(setReturnState(v))}
        />
      </ActionDialog>
      <Toast
        message={t('tablets.returnTablets.toast', {
          count: returningTabletsCount,
        })}
        open={isReturnTabletsSuccess}
      />
    </>
  )
}

interface TabletsTableActionsBarProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onSearch: () => void
  selectedTablets: StockTablet[]
}

const TabletsTableActionsBar = ({
  onChange,
  onSearch,
  selectedTablets,
}: TabletsTableActionsBarProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const [selectedTabletsAction, setselectedTabletsAction] = useState<
    SelectedTabletsAction | undefined
  >()

  const states = new Set(selectedTablets.map((tablet) => tablet?.state))
  let actions
  if (states.has(StockState.ASSIGNED)) {
    actions = [
      {
        local: 'remove',
        fn: () => setselectedTabletsAction(SelectedTabletsAction.Remove),
        icon: RemoveIcon,
      },
      {
        local: 'return',
        fn: () => setselectedTabletsAction(SelectedTabletsAction.Return),
        icon: ReturnIcon,
      },
    ]
  } else if (states.has(StockState.REMOVED)) {
    actions = [
      {
        local: 'return',
        fn: () => setselectedTabletsAction(SelectedTabletsAction.Return),
        icon: RemoveIcon,
      },
    ]
  }

  const handleCloseAction = useCallback(() => {
    setselectedTabletsAction(undefined)
    dispatch(resetReturnState())
  }, [dispatch])

  return (
    <>
      <Grid container className={classes.TableActionsBar}>
        <Grid item className={classes.Filters}>
          <TableSearchForm
            placeholder={t('tableActionsBar.search')}
            onChange={onChange}
            onSearch={onSearch}
          />
        </Grid>
        <Grid item>
          {selectedTablets.length > 0 && (
            <>
              <span className={classes.SelectedRowsText}>
                {selectedTablets.length}{' '}
                {t('tablets.listTablets.selectedRows.label', {
                  count: selectedTablets.length,
                })}{' '}
                {t('tableActionsBar.selected')}
              </span>
              {actions && <SelectedRowsActions actions={actions} />}
            </>
          )}
        </Grid>
      </Grid>
      <SelectedTabletsRemoveActionDialog
        open={selectedTabletsAction === SelectedTabletsAction.Remove}
        tabletsToRemove={selectedTablets}
        onClose={handleCloseAction}
      />
      <SelectedTabletsReturnActionDialog
        open={selectedTabletsAction === SelectedTabletsAction.Return}
        tabletsToReturn={selectedTablets}
        onClose={handleCloseAction}
      />
    </>
  )
}

export default TabletsTableActionsBar

import React from 'react'
import { Tab as MuiTab, type Theme } from '@mui/material'

import { withStyles } from 'tss-react/mui'

interface TabProps {
  label: string
}

export const Tab = withStyles(
  (props: TabProps) => <MuiTab disableRipple {...props} />,
  (theme: Theme) => ({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 50,
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(16),
      marginRight: theme.spacing(3),
      '&$selected': {
        color: theme.palette.primary.main,
      },
      '&:focus': {
        opacity: 1,
      },
    },
  })
)

export default Tab

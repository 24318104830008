import { ListItemButton, ListItemIcon, styled } from '@mui/material'

// Note: using ListItemButton here instead of NavLink in order to be able to click on parent item for expanding subMenuItems
// trade-off is that we need to check the active menu item by ourselves
export const StyledMenuItemButton = styled(ListItemButton)<{
  isSubMenu?: boolean
}>(({ theme, isSubMenu = false }) => ({
  textAlign: theme.direction === 'rtl' ? 'right' : 'left',

  '&.Mui-selected, &.Mui-selected:hover, &:hover': {
    // No change in background color
    backgroundColor: 'inherit',

    // Change text color to primary color
    color: theme.palette.primary.main,

    // Change icon color to primary color
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },

    // Selected side border with primary color
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: theme.direction === 'rtl' ? 'auto' : 0,
      right: theme.direction === 'rtl' ? 0 : 'auto',
      width: '4px',
      backgroundColor: theme.palette.primary.main,
    },
  },

  ...(theme.direction === 'ltr' && {
    paddingLeft: isSubMenu ? theme.spacing(7) : theme.spacing(2),
  }),

  ...(theme.direction === 'rtl' && {
    paddingRight: isSubMenu ? theme.spacing(7) : theme.spacing(2),
  }),

  // fix padding for disabled button in RTL
  '&.Mui-disabled': {
    ...(theme.direction === 'rtl' && {
      paddingRight: isSubMenu
        ? `${theme.spacing(7)} !important`
        : `${theme.spacing(2)} !important`,
    }),
  },
}))

export const StyledMenuItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '2.5rem',
  color: theme.palette.text.primary,
}))

import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

const BgImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
})

const BgImage = styled(Box)<{ imageUrl?: string | null }>(
  ({ imageUrl, theme }) => ({
    position: 'absolute',
    zIndex: -100,
    width: '100%',
    height: '550px',
    backgroundColor: '#ddd',
    backgroundSize: 'cover',
    ...(imageUrl && { backgroundImage: `url('${imageUrl}')` }),
  })
)

const BgImageGradient = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundImage:
    'linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 55.62%)',
}))

const PageBgImage = ({ imageUrl }: { imageUrl?: string | null }) => {
  return (
    <BgImageContainer>
      <BgImage imageUrl={imageUrl}>
        <BgImageGradient />
      </BgImage>
    </BgImageContainer>
  )
}

export default PageBgImage

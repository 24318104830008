import React, { useMemo } from 'react'
import Dialog from 'common/components/Dialog'
import { makeStyles } from 'tss-react/mui'
import { type EmailUser } from 'App/SuperAdmin/super-admin-types'

const useStyles = makeStyles()((theme) => ({
  PreviewTemplateDialogContainer: {
    width: 'calc(700px + (36px * 2))', // 36px is the padding of the Dialog
    height: '100%',
    maxHeight: 'calc(100vh - 36px)',
  },
  PreviewTemplateDialogContent: {
    overflow: 'hidden',
  },
  PreviewTemplateDialogIframe: {
    width: '100%',
    height: '100%',
    border: `1px solid #e0e0e0`,
  },
}))

interface PreviewTemplateDialogProps {
  emailCategory: string
  emailName: string
  templateVariant: string
  emailUser?: EmailUser
  onClose: () => void
}

const PreviewTemplateDialog = ({
  emailCategory,
  emailName,
  templateVariant,
  emailUser,
  onClose,
}: PreviewTemplateDialogProps) => {
  const { classes } = useStyles()

  const previewUrl = useMemo(() => {
    let previewUrl = `/api/v2/web/admin/${emailCategory}/${emailName}/templates/${templateVariant}`
    if (emailUser != null) {
      previewUrl += `?userId=${emailUser.id}`
    }
    return previewUrl
  }, [emailCategory, emailName, templateVariant, emailUser])

  const title = useMemo(() => {
    if (emailUser != null) {
      return `Preview template: ${emailUser.email}`
    }
    return 'Preview template'
  }, [emailUser])

  return (
    <Dialog
      classes={{
        container: classes.PreviewTemplateDialogContainer,
        content: classes.PreviewTemplateDialogContent,
      }}
      title={title}
      open={true}
      onClose={onClose}
    >
      <iframe
        className={classes.PreviewTemplateDialogIframe}
        src={previewUrl}
        title="Preview template"
      />
    </Dialog>
  )
}

export default PreviewTemplateDialog

import { useState, useEffect } from 'react'

// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
// ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` = + -
const specialCharsRegex = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/

const usePwdComplexityChecks = (newPassword: string) => {
  const [pwdComplexityChecks, setPwdComplexityChecks] = useState<string[]>([])

  useEffect(() => {
    const checks: string[] = []

    if (newPassword.length < 8) {
      checks.push('loginv2.error.passwordTooShort')
    }
    if (!/\d/.test(newPassword)) {
      checks.push('loginv2.error.passwordNoNumber')
    }
    if (!specialCharsRegex.test(newPassword)) {
      checks.push('loginv2.error.passwordNoSpecialChar')
    }
    if (!/[A-Z]/.test(newPassword)) {
      checks.push('loginv2.error.passwordNoUppercase')
    }
    if (!/[a-z]/.test(newPassword)) {
      checks.push('loginv2.error.passwordNoLowercase')
    }

    setPwdComplexityChecks(checks)
  }, [newPassword])

  return pwdComplexityChecks
}

export default usePwdComplexityChecks

import React, { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Link } from 'react-router-dom'
import Tabs from 'common/components/Tabs'
import Tab from 'common/components/Tab'
import TabPanel from 'common/components/TabPanel'

enum PathStepKind {
  Email = 'Email',
  DataManagement = 'Data management',
}

interface PathStepProps {
  step?: number
  title: string
  description: string
  link: string
  stepKind: PathStepKind
  scheduledAt?: string
}

const PathStep = ({
  step,
  title,
  description,
  link,
  stepKind,
  scheduledAt,
}: PathStepProps) => {
  const info: string[] = [stepKind]

  if (scheduledAt != null) {
    info.push(scheduledAt)
  }

  return (
    <Box
      component={Link}
      to={link}
      sx={(theme) => ({
        padding: theme.spacing(2),
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
        border: `1px solid #fff`,
        '&:hover': {
          cursor: 'pointer',
          boxShadow: 'rgb(108 37 118 / 10%) 0px 4px 20px',
          borderColor: theme.palette.primary.light,
        },
        display: 'block',
        color: '#000',
        textDecoration: 'none',
        transition: 'all 0.05s ease-in',
        position: 'relative',
      })}
    >
      {step !== undefined && (
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: '-8px',
            left: '-8px',
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: '#fff',
            border: `1px solid ${theme.palette.primary.light}`,
            color: theme.palette.primary.light,
            lineHeight: 1,
            fontSize: '12px',
            fontWeight: 600,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          {step}
        </Box>
      )}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <Box>
            <Typography
              sx={(theme) => ({
                fontSize: '10px',
                textTransform: 'uppercase',
                color: theme.palette.text.secondary,
              })}
            >
              {info.join(' · ')}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={(theme) => ({
                fontWeight: 600,
                fontSize: '18px',
              })}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: '14px' }}>{description}</Typography>
          </Box>
        </Stack>
        <Box
          sx={(theme) => ({
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
          })}
        >
          <ChevronRightIcon />
        </Box>
      </Stack>
    </Box>
  )
}

const SuperAdmin = () => {
  const [tabValue, setTabValue] = useState(0)

  return (
    <Page>
      <PageHeader>
        <PageTitle title="Super-admin " />
      </PageHeader>
      <PageBody>
        <Stack gap={4}>
          <Tabs
            value={tabValue}
            onChange={(_e, newValue) => setTabValue(newValue)}
          >
            <Tab label="Data Review" />
            <Tab label="AWS Cognito" />
            <Tab label="Products" />
          </Tabs>
          <TabPanel value={tabValue} index={0} p={0}>
            <Stack gap={2}>
              <Typography variant="h2">Data Review</Typography>
              <Typography variant="body1">
                Steps required for Hublet super-admins to review all relevant
                data.
              </Typography>
              <PathStep
                step={1}
                title="Review organizations data"
                description="Review distributor and customer organization data in Hublet database."
                link="/admin/data-review/organizations"
                stepKind={PathStepKind.DataManagement}
              />
              <PathStep
                step={2}
                title="Review sites data"
                description="Review all site data in Hublet database."
                link="/admin/data-review/sites"
                stepKind={PathStepKind.DataManagement}
              />
              <PathStep
                step={3}
                title="Review docks data"
                description="Review all docks data in Hublet database."
                link="/admin/data-review/docks"
                stepKind={PathStepKind.DataManagement}
              />
              <PathStep
                step={4}
                title="Review tablets data"
                description="Review all tablets data in Hublet database."
                link="/admin/data-review/tablets"
                stepKind={PathStepKind.DataManagement}
              />
            </Stack>
          </TabPanel>
          <TabPanel value={tabValue} index={1} p={0}>
            <Stack gap={2}>
              <Typography variant="h2">AWS Cognito</Typography>
              <Typography variant="body1">
                Steps required to move to using AWS Cognito for authentication.
              </Typography>
              <PathStep
                step={1}
                title="Pre-release notification email"
                description='"We have upgraded our security systems. Please update your
            password." email.'
                link="/admin/aws-cognito/pre-release"
                stepKind={PathStepKind.Email}
                scheduledAt="2023-10-02"
              />
              <PathStep
                step={2}
                title="Move users to Cognito user pool"
                description="Create users in Cognito user pool from existing active users in Hublet database."
                link="/admin/aws-cognito/user-import"
                stepKind={PathStepKind.DataManagement}
                scheduledAt="2023-10-09"
              />
              <PathStep
                step={3}
                title="Tips for updating password email"
                description="Tips for common issues for users who haven't yet changed their password in Cognito."
                link="/admin/aws-cognito/update-password-tips"
                stepKind={PathStepKind.Email}
                scheduledAt="2023-10-10"
              />
            </Stack>
          </TabPanel>
          <TabPanel value={tabValue} index={2} p={0}>
            <Stack gap={2}>
              <Typography variant="h2">Products</Typography>
              <Typography variant="body1">
                Use to manage product SKUs
              </Typography>
              <PathStep
                title="Manage products data"
                description="Manage all product SKUs in Hublet databse"
                link="/admin/products"
                stepKind={PathStepKind.DataManagement}
              />
            </Stack>
          </TabPanel>
        </Stack>
      </PageBody>
    </Page>
  )
}

export default SuperAdmin

import {
  type TabletApp,
  type TabletLogStream,
  type TabletPackage,
} from 'App/Tablets/tablets-types'
import { listToQueryParams } from 'common/utils/api-utils'
import rtkApi from 'rtk-api'

interface TabletsApi {
  organizationId: number
  siteId: number
}
type TabletApi = TabletsApi & { tabletId: number }

type TestLoanTabletReq = TabletApi & {
  username: string
  password: string
  language: string
  profileId: number | null
}
type TabetLogsReq = TabletApi & { start?: string; end?: string; count?: number }

const tabletsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    updateTabletDockDetails: builder.mutation<
      null,
      TabletApi & { dockId?: number; dockSlot?: number }
    >({
      query: ({ organizationId, siteId, tabletId, dockId, dockSlot }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/dock`,
        method: 'PUT',
        body: {
          dockId,
          dockSlot,
        },
      }),
    }),
    updateTabletLMSDetails: builder.mutation<
      null,
      TabletApi & { numberInLms?: string }
    >({
      query: ({ organizationId, siteId, tabletId, numberInLms }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/lms`,
        method: 'PUT',
        body: {
          numberInLms,
        },
      }),
    }),
    lockTablets: builder.mutation<void, TabletsApi & { tabletIds: number[] }>({
      query: ({ organizationId, siteId, tabletIds }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/tablets/lock`,
        method: 'POST',
        body: {
          tabletIds,
        },
      }),
    }),
    rebootTablets: builder.mutation<void, TabletsApi & { tabletIds: number[] }>(
      {
        query: ({ organizationId, siteId, tabletIds }) => ({
          url: `/organizations/${organizationId}/sites/${siteId}/tablets/reboot`,
          method: 'POST',
          body: {
            tabletIds,
          },
        }),
      }
    ),
    removeTablets: builder.mutation<void, TabletsApi & { tabletIds: number[] }>(
      {
        query: ({ organizationId, siteId, tabletIds }) => ({
          url: `/organizations/${organizationId}/sites/${siteId}/tablets?${listToQueryParams(
            'tabletIds',
            tabletIds
          )}`,
          method: 'DELETE',
        }),
      }
    ),
    testLoanTablet: builder.mutation<any, TestLoanTabletReq>({
      query: ({ organizationId, siteId, tabletId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/test/loan-tablet`,
        method: 'POST',
        body,
      }),
    }),
    loadFactoryResetCode: builder.query<{ svg: string }, TabletApi>({
      query: ({ organizationId, siteId, tabletId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/factory-reset-qr-code`,
      }),
    }),
    loadTabletApps: builder.query<TabletApp[], TabletApi>({
      query: ({ organizationId, siteId, tabletId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/apps`,
      }),
    }),
    getTabletLogs: builder.query<TabletLogStream, TabetLogsReq>({
      query: ({ organizationId, siteId, tabletId, start, end, count }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/tablets/${tabletId}/logs`,
        params: {
          start,
          end,
          count,
        },
      }),
    }),
    getTabletsPackages: builder.query<TabletPackage[], TabletsApi>({
      query: ({ organizationId, siteId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/tablets/packages`,
      }),
    }),
  }),
})

export const {
  useUpdateTabletDockDetailsMutation,
  useLockTabletsMutation,
  useRebootTabletsMutation,
  useRemoveTabletsMutation,
  useUpdateTabletLMSDetailsMutation,
  useTestLoanTabletMutation,
  useLoadFactoryResetCodeQuery,
  useLoadTabletAppsQuery,
  useLazyGetTabletLogsQuery,
  useGetTabletsPackagesQuery,
} = tabletsRtkApi

export default tabletsRtkApi

import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import {
  DialogTitle,
  Box,
  Grid,
  Stack,
  Typography,
  Button as MuiButton,
  type Theme,
} from '@mui/material'
import WizardDialog from 'common/components/WizardDialog'
import Button from 'common/components/Button'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import BadgeSVG from 'common/graphics/badge.svg'

const useStyles = makeStyles()((theme: Theme) => ({
  BaseItem: {
    textTransform: 'none',
  },
  FulfilledItem: {
    backgroundColor: '#F3FFEA',
    color: '#72D3BC',
  },
  UnfulfilledItem: {
    backgroundColor: '#FFF4F7',
    color: theme.palette.primary.main,
  },
}))

interface ListItemProps {
  fulfilled: boolean
  title: string
  description?: string
  onClick?: () => void
  zIndex: number
  disabled?: boolean
}

const ListItem = ({
  fulfilled,
  title,
  description,
  onClick = () => {},
  zIndex,
  disabled = false,
}: ListItemProps) => {
  const { classes, cx } = useStyles()

  const itemClasses = [classes.BaseItem]

  if (fulfilled) {
    itemClasses.push(classes.FulfilledItem)
  } else {
    itemClasses.push(classes.UnfulfilledItem)
  }

  return (
    <MuiButton
      className={cx(itemClasses)}
      onClick={onClick}
      sx={{ width: '592px', height: '115px', zIndex }}
      disabled={disabled}
    >
      <Grid
        container
        alignItems={fulfilled ? 'flex-start' : 'center'}
        height="100%"
      >
        <Grid
          container
          xs={10}
          pl={2}
          direction="column"
          alignItems="flex-start"
          height="100%"
        >
          <Grid container alignItems="flex-start" height="50%" pt={2}>
            <Typography
              variant="body1"
              fontSize="1.1rem"
              sx={(theme) => ({
                color:
                  disabled || fulfilled
                    ? '#72D3BC'
                    : theme.palette.primary.main,
              })}
            >
              {title}
            </Typography>
          </Grid>
          <Grid container alignItems="flex-start" height="50%" pb={1}>
            {fulfilled ? (
              <CheckIcon sx={{ color: '#72D3BC', fontSize: '2rem' }} />
            ) : (
              <Typography textAlign="left" variant="body1">
                {description}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {fulfilled ? (
            <img src={BadgeSVG} alt="Badge" style={{ width: '90px' }} />
          ) : (
            <ArrowForwardIcon sx={{ fontSize: '2rem' }} />
          )}
        </Grid>
      </Grid>
    </MuiButton>
  )
}

interface SetupListDialogProps {
  open: boolean
  onClose: () => void
  addTabletFulfilled: boolean
  onActionAddTablet: () => void
  addDockFulfilled: boolean
  onActionAddDock: () => void
}

const SetupListDialog = ({
  open,
  onClose,
  addTabletFulfilled,
  onActionAddTablet,
  addDockFulfilled,
  onActionAddDock,
}: SetupListDialogProps) => {
  const { t } = useTranslation()

  return (
    <WizardDialog open={open} onClose={onClose} width="668px" height="600px">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '20px',
          paddingTop: '20px',
        }}
      >
        <ClearIcon onClick={onClose} style={{ cursor: 'pointer' }} />
      </Box>
      <DialogTitle
        sx={(theme) => ({
          textAlign: 'center',
          fontSize: '2rem',
          fontWeight: 'bold',
          color: theme.palette.primary.main,
          mt: 2,
        })}
      >
        {t('installationWizard.device.title')}
      </DialogTitle>
      <Typography
        sx={{
          fontSize: '1.1rem',
          textAlign: 'center',
          mt: 1,
          mx: 4,
        }}
        variant="body1"
      >
        {t('installationWizard.device.description')}
      </Typography>
      <Stack
        spacing={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 5,
        }}
      >
        <ListItem
          fulfilled={addDockFulfilled}
          title={t('installationWizard.dock.title')}
          description={t('installationWizard.device.dock.description')}
          onClick={onActionAddDock}
          zIndex={998}
        />
        <ListItem
          fulfilled={addTabletFulfilled}
          title={t('installationWizard.tablet.title')}
          description={t('installationWizard.device.tablet.description')}
          onClick={onActionAddTablet}
          zIndex={999}
        />
      </Stack>
      <Box sx={{ mx: 5, my: 4 }}>
        <Grid container>
          <Grid item xs={5} />
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            item
            xs={7}
          >
            <Button small outlined onClick={onClose}>
              {t('installationWizard.continueLater')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </WizardDialog>
  )
}

export default SetupListDialog

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useNetworkForm from 'common/hooks/useNetworkForm'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  type Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import NetworkFields from 'App/Networks/NetworkFields'
import Button from 'common/components/Button'
import ClearIcon from '@mui/icons-material/Clear'
import Toast from 'common/components/Toast'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogPaper: {
    width: '505px',
    maxWidth: '100%',
    padding: '36px',
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#F8F8FB',
  },
  DialogTitle: {
    padding: 0,
    '& > h2': {
      lineHeight: 1,
      fontSize: '24px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  DialogHeadClose: {
    textAlign: 'right',
    color: '#000',
  },
  DialogCloseIcon: {
    cursor: 'pointer',
  },
  DialogContent: {
    padding: 0,
    marginTop: '16px',
    marginBottom: '20px',
    '& > p': {
      fontSize: '16px',
      color: '#000',
    },
  },
  DialogActions: {
    padding: 0,
  },
}))

interface AddNetworkPopupProps {
  open: boolean
  onClose: () => void
}

const AddNetworkPopup = ({ open, onClose }: AddNetworkPopupProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const {
    networkForm,
    fieldErrors,
    handleFieldChange,
    handleSwitchToggle,
    handleAdd,
    addedNetwork,
  } = useNetworkForm()

  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  useEffect(() => {
    if (addedNetwork) {
      onClose()
    }
  }, [onClose, addedNetwork])

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          className: classes.DialogPaper,
        }}
        dir={languageDirection}
      >
        <Grid container>
          <Grid item xs={6}>
            <DialogTitle className={classes.DialogTitle}>
              {t('profiles.editProfile.networks.addNetwork.title')}
            </DialogTitle>
          </Grid>
          <Grid className={classes.DialogHeadClose} item xs={6}>
            <ClearIcon className={classes.DialogCloseIcon} onClick={onClose} />
          </Grid>
        </Grid>
        <DialogContent className={classes.DialogContent}>
          <NetworkFields
            withLabels={false}
            networkForm={networkForm}
            fieldErrors={fieldErrors}
            onChangeField={handleFieldChange}
            onToggleSwitch={handleSwitchToggle}
          />
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          <Button onClick={onClose} small outlined>
            {t('common.actionDialog.buttons.cancel')}
          </Button>
          <Button onClick={handleAdd} small>
            {t('profiles.editProfile.networks.addNetwork.addButton')}
          </Button>
        </DialogActions>
      </Dialog>

      <Toast
        open={addedNetwork}
        message={t('networks.addNetwork.toast.success')}
      />
    </>
  )
}

export default AddNetworkPopup

import React, { useMemo, useState } from 'react'
import { Alert, LinearProgress, Stack, Typography } from '@mui/material'
import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Table, {
  type TableColumn,
  TableColumnFilter,
} from 'common/components/TableNew'
import TableActionsBar from 'common/components/TableActionsBar'
import { useGetSitesDataForReviewQuery } from 'App/SuperAdmin/super-admin-rtk-api'
import { type SiteDataForAdminReview } from 'App/SuperAdmin/super-admin-types'
import IconMenu from 'common/components/IconMenu'
import { Edit as EditIcon } from '@mui/icons-material'
import DataReviewSiteDrawer from 'App/SuperAdmin/components/DataReviewSiteDrawer'
import Toast from 'common/components/Toast'
import { printAddress } from 'common/utils/print-address'

interface SitesTableActionsBarProps {
  sitesTotalCount: number
  sitesReviewedCount: number
}

function SitesTableActionsBar({
  sitesTotalCount,
  sitesReviewedCount,
}: SitesTableActionsBarProps) {
  const severity = useMemo(() => {
    if (sitesReviewedCount === 0) {
      return 'info'
    }
    if (sitesReviewedCount < sitesTotalCount) {
      return 'warning'
    }
    return 'success'
  }, [sitesReviewedCount, sitesTotalCount])

  return (
    <TableActionsBar>
      <Alert severity={severity}>
        Reviewed {sitesReviewedCount} / {sitesTotalCount} sites
      </Alert>
    </TableActionsBar>
  )
}

interface SiteRowActionsProps {
  site: SiteDataForAdminReview
  onClickEdit: (siteId: number) => void
}

const SiteRowActions = ({ site, onClickEdit }: SiteRowActionsProps) => {
  const actions = [
    {
      local: 'Edit',
      fn: () => onClickEdit(site.id),
      icon: EditIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

const SitesTable = () => {
  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        title: 'Distributor',
        field: 'distributorName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Organization',
        field: 'organizationName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Name',
        field: 'name',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Address',
        filter: TableColumnFilter.Text,
        valueFn: (model: SiteDataForAdminReview) =>
          printAddress({
            street: model.addressStreet1,
            street2: model.addressStreet2,
            city: model.addressCity,
            region: model.addressRegion,
            zipcode: model.addressZipcode,
          }),
        canSort: true,
      },
      {
        title: 'Country',
        field: 'addressCountry',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Timezone',
        field: 'tzdbTimezone',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Sector',
        field: 'customerSectorName',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Tags',
        field: 'tags',
        filter: TableColumnFilter.Text,
        render: (model: SiteDataForAdminReview) => model.tags.join(', '),
        canSort: true,
      },
      {
        title: 'Is reviewed',
        field: 'isReviewed',
        filter: TableColumnFilter.Boolean,
        render: (model: SiteDataForAdminReview) =>
          model.isReviewed ? 'Yes' : 'No',
        canSort: true,
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (site: SiteDataForAdminReview) => (
          <SiteRowActions
            site={site}
            onClickEdit={(siteId: number) => setToEditSiteId(siteId)}
          />
        ),
      },
    ]
  }, [])

  const { data: sitesData, refetch: refetchSitesData } =
    useGetSitesDataForReviewQuery()

  const [toEditSiteId, setToEditSiteId] = useState<number | null>(null)
  const toEditSiteData = useMemo(() => {
    if (toEditSiteId == null) {
      return null
    }

    return sitesData?.find((site) => site.id === toEditSiteId)
  }, [sitesData, toEditSiteId])

  const [isSaveSuccess, setIsSaveSuccess] = useState(false)

  const sitesTotalCount = sitesData?.length ?? 0
  const sitesReviewedCount = useMemo(() => {
    return sitesData?.filter((site) => site.isReviewed).length ?? 0
  }, [sitesData])

  function handleSaved() {
    refetchSitesData()
    setToEditSiteId(null)
    setIsSaveSuccess(true)
  }

  if (sitesData == null) {
    return <LinearProgress />
  }

  return (
    <>
      <Stack>
        <SitesTableActionsBar
          sitesTotalCount={sitesTotalCount}
          sitesReviewedCount={sitesReviewedCount}
        />
        <Table data={sitesData} columns={columns} />
      </Stack>
      {toEditSiteData != null && (
        <DataReviewSiteDrawer
          open={toEditSiteData != null}
          onClose={() => setToEditSiteId(null)}
          onSaved={handleSaved}
          siteData={toEditSiteData}
        />
      )}
      <Toast
        message="Site data saved"
        open={isSaveSuccess}
        onClose={() => setIsSaveSuccess(false)}
      />
    </>
  )
}

function DataReviewSites() {
  return (
    <Page>
      <PageHeader>
        <Stack>
          <Typography
            sx={(theme) => ({
              fontWeight: 600,
              color: theme.palette.secondary.main,
            })}
          >
            Super-admin
          </Typography>
          <PageTitle title="Data Review: Sites" />
        </Stack>
      </PageHeader>
      <PageBody>
        <Stack gap={2}>
          <Typography variant="h2">Sites</Typography>
          <SitesTable />
        </Stack>
      </PageBody>
    </Page>
  )
}

export default DataReviewSites

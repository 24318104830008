import React, { useState } from 'react'
import {
  TextField,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Alert,
  styled,
  alpha,
} from '@mui/material'
import { Error } from '@mui/icons-material'
import {
  useForceChangePasswordMutation,
  useEmailPasswordLoginMutation,
} from 'App/CognitoLogin/cogonit-login-rtk-api'
import LoginPageWrapper from 'App/Login/components/LoginPageWrapper'
import usePwdComplexityChecks from 'App/Login/Login.hooks'
import { fetchCurrentUser } from 'App/app-state'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { useTranslation } from 'react-i18next'

const FormStyled = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}))

const ErrorListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.error.main, 0.08),
}))

interface InputError {
  newPassword: string
  confirmNewPassword: string
}

const ForceChangePassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [isDirty, setIsDirty] = useState(false)
  const [inputError, setInputError] = useState<InputError>({
    newPassword: '',
    confirmNewPassword: '',
  })

  const location = useLocation()
  const { email, session } = location.state

  const pwdComplexityChecks = usePwdComplexityChecks(newPassword)

  const [postForceChangePassword, { reset: resetForceChangePasswordData }] =
    useForceChangePasswordMutation()
  const [postLogin] = useEmailPasswordLoginMutation()

  const isValidInput = () => {
    let newPasswordError = ''
    let confirmNewPasswordError = ''

    if (!newPassword) {
      newPasswordError = t('loginv2.error.requiredField')
    }

    if (!confirmNewPassword) {
      confirmNewPasswordError = t('loginv2.error.requiredField')
    } else if (confirmNewPassword !== newPassword) {
      confirmNewPasswordError = t('loginv2.error.passwordNotMatched')
    }

    setInputError({
      newPassword: newPasswordError,
      confirmNewPassword: confirmNewPasswordError,
    })

    if (
      !newPasswordError &&
      !confirmNewPasswordError &&
      pwdComplexityChecks.length === 0
    ) {
      return true
    }
    return false
  }

  const resetInput = () => {
    setNewPassword('')
    setErrorMsg('')
    setInputError({
      newPassword: '',
      confirmNewPassword: '',
    })
  }

  const resetInputState = () => {
    resetForceChangePasswordData()
    setErrorMsg('')
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value)
    resetInputState()
    setInputError((prev) => ({ ...prev, password: '' }))
    setIsDirty(true)
  }

  const handleForceChangePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!email || !session || !isValidInput()) {
      return
    }

    postForceChangePassword({ email, session, newPassword })
      .unwrap()
      .then((res) => {
        if (res.isSuccess) {
          postLogin({ username: email, password: newPassword })
            .unwrap()
            .then(() => {
              resetInput()
              dispatch(fetchCurrentUser())
              navigate('/dashboard')
            })
        } else {
          setErrorMsg(
            t(`loginv2.error.${res.error?.code ?? 'InternalErrorException'}`)
          )
        }
      })
      .catch((error) => {
        console.warn('Failed to reset password', error)
        setErrorMsg(t('loginv2.error.InternalErrorException'))
      })
  }

  return (
    <LoginPageWrapper title={t('loginv2.forceChangePassword')}>
      <FormStyled onSubmit={handleForceChangePassword}>
        <Typography variant="body1" marginBottom={5} align="center">
          {t('loginv2.forceChangePasswordInstruction')}
        </Typography>

        {errorMsg && (
          <Alert severity="error" sx={{ marginBottom: 5 }}>
            {errorMsg}
          </Alert>
        )}

        <TextField
          value={newPassword}
          onChange={handlePasswordInput}
          placeholder={t('loginv2.newPassword')}
          label={t('loginv2.newPassword')}
          type="password"
          size="small"
          error={Boolean(inputError.newPassword)}
          helperText={inputError.newPassword}
        />
        <TextField
          value={confirmNewPassword}
          onChange={(e) => {
            setConfirmNewPassword(e.target.value)
            resetInputState()
            setInputError((prev) => ({ ...prev, confirmPassword: '' }))
          }}
          placeholder={t('loginv2.confirmNewPassword')}
          label={t('loginv2.confirmNewPassword')}
          type="password"
          size="small"
          error={Boolean(inputError.confirmNewPassword)}
          helperText={inputError.confirmNewPassword}
        />

        {isDirty && pwdComplexityChecks.length > 0 && (
          <List sx={{ paddingTop: 2, paddingBottom: 0 }}>
            {pwdComplexityChecks.map((item) => (
              <ErrorListItem key={item} dense>
                <ListItemIcon sx={{ minWidth: '2rem' }}>
                  <Error sx={{ color: 'error.main' }} />
                </ListItemIcon>
                {t(item)}
              </ErrorListItem>
            ))}
          </List>
        )}

        <ButtonWrapper>
          <ButtonStyled variant="contained" color="primary" type="submit">
            {t('loginv2.savePassword')}
          </ButtonStyled>
        </ButtonWrapper>
      </FormStyled>
    </LoginPageWrapper>
  )
}

export default ForceChangePassword

import React from 'react'
import { type ImportedCode } from 'App/Codes/codes-types'
import Table from 'common/components/TableNew'
import { useTranslation } from 'react-i18next'

interface GroupCodesTableProps {
  importing?: boolean
  codes: ImportedCode[]
}

const GroupCodesTable = ({
  importing = false,
  codes,
}: GroupCodesTableProps) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('codes.importCodes.code'),
      field: 'value',
    },
    {
      title: t('codes.importCodes.status'),
      render: (rowData: ImportedCode) =>
        rowData.exists
          ? t('codes.importCodes.exists')
          : rowData.invalid
            ? t('codes.importCodes.invalid')
            : t('codes.importCodes.valid'),
    },
  ]
  return <Table data={codes} columns={columns} />
}

export default GroupCodesTable

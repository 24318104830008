import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import { type Tablet } from 'App/Tablets/tablets-types'
import {
  StockState,
  type StockTablet,
} from 'App/StockTablets/stock-tablets-types'
import TabletsTableActionsBar from 'App/StockTablets/components/TabletsTableActionsBar'
import TabletTable from 'App/StockTablets/components/PaginatedTabletTable'
import ReaturnStateFormSelect from 'App/StockTablets/components/ReaturnStateFormSelect'
import AddTabletsPopup from './components/AddTabletsPopup'
import ActionDialog from 'common/components/ActionDialog'
import Toast from 'common/components/Toast'
import {
  setRemoveTablet,
  setReturnTablet,
  resetUploadSuccess,
  resetRemoveTablet,
  resetReturnTablet,
  setReturnState,
  resetReturnState,
} from 'App/StockTablets/stock-tablets-state'
import {
  usePostRemoveTabletsMutation,
  usePostReturnTabletsMutation,
} from 'App/StockTablets/stock-tablets-rtk-api'

const StockTabletsList = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [queryInput, setQueryInput] = useState('')
  const [query, setQuery] = useState('')
  const [selectedTablets, setSelectedTablets] = useState<Tablet[]>([])

  const addedTablets = useAppSelector(
    (state) => state.stockTablets.isUploadSuccess
  )
  const removedTablet = useAppSelector(
    (state) => state.stockTablets.removedTablet
  )
  const returnedTablet = useAppSelector(
    (state) => state.stockTablets.returnedTablet
  )
  const returnState = useAppSelector((state) => state.stockTablets.returnState)

  useEffect(() => {
    if (removedTablet) dispatch(resetRemoveTablet())
  }, [dispatch, removedTablet])

  useEffect(() => {
    if (returnedTablet) dispatch(resetReturnTablet())
  }, [dispatch, returnedTablet])

  const [activeTablets, setActiveTablets] = useState<StockTablet[]>([])

  const activeTabletSNs = useMemo(() => {
    return activeTablets.map((tablet) => tablet.serialNumber).join(', ')
  }, [activeTablets])

  const [addOpen, setAddOpen] = useState(false)
  const onClickAdd = () => setAddOpen(true)
  const onCloseAddPopup = () => setAddOpen(false)

  useEffect(() => {
    if (addedTablets) {
      dispatch(resetUploadSuccess())
      setAddOpen(false)
    }
  }, [dispatch, addedTablets])

  const [removeOpen, setRemoveOpen] = useState(false)
  const [postRemoveTablets, { isSuccess: removeTabletsSuccess }] =
    usePostRemoveTabletsMutation()
  const onClickRemove = (tablets: StockTablet[]) => {
    setRemoveOpen(true)
    setActiveTablets(tablets)
  }
  const onCloseRemoveDialog = () => setRemoveOpen(false)
  const onActionRemove = () => {
    postRemoveTablets({
      serialNumbers: activeTablets
        .filter((tablet) => tablet.state === StockState.ASSIGNED)
        .map((tablet) => tablet.serialNumber),
    })
    setRemoveOpen(false)
  }

  useEffect(() => {
    if (removeTabletsSuccess) dispatch(setRemoveTablet())
  }, [dispatch, removeTabletsSuccess])

  const [returnOpen, setReturnOpen] = useState(false)
  const [postReturnTablets, { isSuccess: returnTabletsSuccess }] =
    usePostReturnTabletsMutation()
  const onClickReturn = (tablets: StockTablet[]) => {
    setReturnOpen(true)
    setActiveTablets(tablets)
  }
  const onCloseReturnDialog = () => {
    setReturnOpen(false)
    dispatch(resetReturnState())
  }
  const onActionReturn = () => {
    postReturnTablets({
      serialNumbers: activeTablets
        .filter(
          (tablet) =>
            tablet.state === StockState.ASSIGNED ||
            tablet.state === StockState.REMOVED
        )
        .map((tablet) => tablet.serialNumber),
      returnState,
    })
    setReturnOpen(false)
  }

  useEffect(() => {
    if (returnTabletsSuccess) dispatch(setReturnTablet())
  }, [dispatch, returnTabletsSuccess])

  return (
    <>
      <Page>
        <PageHeader>
          <PageTitle title={t('tablets.title')} />
          <PageActions>
            <Button small onClick={onClickAdd}>
              {t('sotckTablets.actions.addTablets')}
            </Button>
          </PageActions>
        </PageHeader>
        <PageBody>
          <TabletsTableActionsBar
            onChange={(e) => setQueryInput(e.target.value)}
            onSearch={() => setQuery(queryInput)}
            selectedTablets={selectedTablets}
          />
          <TabletTable
            query={query}
            onSelectTablets={(tablets: Tablet[]) => setSelectedTablets(tablets)}
            onClickRemove={(tablet: Tablet) => onClickRemove([tablet])}
            onClickReturn={(tablet: Tablet) => onClickReturn([tablet])}
          />
        </PageBody>
      </Page>

      <AddTabletsPopup open={addOpen} onClose={onCloseAddPopup} />
      <Toast
        open={addedTablets}
        message={t('tablets.addStockTablets.toast.success')}
      />

      <ActionDialog
        open={removeOpen}
        title={t('tablets.removeStockTablet.title')}
        description={t('tablets.removeStockTablet.description', {
          tablet: activeTabletSNs,
        })}
        actionText={t('tablets.removeStockTablet.action')}
        onAction={onActionRemove}
        onClose={onCloseRemoveDialog}
      />
      <Toast
        open={removedTablet}
        message={t('tablets.removeStockTablet.toast.success')}
      />

      <ActionDialog
        open={returnOpen}
        title={t('tablets.returnStockTablet.title')}
        description={t('tablets.returnStockTablet.description', {
          tablet: activeTabletSNs,
        })}
        actionText={t('tablets.returnStockTablet.action')}
        onAction={onActionReturn}
        onClose={onCloseReturnDialog}
      >
        {t('tablets.returnStockTablet.action.select')}
        <ReaturnStateFormSelect
          state={returnState}
          onChange={(v) => dispatch(setReturnState(v))}
        />
      </ActionDialog>
      <Toast
        open={returnedTablet}
        message={t('tablets.returnStockTablet.toast.success')}
      />
    </>
  )
}

export default StockTabletsList

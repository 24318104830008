import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DialogTitle, Typography, DialogActions, Stack } from '@mui/material'
import Button from 'common/components/Button'
import WizardDialog from 'common/components/WizardDialog'

interface CreateProfileDialogProps {
  open: boolean
  onClose: () => void
}

const CreateProfileDialog = ({ open, onClose }: CreateProfileDialogProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const onClickGotoProfiles = () => {
    navigate('/profiles')
    onClose()
  }

  return (
    <WizardDialog open={open} onClose={onClose} narrow bottomBackground>
      <Stack sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
          <DialogTitle
            mt={3}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontSize: '2.2rem',
              fontWeight: 'bold',
            })}
          >
            {t('installationWizard.profile.title')}
          </DialogTitle>
          <Typography width="519px" textAlign="center" fontSize="1.1rem" mt={1}>
            {t('installationWizard.profile.description')}
          </Typography>
        </Stack>
        <DialogActions
          sx={{ pt: 4, pb: 8, display: 'flex', justifyContent: 'center' }}
        >
          <Button small onClick={onClickGotoProfiles}>
            {t('installationWizard.profile.goto')}
          </Button>
        </DialogActions>
      </Stack>
    </WizardDialog>
  )
}

export default CreateProfileDialog

import React, {
  type ChangeEventHandler,
  type RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import FormField from 'common/components/FormField'

import {
  fetchPosts,
  resetAddPost,
  resetUpdatePost,
  resetUploadPostImage,
  uploadPostImage,
} from 'App/Posts/posts-state'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import { type PostEditable } from 'App/Posts/posts-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { Box, CircularProgress, Grid, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import ImageIcon from '@mui/icons-material/ImageOutlined'
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined'

import Button from 'common/components/Button'
import ContentDialog from 'common/components/ContentDialog'
import { renderIf } from 'common/utils/render-utils'
import FormSwitch from 'common/components/FormSwitch'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  PostDrawerFormFields: {
    '& > .MuiFormControl-root, & > .MuiGrid-root': {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  ImageFieldContainer: {
    flexWrap: 'nowrap',
  },
  ImageFieldLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '200px',
    minWidth: '200px',
    height: '200px',
    padding: '9px',
    backgroundColor: '#fff',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: '10px',
    '& .MuiSvgIcon-root': {
      fontSize: '90px',
      color: '#000',
    },
    '& img': {
      borderRadius: '10px',
    },
  },
  ImageFieldLabel: {
    display: 'block',
    marginBottom: '16px',
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
  },
  ImageFieldRight: {
    flexGrow: 1,
    padding: '24px',
  },
  ImageFieldRightTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  ImageFieldRightInfo: {
    marginTop: '16px',
  },
  ImageFieldRightAction: {
    marginTop: '16px',
  },
  ImageFieldImage: {
    width: '100%',
    maxHeight: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
    '& > img': {
      width: '100%',
    },
  },
  ImageFieldError: {
    marginTop: '6px',
    color: theme.palette.error.main,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  PostPreview: {
    width: '530px',
    height: '172px',
    padding: '8px',
    border: `1px dashed ${theme.palette.secondary.main}`,
    borderRadius: '10px',
    flexWrap: 'nowrap',
  },
  PostPreviewImage: {
    minWidth: '156px',
    maxWidth: '156px',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
      borderRadius: '10px',
    },
  },
  PostPreviewContent: {
    padding: '16px 24px',
    flexGrow: 1,
  },
  PostPreviewTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000',
    lineHeight: 1,
  },
  PostPreviewDescription: {
    marginTop: '12px',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#000',
  },
  PostPreviewLink: {
    marginTop: '12px',
    '& > a': {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontSize: '14px',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },
}))

interface FormImageFieldProps {
  image?: string | null
  onUploadImage: (file: File) => void
}

const FormImageField = ({ image, onUploadImage }: FormImageFieldProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const fileInput: RefObject<HTMLInputElement> = useRef(null)

  const uploadingPostImage = useAppSelector(
    (state) => state.posts.uploadingPostImage
  )
  const uploadPostImageError = useAppSelector(
    (state) => state.posts.uploadPostImageError
  )

  const onClickUploadImage = () => fileInput.current?.click()
  const onChangeInputFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files

    if (files === null || files.length === 0) {
      return
    }

    onUploadImage(files[0])
  }

  return (
    <>
      <label className={classes.ImageFieldLabel}>
        {t('posts.postForm.fields.image.label')}
      </label>
      <Grid container className={classes.ImageFieldContainer}>
        <Grid item className={classes.ImageFieldLeft}>
          {!uploadingPostImage && image && (
            <Box className={classes.ImageFieldImage}>
              <img src={image} alt={t('posts.postForm.fields.image.label')} />
            </Box>
          )}
          {!uploadingPostImage && !image && <ImageIcon />}
          {uploadingPostImage && <CircularProgress />}
        </Grid>
        <Grid item className={classes.ImageFieldRight}>
          <Box className={classes.ImageFieldRightTitle}>
            {t('posts.postForm.fields.image.infoTitle')}
          </Box>
          <Box className={classes.ImageFieldRightInfo}>
            {t('posts.postForm.fields.image.infoContent')}
          </Box>
          <Box className={classes.ImageFieldRightAction}>
            <Button small onClick={onClickUploadImage}>
              {t('posts.postForm.fields.image.uploadButton')}
            </Button>
            <input
              onChange={onChangeInputFile}
              ref={fileInput}
              type="file"
              accept="image/png, image/jpeg"
              hidden
            />
            {renderIf(uploadPostImageError, () => (
              <Box className={classes.ImageFieldError}>
                {t('posts.postForm.errors.imageUpload')}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

interface PostPreviewProps {
  postForm: PostEditable
  imageThumb?: string
}

const PostPreview = ({ postForm, imageThumb }: PostPreviewProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const previewImage = imageThumb ?? postForm.image

  return (
    <Grid container className={classes.PostPreview}>
      <Grid item className={classes.PostPreviewImage}>
        {previewImage && (
          <img src={previewImage} alt={t('posts.previewPost.action')} />
        )}
      </Grid>
      <Grid item className={classes.PostPreviewContent}>
        {postForm.title && (
          <Box className={classes.PostPreviewTitle}>{postForm.title}</Box>
        )}
        {postForm.description && (
          <Box className={classes.PostPreviewDescription}>
            {postForm.description}
          </Box>
        )}
        {postForm.link && (
          <Box className={classes.PostPreviewLink}>
            <a href={postForm.link}>{t('posts.previewPost.linkText')}</a>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

interface PostDrawerFormProps {
  open: boolean
  onClose: () => void
  onSubmit: (form: PostEditable) => void
  title: string
  submitText: string
  initialForm: PostEditable
  imageThumb?: string
}

interface FieldErrors {
  title?: string
  description?: string
  link?: string
}

const PostDrawerForm = ({
  open,
  onClose,
  onSubmit,
  title,
  submitText,
  initialForm,
  imageThumb,
}: PostDrawerFormProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { classes } = useStyles()

  const [postForm, setPostForm] = useState<PostEditable>(initialForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})
  const [showPreview, setShowPreview] = useState(false)

  const { organizationId, siteId } = useCurrentAccount()
  const context = useMemo(
    () => ({ organizationId, siteId }),
    [organizationId, siteId]
  )

  const addedPost = useAppSelector((state) => state.posts.addedPost)
  const updatedPost = useAppSelector((state) => state.posts.updatedPost)
  const uploadedPostImage = useAppSelector(
    (state) => state.posts.uploadedPostImage
  )
  const uploadedPostImageFile = useAppSelector(
    (state) => state.posts.uploadedPostImageFile
  )

  useEffect(() => {
    setPostForm(initialForm)
  }, [initialForm])

  useEffect(() => {
    if (addedPost) {
      onClose()
      setPostForm(initialForm)
      dispatch(resetAddPost())
      dispatch(fetchPosts(context))
    }
  }, [dispatch, onClose, addedPost, initialForm, context])

  useEffect(() => {
    if (updatedPost) {
      onClose()
      setPostForm(initialForm)
      dispatch(resetUpdatePost())
      dispatch(fetchPosts(context))
    }
  }, [dispatch, onClose, updatedPost, initialForm, context])

  useEffect(() => {
    if (uploadedPostImage) {
      setPostForm({ ...postForm, image: uploadedPostImageFile })
      dispatch(resetUploadPostImage())
    }
  }, [uploadedPostImage, uploadedPostImageFile, postForm, dispatch])

  const onUploadImage = (imageFile: File) => {
    dispatch(uploadPostImage({ context, imageFile }))
  }

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setPostForm({ ...postForm, [key]: e.target.value })
    }

  const onChangeSwitch =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setPostForm({ ...postForm, [key]: e.target.checked })
    }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    let hasError = false
    const errors: FieldErrors = {}

    if (postForm.title === '') {
      hasError = true
      errors.title = t('posts.postForm.errors.emptyField')
    }

    if (!hasError) {
      onSubmit(postForm)
    }

    setFieldErrors(errors)
  }

  const onClickPreview = () => setShowPreview(true)
  const onClosePreview = () => setShowPreview(false)

  const extraActions = [
    {
      Icon: EyeIcon,
      text: t('posts.previewPost.action'),
      onClick: onClickPreview,
    },
  ]

  return (
    <>
      <DrawerForm
        open={open}
        onClose={onClose}
        title={title}
        submitText={submitText}
        onSubmit={onSubmitForm}
        extraActions={extraActions}
      >
        <Box className={classes.PostDrawerFormFields}>
          <FormField
            label={t('posts.postForm.fields.title.label')}
            placeholder={t('posts.postForm.fields.title.placeholder')}
            value={postForm.title}
            onChange={onChangeField('title')}
            error={fieldErrors.title}
            autoFocus
          />
          <FormField
            label={t('posts.postForm.fields.description.label')}
            placeholder={t('posts.postForm.fields.description.placeholder')}
            value={postForm.description}
            onChange={onChangeField('description')}
            error={fieldErrors.description}
            multiline
          />
          <FormField
            type="url"
            label={t('posts.postForm.fields.link.label')}
            placeholder={t('posts.postForm.fields.link.placeholder')}
            value={postForm.link}
            onChange={onChangeField('link')}
            error={fieldErrors.link}
            info={{ example: 'https://www.gethublet.com/' }}
          />
          <FormSwitch
            title={t('posts.postForm.fields.fullscreen.title')}
            description={t('posts.postForm.fields.fullscreen.description')}
            checked={postForm.fullscreen}
            onChange={onChangeSwitch('fullscreen')}
            variant="drawer"
          />
          <FormImageField
            image={postForm.image}
            onUploadImage={onUploadImage}
          />
        </Box>
      </DrawerForm>
      <ContentDialog
        open={showPreview}
        title={t('posts.previewPost.title')}
        onClose={onClosePreview}
      >
        <PostPreview postForm={postForm} imageThumb={imageThumb} />
      </ContentDialog>
    </>
  )
}

export default PostDrawerForm

import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import loginBackgroundImage from 'common/login-bg.jpg'
import logo from 'common/hublet-manager-logo.svg'
import SelectLanguage from 'App/Login/components/SelectLanguage'
import { useAppSelector } from 'store'

const LeftSide = styled(Box)(({ dir }) => ({
  height: '100vh',
  objectFit: 'cover',
  '& img': {
    height: '100%',
    marginRight: '3rem',
    ...(dir === 'rtl' && {
      '-webkit-transform': 'scaleX(-1)',
      transform: 'scaleX(-1)',
    }),
  },

  '@media (max-width: 75rem)': {
    display: 'none',
  },
  overflow: 'hidden',
}))

const LogoBox = styled(Box)({
  marginBottom: 5,
  '& img': {
    width: '173px',
    height: '160px',
  },
})

const RightSide = styled(Box)(({ theme }) => ({
  width: '43.75rem',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  padding: theme.spacing(8, 10),
  position: 'relative',
  overflowY: 'scroll',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 5),
  },
}))

const ContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  padding: 0,
  margin: 0,
  backgroundColor: theme.palette.sandYellow.light,
}))

const LoginPageWrapper = ({
  children,
  title = '',
}: {
  children: React.ReactNode
  title?: string
}) => {
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  return (
    <ContainerStyled dir={languageDirection}>
      <LeftSide>
        <img
          src={loginBackgroundImage}
          alt="Login background"
          style={{ height: '100%', marginRight: '3rem' }}
        />
      </LeftSide>
      <RightSide>
        <SelectLanguage />

        <LogoBox>
          <img src={logo} alt="Hublet logo" />
        </LogoBox>

        {title && (
          <Typography variant="h6" gutterBottom marginBottom={5}>
            {title}
          </Typography>
        )}

        {children}
      </RightSide>
    </ContainerStyled>
  )
}

export default LoginPageWrapper

import React, { type ForwardRefRenderFunction } from 'react'
import { ButtonBase, type ButtonBaseProps, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'
import { type SvgIconComponent } from '@mui/icons-material'

const useStyles = makeStyles()((theme: Theme) => ({
  IconAction: {
    display: 'inline-block',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '32px',
    cursor: 'pointer',
    borderRadius: '100px',
    '&:hover': {
      backgroundColor: '#F8F8FB',
    },
  },
  Icon: {
    verticalAlign: 'middle',
  },
  IconVariantWhite: {
    color: '#fff',
    filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
    '&:hover, &:focus, &:active': {
      filter: 'none',
    },
    '&:hover': {
      backgroundColor: '#F8F8FB',
    },
  },
}))

interface IconActionProps extends ButtonBaseProps {
  Icon: SvgIconComponent
  variant?: 'white'
}

type IconActionT = ForwardRefRenderFunction<HTMLButtonElement, IconActionProps>

const IconAction: IconActionT = (
  { className, Icon, variant, onClick }: IconActionProps,
  ref
) => {
  const { classes, cx } = useStyles()
  const buttonClassNames = [classes.IconAction]

  if (variant === 'white') {
    buttonClassNames.push(classes.IconVariantWhite)
  }

  if (className) {
    buttonClassNames.push(className)
  }

  return (
    <ButtonBase ref={ref} className={cx(buttonClassNames)} onClick={onClick}>
      <Icon className={classes.Icon} />
    </ButtonBase>
  )
}

export default React.forwardRef(IconAction)

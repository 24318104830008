import React, { type PropsWithChildren } from 'react'
import { type Theme, useTheme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  ErrorBox: {
    padding: '20px 24px',
    borderRadius: '5px',
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    fontSize: '16px',
    lineHeight: '22px',
  },
}))

interface ErrorBoxProps extends PropsWithChildren {
  spacingTop?: number
  spacingBottom?: number
}

const ErrorBox = ({ children, spacingTop, spacingBottom }: ErrorBoxProps) => {
  const theme = useTheme()
  const { classes } = useStyles()

  const style: React.CSSProperties = {}

  if (spacingTop !== undefined) {
    style.marginTop = theme.spacing(spacingTop)
  }

  if (spacingBottom !== undefined) {
    style.marginBottom = theme.spacing(spacingBottom)
  }

  return (
    <div className={classes.ErrorBox} style={style}>
      {children}
    </div>
  )
}

export default ErrorBox

import React from 'react'
import { Box, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'
import { JSONTree } from 'react-json-tree'

const useStyles = makeStyles()((theme: Theme) => ({
  JsonView: {
    fontSize: '12px',
    whiteSpace: 'pre',
  },
}))

/**
 * Source: https://github.com/reduxjs/redux-devtools/blob/75322b15ee7ba03fddf10ac3399881e302848874/src/react/themes/default.js
 */
const jsonTreeTheme = {
  scheme: 'default',
  author: 'chris kempson (http://chriskempson.com)',
  base00: '#181818',
  base01: '#282828',
  base02: '#383838',
  base03: '#585858',
  base04: '#b8b8b8',
  base05: '#d8d8d8',
  base06: '#e8e8e8',
  base07: '#f8f8f8',
  base08: '#ab4642',
  base09: '#dc9656',
  base0A: '#f7ca88',
  base0B: '#a1b56c',
  base0C: '#86c1b9',
  base0D: '#7cafc2',
  base0E: '#ba8baf',
  base0F: '#a16946',
}

interface JsonViewProps {
  object?: object
}

const JsonView = ({ object }: JsonViewProps) => {
  const { classes: styles } = useStyles()

  return (
    <Box className={styles.JsonView}>
      <JSONTree
        data={object}
        invertTheme={true}
        theme={{
          extend: jsonTreeTheme,
          tree: {
            backgroundColor: 'transparent',
          },
          background: '#F8F8FB',
        }}
        shouldExpandNodeInitially={(_keyName, _data, level) => level < 6}
      />
    </Box>
  )
}

export default JsonView

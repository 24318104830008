import React, { useCallback, useMemo, useState } from 'react'
import {
  Alert,
  AlertColor,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Snackbar,
  SnackbarCloseReason,
} from '@mui/material'
import {
  DataGrid,
  GridRowId,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid'
import { useAppDispatch, useAppSelector } from 'store'
import { columns } from './columns'
import useDocks from 'common/hooks/useDocks'
import CustomDrawer from 'common/components/Drawer2'
import CustomTabs from 'common/components/Tabs2'
import { Dock } from 'App/Docks/docks-types'
import DockGeneral from 'App/Docks/components/DockGeneral'
import DockConnectedTablets from 'App/Docks/components/DockConnectedTablets'
import DockLocation from 'App/Docks/components/DockLocation'
import DockActions from 'App/Docks/components/DockActions'
import DockTest from 'App/Docks/components/DockTest'
import useApp from 'common/hooks/useApp'
import {
  useDeleteDocksMutation,
  useRebootDocksMutation,
  useShutdownDocksMutation,
} from 'App/Docks/docks-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { ConfirmationDialog } from 'App/DockingStations/ConfirmationDialog'
import ActionsToolbar from 'App/DockingStations/ActionsToolbar'
import { fetchDocks } from 'App/Docks/docks-state'
import { dedup } from 'App/DockingStations/DockingStations.utils'
import DocksReactContext from 'App/Docks/docks-react-context'
import DownloadButton from 'App/Analytics/DownloadButton'

export const DockingStationsComponent = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0, // first page
  })
  const [excludeDeleted, setExcludeDeleted] = useState(false)
  const [compactView, setCompactView] = useState(false)
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])
  const [activeDock, setActiveDock] = useState<Dock>()
  const [showDrawer, setShowDrawer] = useState(false)
  // TODO: refactor snackbar to root App for better reuse across the app?
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [snackbarVariant, setSnackbarVariant] = useState<AlertColor>('info')
  // TODO: refactor ConfirmationDialog for better reuse?
  const [showRebootConfirmationDialog, setShowRebootConfirmationDialog] =
    useState(false)
  const [showShutdownConfirmationDialog, setShowShutdownConfirmationDialog] =
    useState(false)
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false)
  const apiRef = useGridApiRef()
  const { adminMode } = useApp()
  const { organizationId, siteId } = useCurrentAccount()
  const [rebootDocks] = useRebootDocksMutation()
  const [shutdownDocks] = useShutdownDocksMutation()
  // TODO: confirm if 'undeleteDocks' is needed
  const [deleteDocks] = useDeleteDocksMutation()
  const dispatch = useAppDispatch()
  const fetchedDocks = useAppSelector((state) => state.docks.fetchedDocks)

  const docks = useDocks('manufacturer')

  const filteredDocks = useMemo(() => {
    if (!docks) {
      return []
    }

    const { cleanedResult, duplicatedDockIds } = dedup(
      excludeDeleted ? docks.filter((item) => !item.deletedAt) : docks
    )
    if (duplicatedDockIds.length > 0) {
      console.warn('There are duplicated docks', duplicatedDockIds)
    }
    return cleanedResult
  }, [docks, excludeDeleted])

  const handleDrawerClose = () => {
    setShowDrawer(false)
    setActiveDock(undefined)
  }

  const handleViewDetails = useCallback(() => {
    // TODO: double-check if we want to show deleted dock
    setShowDrawer(true)
    const dockId = selectedRows[0] as number | string
    // @ts-expect-error there is a probability that dock.id is string, but it shouldn't prevent showing data
    setActiveDock(filteredDocks.find((item) => item.id === dockId))
  }, [filteredDocks, selectedRows])

  if (!fetchedDocks) {
    return <CircularProgress />
  }

  const handleReboot = () => {
    // remove deleted docks before taking action
    const dockIds = selectedRows.filter(
      (item) => typeof item === 'number'
    ) as number[]
    rebootDocks({
      dockIds,
      context: 'manufacturer',
      organizationId,
      siteId,
    })
      .unwrap()
      .then(() => showFeedback('Selected docks have been rebooted', 'success'))
      .catch((error) => {
        showFeedback('Failed rebooting selected docks', 'error')
        console.log('Failed rebooting docks', error)
      })
      .finally(() => setShowRebootConfirmationDialog(false))
  }

  const handleShutdown = () => {
    // remove deleted docks before taking action
    const dockIds = selectedRows.filter(
      (item) => typeof item === 'number'
    ) as number[]
    shutdownDocks({
      dockIds,
      context: 'manufacturer',
      organizationId,
      siteId,
    })
      .unwrap()
      .then(() => showFeedback('Selected docks have been shut down', 'success'))
      .catch((error) => {
        showFeedback('Failed shutting down selected docks', 'error')
        console.log('Failed shutting down docks', error)
      })
      .finally(() => setShowShutdownConfirmationDialog(false))
  }

  const handleDelete = () => {
    // remove deleted docks before taking action
    const dockIds = selectedRows.filter(
      (item) => typeof item === 'number'
    ) as number[]
    deleteDocks({
      dockIds,
      context: 'manufacturer',
      organizationId,
      siteId,
    })
      .unwrap()
      .then(() => {
        dispatch(
          fetchDocks({ organizationId, siteId, context: 'manufacturer' })
        )
        setSelectedRows([])
        showFeedback('Docks have been deleted', 'success')
      })
      .catch((error) => {
        showFeedback('Failed deleting docks', 'error')
        console.log('Failed deleting docks', error)
      })
      .finally(() => setShowDeleteConfirmationDialog(false))
  }

  const onRowSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel
  ): void => {
    setSelectedRows(rowSelectionModel as GridRowId[])
  }

  const showFeedback = (msg: string, variant: AlertColor) => {
    setShowSnackbar(true)
    setSnackbarMsg(msg)
    setSnackbarVariant(variant)
  }

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setShowSnackbar(false)
    setSnackbarMsg('')
  }

  return (
    <Box flexDirection="column" sx={{ width: '100%' }}>
      <Box>
        Docking stations under the &apos;manufacturer&apos; context. Select one
        or multiple row to see actions
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
        mb={2}
      >
        <Box display="flex" gap={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={compactView}
                onChange={(e) => setCompactView(e.target.checked)}
              />
            }
            label="Compact view"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeDeleted}
                onChange={(e) => setExcludeDeleted(e.target.checked)}
              />
            }
            label="Exclude deleted"
          />
        </Box>

        <Box>
          <DownloadButton
            title="Export to CSV"
            apiRef={apiRef}
            fileName="Hublet-docking-stations-manufacturer"
            disabled={!filteredDocks.length}
          />
        </Box>
      </Box>

      <Box sx={{ height: 628, width: '100%' }}>
        <DataGrid
          rows={filteredDocks}
          columns={columns}
          apiRef={apiRef}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
          density={compactView ? 'compact' : 'standard'}
          onRowSelectionModelChange={onRowSelectionModelChange}
          slots={{
            toolbar: () => (
              <ActionsToolbar
                selectedRows={selectedRows}
                onDelete={() => setShowDeleteConfirmationDialog(true)}
                onReboot={() => setShowRebootConfirmationDialog(true)}
                onShutdown={() => setShowShutdownConfirmationDialog(true)}
                onViewDetails={handleViewDetails}
              />
            ),
          }}
        />
      </Box>

      {/* We need this context since we are using old components in tabs */}
      <DocksReactContext.Provider value="manufacturer">
        <CustomDrawer
          title="Docking station"
          open={showDrawer}
          onClose={handleDrawerClose}
        >
          <CustomTabs
            tabs={[
              {
                label: 'General',
                component: <DockGeneral dock={activeDock} />,
              },
              {
                label: 'Connected tablets',
                // @ts-expect-error false alert since the drawer won't be opened if activeDock is undefined
                component: <DockConnectedTablets dock={activeDock} />,
              },
              {
                label: 'Location',
                component: <DockLocation dock={activeDock} />,
              },
              ...(adminMode
                ? [{ label: 'Test', component: <DockTest dock={activeDock} /> }]
                : []),
              {
                label: 'Actions',
                component: <DockActions dock={activeDock} />,
              },
            ]}
          />
        </CustomDrawer>
      </DocksReactContext.Provider>

      <ConfirmationDialog
        text={`Do you want to reboot docks ${JSON.stringify(selectedRows)}?`}
        open={showRebootConfirmationDialog}
        cancelBtnAction={() => setShowRebootConfirmationDialog(false)}
        confirmBtnAction={handleReboot}
      />
      <ConfirmationDialog
        text={`Do you want to shutdown docks ${JSON.stringify(selectedRows)}?`}
        open={showShutdownConfirmationDialog}
        cancelBtnAction={() => setShowShutdownConfirmationDialog(false)}
        confirmBtnAction={handleShutdown}
      />
      <ConfirmationDialog
        text={`Do you want to delete docks ${JSON.stringify(selectedRows)}?`}
        open={showDeleteConfirmationDialog}
        cancelBtnAction={() => setShowDeleteConfirmationDialog(false)}
        confirmBtnAction={handleDelete}
      />

      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarVariant}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default DockingStationsComponent

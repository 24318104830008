export const FEATURE_FLAG_KEY = 'hublet-features'

/**
 * function to get all features from localStorage, return an object
 */
export const getAllFeatures = (): Record<string, boolean> => {
  return JSON.parse(localStorage.getItem(FEATURE_FLAG_KEY) || '{}')
}

/**
 * function to enable a feature flag and store it to localStorage
 */
export const enableFeature = (featureName: string) => {
  const featuresInStorage = getAllFeatures()
  featuresInStorage[featureName] = true
  localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(featuresInStorage))
}

/**
 * function to enable multiple feature flags and store them to localStorage in bulk
 */
export const enableFeaturesInBulk = (features: string[]) => {
  const featuresInStorage = getAllFeatures()
  features.forEach((feature) => feature && (featuresInStorage[feature] = true))
  localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(featuresInStorage))
}

/**
 * function to disable a feature flag and remove it from localStorage
 */
export const disableFeature = (featureName: string) => {
  const featuresInStorage = getAllFeatures()
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete featuresInStorage[featureName]
  localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(featuresInStorage))
}

/**
 * function to disable multiple feature flags and store them to localStorage in bulk
 */
export const disableFeaturesInBulk = (features: string[]) => {
  const featuresInStorage = getAllFeatures()
  features.forEach((feature) => feature && (featuresInStorage[feature] = false))
  localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(featuresInStorage))
}

/**
 * function to check if a feature is enabled (has true value in localStorage)
 */
export const isFeatureEnabled = (featureName: string) => {
  const featuresInStorage = getAllFeatures()
  return featuresInStorage[featureName]
}

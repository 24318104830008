import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type FetchLoansParams,
  type Loan,
  type LoansContext,
} from 'App/Loans/loans-types'
import { type RowData } from 'App/Analytics/Analytics.types'
import { v4 as uuidv4 } from 'uuid'

const dataToLoanObject = (data: any): Loan => ({
  id: data.id,
  siteId: data.siteId,
  startTime: new Date(data.startTime),
  endTime: new Date(data.endTime),
  status: data.status,
  profileName: data.profileName,
  profileSource: data.profileSource ?? undefined,
  tabletActiveAt: data.tabletActiveAt
    ? new Date(data.tabletActiveAt)
    : undefined,
  tabletSerialNumber: data.tabletSerialNumber,
  userName: data.userName,
  pinCode: data.pinCode,
  returnTime: data.returnTime ? new Date(data.returnTime) : undefined,
  userDefaultLanguage: data.userDefaultLanguage,
})

class LoansApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchLoans({
    organizationId,
    siteId,
  }: LoansContext): Promise<Loan[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/loans`
    const { data } = await axios.get(apiUrl)

    return data.map(dataToLoanObject)
  }

  static async fetchLoansStatisticsWithParams(
    params: FetchLoansParams
  ): Promise<RowData[]> {
    const apiUrl = `${API_BASE_URL}/analytics`
    const { data }: { data: RowData[] } = await axios.get(apiUrl, {
      params,
    })

    if (params.groupByTime) {
      // update ID with tgroup so that the ID is unique to be shown correctly with Data Grid
      // tgroup value is a date formatted in string YYYY/MM/DD
      return data.map(({ id, tgroup, ...rest }) => ({
        // in case of id is null, we generate a unique id with '0' as a prefix
        // unique id is needed so that each row in DataGrid will be unique
        // prefix '0' will let us know the original id was null (in case we need it),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        id: id ? `${id}#${tgroup}` : `0${uuidv4()}#${tgroup}`,
        tgroup,
        ...rest,
      })) as RowData[]
    }

    return (
      // in case of id is null, we generate a unique id with '0' as a prefix
      // unique id is needed so that each row in DataGrid will be unique
      // prefix '0' will let us know the original id was null (in case we need it),
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      data.map(({ id, ...rest }) => ({ id: id ?? `0${uuidv4()}`, ...rest })) ??
      []
    )
  }
}

export default LoansApi

import React from 'react'
import {
  Dialog,
  styled,
  Box,
  IconButton,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { resetShowCode } from 'App/Codes/codes-state'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store'

interface ShowCodeDialogProps {
  code?: string
  onClose?: () => void
}

const CodeChar = styled(Box)(({ theme }) => ({
  width: '60px',
  height: '70px',
  float: 'left',
  textAlign: 'center',
  lineHeight: '70px',
  borderRadius: '10px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
  backgroundColor: '#fff',
  fontSize: '48px',
  fontWeight: 'bold',
}))

const DialogShowCode = ({ code, onClose }: ShowCodeDialogProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(resetShowCode())
    onClose?.()
  }

  if (!code) {
    return null
  }

  return (
    <Dialog open={!!code} onClose={handleClose}>
      <DialogTitle>{t('codes.showCode.title')}</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '10px',
          top: '12px',
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        {code?.split('').map((c, i) => <CodeChar key={i}>{c}</CodeChar>)}
      </DialogContent>
    </Dialog>
  )
}

export default DialogShowCode

// TODO: fix eslint when possible
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  fetchManagedConfiguration,
  resetUpdateManagedConfiguration,
  updateManagedConfiguration,
} from 'App/Tablets/tablets-state'
import {
  type TabletAppSettings,
  type UpdateManagedConfiguration,
} from 'App/Tablets/tablets-types'
import FormField from 'common/components/FormField'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { renderItems } from 'common/utils/render-utils'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import Button from 'common/components/Button'
import { useTranslation } from 'react-i18next'
import Toast from 'common/components/Toast'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  Table: {
    borderSpacing: '2px',
    width: '100%',
    '& th': {
      textAlign: 'left',
      fontWeight: 'bold',
    },
  },
  ColRemove: {
    width: '24px',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: 1,
    '& > span': {
      cursor: 'pointer',
      color: theme.palette.error.main,
    },
  },
  AddRowButton: {
    width: '100%',
    borderRadius: '5px',
    backgroundColor: '#fff',
    border: `1px solid #e0e0e0`,
    textAlign: 'center',
  },
  FormError: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
}))

interface ManagedConfigurationsTableProps {
  tabletId: number
}

const ManagedConfigurationsTable = ({
  tabletId,
}: ManagedConfigurationsTableProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const managedConfiguration = useAppSelector(
    (state) => state.tablets.managedConfiguration
  )
  const fetchedManagedConfiguration = useAppSelector(
    (state) => state.tablets.fetchedManagedConfiguration
  )
  const updatedManagedConfiguration = useAppSelector(
    (state) => state.tablets.updatedManagedConfiguration
  )

  const [toCreateAppSettings, setToCreateAppSettings] = useState<
    TabletAppSettings[]
  >([])
  const [toUpdateAppSettings, setToUpdateAppSettings] = useState<
    Record<number, Partial<TabletAppSettings>>
  >([])
  const [toDeleteAppSettings, setToDeleteAppSettings] = useState<number[]>([])

  const [formError, setFormError] = useState<string | undefined>()

  const dataWithUpdates = useMemo(() => {
    return managedConfiguration.map((mc) => {
      if (toUpdateAppSettings[mc.id] !== undefined) {
        return {
          ...mc,
          ...toUpdateAppSettings[mc.id],
        }
      } else {
        return mc
      }
    })
  }, [managedConfiguration, toUpdateAppSettings])

  const dataWithoutDeleted = useMemo(() => {
    return dataWithUpdates.filter(({ id }) => {
      return !toDeleteAppSettings.includes(id)
    })
  }, [dataWithUpdates, toDeleteAppSettings])

  const data = useMemo(() => {
    return dataWithoutDeleted.concat(toCreateAppSettings)
  }, [dataWithoutDeleted, toCreateAppSettings])

  const { organizationId, siteId } = useCurrentAccount()

  useEffect(() => {
    setToCreateAppSettings([])
    setToDeleteAppSettings([])
    setToUpdateAppSettings([])
  }, [fetchedManagedConfiguration])

  useEffect(() => {
    dispatch(fetchManagedConfiguration({ organizationId, siteId, tabletId }))
  }, [dispatch, organizationId, siteId, tabletId])

  useEffect(() => {
    if (updatedManagedConfiguration) {
      dispatch(resetUpdateManagedConfiguration())
      dispatch(fetchManagedConfiguration({ organizationId, siteId, tabletId }))
    }
  }, [dispatch, organizationId, siteId, tabletId, updatedManagedConfiguration])

  const onChangeAppSettings = (
    id: number,
    field: 'key' | 'value',
    value: string
  ) => {
    if (id >= 0) {
      const updateExisting = {
        ...toUpdateAppSettings,
        [id]: {
          ...toUpdateAppSettings[id],
          id,
          tabletId,
          [field]: value,
        },
      }

      setToUpdateAppSettings(updateExisting)
    } else {
      const updateCreated = toCreateAppSettings.map((item) => {
        if (item.id !== id) {
          return item
        }

        return {
          ...item,
          [field]: value,
        }
      })

      setToCreateAppSettings(updateCreated)
    }
  }

  const onClickAddRow = () => {
    const appSettings: TabletAppSettings = {
      // Ensure a temporary unique id for new rows
      id: -1 * (toCreateAppSettings.length + 1),
      tabletId,
      key: '',
      value: '',
    }
    setToCreateAppSettings([...toCreateAppSettings, appSettings])
  }

  const onClickDeleteRow = (appSettingsId: number) => () => {
    if (appSettingsId >= 0) {
      setToDeleteAppSettings([...toDeleteAppSettings, appSettingsId])
    } else {
      setToCreateAppSettings(
        toCreateAppSettings.filter(({ id }) => id !== appSettingsId)
      )
    }
  }

  const onClickSave = () => {
    const saveData: UpdateManagedConfiguration = {
      create: toCreateAppSettings.map(({ tabletId, key, value }) => ({
        tabletId,
        key,
        value,
      })), // Remove temp id
      update: Object.values(toUpdateAppSettings),
      delete: toDeleteAppSettings,
    }

    const hasEmptyFields = data.some(({ key, value }) => {
      if (key === undefined || key.trim() === '') {
        return true
      }

      if (value === undefined || value.trim() === '') {
        return true
      }

      return false
    })

    if (hasEmptyFields) {
      setFormError(
        t('tablets.viewTablet.managedConfiguration.errors.emptyFields')
      )
      return
    }

    setFormError(undefined)
    dispatch(
      updateManagedConfiguration({
        tabletId,
        organizationId,
        siteId,
        data: saveData,
      })
    )
  }

  if (fetchedManagedConfiguration === undefined) {
    return null
  }

  return (
    <>
      <table className={classes.Table}>
        <thead>
          <tr>
            <th>{t('tablets.viewTablet.managedConfiguration.fields.key')}</th>
            <th>{t('tablets.viewTablet.managedConfiguration.fields.value')}</th>
            <th className={classes.ColRemove}></th>
          </tr>
        </thead>
        <tbody>
          {renderItems(data, ({ id, key, value }) => (
            <tr>
              <td>
                <FormField
                  value={key}
                  onChange={(e) => {
                    onChangeAppSettings(id, 'key', e.target.value)
                  }}
                  dense
                />
              </td>
              <td>
                <FormField
                  value={value}
                  onChange={(e) => {
                    onChangeAppSettings(id, 'value', e.target.value)
                  }}
                  dense
                />
              </td>
              <td className={classes.ColRemove}>
                <span
                  title={t('tablets.viewTablet.managedConfiguration.removeRow')}
                  onClick={() => onClickDeleteRow(id)}
                >
                  <DeleteIcon />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <td colSpan={2}>
            <Button
              className={classes.AddRowButton}
              small
              outlined
              onClick={onClickAddRow}
            >
              + {t('tablets.viewTablet.managedConfiguration.addRow')}
            </Button>
          </td>
        </tfoot>
      </table>
      {formError !== undefined ? (
        <div className={classes.FormError}>{formError}</div>
      ) : null}
      <br />
      <Button small onClick={onClickSave}>
        {t('tablets.viewTablet.managedConfiguration.save')}
      </Button>

      <Toast
        open={updatedManagedConfiguration}
        message={t('tablets.viewTablet.managedConfiguration.toast.success')}
      />
    </>
  )
}

export default ManagedConfigurationsTable

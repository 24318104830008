import {
  connectedTabletsCount,
  dockSlotsCount,
} from 'App/Docks/components/DockSlotsInfo'
import * as datefns from 'date-fns'
import { GridColDef } from '@mui/x-data-grid'
import { Dock } from 'App/Docks/docks-types'
import { isDockOnline } from 'App/Docks/components/DockStatus'

// TODO: improve type GridColDef?
export const columns: GridColDef<any>[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 55,
    valueGetter: (value: string | number) =>
      typeof value === 'string' ? `${value.split('#')[0]} (deleted)` : value,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    flex: 1,
    valueGetter: (value) => value ?? '-',
  },
  {
    field: 'serialNumber',
    headerName: 'Serial number',
    width: 120,
    valueGetter: (value) => value ?? '-',
  },
  {
    field: 'manufactureDate',
    headerName: 'Manufactured date',
    type: 'string',
    width: 160,
    valueGetter: (value: string) =>
      value ? datefns.format(new Date(value), 'yyyy/MM/dd HH:mm') : '-',
  },
  {
    field: 'keepaliveLast', // we also have 'networkUpdatedAt'
    headerName: 'Last active',
    type: 'string',
    width: 160,
    valueGetter: (value: string) =>
      value ? datefns.format(new Date(value), 'yyyy/MM/dd HH:mm') : '-',
  },
  {
    field: 'connectedTablets',
    headerName: 'Tablets',
    type: 'string',
    width: 65,
    valueGetter: (value, row: Dock) =>
      `${connectedTabletsCount(row)} / ${dockSlotsCount(row)}`,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    width: 65,
    valueGetter: (value, row: Dock) =>
      isDockOnline(row) ? 'online' : 'offline',
  },
  {
    field: 'stockStatus',
    headerName: 'Stock status',
    type: 'string',
    width: 140,
    valueGetter: (value: number) => {
      // these status is based on enum DockStockStatus on docks-types.ts file
      switch (value) {
        case 0:
          return 'Commissioned'
        case 1:
          return 'Manufactured'
        case 2:
          return 'Assigned'
        case 3:
          return 'Decommissioned'
        case 4:
          return 'Returned'
        default:
          return '-'
      }
    },
  },
]

import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material'
import { StyledMenuItemButton, StyledMenuItemIcon } from './SideMenu2.styled'
import { SideMenuItem } from './SideMenu2.types'
import {
  ChevronRightRounded,
  ExpandMoreRounded,
  HelpOutlineRounded,
} from '@mui/icons-material'

interface MainMenuItemProps {
  item: SideMenuItem
  selected?: boolean
  onClick: () => void
  collapsed?: boolean
}

const MainMenuItem = ({
  item,
  selected,
  onClick,
  collapsed,
}: MainMenuItemProps) => {
  const { Icon, label, subItems } = item

  return (
    <ListItem
      disablePadding
      secondaryAction={
        item.disabled ? (
          <Tooltip title={item.disabledNote} placement="left">
            <IconButton edge="end" size="small">
              <HelpOutlineRounded />
            </IconButton>
          </Tooltip>
        ) : null
      }
    >
      <StyledMenuItemButton
        selected={selected}
        disabled={Boolean(item.disabled)}
        onClick={onClick}
      >
        {Icon && (
          <StyledMenuItemIcon>
            <Icon />
          </StyledMenuItemIcon>
        )}

        <ListItemText
          primary={label}
          primaryTypographyProps={{ fontWeight: 'bold' }}
        />

        {!subItems?.length || Boolean(item.disabled) ? null : collapsed ? (
          <ExpandMoreRounded />
        ) : (
          <ChevronRightRounded />
        )}
      </StyledMenuItemButton>
    </ListItem>
  )
}

export default MainMenuItem

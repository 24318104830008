import { getSupportedLocale } from '@hublet/react-components'
import getDateFnsLocale from 'common/utils/get-date-fns-locale'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as dataGridLocales from '@mui/x-data-grid/locales'

const DEFAULT_LOCALE = 'enUS'

export const useMuiThemeLocale = () => {
  const [dateFnsLocale, setDateFnsLocale] = useState(
    getDateFnsLocale(DEFAULT_LOCALE)
  )
  const [themeLocale, setThemeLocale] = useState(DEFAULT_LOCALE)
  const [dataGridLocale, setDataGridLocale] = useState(
    dataGridLocales[DEFAULT_LOCALE]
  )
  const { i18n } = useTranslation()

  useEffect(() => {
    const supportedLocale = getSupportedLocale(i18n.language)
    setThemeLocale(supportedLocale)
    setDataGridLocale(
      // @ts-expect-error: correct typing with fallback
      dataGridLocales[supportedLocale] ?? dataGridLocales[DEFAULT_LOCALE]
    )
    setDateFnsLocale(getDateFnsLocale(i18n.language))
  }, [i18n.language])

  return { themeLocale, dateFnsLocale, dataGridLocale }
}

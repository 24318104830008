import { TrackRequestProps } from 'common/hooks/useTracking.types'
import { RootState } from 'store'
import { UAParser } from 'ua-parser-js'

const uaParser = new UAParser()

// export this for using in non-React component (e.g. redux)
export const checkIsMobile = () => {
  if (navigator.userAgentData) {
    return navigator.userAgentData.mobile
  }

  const device = uaParser.getDevice()
  return ['mobile', 'tablet', 'wearable'].includes(device.type!)
}

// export this for using in non-React component (e.g. redux)
export const getBrowser = () => {
  return uaParser.getBrowser()
}

export const getCommonTrackingData = (
  state: RootState
): Partial<TrackRequestProps> => {
  const { currentUser, ipAddress, city, country } = state.app
  const {
    role: userRole,
    id: userId,
    organizationId: orgId,
  } = currentUser ?? {}

  const isMobile = checkIsMobile()
  const browser = getBrowser()

  return {
    isMobile,
    browser: browser.name,
    browserVersion: browser.version,
    userId,
    userRole,
    orgId,
    ipAddress,
    city,
    country,
  }
}

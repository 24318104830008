export enum IntegrationProxyType {
  None = 'none',
  Cloud = 'cloud',
  Dock = 'docking stations',
}

export enum VirtualKeyboard {
  None = 'none',
  Numeric = 'numeric',
  Alphanumeric = 'alphanumeric',
}

export enum IntegrationMode {
  AstecNcip = 'integration.template.astecNcip',
  Capita = 'integration.template.capita',
  FilmFriendsx = 'integration.template.filmfriends',
  SIP2 = 'integration.template.sip2',
  Codes = 'integration.template.codes',
  Tester = 'integration.template.tester',
  Open = 'integration.template.open',
  Simple = 'integration.template.simple',
  Innovative = 'integration.template.innovative',
  Koha = 'integration.template.koha',
  Libero = 'integration.template.libero',
  Micromarc = 'integration.template.micromarc',
  Sierra = 'integration.template.sierra',
  SpydusLCF = 'integration.template.spydusLcf',
  SpydusUK = 'integration.template.spydusUk',
  Vubis = 'integration.template.vubis',
  Custom = 'custom',
}

export interface Integration {
  id: number
  organizationId: number
  siteId: number
  name: string
  communicationConfigurationId?: number
  authApiUrl?: string
  integrationParams?: IntegrationParameter[]
  integrationParameterFormFields?: IntegrationParameter[]
  templateKey?: string
  debugMode: boolean
  debugModeExpiration?: string
  proxyType: IntegrationProxyType
  isEnabled: boolean
  usernameKeyboard: VirtualKeyboard
  passwordKeyboard: VirtualKeyboard
  canBorrowSuccess?: string
  canBorrowFailed?: string
  checkoutSuccess?: string
  checkoutFailed?: string
  checkinSuccess?: string
  checkinFailed?: string
  didBorrowSuccess?: string
  didBorrowFailed?: string
  didReturnSuccess?: string
  didReturnFailed?: string
}

export interface IntegrationEditable {
  id?: number
  name?: string
  communicationConfigurationId?: number
  authApiUrl?: string
  integrationParams?: IntegrationParameter[]
  integrationParameterFormFields?: IntegrationParameter[]
}

export enum ParamDataType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Secure = 'secure',
  SelectOne = 'selectOne',
  SelectMulti = 'selectMulti',
  Profile = 'profile',
}

export interface IntegrationParameter {
  paramKey: string
  paramValue: string
  paramType: string
  paramDataType: ParamDataType
  required: boolean
  label: string
  hint?: string
  default?: string | number | boolean
  values?: Array<string | number>
}

export interface IntegrationPlugin {
  id: number
  pluginName: string
}

export interface CommunicationConfiguration {
  id: number
  integrationId: number
  proxyType: string
  debugModeExpiration: string | null
}

export enum ProxyTlsVersion {
  TlsV1_2 = 'TLSv1.2',
  TlsV1_3 = 'TLSv1.3',
}

export interface CommunicationChannel {
  id: number | undefined
  communicationConfigurationId: number
  proxyServerAddress: string
  proxyServerPort: number
  proxyPass: boolean
  proxyRootCertificate: string
  proxyClientCertificate: string
  proxyClientKey?: string
  proxyProtocol: string
  proxyTlsVersion?: ProxyTlsVersion
  number: number
  createdAt: Date | undefined
  updatedAt: Date | undefined
}

export interface DebugLog {
  level?: string
  authConfigurationId?: number
  dockId?: string
  summary?: string
  details?: Record<string, any>
  timestamp?: number
}

export interface IntegrationTemplate {
  id: number
  key: string
  url: string
}

export interface IntegrationTest {
  id: number
  username: string
  password?: string
  authConfigId: number
  isValid: boolean
  tabletSerialNumber?: string
  profileId?: number
}

export interface IntegrationTestAction {
  action: 'can-borrow' | 'do-borrow' | 'do-return' | 'checkout' | 'checkin'
  test: IntegrationTest
}

export interface AddIntegrationData {
  name: string
  url: string
}

export interface IntegrationInputRule {
  id: number
  authConfigurationId: number
  match: string
  replaceWith: string
}

import React, { useEffect, useMemo } from 'react'
import App from 'App'
import {
  LightThemeProvider,
  getSupportedLocale,
  isLocaleRtl,
} from '@hublet/react-components'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import getDateFnsLocale from 'common/utils/get-date-fns-locale'
// import createCache from '@emotion/cache'
// import { prefixer } from 'stylis'
// import rtlPlugin from 'stylis-plugin-rtl'
// import { CacheProvider } from '@emotion/react'
import { useAppDispatch } from 'store'
import {
  setCity,
  setCountry,
  setIpAddress,
  setLanguageDirection,
} from 'App/app-state'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

// const rtlCache = createCache({
//   key: 'mui-rtl',
//   stylisPlugins: [prefixer, rtlPlugin],
// })

// const ltrCache = createCache({
//   key: 'mui-ltr',
// })

const LocalizedAppWithThemeProvider = () => {
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const themeLocale = useMemo(() => {
    return getSupportedLocale(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    dispatch(setLanguageDirection(isLocaleRtl(themeLocale) ? 'rtl' : 'ltr'))
  }, [dispatch, themeLocale])

  useEffect(() => {
    const setIpInfo = async () => {
      try {
        const res = await axios.get('https://ipinfo.io/json')

        if (res.data) {
          dispatch(setIpAddress(res.data.ip as string))
          dispatch(setCity(res.data.city as string))
          dispatch(setCountry(res.data.country as string))
        }
      } catch (error) {
        console.log('Failed getting IP info', error)
      }
    }

    void setIpInfo()
  }, [dispatch])

  const dateFnsLocale = useMemo(() => {
    return getDateFnsLocale(i18n.language)
  }, [i18n.language])

  return (
    // Cache is causing issue when switching back-and-forth between language
    // <CacheProvider value={isLocaleRtl(themeLocale) ? rtlCache : ltrCache}>
    <LightThemeProvider locale={themeLocale}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateFnsLocale}
      >
        <App />
      </LocalizationProvider>
    </LightThemeProvider>
    // </CacheProvider>
  )
}

export default LocalizedAppWithThemeProvider

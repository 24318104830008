import React from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import { useTranslation } from 'react-i18next'
import { AnalyticsComponent } from 'App/Analytics/Analytics.components'

const Analytics = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <PageHeader>
        <PageTitle title={t('analytics.title')} />
      </PageHeader>
      <PageBody>
        <AnalyticsComponent />
      </PageBody>
    </Page>
  )
}

export default Analytics

import React, { useState } from 'react'
import { Grid, LinearProgress, Stack, Typography } from '@mui/material'
import HubletBanner from 'common/components/HubletBanner'
import Button from 'common/components/Button'
import {
  type DataForReview,
  useGetDataForReviewQuery,
  usePostReviewDataMutation,
  type UserDataForReview,
} from 'App/DataReview/data-review-rtk-api'
import Toast from 'common/components/Toast'
import UserDataReview from 'App/DataReview/UserDataReview'
import SiteDataReview from 'App/DataReview/SiteDataReview'
import OrganizationDataReview from 'App/DataReview/OrganizationDataReview'
import SelectLanguage from 'common/components/SelectLanguage'
import { useTranslation } from 'react-i18next'

interface DataReviewWithDataProps {
  data: DataForReview
}

const DataReviewWithData = ({
  data: { organizations, sites, users },
}: DataReviewWithDataProps) => {
  const { t } = useTranslation()

  const [organizationsData, setOrganizationsData] = useState(organizations)
  const [sitesData, setSitesData] = useState(sites)
  const [usersData, setUsersData] = useState(users)

  const [fieldErrors, setFieldrErrors] = useState<Record<string, string>>({})

  const [
    postReviewData,
    { isSuccess: isReviewDataPosted, isLoading: isPostingReviewData },
  ] = usePostReviewDataMutation()

  function handleSubmitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (isPostingReviewData || isReviewDataPosted) {
      return
    }

    const users = Object.entries(usersData).reduce(
      (acc: Record<string, UserDataForReview>, [userId, userData]) => ({
        ...acc,
        [userId]: {
          ...userData,
          firstName: userData.firstName.trim(),
          lastName: userData.lastName.trim(),
        },
      }),
      {}
    )

    const fieldErrors: Record<string, string> = {}

    // Validate users data
    for (const [userId, userData] of Object.entries(users)) {
      if (userData.firstName === '') {
        fieldErrors[`/users/${userId}/firstName`] = t(
          'common.errors.emptyField'
        )
      }
      if (userData.lastName === '') {
        fieldErrors[`/users/${userId}/lastName`] = t('common.errors.emptyField')
      }
    }

    if (Object.keys(fieldErrors).length > 0) {
      setFieldrErrors(fieldErrors)
      return
    }

    const data: DataForReview = {
      organizations: organizationsData,
      sites: sitesData,
      users,
    }

    postReviewData({
      data,
    })
  }

  function handleSubmitSuccess() {
    if (window.location.search.includes('from=om')) {
      // In this case redirect back to old manager
      window.location.href = window.location.origin
    } else {
      window.location.href = '/ui/dashboard'
    }
  }

  return (
    <>
      <Stack
        sx={(theme) => ({
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
        })}
        gap={1}
      >
        <SelectLanguage />
      </Stack>
      <Grid
        container
        direction="column"
        p={2}
        alignItems="center"
        margin="0 auto"
        maxWidth="1164px"
      >
        <Grid component="header" item mb={2}>
          <HubletBanner />
        </Grid>
        <Grid component="main" item>
          <form onSubmit={handleSubmitForm}>
            <Stack textAlign="center" gap={2}>
              <Typography variant="h1">{t('dataReview.title')}</Typography>
              <Typography variant="body1" fontSize="1.1rem">
                {t('dataReview.description')}
              </Typography>
              <Stack gap={3} my={4} textAlign="left">
                {Object.entries(organizationsData).map(
                  ([organizationId, organizationData]) => (
                    <OrganizationDataReview
                      key={organizationId}
                      organizationId={organizationId}
                      data={organizationData}
                      onChange={(data) =>
                        setOrganizationsData({
                          ...organizationsData,
                          [organizationId]: data,
                        })
                      }
                      errors={fieldErrors}
                    />
                  )
                )}
                {Object.entries(sitesData).map(([siteId, siteData]) => (
                  <SiteDataReview
                    key={siteId}
                    siteId={siteId}
                    data={siteData}
                    onChange={(data) =>
                      setSitesData({ ...sitesData, [siteId]: data })
                    }
                    errors={fieldErrors}
                  />
                ))}
                {Object.entries(usersData).map(([userId, userData]) => (
                  <UserDataReview
                    key={userId}
                    userId={userId}
                    data={userData}
                    onChange={(data) =>
                      setUsersData({ ...usersData, [userId]: data })
                    }
                    errors={fieldErrors}
                  />
                ))}
              </Stack>
              <Stack spacing={2} alignItems="center" mb={4}>
                <Button
                  wide
                  type="submit"
                  disabled={isPostingReviewData || isReviewDataPosted}
                >
                  {t('dataReview.submit')}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
      <Toast
        open={isReviewDataPosted}
        message={t('dataReview.success')}
        onClose={handleSubmitSuccess}
      />
    </>
  )
}

const DataReview = () => {
  const { data: dataForReview } = useGetDataForReviewQuery()

  if (dataForReview == null) {
    return <LinearProgress />
  }

  return <DataReviewWithData data={dataForReview} />
}

export default DataReview

import React, { useMemo } from 'react'
import {
  Select,
  MenuItem,
  type Theme,
  Box,
  FormControl,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { renderIf, renderItems } from 'common/utils/render-utils'
import { type SvgIconComponent } from '@mui/icons-material'

import IconTooltip from 'common/components/IconTooltip'
import TooltipInfo, {
  type TooltipInfoContent,
} from 'common/components/TooltipInfo'
import Checkbox from 'common/components/Checkbox'

const useStyles = makeStyles()((theme: Theme) => ({
  FormController: {
    width: '100%',
  },
  FormSelect: {
    width: '100%',
    padding: '12px 26px',
    backgroundColor: '#fff',
    border: `1px solid #e0e0e0`,
    borderRadius: '5px',
    fontSize: '16px',
    color: '#000',
    '&:focus-within': {
      borderColor: theme.palette.primary.light,
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'inherit',
    },
    '& .MuiSelect-icon': {
      marginRight: '20px',
    },
  },
  FormSelectRounded: {
    borderRadius: '100px',
  },
  FormSelectDense: {
    padding: '8px 16px',
  },
  FormSelectLabel: {
    marginBottom: '16px',
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
  },
  FormSelectOption: {
    minHeight: '44px',
  },
  FormSelectErrorMessage: {
    display: 'none',
    '&.Mui-error': {
      display: 'block',
      marginTop: '6px',
      color: theme.palette.error.main,
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  FormSelectHighlight: {
    // borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: '#F8F4F8',
    },
  },
  FormSelectSmall: {
    width: 'auto',
    height: '39px',
    fontSize: '14px',
    padding: '8px 16px',
    '& .MuiSelect-icon': {
      marginRight: '12px',
    },
    '& .MuiSelect-select': {
      paddingBottom: 1,
    },
  },
  FormControllerSmall: {
    width: 'auto',
  },
  DescriptionIcon: {
    display: 'inline-block',
    float: 'right',
    '& svg': {
      color: theme.palette.grey['300'],
      transition: 'color 0.15s ease',
    },
    '&:hover svg': {
      color: theme.palette.warning.main,
    },
  },
  FormSubheader: {
    textTransform: 'uppercase',
  },
}))

type Value = string | number | null

export interface FormSelectOption {
  value: Value
  label: string | React.ReactNode
  Icon?: SvgIconComponent
  startGroup?: string // can be used to add a subheader above this options
}

interface FormSelectProps {
  className?: string
  label?: string
  emptyLabel?: string
  placeholder?: string
  value?: Value | Value[]
  options: FormSelectOption[]
  error?: string
  onChange: (e: any, value: Value) => void
  rounded?: boolean
  dense?: boolean
  readOnly?: boolean
  disabled?: boolean
  highlight?: boolean
  small?: boolean
  info?: TooltipInfoContent
  multiple?: boolean // enable multi-select
  renderValue?: (value: any) => React.ReactNode
  checkmarks?: boolean
}

const withEmptyOption = (options: FormSelectOption[], emptyLabel: string) => [
  {
    label: emptyLabel,
    value: '',
  },
  ...options,
]

const FormSelect = ({
  className,
  label,
  emptyLabel,
  placeholder,
  value,
  options,
  error,
  onChange,
  rounded,
  dense,
  readOnly,
  disabled,
  highlight,
  small,
  info,
  multiple,
  renderValue,
  checkmarks,
}: FormSelectProps) => {
  const { classes, cx } = useStyles()
  const formControllerClassNames = [classes.FormController]
  const formSelectClassNames = [classes.FormSelect]

  options = useMemo(() => {
    if (emptyLabel != null) {
      return withEmptyOption(options, emptyLabel)
    } else {
      return options
    }
  }, [emptyLabel, options])

  if (rounded) {
    formSelectClassNames.push(classes.FormSelectRounded)
  }

  if (dense === true) {
    formSelectClassNames.push(classes.FormSelectDense)
  }

  if (highlight === true) {
    formSelectClassNames.push(classes.FormSelectHighlight)
  }

  if (small === true) {
    formSelectClassNames.push(classes.FormSelectSmall)
    formControllerClassNames.push(classes.FormControllerSmall)
  }

  if (className) {
    formControllerClassNames.push(className)
  }

  return (
    <FormControl
      className={cx(formControllerClassNames)}
      error={error !== undefined}
    >
      <label>
        {renderIf(label !== undefined, () => (
          <Box className={classes.FormSelectLabel}>
            {label}
            {info != null && (
              <div className={classes.DescriptionIcon}>
                <IconTooltip title={<TooltipInfo content={info} />} />
              </div>
            )}
          </Box>
        ))}
        <Select
          disabled={disabled}
          readOnly={readOnly}
          className={cx(formSelectClassNames)}
          value={value}
          onChange={(e) => onChange(e, e.target.value as Value)}
          disableUnderline
          displayEmpty
          native={false}
          renderValue={
            renderValue != null
              ? renderValue
              : (value: any) => {
                  let optLabel: React.ReactNode | undefined
                  if (multiple) {
                    const matches = options
                      .filter((opt) => value?.includes(opt.value))
                      .map((o) => o.label)
                    if (matches.length > 0) {
                      optLabel = matches.join(', ')
                    }
                  } else {
                    optLabel = options.find((opt) => opt.value === value)?.label
                  }
                  return <>{optLabel ?? placeholder}</>
                }
          }
          multiple={multiple}
          variant="standard"
        >
          {renderItems(options, (option, i) => [
            // Returning an array ensures these will be direct children
            // under `Select`
            option.startGroup && (
              <ListSubheader
                key={`start-group-${i ?? 0}`}
                className={classes.FormSubheader}
              >
                {option.startGroup}
              </ListSubheader>
            ),
            <MenuItem
              className={classes.FormSelectOption}
              key={`option-${i ?? 0}`}
              value={option.value ?? undefined}
            >
              {checkmarks && (
                <Checkbox
                  checked={
                    Array.isArray(value)
                      ? value.includes(option.value)
                      : value === option.value
                  }
                />
              )}
              {option.Icon != null && (
                <ListItemIcon>
                  <option.Icon fontSize="small" />
                </ListItemIcon>
              )}
              <ListItemText primary={option.label} />
            </MenuItem>,
          ])}
        </Select>
      </label>
      <FormHelperText className={classes.FormSelectErrorMessage}>
        {error}
      </FormHelperText>
    </FormControl>
  )
}

export default FormSelect

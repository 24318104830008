import React, { type PropsWithChildren } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  type Theme,
} from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import Button from 'common/components/Button'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogPaper: {
    width: '505px',
    maxWidth: '100%',
    padding: '36px',
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  DialogPaperGray: {
    backgroundColor: '#F8F8FB',
  },
  DialogTitle: {
    padding: 0,
    '& > h2': {
      lineHeight: 1,
      fontSize: '24px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  DialogHeadClose: {
    textAlign: 'right',
    color: '#000',
  },
  DialogCloseIcon: {
    cursor: 'pointer',
  },
  DialogContent: {
    padding: 0,
    marginTop: '16px',
    marginBottom: '20px',
    '& > p': {
      fontSize: '16px',
      color: '#000',
    },
  },
  DialogActions: {
    padding: 0,
    justifyContent: 'space-between',
  },
}))

interface ActionDialogOptions extends PropsWithChildren {
  open: boolean
  title: string
  description?: string
  actionText: string
  onAction: () => void
  onClose: () => void
  actionDisabled?: boolean
  gray?: boolean
  className?: string
  closeOnAction?: boolean
}

const ActionDialog = ({
  children,
  open,
  title,
  description,
  actionText,
  onAction,
  onClose,
  actionDisabled,
  gray,
  className,
  closeOnAction = true,
}: ActionDialogOptions) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  const onClickAction = () => {
    if (!actionDisabled) {
      onAction()
      if (closeOnAction) {
        onClose()
      }
    }
  }

  const dialogClassNames = [classes.DialogPaper]

  if (gray) {
    dialogClassNames.push(classes.DialogPaperGray)
  }

  if (className !== undefined) {
    dialogClassNames.push(className)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: cx(dialogClassNames),
      }}
      dir={languageDirection}
    >
      <Grid container>
        <Grid item xs={11}>
          <DialogTitle className={classes.DialogTitle}>{title}</DialogTitle>
        </Grid>
        <Grid className={classes.DialogHeadClose} item xs={1}>
          <ClearIcon className={classes.DialogCloseIcon} onClick={onClose} />
        </Grid>
      </Grid>
      <DialogContent className={classes.DialogContent}>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        <Button onClick={onClose} small outlined>
          {t('common.actionDialog.buttons.cancel')}
        </Button>
        <Button onClick={onClickAction} disabled={actionDisabled} small>
          {actionText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ActionDialog

import {
  configureStore,
  type ThunkAction,
  type Action,
  combineReducers,
  type ThunkDispatch,
  type Middleware,
  type MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import appReducer from 'App/app-state'
import loginReducer from 'App/Login/login-state'
import sitesReducer from 'App/Sites/sites-state'
import profilesReducer from 'App/Profiles/profiles-state'
import customersReducer from 'App/Customers/customers-state'
import distributorsReducer from 'App/Distributors/distributors-state'
import networksReducer from 'App/Networks/networks-state'
import profileNetworksReducer from 'App/Profiles/ProfilesView/NetworkSettings/network-settings-state'
import tabletsReducer from 'App/Tablets/tablets-state'
import stockTabletsReducer from 'App/StockTablets/stock-tablets-state'
import docksReducer from 'App/Docks/docks-state'
import postsReducer from 'App/Posts/posts-state'
import categoriesReducer from 'App/Categories/categories-state'
import contentsReducer from 'App/Categories/contents-state'
import codesReducer from 'App/Codes/codes-state'
import loansReducer from 'App/Loans/loans-state'
import usersReducer from 'App/Users/users-state'
import invitationsReducer from 'App/Invitations/invitations-state'
import integrationsReducer from 'App/Integrations/integrations-state'
import dashboardReducer from 'App/Dashboard/dashboard-state'
import translationsReducer from 'App/Translations/translations-state'
import rtkApi from 'rtk-api'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'

export const rootReducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  sites: sitesReducer,
  profiles: profilesReducer,
  customers: customersReducer,
  distributors: distributorsReducer,
  networks: networksReducer,
  profileNetworks: profileNetworksReducer,
  tablets: tabletsReducer,
  stockTablets: stockTabletsReducer,
  docks: docksReducer,
  posts: postsReducer,
  categories: categoriesReducer,
  contents: contentsReducer,
  codes: codesReducer,
  loans: loansReducer,
  users: usersReducer,
  invitations: invitationsReducer,
  integrations: integrationsReducer,
  dashboard: dashboardReducer,
  translations: translationsReducer,
  [rtkApi.reducerPath]: rtkApi.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export const rtkErrorMiddleware: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    // Replace any error messages (we don't use them) with the server-side request id
    if (isRejectedWithValue(action)) {
      const headers: Headers | undefined =
        action?.meta?.baseQueryMeta?.response?.headers
      if (headers === undefined) {
        console.log(
          'rtkErrorMiddleware: undefined headers in action = ',
          action
        )
        return next(action)
      }
      const reqId = headers.get('x-request-id')
      action.payload.error = reqId
    }

    return next(action)
  }

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rtkApi.middleware).concat(rtkErrorMiddleware),
})

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export interface ThunkDispatchProp {
  dispatch: ThunkDispatch<RootState, undefined, any>
}

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

import { useContext, useEffect } from 'react'
import { fetchDocks } from 'App/Docks/docks-state'
import { type Dock, type DocksContext } from 'App/Docks/docks-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import DocksReactContext from 'App/Docks/docks-react-context'
import { useAppDispatch, useAppSelector } from 'store'

const useDocks = (
  explicitContext?: DocksContext,
  withDeleted = true
): Dock[] => {
  const dispatch = useAppDispatch()
  const implicitContext = useContext(DocksReactContext)
  const context = explicitContext ?? implicitContext

  const { organizationId, siteId } = useCurrentAccount()
  const docks = useAppSelector((state) => state.docks.docks)

  useEffect(() => {
    dispatch(fetchDocks({ organizationId, siteId, context, withDeleted }))
  }, [dispatch, organizationId, siteId, context, withDeleted])

  return docks
}

export default useDocks

import React from 'react'
import { MenuItem, Select } from '@mui/material'
import { type GROUP_BY_OPTIONS } from 'App/Analytics/Analytics.types'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store'

interface GroupBySelectProps {
  onChange: (value: string) => void
  selectedOption: '' | GROUP_BY_OPTIONS
}

const GroupBySelect = ({ onChange, selectedOption }: GroupBySelectProps) => {
  const { t } = useTranslation()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  return (
    <Select
      onChange={(e) => onChange(e.target.value)}
      defaultValue=""
      value={selectedOption}
      displayEmpty
      size="small"
      sx={{ width: '150px' }}
      data-testid="select-group-by"
    >
      <MenuItem value="" dir={languageDirection}>
        -
      </MenuItem>
      <MenuItem value="month" dir={languageDirection}>
        {t('analytics.month')}
      </MenuItem>
      <MenuItem value="week" dir={languageDirection}>
        {t('analytics.week')}
      </MenuItem>
      <MenuItem value="day" dir={languageDirection}>
        {t('analytics.day')}
      </MenuItem>
    </Select>
  )
}

export default GroupBySelect

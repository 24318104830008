import React, {
  type RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import DrawerForm from 'common/components/DrawerForm'
import { Box, CircularProgress, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import Button from 'common/components/Button'
import { renderIf } from 'common/utils/render-utils'
import ImageIcon from '@mui/icons-material/ImageOutlined'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import {
  uploadProfileImage,
  resetUploadProfileImage,
  updateProfile,
  resetUpdateProfile,
  fetchProfiles,
} from 'App/Profiles/profiles-state'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  ImageFieldContainer: {
    flexWrap: 'nowrap',
  },
  ImageFieldLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '200px',
    minWidth: '200px',
    height: '200px',
    padding: '9px',
    backgroundColor: '#fff',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: '10px',
    '& .MuiSvgIcon-root': {
      fontSize: '90px',
      color: '#000',
    },
    '& img': {
      borderRadius: '10px',
    },
  },
  ImageFieldLabel: {
    display: 'block',
    marginBottom: '16px',
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
  },
  ImageFieldRight: {
    flexGrow: 1,
    padding: '24px',
  },
  ImageFieldRightTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  ImageFieldRightInfo: {
    marginTop: '16px',
  },
  ImageFieldRightAction: {
    marginTop: '16px',
  },
  ImageFieldImage: {
    width: '100%',
    maxHeight: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
    '& > img': {
      width: '100%',
    },
  },
  ImageFieldError: {
    marginTop: '6px',
    color: theme.palette.error.main,
    fontSize: '16px',
    fontWeight: 'bold',
  },
}))

interface FormImageFieldProps {
  image?: string | null
  onUploadImage: (file: File) => void
}

const FormImageField = ({ image, onUploadImage }: FormImageFieldProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const fileInput: RefObject<HTMLInputElement> = useRef(null)

  const uploadingProfileImage = useAppSelector(
    (state) => state.profiles.uploadingProfileImage
  )
  const uploadProfileImageError = useAppSelector(
    (state) => state.profiles.uploadProfileImageError
  )

  const onClickUploadImage = () => fileInput.current?.click()
  const onChangeInputFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files

    if (files === null || files.length === 0) {
      return
    }

    onUploadImage(files[0])
  }

  return (
    <>
      <label className={classes.ImageFieldLabel}>
        {t('profiles.image.fieldName')}
      </label>
      <Grid container className={classes.ImageFieldContainer}>
        <Grid item className={classes.ImageFieldLeft}>
          {!uploadingProfileImage && image && (
            <Box className={classes.ImageFieldImage}>
              <img src={image} alt={t('profiles.image.fieldName')} />
            </Box>
          )}
          {!uploadingProfileImage && !image && <ImageIcon />}
          {uploadingProfileImage && <CircularProgress />}
        </Grid>
        <Grid item className={classes.ImageFieldRight}>
          <Box className={classes.ImageFieldRightTitle}>
            {t('profiles.image.infoTitle')}
          </Box>
          <Box className={classes.ImageFieldRightInfo}>
            {t('profiles.image.infoContent')}
          </Box>
          <Box className={classes.ImageFieldRightAction}>
            <Button small onClick={onClickUploadImage}>
              {t('profiles.image.uploadButton')}
            </Button>
            <input
              onChange={onChangeInputFile}
              ref={fileInput}
              type="file"
              accept="image/png, image/jpeg"
              hidden
            />
            {renderIf(uploadProfileImageError, () => (
              <Box className={classes.ImageFieldError}>
                {t('profiles.image.error')}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

interface ChangePictureDialogProps {
  currentImage: string | null
  profileId: number
  open: boolean
  onClose: () => void
}

const ChangePictureDialog = ({
  currentImage,
  profileId,
  open,
  onClose,
}: ChangePictureDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [image, setImage] = useState<string | null>(currentImage)

  const { organizationId, siteId } = useCurrentAccount()
  const context = useMemo(
    () => ({ organizationId, siteId }),
    [organizationId, siteId]
  )

  const uploadedProfileImage = useAppSelector(
    (state) => state.profiles.uploadedProfileImage
  )
  const uploadedProfileImageFile = useAppSelector(
    (state) => state.profiles.uploadedProfileImageFile
  )

  useEffect(() => {
    if (uploadedProfileImage) {
      setImage(uploadedProfileImageFile)
      dispatch(resetUploadProfileImage())
    }
  }, [uploadedProfileImage, uploadedProfileImageFile, dispatch])

  const fetchingProfiles = useAppSelector(
    (state) => state.profiles.fetchingProfiles
  )
  const updatedProfile = useAppSelector(
    (state) => state.profiles.updatedProfile
  )

  useEffect(() => {
    if (updatedProfile) {
      dispatch(resetUpdateProfile())
      if (!fetchingProfiles) {
        dispatch(fetchProfiles(context))
      }
    }
  }, [dispatch, context, fetchingProfiles, updatedProfile])

  const onSubmit = () => {
    dispatch(updateProfile({ context, form: { image }, profileId }))
    onClose()
  }

  const onUploadImage = (imageFile: File) => {
    dispatch(uploadProfileImage({ context, imageFile, profileId }))
  }

  return (
    <DrawerForm
      open={open}
      title={t('profiles.viewProfile.changePictureButton')}
      onClose={onClose}
      onSubmit={onSubmit}
      submitText={t('profiles.editProfile.general.edit.saveButton')}
    >
      <FormImageField image={image} onUploadImage={onUploadImage} />
    </DrawerForm>
  )
}

export default ChangePictureDialog

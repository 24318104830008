import React from 'react'
import { type Loan } from 'App/Loans/loans-types'
import Drawer from 'common/components/Drawer'
import Panel from 'common/components/Panel'
import PanelField from 'common/components/PanelField'
import useRenderDate from 'common/hooks/useRenderDate'
import { useTranslation } from 'react-i18next'
import LoanStatusView from 'App/Loans/components/LoanStatus'
import useCurrentUser from 'common/hooks/useCurrentUser'
import { roleShouldHaveAccess } from 'common/utils/roles'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import { Grid } from '@mui/material'

interface ViewLoanDrawerProps {
  loan: Loan
  open: boolean
  onClose: () => void
}

const ViewLoanDrawer = ({ loan, open, onClose }: ViewLoanDrawerProps) => {
  const { t } = useTranslation()
  const { role } = useCurrentUser()
  const renderDate = useRenderDate()

  return (
    <Drawer title={t('loans.loanDetails.title')} open={open} onClose={onClose}>
      <Panel title={t('loans.loanDetails.title')}>
        <PanelField
          title={t('loans.loanDetails.fields.startTime')}
          value={renderDate(loan.startTime)}
        />
        <PanelField
          title={t('loans.loanDetails.fields.endTime')}
          value={
            loan.returnTime === undefined ? (
              <DateWithRelativeTime
                datetime={loan.endTime}
                pastTimeText={t('loans.loanTimeLeft.expired')}
              />
            ) : (
              renderDate(loan.returnTime)
            )
          }
        />
        <PanelField
          title={t('loans.loanDetails.fields.status')}
          value={
            <LoanStatusView
              status={loan.status}
              tabletActiveAt={loan.tabletActiveAt}
            />
          }
        />
        <PanelField
          title={t('loans.loanDetails.fields.profile')}
          value={loan.profileName}
        />
        <PanelField
          title={t('loans.loanDetails.fields.profileSource')}
          value={
            loan.profileSource === undefined ? undefined : (
              <Grid container alignItems="flex-end" direction="column">
                <div>
                  {t(
                    `loans.loanDetails.values.profileSource.${loan.profileSource}`
                  )}
                </div>
              </Grid>
            )
          }
        />
        <PanelField
          title={t('loans.loanDetails.fields.defaultLanguage')}
          value={
            loan.userDefaultLanguage != null
              ? t(`languages.${loan.userDefaultLanguage}`)
              : null
          }
        />
      </Panel>
      <Panel title={t('loans.loanDetails.tablet.title')}>
        <PanelField
          title={t('loans.loanDetails.tablet.fields.serialNumber')}
          value={loan.tabletSerialNumber}
        />
      </Panel>
      {roleShouldHaveAccess(role, 'borrowers') && (
        <Panel title={t('loans.loanDetails.permission.title')}>
          {loan.userName && (
            <PanelField
              title={t('loans.loanDetails.permission.fields.username')}
              value={loan.userName}
            />
          )}
          {loan.pinCode && (
            <PanelField
              title={t('loans.loanDetails.permission.fields.pinCode')}
              value={loan.pinCode}
            />
          )}
        </Panel>
      )}
    </Drawer>
  )
}

export default ViewLoanDrawer

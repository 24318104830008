import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  type Theme,
  TableFooter,
  TablePagination,
  Box,
  Stack,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { forwardRef, useEffect, useMemo } from 'react'
import {
  useRowSelect,
  useTable,
  useSortBy,
  usePagination,
  useFilters,
} from 'react-table'
import Checkbox from 'common/components/Checkbox'
import { useTranslation } from 'react-i18next'
import { renderIf } from 'common/utils/render-utils'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpOutlined'
import SortIcon from '@mui/icons-material/SortOutlined'

const useStyles = makeStyles()((theme: Theme) => ({
  Table: {
    borderRadius: '10px',
    overflow: 'auto',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    color: '#000',
    fontSize: '16px',
    '& .MuiTableRow-root': {
      borderBottom: `1px solid #e0e0e0`,
      '&:last-child': {
        borderBottom: 0,
      },
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(3),
      fontSize: '16px',
    },
    '& .MuiTableCell-head': {
      padding: theme.spacing(3),
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  RowSelected: {
    backgroundColor: '#F8F4F8',
  },
  DrawerTable: {
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
  },
  PanelTable: {
    boxShadow: 'none',
    border: 0,
    borderRadius: 0,
  },
  HeaderCell: {
    verticalAlign: 'middle',
  },
  SortByIcon: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle',
  },
  CanSortIcon: {
    fontSize: '1rem',
  },
}))

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef<any>()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      if (resolvedRef?.current) {
        resolvedRef.current.indeterminate = indeterminate
      }
    }, [resolvedRef, indeterminate])

    return <Checkbox ref={resolvedRef} {...rest} />
  }
)

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox'

export enum TableColumnFilter {
  Text = 'text',
  Boolean = 'boolean',
  Select = 'select',
  Action = 'action',
  DateRange = 'dateRange',
}

export interface TableColumn {
  id?: string
  title?: React.ReactNode
  field?: string
  valueFn?: (model: any) => any
  render?: (model: any) => React.ReactNode
  style?: any
  canSort?: boolean
  filter?: TableColumnFilter
}

interface TableProps {
  data: any[]
  columns: TableColumn[]
  selectable?: boolean
  onSelectionChange?: (rows: any) => void
  pagination?: boolean
  variant?: 'page' | 'drawer' | 'panel'
}

function ColumnTextFilter({ column: { filterValue, setFilter } }: any) {
  return (
    <Box>
      <input
        value={filterValue || ''}
        onChange={(e) => {
          const newFilterValue = e.target.value || undefined // Set undefined to remove the filter entirely
          setFilter(newFilterValue)
        }}
        placeholder=""
        style={{ width: '100%' }}
      />
    </Box>
  )
}

function ColumnBooleanFilter({ column: { filterValue, setFilter } }: any) {
  return (
    <Box>
      <select
        value={filterValue}
        onChange={(e) => {
          const newFilterValue = e.target.value

          if (newFilterValue === 'true') {
            setFilter(true)
          } else if (newFilterValue === 'false') {
            setFilter(false)
          } else {
            setFilter(undefined)
          }
        }}
        style={{ width: '100%' }}
      >
        <option value=""></option>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
    </Box>
  )
}

const actionFilter = (rows: any, id: any, filterValue: any) => {
  switch (filterValue) {
    case 'done':
      return rows.filter((row: any) => row.original[id] != null)
    case 'todo':
      return rows.filter((row: any) => row.original[id] == null)
    default:
      return rows
  }
}

function ColumnActionFilter({ column: { filterValue, setFilter } }: any) {
  return (
    <Box>
      <select
        value={filterValue}
        onChange={(e) => {
          const newFilterValue = e.target.value
          setFilter(newFilterValue || undefined)
        }}
      >
        <option value=""></option>
        <option value="done">Done</option>
        <option value="todo">Todo</option>
      </select>
    </Box>
  )
}

function ColumnSelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    return Array.from(options)
  }, [id, preFilteredRows])

  return (
    <Box>
      <select
        value={filterValue}
        onChange={(e) => {
          const newFilterValue = e.target.value
          setFilter(newFilterValue || undefined)
        }}
        style={{ width: '100%' }}
      >
        <option value=""></option>
        {options.map((option: any) => (
          <option key={option} value={option ?? null}>
            {option}
          </option>
        ))}
      </select>
    </Box>
  )
}

function ColumnDateRangeFilter({ column: { filterValue, setFilter } }: any) {
  const [startDate, setStartDate] = React.useState(
    filterValue ? filterValue[0] : ''
  )
  const [endDate, setEndDate] = React.useState(
    filterValue ? filterValue[1] : ''
  )

  useEffect(() => {
    const newFilterValue = [startDate || undefined, endDate || undefined]
    if (
      !Array.isArray(filterValue) ||
      filterValue[0] !== newFilterValue[0] ||
      filterValue[1] !== newFilterValue[1]
    ) {
      setFilter(newFilterValue)
    }
  }, [startDate, endDate, setFilter, filterValue])

  return (
    <Box>
      <input
        type="date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value)
        }}
        style={{
          fontSize: '10px',
        }}
      />
      -
      <input
        type="date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value)
        }}
        style={{
          fontSize: '10px',
        }}
      />
    </Box>
  )
}

function dateRangeFilter(rows: any, id: any, filterValue: any) {
  if (!Array.isArray(filterValue) || filterValue.length !== 2) {
    return rows
  }

  const [startDateStr, endDateStr] = filterValue

  if (startDateStr == null && endDateStr == null) {
    return rows
  }

  const filteredRows = rows.filter((row: any) => {
    const date = new Date(row.original[id])
    if (startDateStr == null && endDateStr == null) {
      return true
    } else if (startDateStr == null && endDateStr != null) {
      return date <= new Date(endDateStr)
    } else if (startDateStr != null && endDateStr == null) {
      return date >= new Date(startDateStr)
    } else {
      return date >= new Date(startDateStr) && date <= new Date(endDateStr)
    }
  })

  return filteredRows
}

function getFilterOptions(tableColumnFilter?: TableColumnFilter) {
  const defaultCanFilter = tableColumnFilter != null
  const disableFilters = tableColumnFilter == null

  let Filter
  let filter

  switch (tableColumnFilter) {
    case TableColumnFilter.Text:
      Filter = ColumnTextFilter
      filter = 'text'
      break
    case TableColumnFilter.Boolean:
      Filter = ColumnBooleanFilter
      filter = 'exact'
      break
    case TableColumnFilter.Select:
      Filter = ColumnSelectFilter
      filter = 'equals'
      break
    case TableColumnFilter.Action:
      Filter = ColumnActionFilter
      filter = 'actionFilter'
      break
    case TableColumnFilter.DateRange:
      Filter = ColumnDateRangeFilter
      filter = 'dateRangeFilter'
      break
  }

  return {
    defaultCanFilter,
    disableFilters,
    Filter,
    filter,
  }
}

const Table = ({
  data,
  columns,
  selectable = false,
  pagination = true,
  variant = 'page',
  // TODO: old code, need to check and remove this
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelectionChange = () => {},
}: TableProps) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const rowsPerPageOptions = [10, 25, 50, 100]

  function getAccessorFn(item: TableColumn) {
    return (originalRow: any) => {
      if (item.valueFn != null) {
        return item.valueFn(originalRow)
      }

      const key = item.field ?? item.id
      if (key == null) {
        return '?'
      }
      const value = originalRow[key]
      return value ?? '-'
    }
  }

  const _columns = useMemo(
    () =>
      columns.map((item: TableColumn): any => ({
        id: item.id,
        Header: item.title ?? '',
        Cell: ({ row }: any) =>
          item.render != null
            ? item.render(row.original)
            : getAccessorFn(item)(row.original),
        accessor: getAccessorFn(item),
        style: item.style,
        disableSortBy: item.canSort !== true,
        ...getFilterOptions(item.filter),
      })),
    [columns]
  )

  const filterTypes = React.useMemo(
    () => ({
      actionFilter,
      dateRangeFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows: allRows,
    // @ts-expect-error: old code, need to check later
    page: pageRows,
    // @ts-expect-error: old code, need to check later
    gotoPage,
    // @ts-expect-error: old code, need to check later
    setPageSize,
    // @ts-expect-error: old code, need to check later
    state: { pageIndex, pageSize },
    // @ts-expect-error: old code, need to check later
    selectedFlatRows,
  } = useTable<Record<string, unknown>>(
    {
      columns: _columns,
      data,
      // @ts-expect-error: old code, need to check later
      initialState: { pageIndex: 0, pageSize: rowsPerPageOptions[0] },
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!selectable) {
        return
      }
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }: any) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          style: { width: '24px' },
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    if (selectable) {
      onSelectionChange(selectedFlatRows.map((row: any) => row.original))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectable, selectedFlatRows])

  const tableClasses = [classes.Table]

  if (variant === 'drawer') {
    tableClasses.push(classes.DrawerTable)
  } else if (variant === 'panel') {
    tableClasses.push(classes.PanelTable)
  }

  return (
    <TableContainer component={Paper} className={cx(tableClasses)}>
      <MuiTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // eslint-disable-next-line react/jsx-key
                <TableCell {...column.getHeaderProps()}>
                  <Stack
                    {...column.getSortByToggleProps({
                      className: column.className,
                      style: column.style,
                      title: column.canSort
                        ? t('common.table.sortBy')
                        : undefined,
                    })}
                    direction="row"
                    alignItems="center"
                  >
                    <span className={classes.HeaderCell}>
                      {column.render('Header')}
                    </span>
                    {column.canSort ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDropDownIcon className={classes.SortByIcon} />
                        ) : (
                          <ArrowDropUpIcon className={classes.SortByIcon} />
                        )
                      ) : (
                        <SortIcon
                          className={cx([
                            classes.SortByIcon,
                            classes.CanSortIcon,
                          ])}
                        />
                      )
                    ) : null}
                  </Stack>

                  {column.canFilter ? column.render('Filter') : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {pageRows.map((row: any) => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <TableRow
                {...row.getRowProps()}
                className={row.isSelected ? classes.RowSelected : ''}
              >
                {row.cells.map((cell: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                    ])}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
        {renderIf(pagination, () => (
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ padding: 0 }}
                count={allRows.length}
                rowsPerPageOptions={rowsPerPageOptions}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(_, p) => gotoPage(p)}
                onRowsPerPageChange={(e) =>
                  setPageSize(parseInt(e.target.value))
                }
                labelRowsPerPage={t('common.table.pagination.rowsPerPage')}
                labelDisplayedRows={(props) =>
                  t('common.table.pagination.displayedRows', props)
                }
                getItemAriaLabel={(type) => {
                  switch (type) {
                    case 'previous':
                      return t('common.table.pagination.previousPage')
                    case 'next':
                      return t('common.table.pagination.nextPage')
                    default:
                      return ''
                  }
                }}
              />
            </TableRow>
          </TableFooter>
        ))}
      </MuiTable>
    </TableContainer>
  )
}

export default Table

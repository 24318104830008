import useApp from 'common/hooks/useApp'

export enum UserContextLevel {
  Organization = 'organization',
  Site = 'site',
}

interface CurrentAccount {
  contextLevel: UserContextLevel
  organizationDistributorId?: number
  organizationId: number
  siteId: number
}

const useCurrentAccount = (): CurrentAccount => {
  const { site, organization, organizationDistributorId } = useApp()
  const siteId = !site ? -1 : site.id

  const contextLevel = !site
    ? UserContextLevel.Organization
    : UserContextLevel.Site

  return {
    contextLevel,
    organizationDistributorId,
    organizationId: organization.id,
    siteId,
  }
}

export default useCurrentAccount

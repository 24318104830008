import React, { type PropsWithChildren } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  type Theme,
} from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import ClearIcon from '@mui/icons-material/Clear'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogPaper: {
    minWidth: '505px',
    maxWidth: '100%',
    padding: '36px',
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  DialogPaperGray: {
    backgroundColor: '#F8F8FB',
  },
  DialogTitle: {
    padding: 0,
    '& > h2': {
      lineHeight: 1,
      fontSize: '24px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  DialogHeadClose: {
    textAlign: 'right',
    color: '#000',
  },
  DialogCloseIcon: {
    cursor: 'pointer',
  },
  DialogContent: {
    padding: 0,
    marginTop: '16px',
  },
}))

interface ContentDialogProps extends PropsWithChildren {
  open: boolean
  title: string
  onClose: () => void
  gray?: boolean
}

const ContentDialog = ({
  children,
  open,
  title,
  onClose,
  gray,
}: ContentDialogProps) => {
  const { classes, cx } = useStyles()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  const dialogClassNames = [classes.DialogPaper]

  if (gray) {
    dialogClassNames.push(classes.DialogPaperGray)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: cx(dialogClassNames),
      }}
      dir={languageDirection}
    >
      <Grid container>
        <Grid item xs={11}>
          <DialogTitle className={classes.DialogTitle}>{title}</DialogTitle>
        </Grid>
        <Grid className={classes.DialogHeadClose} item xs={1}>
          <ClearIcon className={classes.DialogCloseIcon} onClick={onClose} />
        </Grid>
      </Grid>
      <DialogContent className={classes.DialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default ContentDialog

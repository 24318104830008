import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import Panel, { EditState } from 'common/components/NewPanel'
import useCurrentUser from 'common/hooks/useCurrentUser'
import PanelField from 'common/components/PanelField'
import { Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FormField from 'common/components/FormField'
import { useUpdateCurrentUserProfileMutation } from 'App/MyAccount/my-account-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import { fetchCurrentUser } from 'App/app-state'
import { useAppDispatch } from 'store'
import { useCurrentUserContext } from 'common/hooks/useCurrentUserContext'

const AccountPanel = () => {
  const { t } = useTranslation()
  const { login, orgName, siteName } = useCurrentUser()

  return (
    <Panel title={t('myAccount.account.title')} variant="page">
      <PanelField
        title={t('myAccount.fields.email.label')}
        value={login}
        variant="page"
      />
      <Divider light />
      <PanelField
        title={t('myAccount.fields.organization.label')}
        value={orgName}
        variant="page"
      />
      {siteName && (
        <>
          <Divider light />
          <PanelField
            title={t('myAccount.fields.site.label')}
            value={siteName}
            variant="page"
          />
        </>
      )}
    </Panel>
  )
}

const ProfilePanel = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const currentUserContext = useCurrentUserContext()
  const currentUser = useCurrentUser()
  const [editState, setEditState] = useState(EditState.Default)

  const [firstName, setFirstName] = useState(currentUser.firstName)
  const [lastName, setLastName] = useState(currentUser.lastName)

  const [
    updateCurrentUserProfile,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateCurrentUserProfileMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchCurrentUser(currentUserContext))
    }
  }, [dispatch, isUpdateSuccess, hasApiMutationErrors, currentUserContext])

  const handleSave = () => {
    setEditState(EditState.Pending)
    updateCurrentUserProfile({
      firstName,
      lastName,
    })
  }

  const renderEditMode = () => (
    <>
      <FormField
        label={t('myAccount.fields.firstName.label')}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        disabled={editState === EditState.Pending}
      />
      <Divider light />
      <FormField
        label={t('myAccount.fields.lastName.label')}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        disabled={editState === EditState.Pending}
      />
    </>
  )

  return (
    <Panel
      title={t('myAccount.profile.title')}
      variant="page"
      editable
      editState={editState}
      renderEditMode={renderEditMode}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(EditState.Default)}
      onSave={handleSave}
      onSuccess={() => setEditState(EditState.Success)}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('myAccount.fields.firstName.label')}
        value={currentUser.firstName}
        variant="page"
      />
      <Divider light />
      <PanelField
        title={t('myAccount.fields.lastName.label')}
        value={currentUser.lastName}
        variant="page"
      />
    </Panel>
  )
}

const MyAccount = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <PageTitle title={t('myAccount.title')} />
      <PageBody>
        <AccountPanel />
        <ProfilePanel />
      </PageBody>
    </Page>
  )
}

export default MyAccount

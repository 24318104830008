import React from 'react'
import { useTranslation } from 'react-i18next'
import { renderIf } from 'common/utils/render-utils'
import {
  EAPAuth,
  EAPMethod,
  type NetworkForm,
  NetworkSecurityMode,
  SSIDVisibility,
} from 'App/Networks/networks-types'
import { LockOutlined, WifiOutlined } from '@mui/icons-material'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import PasswordField from 'common/components/PasswordField'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import FormSwitch from 'common/components/FormSwitch'

const useStyles = makeStyles()((_theme: Theme) => ({
  Field: {
    marginBottom: '32px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
}))

export interface NetworkFormErrors {
  ssid?: string
  securityMode?: string
}

interface NetworkFieldsProps {
  withLabels?: boolean
  networkForm: NetworkForm
  fieldErrors: NetworkFormErrors
  onChangeField: (
    key: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
  onToggleSwitch: (
    key: string
  ) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const NetworkFields = ({
  withLabels = true,
  networkForm,
  fieldErrors,
  onChangeField,
  onToggleSwitch,
}: NetworkFieldsProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const secModes = [
    {
      value: NetworkSecurityMode.OPEN,
      label: t('networks.securityMode.open'),
      Icon: WifiOutlined,
    },
    {
      value: NetworkSecurityMode.WPA_PSK,
      label: t('networks.securityMode.wpaPersonal'),
      Icon: LockOutlined,
    },
    {
      value: NetworkSecurityMode.WPA2_PSK,
      label: t('networks.securityMode.wpa2Personal'),
      Icon: LockOutlined,
    },
    {
      value: NetworkSecurityMode.WPA_ENTERPRISE,
      label: t('networks.securityMode.wpaEnterprise'),
      Icon: LockOutlined,
    },
  ]

  const visibilities = [
    {
      value: SSIDVisibility.BROADCAST,
      label: t('networks.ssidVisibility.broadcast'),
    },
    {
      value: SSIDVisibility.HIDDEN,
      label: t('networks.ssidVisibility.hidden'),
    },
  ]

  const eapMethods = [
    { value: EAPMethod.PEAP, label: t('networks.eapMethod.peap') },
  ]

  const eapAuths = [
    { value: EAPAuth.MSCHAPv2, label: t('networks.eapAuth.mschapv2') },
    { value: EAPAuth.MD5, label: t('networks.eapAuth.md5') },
    { value: EAPAuth.GTC, label: t('networks.eapAuth.gtc') },
  ]

  return (
    <>
      <FormField
        className={classes.Field}
        label={
          withLabels ? t('networks.networkForm.ssidField.label') : undefined
        }
        placeholder={t('networks.networkForm.ssidField.placeholder')}
        value={networkForm.ssid}
        onChange={onChangeField('ssid')}
        autoFocus
        error={fieldErrors.ssid}
      />
      <FormSelect
        className={classes.Field}
        label={
          withLabels
            ? t('networks.networkForm.securityModeField.label')
            : undefined
        }
        placeholder={t('networks.networkForm.securityModeField.placeholder')}
        value={networkForm.securityMode}
        onChange={onChangeField('securityMode')}
        error={fieldErrors.securityMode}
        options={secModes}
      />
      <FormSelect
        className={classes.Field}
        label={
          withLabels
            ? t('networks.networkForm.visibilityField.label')
            : undefined
        }
        placeholder={t('networks.networkForm.visibilityField.placeholder')}
        value={networkForm.visibility}
        onChange={onChangeField('visibility')}
        options={visibilities}
      />
      {renderIf(
        networkForm.securityMode === NetworkSecurityMode.WPA_ENTERPRISE,
        () => (
          <>
            <FormSelect
              className={classes.Field}
              label={
                withLabels
                  ? t('networks.networkForm.eapMethodField.label')
                  : undefined
              }
              placeholder={t('networks.networkForm.eapMethodField.placeholder')}
              value={networkForm.eapMethod}
              onChange={onChangeField('eapMethod')}
              options={eapMethods}
            />
            <FormSelect
              className={classes.Field}
              label={
                withLabels
                  ? t('networks.networkForm.eapAuthField.label')
                  : undefined
              }
              placeholder={t('networks.networkForm.eapAuthField.placeholder')}
              value={networkForm.eapAuth}
              onChange={onChangeField('eapAuth')}
              options={eapAuths}
            />
            <FormField
              className={classes.Field}
              label={
                withLabels
                  ? t('networks.networkForm.usernameField.label')
                  : undefined
              }
              placeholder={t('networks.networkForm.usernameField.placeholder')}
              value={networkForm.username}
              onChange={onChangeField('username')}
            />
          </>
        )
      )}
      {renderIf(
        networkForm.securityMode &&
          networkForm.securityMode !== NetworkSecurityMode.OPEN,
        () => (
          <PasswordField
            className={classes.Field}
            label={
              withLabels
                ? t('networks.networkForm.passwordField.label')
                : undefined
            }
            placeholder={t('networks.networkForm.passwordField.placeholder')}
            value={networkForm.password}
            onChange={onChangeField('password')}
          />
        )
      )}
      <FormSwitch
        title={t('networks.networkForm.enableCaptivePortal.label')}
        checked={networkForm.enableCaptivePortal}
        onChange={onToggleSwitch('enableCaptivePortal')}
        variant="drawer"
      />
      {renderIf(networkForm.enableCaptivePortal, () => (
        <>
          <p>{t('networks.networkForm.captivePortalInfo.p1')}</p>
          <p>{t('networks.networkForm.captivePortalInfo.p2')}</p>
          <FormField
            className={classes.Field}
            label={t('networks.networkForm.captivePortalUsername.label')}
            placeholder={t(
              'networks.networkForm.captivePortalUsername.placeholder'
            )}
            value={networkForm.captivePortalUsername}
            onChange={onChangeField('captivePortalUsername')}
          />
          <PasswordField
            className={classes.Field}
            label={t('networks.networkForm.captivePortalPassword.label')}
            placeholder={t(
              'networks.networkForm.captivePortalPassword.placeholder'
            )}
            value={networkForm.captivePortalPassword}
            onChange={onChangeField('captivePortalPassword')}
          />
        </>
      ))}
    </>
  )
}

export default NetworkFields

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  type Theme,
} from '@mui/material'
import { type Network } from 'App/Networks/networks-types'
import { makeStyles } from 'tss-react/mui'
import ClearIcon from '@mui/icons-material/Clear'
import {
  fetchNetworkQRCode,
  resetNetworkQrCode,
} from 'App/Networks/networks-state'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogPaper: {
    width: '505px',
    maxWidth: '100%',
    padding: '36px',
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  DialogHeadClose: {
    textAlign: 'right',
    color: '#000',
  },
  DialogCloseIcon: {
    cursor: 'pointer',
  },
  DialogContent: {
    padding: 0,
    marginTop: '16px',
    marginBottom: '20px',
    '& > p': {
      fontSize: '16px',
      color: '#000',
    },
  },
  Loader: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(5),
  },
}))

interface ScanNetworkQRPopupProps {
  open: boolean
  onClose: () => void
  network: Network | undefined
}

const ScanNetworkQRPopup = ({
  open,
  onClose,
  network,
}: ScanNetworkQRPopupProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  const qrCode = useAppSelector((state) => state.networks.networkQrCode)

  useEffect(() => {
    if (network === undefined) {
      return
    }

    if (open) {
      dispatch(fetchNetworkQRCode(network))
    } else {
      dispatch(resetNetworkQrCode())
    }
  }, [dispatch, open, network])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.DialogPaper,
      }}
      dir={languageDirection}
    >
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid className={classes.DialogHeadClose} item xs={6}>
          <ClearIcon className={classes.DialogCloseIcon} onClick={onClose} />
        </Grid>
      </Grid>
      <DialogContent className={classes.DialogContent}>
        {qrCode ? (
          <div dangerouslySetInnerHTML={{ __html: qrCode }} />
        ) : (
          <CircularProgress className={classes.Loader} />
        )}
        <DialogContentText>
          {t('tablets.scanNetworkQR.description')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default ScanNetworkQRPopup

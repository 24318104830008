import { type Locale } from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'
import gaLocale from 'common/locales/ga'

// Available locales: https://github.com/date-fns/date-fns/tree/master/src/locale

const getDateFnsLocale = (lng: string): Locale => {
  // `... as ...` is required for checking for any string key
  const locale: Locale | undefined = (dateFnsLocales as Record<string, Locale>)[
    lng
  ]

  if (locale === undefined) {
    // Special cases when there isn't a 1-to-1 match with the language code and a locale
    switch (lng) {
      case 'en':
        return dateFnsLocales.enUS
      case 'zh':
        return dateFnsLocales.zhCN
      case 'zh-TW':
        return dateFnsLocales.zhTW
      case 'pt-PT':
        return dateFnsLocales.pt
      case 'no':
        // Norwegian has two variants: Bokmål and Nynorsk
        // `nb` is for Norwegian Bokmål
        // `nn` is for Norwegian Nynorsk
        // We use Bokmål because it is more common and it is the one AWS Translate uses
        return dateFnsLocales.nb
      case 'ga':
        return gaLocale
      default:
        console.error(`getDateFns: Unknown lng=${lng}. Fallback to lng=fi.`)
        // Fallback to default locale
        return dateFnsLocales.fi
    }
  }

  return locale
}

export default getDateFnsLocale

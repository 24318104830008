import React from 'react'
import FormField from 'common/components/FormField'
import { Divider, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import IconTooltip from 'common/components/IconTooltip'
import { useTranslation } from 'react-i18next'
import { type FileForm } from 'App/Categories/categories-types'
import { type FileFormErrors } from 'common/hooks/useFileForm'
import Button from 'common/components/Button'
import FormImageFile from 'common/components/FormImageFile'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  FormField: {
    marginBottom: '32px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  SubtitleRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  Subtitle: {
    margin: 0,
    marginRight: theme.spacing(1),
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  UploadField: {
    border: '1px dashed #6C2576',
    boxSizing: 'border-box',
    borderRadius: '10px',
    textAlign: 'center',
    padding: theme.spacing(2),
    background: '#fff',
  },
  UploadFieldError: {
    borderColor: theme.palette.error.main,
  },
  UploadFieldErrorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    fontWeight: 'bold',
    margin: '6px 14px 0',
  },
}))

interface Props {
  fileForm: FileForm
  fieldErrors: FileFormErrors
  onFieldChange: (
    key: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
  onUploadOpen: () => void
  onUploadCustomIcon: (imageFile: File) => void
}

const FileFields = ({
  fileForm,
  fieldErrors,
  onFieldChange,
  onUploadOpen,
  onUploadCustomIcon,
}: Props) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const uploadFileCustomIconError = useAppSelector(
    (state) => state.contents.uploadFileCustomIconError
  )

  return (
    <>
      <Grid
        className={cx(classes.UploadField, {
          [classes.UploadFieldError]: fieldErrors.path != null,
        })}
      >
        <Grid>{fileForm.path}</Grid>
        <Button small flat onClick={onUploadOpen}>
          {t('contents.fileForm.changeFile')}
        </Button>
      </Grid>
      {fieldErrors.path != null && (
        <div className={classes.UploadFieldErrorText}>{fieldErrors.path}</div>
      )}
      <Grid className={classes.SubtitleRow}>
        <h1 className={classes.Subtitle}>{t('contents.fileForm.subtitle')}</h1>
        <IconTooltip title={t('contents.fileForm.tooltip')} />
      </Grid>

      <FormField
        className={classes.FormField}
        label={t('contents.fileForm.nameField.label')}
        placeholder={t('contents.fileForm.nameField.placeholder')}
        value={fileForm.name}
        onChange={onFieldChange('name')}
        error={fieldErrors.name}
      />
      <FormField
        className={classes.FormField}
        label={t('contents.fileForm.descriptionField.label')}
        placeholder={t('contents.fileForm.descriptionField.placeholder')}
        value={fileForm.description}
        onChange={onFieldChange('description')}
      />
      <Divider />
      <Grid className={classes.SubtitleRow}>
        <h1 className={classes.Subtitle}>
          {t('contents.fileForm.customIconField.label')}
        </h1>
      </Grid>
      <FormImageFile
        title={t('contents.fileForm.customIconField.info.title')}
        description={t('contents.fileForm.customIconField.info.description')}
        imageUrl={fileForm.customIcon}
        onChangeFile={onUploadCustomIcon}
        error={uploadFileCustomIconError}
      />
    </>
  )
}

export default FileFields

export enum PermissionType {
  Single = 'single',
  Reusable = 'reusable',
  Multi = 'multi',
}

export interface Code {
  id: number
  code: string
  createdAt: Date
  profileId: number
  expiration: Date
  name: string
  siteId: number
  permissionType: PermissionType
  profileName?: string
  useCount: number
  groupName?: string
}

export interface CodeEditable {
  profileId?: number
  expiration?: Date
  permissionType?: PermissionType
  name?: string
}

export interface CodesContext {
  organizationId: number
  siteId: number
}

export interface ImportedCode {
  value: string
  exists: boolean
  invalid?: boolean
}

import rtkApi from 'rtk-api'
import { type Distributor } from 'App/Distributors/distributors-types'

const distributorsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getDistributors: builder.query<Distributor[], void>({
      query: () => '/distributors',
    }),
  }),
})

export const { useGetDistributorsQuery } = distributorsRtkApi
export default distributorsRtkApi

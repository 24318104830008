import type React from 'react'
import { useEffect, useState } from 'react'
import { type App, type AppForm } from 'App/Categories/categories-types'
import { useTranslation } from 'react-i18next'
import {
  fetchContents,
  resetUpdateApp,
  updateApp,
} from 'App/Categories/contents-state'
import { useAppDispatch, useAppSelector } from 'store'
import useCurrentAccount from 'common/hooks/useCurrentAccount'

export interface AppFormErrors {
  name?: string
  url?: string
}

const initialAppForm = {
  name: '',
  description: '',
  isLockTaskMode: false,
  managedConfigId: null,
  managedConfigName: null,
  permissions: [],
}

const useAppForm = (profileId: number, categoryId: number, app?: App) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { siteId, organizationId } = useCurrentAccount()
  const context = { categoryId, profileId, siteId, organizationId }
  const updatedApp = useAppSelector((state) => state.contents.updatedApp)

  const [appForm, setAppForm] = useState<AppForm>(initialAppForm)
  const [fieldErrors, setFieldErrors] = useState<AppFormErrors>({})

  useEffect(() => {
    if (app != null) {
      setAppForm(app)
    }
  }, [app])

  useEffect(() => {
    if (updatedApp) {
      dispatch(resetUpdateApp())
      dispatch(fetchContents({ categoryId, profileId, siteId, organizationId }))
    }
  }, [dispatch, updatedApp, categoryId, profileId, siteId, organizationId])

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setAppForm({ ...appForm, [key]: e.target.value })
    }

  const validateForm = (): boolean => {
    let hasError = false
    const errors: AppFormErrors = {}

    if (appForm.name === '') {
      hasError = true
      errors.name = t('contents.appForm.errors.emptyField')
    }

    setFieldErrors(errors)
    return hasError
  }

  const handleUpdate = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (!app?.id) {
      return
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(updateApp({ context, id: app.id, form: appForm }))
    }
  }

  return {
    appForm,
    setAppForm,
    fieldErrors,
    handleFieldChange,
    handleUpdate,
    updatedApp,
  }
}

export default useAppForm

import React from 'react'
import { Box } from '@mui/material'
import BannerSVG from 'common/graphics/banner.svg'
import RobotSVG from 'common/graphics/robot.svg'

const HubletBanner = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <img src={BannerSVG} alt="Banner" />
      <Box
        sx={{
          position: 'absolute',
          top: '8%',
          right: '1%',
          height: '82%',
        }}
      >
        <img src={RobotSVG} alt="Robot" style={{ height: '100%' }} />
      </Box>
    </Box>
  )
}

export default HubletBanner

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormField from 'common/components/FormField'
import {
  resetUpdatedCustomer,
  updateCustomer,
  fetchCustomer,
} from 'App/Customers/customers-state'
import DrawerForm from 'common/components/DrawerForm'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Toast from 'common/components/Toast'
import useApp from 'common/hooks/useApp'
import { type Customer } from 'App/Customers/customers-types'
import FormSelect from 'common/components/FormSelect'
import { useAppDispatch, useAppSelector } from 'store'
import { timezoneOptions } from 'common/timezones'

const useStyles = makeStyles()((theme: Theme) => ({
  Field: {
    marginBottom: theme.spacing(3),
  },
  InlineFields: {
    display: 'flex',
    '&>*': {
      marginRight: theme.spacing(3),
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}))

interface GeneralForm {
  name: string
  timezone?: string
}

interface FieldErrors {
  name?: string
}

interface CustomerSettingsGeneralDrawerProps {
  distributorId: number
  customer: Customer
  open: boolean
  onClose: () => void
}

const CustomerSettingsGeneralDrawer = ({
  distributorId,
  customer,
  open,
  onClose,
}: CustomerSettingsGeneralDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { organization } = useApp()
  const updatedCustomer = useAppSelector(
    (state) => state.customers.updatedCustomer
  )

  const initGeneralForm: GeneralForm = useMemo(
    () => ({
      name: customer.name,
      timezone: customer.timezone,
    }),
    [customer]
  )

  const [generalForm, setGeneralForm] = useState<GeneralForm>(initGeneralForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  useEffect(() => {
    setGeneralForm(initGeneralForm)
  }, [initGeneralForm])

  useEffect(() => {
    if (updatedCustomer) {
      onClose()
      dispatch(resetUpdatedCustomer())
      dispatch(fetchCustomer({ distributorId, customerId: customer.id }))
    }
  }, [
    dispatch,
    onClose,
    updatedCustomer,
    organization,
    customer,
    distributorId,
  ])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setGeneralForm({ ...generalForm, [key]: e.target.value })
    }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    if (!customer) {
      return false
    }

    let hasError = false
    const errors: FieldErrors = {}

    if (generalForm.name === '') {
      hasError = true
      errors.name = t('customerSettings.general.errors.emptyField')
    }

    if (!hasError) {
      dispatch(
        updateCustomer({
          distributorId,
          customerId: customer.id,
          form: generalForm,
        })
      )
    }
    setFieldErrors(errors)
  }

  return (
    <>
      <DrawerForm
        open={open}
        onClose={onClose}
        onSubmit={onSubmitForm}
        title={t('customerSettings.general.title')}
        submitText={t('customerSettings.general.form.saveButton')}
      >
        <FormField
          label={t('customerSettings.general.form.name.label')}
          placeholder={t('customerSettings.general.form.name.placeholder')}
          value={generalForm.name}
          onChange={onChangeField('name')}
          autoFocus
          error={fieldErrors.name}
          className={classes.Field}
        />
        <FormSelect
          label={t('customerSettings.settings.general.form.timezone.label')}
          placeholder={t(
            'customerSettings.settings.general.form.timezone.placeholder'
          )}
          options={timezoneOptions}
          value={generalForm.timezone}
          onChange={onChangeField('timezone')}
        />
      </DrawerForm>

      <Toast
        open={updatedCustomer}
        message={t('customerSettings.general.toast.success')}
      />
    </>
  )
}

export default CustomerSettingsGeneralDrawer

import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

import Table from 'common/components/PaginatedTable'
import { type Tablet } from 'App/Tablets/tablets-types'
import {
  type StockTablet,
  StockState,
} from 'App/StockTablets/stock-tablets-types'
import RemoveIcon from '@mui/icons-material/DeleteOutlined'
import ReturnIcon from '@mui/icons-material/UndoOutlined'
import IconMenu from 'common/components/IconMenu'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import { type SortBy } from 'common/components/PaginatedTable'
import {
  type PaginatedTabletsReq,
  useGetStockTabletsQuery,
} from 'App/StockTablets/stock-tablets-rtk-api'

interface TabletsRowActionsProps {
  tablet: StockTablet
  onClickRemove: (tablet: Tablet) => void
  onClickReturn: (tablet: Tablet) => void
}

const TabletsRowActions = ({
  tablet,
  onClickRemove,
  onClickReturn,
}: TabletsRowActionsProps) => {
  let actions: any[] = []

  if (tablet?.state === StockState.ASSIGNED) {
    actions = [
      {
        local: 'remove',
        fn: () => onClickRemove(tablet),
        icon: RemoveIcon,
      },
      {
        local: 'return',
        fn: () => onClickReturn(tablet),
        icon: ReturnIcon,
      },
    ]
  } else if (tablet?.state === StockState.REMOVED) {
    actions = [
      {
        local: 'return',
        fn: () => onClickReturn(tablet),
        icon: ReturnIcon,
      },
    ]
  }

  return <IconMenu actions={actions} />
}

interface TabletTableProps {
  query: string
  onClickRemove: (tablet: Tablet) => void
  onClickReturn: (tablet: Tablet) => void
  onSelectTablets: (tablets: Tablet[]) => void
}

const TabletTable = ({
  query,
  onClickRemove,
  onClickReturn,
  onSelectTablets = () => {},
}: TabletTableProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const addedTablets = useAppSelector(
    (state) => state.stockTablets.isUploadSuccess
  )
  const removedTablet = useAppSelector(
    (state) => state.stockTablets.removedTablet
  )
  const returnedTablet = useAppSelector(
    (state) => state.stockTablets.returnedTablet
  )

  const defaultPaginateParams = useMemo(
    () => ({
      pageSize: 10,
      pageIndex: 0,
      query: '',
      sortBy: '',
      sortDesc: false,
    }),
    []
  )

  const [paginatedParams, setPaginatedParams] = useState<PaginatedTabletsReq>(
    defaultPaginateParams
  )

  const { data } = useGetStockTabletsQuery(paginatedParams)

  const tablets = data?.rows ?? []
  const count = data?.total ?? 0
  const rowsPerPage = data?.pageSize ?? 10
  const currentPage = data?.currentPage ?? 0

  useEffect(() => {
    setPaginatedParams({
      ...defaultPaginateParams,
      query,
      pageSize: paginatedParams.pageSize,
      _: new Date().getTime(),
    })
  }, [defaultPaginateParams, query, paginatedParams.pageSize])

  useEffect(() => {
    if (addedTablets) {
      setPaginatedParams({
        ...paginatedParams,
        _: new Date().getTime(),
      })
    }
  }, [dispatch, addedTablets, paginatedParams])

  useEffect(() => {
    if (removedTablet || returnedTablet) {
      setPaginatedParams({
        ...paginatedParams,
        _: new Date().getTime(),
      })
    }
  }, [removedTablet, returnedTablet, paginatedParams])

  const onPageChange = (pageIndex: number) => {
    setPaginatedParams({
      ...paginatedParams,
      pageIndex,
      _: new Date().getTime(),
    })
  }

  const onRowsPerPageChange = (rowsPerPage: number) => {
    setPaginatedParams({
      ...paginatedParams,
      pageSize: rowsPerPage,
      pageIndex: 0,
      _: new Date().getTime(),
    })
  }

  const onSortChange = (sortBy: SortBy[]) => {
    const newSortBy = sortBy[0]?.id
    const newSortDesc = sortBy[0]?.desc
    if (
      paginatedParams.sortBy !== newSortBy ||
      paginatedParams.sortDesc !== newSortDesc
    ) {
      setPaginatedParams({
        ...paginatedParams,
        sortBy: newSortBy,
        sortDesc: newSortDesc,
        _: new Date().getTime(),
      })
    }
  }

  const columns = useMemo(
    () => {
      return [
        {
          title: t('tablets.table.columns.serialNumber'),
          field: 'serialNumber',
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.activeAt'),
          render: ({ activeAt }: StockTablet) =>
            activeAt != null ? (
              <DateWithRelativeTime datetime={new Date(activeAt)} />
            ) : (
              '-'
            ),
          field: 'activeAt',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.macAddress'),
          field: 'macAddress',
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.modelCode'),
          field: 'modelCode',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.hubletVersionName'),
          field: 'hubletVersionName',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.type'),
          field: 'type',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.location'),
          field: 'location',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.state'),
          field: 'state',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.site'),
          field: 'site',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          id: 'actions',
          // eslint-disable-next-line react/display-name
          render: (tablet: StockTablet) =>
            (tablet.state === StockState.ASSIGNED ||
              tablet.state === StockState.REMOVED) && (
              <TabletsRowActions
                tablet={tablet}
                onClickRemove={onClickRemove}
                onClickReturn={onClickReturn}
              />
            ),
          style: { width: '24px' },
        },
      ]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return tablets.length > 0 ? (
    <Table
      data={tablets}
      columns={columns}
      count={count}
      rowsPerPage={rowsPerPage}
      pageIndex={currentPage}
      selectable
      onSelectionChange={onSelectTablets}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      onSortChange={onSortChange}
    />
  ) : null
}

export default TabletTable

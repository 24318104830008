import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Stack,
  DialogTitle,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Grid,
  FormControlLabel,
  Switch,
  type Theme,
} from '@mui/material'
import WizardDialog from 'common/components/WizardDialog'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import {
  useGetIntegrationTemplatesQuery,
  useGetDefaultIntegrationParametersQuery,
} from 'App/Integrations/integrations-rtk-api'
import {
  useAddIntegrationWithParamsMutation,
  useUpdateStateMutation,
  useTestCanBorrowMutation,
  useLoadDebugLogsQuery,
} from 'App/InstallationWizard/installation-wizard-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import useApiErrors from 'common/hooks/useApiErrors'
import useAuthMethodOptions from 'App/Integrations/hooks/useAuthMethodOptions'
import useProfiles from 'common/hooks/useProfiles'
import {
  IntegrationProxyType,
  ProxyTlsVersion,
  ParamDataType,
  type IntegrationParameter,
  type DebugLog,
} from 'App/Integrations/integrations-types'
import WideForm, {
  FormItemType,
  type FormItemDef,
} from 'common/components/WideForm'
import { fieldInfo } from 'App/Integrations/components/AddIntegrationDrawer'
import { startsWithHttpOrHttpsAndHasMore } from 'common/utils/validation-utils'
import Dialog from 'common/components/Dialog'
import JsonView from 'common/components/JsonView'
import WomanSVG from 'common/graphics/woman.svg'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  StepperLabel: {
    '& .MuiStepLabel-label': {
      color: theme.palette.primary.main,
    },
  },
}))

type Values = Record<string, any>
type Errors = Record<string, string>

const clearError = (
  key: string,
  errors: Errors,
  setErrors: Dispatch<SetStateAction<Errors>>
) => {
  if (key in errors) {
    const { [key]: _, ...newErrors } = errors
    setErrors(newErrors)
  }
}

const getInfo = (param: IntegrationParameter) =>
  param.hint === undefined ? undefined : { description: param.hint }

interface StepFormProps {
  nextStep: number
  setActiveStep: () => void
  resetNextStep?: () => void
  onSaveIntermediateValues?: (data: any) => void
  data?: any
  saveAndExit?: boolean
  onClose?: () => void
}

const IntegrationTypeForm = ({
  nextStep,
  setActiveStep,
  resetNextStep,
  onSaveIntermediateValues,
  data,
}: StepFormProps) => {
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const { intermediateValues, state } = data

  const { data: integrationTemplates } = useGetIntegrationTemplatesQuery({
    organizationId,
    siteId,
  })

  const authModeOptions = useAuthMethodOptions(integrationTemplates)

  const [integrationValues, setIntegrationVaules] = useState<Values>({})
  const [integrationErrors, setIntegrationErrors] = useState<Errors>({})

  const [customUrlValue, setCustomUrlValue] = useState<Values>({})
  const [customUrlError, setCustomUrlError] = useState<Errors>({})

  const [url, setUrl] = useState('')

  const integrationItems = useMemo(
    () => [
      {
        type: FormItemType.Field,
        key: 'name',
        label: t('installationWizard.integration.add.name.form.label'),
        placeholder: t(
          'installationWizard.integration.add.name.form.placeholder'
        ),
      },
      {
        type: FormItemType.SelectOne,
        key: 'authMode',
        label: t('installationWizard.integration.add.authMode.form.label'),
        placeholder: t(
          'installationWizard.integration.add.authMode.form.placeholder'
        ),
        options: authModeOptions,
      },
    ],
    [authModeOptions, t]
  )

  const customUrlItem = useMemo(
    () => [
      {
        type: FormItemType.URL,
        key: 'url',
        label: t('installationWizard.integration.add.url.form.label'),
        placeholder: t(
          'installationWizard.integration.add.url.form.placeholder'
        ),
        info: fieldInfo.url,
      },
    ],
    [t]
  )

  const handleIntegrationValues = (key: string, value: string) => {
    if (value.length > 0) {
      clearError(key, integrationErrors, setIntegrationErrors)
    }

    setIntegrationVaules((prev) => ({ ...prev, [key]: value }))
  }

  const handleCustomUrlValue = (key: string, value: string) => {
    if (value.length > 0) {
      clearError(key, customUrlError, setCustomUrlError)
    }

    setCustomUrlValue((prev) => ({ ...prev, [key]: value }))
  }

  const handleErrors = (
    items: FormItemDef[],
    values: Values,
    setErrors: Dispatch<SetStateAction<Errors>>
  ) => {
    let hasError = false

    items.forEach(({ type, key, nullable }) => {
      if (!nullable) {
        if (values[key] === undefined || values[key] === '') {
          hasError = true

          setErrors((prev) => ({
            ...prev,
            [key]: t('installationWizard.error.message.empty'),
          }))
        } else if (
          type === FormItemType.URL &&
          !startsWithHttpOrHttpsAndHasMore(values[key])
        ) {
          hasError = true

          setErrors((prev) => ({
            ...prev,
            [key]: t('installationWizard.error.message.invalidUrl'),
          }))
        }
      }
    })
    return hasError
  }

  useEffect(() => {
    if (authModeOptions?.length > 0) {
      if (intermediateValues !== undefined) {
        const { name, authMode, url } = intermediateValues
        setIntegrationVaules({ name, authMode })

        if (authMode === 'custom') {
          setCustomUrlValue({ url })
        }
      } else if (state !== undefined && state !== null) {
        const { name, authMode, url } = state
        setIntegrationVaules({ name, authMode })

        if (authMode === 'custom') {
          setCustomUrlValue({ url })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authModeOptions, intermediateValues, state])

  useEffect(() => {
    setUrl(
      integrationValues.authMode === 'custom'
        ? customUrlValue.url
        : integrationTemplates !== undefined
          ? (integrationTemplates.find(
              (it) => it.key === integrationValues.authMode
            )?.url ?? '')
          : ''
    )
  }, [integrationValues, customUrlValue, integrationTemplates])

  useEffect(() => {
    if (nextStep === 1) {
      let hasError = handleErrors(
        integrationItems,
        integrationValues,
        setIntegrationErrors
      )

      if (integrationValues.authMode === 'custom') {
        const hasCustomUrlError = handleErrors(
          customUrlItem,
          customUrlValue,
          setCustomUrlError
        )
        hasError = hasError || hasCustomUrlError
      }

      if (hasError && resetNextStep !== undefined) {
        resetNextStep()
      } else {
        if (onSaveIntermediateValues !== undefined) {
          if (
            integrationValues?.authMode !== intermediateValues?.authMode ||
            (intermediateValues?.authMode === 'custom' &&
              url !== intermediateValues?.url)
          ) {
            onSaveIntermediateValues({
              ...integrationValues,
              url,
              parameters: undefined,
            })
          }
        }
        setActiveStep()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep])

  return (
    <>
      <WideForm
        items={integrationItems}
        values={integrationValues}
        errors={integrationErrors}
        onChange={handleIntegrationValues}
      />
      {integrationValues.authMode === 'custom' && (
        <WideForm
          items={customUrlItem}
          values={customUrlValue}
          errors={customUrlError}
          onChange={handleCustomUrlValue}
        />
      )}
    </>
  )
}

const CommunicationForm = ({
  nextStep,
  setActiveStep,
  resetNextStep,
  onSaveIntermediateValues,
  data,
  saveAndExit,
  onClose,
}: StepFormProps) => {
  const { t } = useTranslation()

  const { intermediateValues, state } = data
  const { organizationId, siteId } = useCurrentAccount()

  const [communicationTypeValue, setCommunicationTypeValue] = useState<Values>(
    {}
  )
  const [communicationTypeError, setCommunicationTypeError] = useState<Errors>(
    {}
  )

  const [communicationChannelValues, setCommunicationChannelValues] =
    useState<Values>({
      proxyServerAddress: '',
      proxyProtocol: 'tcp',
    })

  const [httpsSettingValues, setHttpsSettingValues] = useState<Values>({
    proxyRootCertificate: '',
    proxyClientCertificate: '',
    proxyClientKey: '',
    proxyTlsVersion: '',
  })

  const [proxyPass, setProxyPass] = useState<boolean>(false)

  const communicationTypeOptions = [
    {
      label: t('integrations.integrationForm.fields.proxyType.values.none'),
      value: IntegrationProxyType.None,
    },
    {
      label: t('integrations.integrationForm.fields.proxyType.values.cloud'),
      value: IntegrationProxyType.Cloud,
    },
    {
      label: t('integrations.integrationForm.fields.proxyType.values.dock'),
      value: IntegrationProxyType.Dock,
    },
  ]

  const protocolOptions = [
    {
      label: t('integrations.channels.form.fields.protocol.labels.tcp'),
      value: 'tcp',
    },
    {
      label: t('integrations.channels.form.fields.protocol.labels.tls'),
      value: 'tls',
    },
    {
      label: t(
        'integrations.channels.form.fields.protocol.labels.tls_insecure'
      ),
      value: 'tls_insecure',
    },
    {
      label: t('integrations.channels.form.fields.protocol.labels.http'),
      value: 'http',
    },
    {
      label: t('integrations.channels.form.fields.protocol.labels.https'),
      value: 'https',
    },
    {
      label: t(
        'integrations.channels.form.fields.protocol.labels.https_insecure'
      ),
      value: 'https_insecure',
    },
  ]

  const proxyTlsVersionOptions = useMemo(() => {
    const options = Object.values(ProxyTlsVersion).map((value) => ({
      label: value,
      value,
    }))
    return options
  }, [])

  const communicationTypeItem = [
    {
      type: FormItemType.SelectOne,
      key: 'communicationType',
      label: t('integrations.integrationForm.fields.communicationType.label'),
      placeholder: t(
        'integrations.integrationForm.fields.paramSelOne.placeholder'
      ),
      options: communicationTypeOptions,
    },
  ]

  const communicationChannelItems = [
    {
      type: FormItemType.Field,
      key: 'proxyServerAddress',
      label: t('integrations.channels.form.fields.proxyServerAddress'),
      nullable: true,
    },
    {
      type: FormItemType.Number,
      key: 'proxyServerPort',
      label: t('integrations.channels.form.fields.port'),
      nullable: true,
    },
    {
      type: FormItemType.SelectOne,
      key: 'proxyProtocol',
      label: t('integrations.channels.form.fields.protocol'),
      options: protocolOptions,
    },
  ]

  let httpsSettingItems: FormItemDef[] = [
    {
      type: FormItemType.Field,
      key: 'proxyRootCertificate',
      label: t('integrations.channels.form.fields.proxyRootCertificate'),
      nullable: true,
    },
    {
      type: FormItemType.Field,
      key: 'proxyClientCertificate',
      label: t('integrations.channels.form.fields.proxyClientCertificate'),
      nullable: true,
    },
    {
      type: FormItemType.Field,
      key: 'proxyClientKey',
      label: t('integrations.channels.form.fields.proxyClientKey'),
      nullable: true,
    },
  ]

  const tlsSettingItem: FormItemDef[] = [
    {
      type: FormItemType.SelectOne,
      key: 'proxyTlsVersion',
      label: t('integrations.channels.form.fields.proxyTlsVersion'),
      options: proxyTlsVersionOptions,
      placeholder: t('common.values.useDefault'),
      emptyLabel: t('common.values.useDefault'),
    },
  ]

  const isTls = (proxyProtocol: string) =>
    ['tls', 'tls_insecure'].includes(proxyProtocol)
  const isHttps = (proxyProtocol: string) =>
    ['tls', 'tls_insecure', 'https', 'https_insecure'].includes(proxyProtocol)

  if (isTls(communicationChannelValues.proxyProtocol)) {
    httpsSettingItems = [...httpsSettingItems, ...tlsSettingItem]
  }

  const handleCommunicationTypeValue = (key: string, value: string) => {
    if (value.length > 0) {
      clearError(key, communicationTypeError, setCommunicationTypeError)
    }

    setCommunicationTypeValue((prev) => ({ ...prev, [key]: value }))
  }

  const handleCommunicationChannelValues = (
    key: string,
    value: string | number
  ) => {
    setCommunicationChannelValues((prev) => ({ ...prev, [key]: value }))
  }

  const handleHttpsSettingValues = (key: string, value: string) => {
    setHttpsSettingValues((prev) => ({ ...prev, [key]: value }))
  }

  const handleErrors = (
    items: FormItemDef[],
    values: Values,
    setErrors: Dispatch<SetStateAction<Errors>>
  ) => {
    let hasError = false

    items.forEach(({ key, nullable }) => {
      if (!nullable) {
        if (values[key] === undefined || values[key] === '') {
          hasError = true

          setErrors((prev) => ({
            ...prev,
            [key]: t('installationWizard.error.message.empty'),
          }))
        }
      }
    })
    return hasError
  }

  const [
    updateState,
    {
      isSuccess: isUpdateStateSuccess,
      error: updateStateError,
      reset: resetStateUpdate,
    },
  ] = useUpdateStateMutation()

  const [hasUpdateStateErrors, apiUpdateStateErrorsMsg] = useApiErrors([
    updateStateError,
  ])

  useEffect(() => {
    if (isUpdateStateSuccess && onClose !== undefined) {
      resetStateUpdate()
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateStateSuccess])

  useEffect(() => {
    if (saveAndExit) {
      updateState({
        organizationId,
        siteId,
        integrationState: {
          ...intermediateValues,
          ...communicationTypeValue,
          ...communicationChannelValues,
          ...httpsSettingValues,
          proxyPass,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAndExit])

  useEffect(() => {
    if (intermediateValues?.communicationType !== undefined) {
      const {
        communicationType,
        proxyServerAddress,
        proxyServerPort,
        proxyProtocol,
        proxyRootCertificate,
        proxyClientCertificate,
        proxyClientKey,
        proxyTlsVersion,
        proxyPass,
      } = intermediateValues
      setCommunicationTypeValue({ communicationType })
      setCommunicationChannelValues({
        proxyServerAddress,
        proxyServerPort,
        proxyProtocol,
      })
      if (isHttps(proxyProtocol)) {
        setHttpsSettingValues({
          proxyRootCertificate,
          proxyClientCertificate,
          proxyClientKey,
          proxyTlsVersion: isTls(proxyProtocol) ? proxyTlsVersion : '',
        })
      }
      setProxyPass(proxyPass)
    } else if (state !== undefined && state !== null) {
      const {
        communicationType,
        proxyServerAddress,
        proxyServerPort,
        proxyProtocol,
        proxyRootCertificate,
        proxyClientCertificate,
        proxyClientKey,
        proxyTlsVersion,
        proxyPass,
      } = state
      setCommunicationTypeValue({ communicationType })
      setCommunicationChannelValues({
        proxyServerAddress,
        proxyServerPort,
        proxyProtocol,
      })
      if (isHttps(proxyProtocol)) {
        setHttpsSettingValues({
          proxyRootCertificate,
          proxyClientCertificate,
          proxyClientKey,
          proxyTlsVersion: isTls(proxyProtocol) ? proxyTlsVersion : '',
        })
      }
      setProxyPass(proxyPass)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intermediateValues, state])

  useEffect(() => {
    if (nextStep === 0 || nextStep === 2) {
      let hasError = false

      if (nextStep === 2) {
        hasError = handleErrors(
          communicationTypeItem,
          communicationTypeValue,
          setCommunicationTypeError
        )
      }

      if (hasError && resetNextStep !== undefined) {
        resetNextStep()
      } else {
        if (onSaveIntermediateValues !== undefined) {
          let data = {
            ...communicationTypeValue,
            channelConfig: {
              ...communicationChannelValues,
              proxyPass: proxyPass ?? false,
            },
          }

          if (communicationChannelValues.proxyProtocol) {
            data = {
              ...data,
              channelConfig: {
                ...data.channelConfig,
                ...httpsSettingValues,
              },
            }
          }

          onSaveIntermediateValues(data)
        }

        setActiveStep()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep])

  return (
    <>
      <WideForm
        items={communicationTypeItem}
        values={communicationTypeValue}
        errors={communicationTypeError}
        onChange={handleCommunicationTypeValue}
      />
      <WideForm
        items={communicationChannelItems}
        values={communicationChannelValues}
        onChange={handleCommunicationChannelValues}
      />
      {isHttps(communicationChannelValues.proxyProtocol) && (
        <WideForm
          items={httpsSettingItems}
          values={httpsSettingValues}
          onChange={handleHttpsSettingValues}
        />
      )}
      <FormControlLabel
        control={
          <Switch
            checked={proxyPass}
            onChange={() => setProxyPass(!proxyPass)}
          />
        }
        label={t('integrations.channels.form.fields.proxyPass')}
      />
      <Toast
        open={hasUpdateStateErrors}
        message={apiUpdateStateErrorsMsg}
        onClose={resetStateUpdate}
      />
    </>
  )
}

const ParametersForm = ({
  nextStep,
  setActiveStep,
  resetNextStep,
  onSaveIntermediateValues,
  data,
  saveAndExit,
  onClose,
}: StepFormProps) => {
  const { t } = useTranslation()

  const { intermediateValues, state } = data
  const { organizationId, siteId } = useCurrentAccount()

  const [paramValues, setParamValues] = useState<Values>({})
  const [paramErrors, setParamErrors] = useState<Errors>({})
  const [paramItems, setParamItems] = useState<FormItemDef[]>([])

  const {
    data: defaultParameters = [],
    isSuccess: isFetchDefaultParamSuccess,
    error: paramError,
  } = useGetDefaultIntegrationParametersQuery({
    organizationId,
    siteId,
    url: intermediateValues?.url,
  })

  const [hasApiFetchErrors, apiFetchErrorMsg] = useApiErrors([paramError])

  const hasProfileParameter = useMemo(() => {
    return defaultParameters.some(
      (p: IntegrationParameter) => p.paramDataType === ParamDataType.Profile
    )
  }, [defaultParameters])

  const profiles = useProfiles(hasProfileParameter)

  const handleParamValues = (key: string, value: string) => {
    if (value.length > 0) {
      clearError(key, paramErrors, setParamErrors)
    }

    setParamValues((prev) => ({ ...prev, [key]: value }))
  }

  const [
    updateState,
    {
      isSuccess: isUpdateStateSuccess,
      error: updateStateError,
      reset: resetStateUpdate,
    },
  ] = useUpdateStateMutation()

  const [hasUpdateStateErrors, apiUpdateStateErrorsMsg] = useApiErrors([
    updateStateError,
  ])

  useEffect(() => {
    if (isUpdateStateSuccess && onClose !== undefined) {
      resetStateUpdate()
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateStateSuccess])

  useEffect(() => {
    if (saveAndExit) {
      updateState({
        organizationId,
        siteId,
        integrationState: {
          ...intermediateValues,
          parameters: Object.keys(paramValues).map((paramKey) => ({
            paramKey,
            paramValue: paramValues[paramKey],
          })),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAndExit])

  useEffect(() => {
    if (isFetchDefaultParamSuccess) {
      let parameters = []
      if (intermediateValues?.parameters !== undefined) {
        parameters = intermediateValues.parameters
      } else if (
        state?.parameters &&
        state?.authMode === intermediateValues?.authMode &&
        (intermediateValues?.authMode !== 'custom' ||
          state?.url === intermediateValues?.url)
      ) {
        parameters = state.parameters
      } else if (defaultParameters.length > 0) {
        parameters = defaultParameters
      }

      setParamValues(
        parameters.reduce((obj: Values, p: IntegrationParameter) => {
          obj[p.paramKey] = p.paramValue
          return obj
        }, {})
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, isFetchDefaultParamSuccess])

  useEffect(() => {
    if (defaultParameters.length > 0) {
      const getOptions = (p: IntegrationParameter) => {
        switch (p.paramDataType) {
          case ParamDataType.SelectOne:
            return (
              p.values?.map((v) => ({ label: v.toString(), value: v })) ?? []
            )
          case ParamDataType.Profile: {
            const profileOptions = profiles.map((profile) => ({
              label: profile.name,
              value: profile.id.toString(),
            }))
            return [
              {
                label: t('installationWizard.integration.useDefault'),
                value: '',
              },
              ...profileOptions,
            ]
          }
        }
      }

      const paramToFormItem = (
        p: IntegrationParameter
      ): FormItemDef | undefined => {
        switch (p.paramDataType) {
          case ParamDataType.String:
            return {
              type: FormItemType.Field,
              key: p.paramKey,
              label: `${p.label}${p.required ? ' *' : ''}`,
              info: getInfo(p),
              nullable: !p.required,
            }
          case ParamDataType.Number:
            return {
              type: FormItemType.Number,
              key: p.paramKey,
              label: `${p.label}${p.required ? ' *' : ''}`,
              info: getInfo(p),
              nullable: !p.required,
            }
          case ParamDataType.Secure:
            return {
              type: FormItemType.Password,
              key: p.paramKey,
              label: `${p.label}${p.required ? ' *' : ''}`,
              info: getInfo(p),
              nullable: !p.required,
            }
          case ParamDataType.SelectMulti:
            return {
              type: FormItemType.SelectMulti,
              key: p.paramKey,
              label: `${p.label}${p.required ? ' *' : ''}`,
              info: getInfo(p),
              nullable: !p.required,
            }
          case ParamDataType.Boolean:
            return {
              type: FormItemType.Switch,
              key: p.paramKey,
              label: `${p.label}${p.required ? ' *' : ''}`,
              info: getInfo(p),
              nullable: !p.required,
            }
          case ParamDataType.Profile:
          case ParamDataType.SelectOne:
            return {
              type: FormItemType.SelectOne,
              key: p.paramKey,
              label: `${p.label}${p.required ? ' *' : ''}`,
              options: getOptions(p),
              info: getInfo(p),
              nullable: !p.required,
            }
        }
      }

      const items = defaultParameters
        .map(paramToFormItem)
        .filter((item): item is FormItemDef => item !== undefined)
      setParamItems(items)
    }
  }, [defaultParameters, profiles, t])

  const handleErrors = (
    items: FormItemDef[],
    values: Values,
    setErrors: Dispatch<SetStateAction<Errors>>
  ) => {
    let hasError = false

    items.forEach(({ type, key, nullable }) => {
      if (!nullable) {
        if (values[key] === undefined || values[key] === '') {
          hasError = true

          setErrors((prev) => ({
            ...prev,
            [key]: t('installationWizard.error.message.empty'),
          }))
        }
      }
    })
    return hasError
  }

  useEffect(() => {
    if (nextStep === 1 || nextStep === 3) {
      let hasError = false

      if (nextStep === 3) {
        hasError = handleErrors(paramItems, paramValues, setParamErrors)
      }

      if (hasError && resetNextStep !== undefined) {
        resetNextStep()
      } else if (onSaveIntermediateValues !== undefined) {
        const params = Object.keys(paramValues).map((paramKey) => ({
          paramKey,
          paramValue: paramValues[paramKey],
        }))
        onSaveIntermediateValues({ parameters: params })
        setActiveStep()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep])

  return (
    <>
      {defaultParameters.length === 0 && isFetchDefaultParamSuccess ? (
        <Typography fontSize="1.2rem" textAlign="center">
          {t('installationWizard.integration.param.noParams')}
        </Typography>
      ) : (
        <WideForm
          items={paramItems}
          values={paramValues}
          errors={paramErrors}
          onChange={handleParamValues}
        />
      )}

      <Toast open={hasApiFetchErrors} message={apiFetchErrorMsg} />
      <Toast
        open={hasUpdateStateErrors}
        message={apiUpdateStateErrorsMsg}
        onClose={resetStateUpdate}
      />
    </>
  )
}

const TestForm = ({
  nextStep,
  setActiveStep,
  data,
  onSaveIntermediateValues,
  saveAndExit,
  onClose,
}: StepFormProps) => {
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()
  const [testCanBorrow] = useTestCanBorrowMutation()

  const { intermediateValues, state } = data
  const profiles = useProfiles(true)

  const [debugModeValues, setDebugModeValues] = useState<Values>({
    debugMode: 'false',
  })
  const [testValues, setTestValues] = useState<Values>({
    assert: 'accept',
  })

  const [logTimestamp, setLogTimestamp] = useState<number | null>(null)
  const [isPolling, setIsPolling] = useState(false)
  const pollingId = useRef<ReturnType<typeof setInterval> | null>(null)

  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  const [detailDialogObject, setDetailDialogObject] = useState<
    Record<any, any>
  >({})

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intermediateValues, state])

  const { data: debugLogs, refetch: refetchDebugLogs } = useLoadDebugLogsQuery({
    organizationId,
    siteId,
    timestamp: logTimestamp ?? new Date().getTime(),
  })

  const [
    addIntegrationWithParameters,
    {
      isSuccess: isUpdateSuccess,
      error: addIntegrationWithParametersError,
      reset: resetUpdate,
    },
  ] = useAddIntegrationWithParamsMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    addIntegrationWithParametersError,
  ])

  useEffect(() => {
    if (isUpdateSuccess) {
      resetUpdate()
      setActiveStep()
    }
  }, [isUpdateSuccess, resetUpdate, setActiveStep])

  const debugModeItem = {
    type: FormItemType.Switch,
    key: 'debugMode',
    label: t('integrations.integrationForm.fields.enableDebugMode.label'),
  }

  const expirationItem = {
    type: FormItemType.Datetime,
    key: 'expiration',
    label: t('integrations.integrationForm.fields.debugModeExpiration.label'),
  }

  const debugModeItems =
    debugModeValues?.debugMode === 'true'
      ? [debugModeItem, expirationItem]
      : [debugModeItem]

  const testItems = [
    {
      type: FormItemType.Field,
      key: 'username',
      label: t('integrations.test.fields.username.label'),
      placeholder: t('integrations.test.fields.username.placeholder'),
      nullable: true,
    },
    {
      type: FormItemType.Field,
      key: 'password',
      label: t('integrations.test.fields.password.label'),
      placeholder: t('integrations.test.fields.password.placeholder'),
      nullable: true,
    },
    {
      type: FormItemType.Field,
      key: 'tabletSerialNumber',
      label: t('integrations.test.table.columns.tablet'),
      placeholder: t('integrations.test.fields.tablet.placeholder'),
      nullable: true,
    },
    {
      type: FormItemType.SelectOne,
      key: 'profile',
      label: t('integrations.test.table.columns.profile'),
      options: profiles.map((p) => ({ label: p.name, value: p.name })),
      placeholder: '-',
      nullable: true,
    },
    {
      type: FormItemType.SelectOne,
      key: 'assert',
      label: t('integrations.test.fields.assert.label'),
      options: [
        { label: t('integrations.test.values.accept'), value: 'accept' },
        { label: t('integrations.test.values.reject'), value: 'reject' },
      ],
    },
  ]

  const handleDebugModeValues = (key: string, value: string | Date) => {
    if (key === 'debugMode' && value === 'false') {
      setDebugModeValues({ debugMode: 'false' })
    } else {
      setDebugModeValues((prev) => ({ ...prev, [key]: value }))
    }
  }

  const handleTestValues = (key: string, value: string) => {
    setTestValues((prev) => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    if (nextStep === 2) {
      if (onSaveIntermediateValues !== undefined) {
        onSaveIntermediateValues({ ...debugModeValues, ...testValues })
      }

      setActiveStep()
    } else if (nextStep === 4) {
      const profile = profiles.find((p) => p.name === testValues?.profile)
      const profileId = profile?.id ?? 0
      addIntegrationWithParameters({
        organizationId,
        siteId,
        data: {
          ...intermediateValues,
          ...debugModeValues,
          ...testValues,
          profileId,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep])

  const pollDebugLogs = () => {
    const intervalSeconds = 1
    const intervalId = setInterval(() => {
      refetchDebugLogs()
    }, intervalSeconds * 1000)

    pollingId.current = intervalId
    setIsPolling(true)

    const timeoutSeconds = 30
    setTimeout(() => {
      clearInterval(intervalId)
      if (pollingId.current === intervalId) {
        pollingId.current = null
        setIsPolling(false)
      }
    }, timeoutSeconds * 1000)
  }

  const startPollingDebugLogs = (d: number) => {
    if (pollingId.current !== null) {
      clearInterval(pollingId.current)
      pollingId.current = null
    }
    setLogTimestamp(d)
    pollDebugLogs()
  }

  useEffect(() => {
    return () => {
      if (pollingId.current !== null) {
        clearInterval(pollingId.current)
        pollingId.current = null
        setIsPolling(false)
      }
    }
  }, [])

  const handleTestCanBorrow = () => {
    const d = new Date().getTime()
    startPollingDebugLogs(d)

    const profile = profiles.find((p) => p.name === testValues?.profile)
    const profileId = profile?.id ?? 0
    testCanBorrow({
      organizationId,
      siteId,
      timestamp: d,
      data: {
        ...intermediateValues,
        ...debugModeValues,
        ...testValues,
        profileId,
      },
    })
  }

  const [
    updateState,
    {
      isSuccess: isUpdateStateSuccess,
      error: updateStateError,
      reset: resetStateUpdate,
    },
  ] = useUpdateStateMutation()

  const [hasUpdateStateErrors, apiUpdateStateErrorsMsg] = useApiErrors([
    updateStateError,
  ])

  useEffect(() => {
    if (isUpdateStateSuccess && onClose !== undefined) {
      resetStateUpdate()
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateStateSuccess])

  useEffect(() => {
    if (saveAndExit) {
      updateState({
        organizationId,
        siteId,
        integrationState: {
          ...intermediateValues,
          ...debugModeValues,
          ...testValues,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAndExit])

  return (
    <>
      <WideForm
        items={debugModeItems}
        values={debugModeValues}
        onChange={handleDebugModeValues}
      />
      <WideForm
        items={testItems}
        values={testValues}
        onChange={handleTestValues}
      />
      <Box my={4}>
        <Button small sx={{ mr: 1 }} onClick={handleTestCanBorrow}>
          {t('integrations.test.table.actions.testBorrow')}
        </Button>
        <Button
          small
          outlined
          disabled={isPolling}
          onClick={() => {
            setLogTimestamp(logTimestamp ?? new Date().getTime() - 1000 * 60)
          }}
        >
          {isPolling
            ? t('integrations.test.fetching_logs')
            : t('integrations.test.fetch_logs')}
        </Button>
      </Box>
      <Box
        style={{
          fontFamily: 'Courier New',
          fontSize: '10pt',
          wordBreak: 'break-all',
        }}
      >
        {debugLogs?.map((l: DebugLog, index: number) => {
          const t = new Date(l.timestamp ?? 0)
          const tString = `${String(t.getHours()).padStart(2, '0')}:${String(
            t.getMinutes()
          ).padStart(2, '0')}:${String(t.getSeconds()).padStart(
            2,
            '0'
          )}.${String(t.getMilliseconds()).padStart(2, '0')}`
          return (
            <p key={index}>
              <span style={{ backgroundColor: '#f0f0f0' }}>{tString}</span>{' '}
              <span style={{ backgroundColor: '#f0f0f0' }}>{l.level}</span>{' '}
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {l.summary}{' '}
                {l?.details?.endpoint ? `(${String(l.details.endpoint)}) ` : ''}
              </span>
              {l?.details != null ? (
                <Button
                  extraSmall
                  textOnly
                  onClick={() => {
                    setDetailDialogObject(l?.details ?? {})
                    setDetailDialogOpen(true)
                  }}
                >
                  DETAILS
                </Button>
              ) : null}
            </p>
          )
        })}
      </Box>
      <Dialog
        open={detailDialogOpen}
        title={t('integrations.test.details')}
        onClose={() => setDetailDialogOpen(false)}
      >
        <JsonView object={detailDialogObject} />
      </Dialog>
      <Toast open={hasApiMutationErrors} message={apiMutationErrorsMsg} />
      <Toast
        open={hasUpdateStateErrors}
        message={apiUpdateStateErrorsMsg}
        onClose={resetStateUpdate}
      />
    </>
  )
}

interface AddIntegrationDialogProps {
  open: boolean
  onClose: () => void
  state: Record<string, any> | undefined | null
}

const AddIntegrationDialog = ({
  open,
  onClose,
  state,
}: AddIntegrationDialogProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const steps = [
    t('installationWizard.integration.step.type'),
    t('installationWizard.integration.step.communication'),
    t('installationWizard.integration.step.parameters'),
    t('installationWizard.integration.step.test'),
  ]

  const [activeStep, setActiveStep] = useState(0)
  const [nextStep, setNextStep] = useState(0)
  const [intermediateValues, setIntermediateValues] = useState<Values>()
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false)

  useEffect(() => {
    if (!open) {
      setActiveStep(0)
      setNextStep(0)
      setIntermediateValues(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleClickBack = () => {
    if (activeStep === 0) {
      onExit()
    } else {
      setNextStep(activeStep - 1)
    }
  }

  const handleSaveAndClose = () => {
    setSaveAndExit(true)
  }

  const onExit = () => {
    setSaveAndExit(false)
    setIntermediateValues(undefined)
    setActiveStep(0)
    setNextStep(0)
    onClose()
  }

  const handleClickFinish = () => {
    onExit()
  }

  return (
    <>
      <WizardDialog
        open={open && activeStep < 4}
        onClose={onClose}
        wide
        bottomBackground
      >
        <Stack
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
            <DialogTitle
              mt={3}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontSize: '2.2rem',
                fontWeight: 'bold',
              })}
            >
              {t('installationWizard.integration.step.title')}
            </DialogTitle>
            {activeStep === 0 && (
              <Typography px={4} pb={3} fontSize="1.2rem">
                {t('installationWizard.integration.step.description')}
              </Typography>
            )}
            <Box width="1112px">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel className={classes.StepperLabel}>
                        {label}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </Box>
            {activeStep === 1 && (
              <Typography px={6} py={1} fontSize="1.2rem">
                {t('installationWizard.integration.communication.description')}
              </Typography>
            )}
            {activeStep === 2 && (
              <Typography px={6} py={1} fontSize="1.2rem">
                {t('installationWizard.integration.parameters.description')}
              </Typography>
            )}
            {activeStep === 3 && (
              <Typography px={6} py={1} fontSize="1.2rem">
                {t('installationWizard.integration.test.description')}
              </Typography>
            )}
            <Box px={2} py={4} width="1112px">
              {activeStep === 0 && (
                <IntegrationTypeForm
                  nextStep={nextStep}
                  setActiveStep={() => setActiveStep(1)}
                  resetNextStep={() => setNextStep(0)}
                  onSaveIntermediateValues={(data) =>
                    setIntermediateValues({ ...intermediateValues, ...data })
                  }
                  data={{ intermediateValues, state }}
                />
              )}
              {activeStep === 1 && (
                <CommunicationForm
                  nextStep={nextStep}
                  setActiveStep={() => setActiveStep(nextStep)}
                  resetNextStep={() => setNextStep(1)}
                  onSaveIntermediateValues={(data) =>
                    setIntermediateValues({ ...intermediateValues, ...data })
                  }
                  data={{ intermediateValues, state }}
                  saveAndExit={saveAndExit}
                  onClose={onExit}
                />
              )}
              {activeStep === 2 && (
                <ParametersForm
                  nextStep={nextStep}
                  setActiveStep={() => setActiveStep(nextStep)}
                  resetNextStep={() => setNextStep(2)}
                  onSaveIntermediateValues={(data) =>
                    setIntermediateValues({ ...intermediateValues, ...data })
                  }
                  data={{ intermediateValues, state }}
                  saveAndExit={saveAndExit}
                  onClose={onExit}
                />
              )}
              {activeStep === 3 && (
                <TestForm
                  nextStep={nextStep}
                  setActiveStep={() => setActiveStep(nextStep)}
                  onSaveIntermediateValues={(data) =>
                    setIntermediateValues({ ...intermediateValues, ...data })
                  }
                  data={{ intermediateValues, state }}
                  saveAndExit={saveAndExit}
                  onClose={onExit}
                />
              )}
            </Box>
          </Stack>
          <Grid container pt={4} pb={6} width="100%">
            <Grid
              container
              xs={6}
              pl={6}
              justifyContent="flex-start"
              alignContent="flex-end"
            >
              {activeStep > 0 && (
                <Button small textOnly onClick={handleSaveAndClose}>
                  {'Save and continue later'}
                </Button>
              )}
            </Grid>
            <Grid
              container
              xs={6}
              pr={6}
              justifyContent="flex-end"
              alignContent="flex-end"
            >
              <Button small outlined onClick={handleClickBack} sx={{ mr: 1 }}>
                {activeStep === 0 ? t('cancel') : t('installationWizard.back')}
              </Button>
              <Button small onClick={() => setNextStep(activeStep + 1)}>
                {t('installationWizard.continue')}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </WizardDialog>

      <WizardDialog
        open={open && activeStep === 4}
        onClose={onClose}
        fullBackground
      >
        <Grid container height="100%">
          <Grid xs={5} container justifyContent="center" alignContent="center">
            <img src={WomanSVG} alt="WomanSVG" style={{ height: '80%' }} />
          </Grid>
          <Grid xs={7}>
            <Stack
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <Stack
                mt={14}
                mr={6}
                sx={{ flexGrow: 1, alignItems: 'flex-start' }}
              >
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  })}
                >
                  {t('installationWizard.integration.done.title')}
                </Typography>
                <Typography mt={2}>
                  {t('installationWizard.integration.done.description')}
                </Typography>
              </Stack>
              <Box
                my={6}
                mr={6}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button small onClick={handleClickFinish}>
                  {t('installationWizard.done')}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </WizardDialog>
    </>
  )
}

export default AddIntegrationDialog

import { Box, List, styled } from '@mui/material'
import {
  AddToHomeScreenRounded,
  BarChartRounded,
  EditCalendarRounded,
  Face2Rounded,
  HandshakeRounded,
  HelpRounded,
  InterestsRounded,
  KeyRounded,
  SettingsRounded,
  SupportRounded,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import logo from 'common/hublet-manager-logo.svg'
import { PATHS } from 'common/constants'
import SideMenu2Item from './SideMenu2Item'
import { SideMenuItem } from './SideMenu2.types'
import { useMemo } from 'react'
import { UserRole } from 'App/app-state'
import SelectLanguage from 'common/components/SelectLanguage'
import useApp from 'common/hooks/useApp'
import useCurrentUser from 'common/hooks/useCurrentUser'

export const SIDE_MENU_WIDTH = 280 // pixels

const StyledContainer = styled(Box)(({ theme }) => ({
  minWidth: `${SIDE_MENU_WIDTH}px`,
  maxWidth: `${SIDE_MENU_WIDTH}px`,
  height: '100vh',
  overflowY: 'scroll',
  boxShadow: 'rgba(108, 37, 118, 0.1) 0px 4px 30px',
}))

const LogoContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3),
  '& img': {
    height: theme.spacing(12.5),
    margin: theme.spacing(1),
  },
}))

const SideMenu2 = () => {
  const { role: currentUserRole } = useCurrentUser()
  const { site } = useApp()

  // One menu array for all users with different visibility permissions
  const menuItems: SideMenuItem[] = useMemo(() => {
    const isHubletAdmin = currentUserRole === UserRole.HubletAdmin
    const isSalesPartnerAdmin = currentUserRole === UserRole.DistributorAdmin
    const isOrgAdmin = currentUserRole === UserRole.OrgAdmin
    const isSiteAdmin = currentUserRole === UserRole.SiteAdmin
    const isOrgAdminOrAbove = isHubletAdmin || isSalesPartnerAdmin || isOrgAdmin
    const isSiteAdminOrAbove = isOrgAdminOrAbove || isSiteAdmin
    // Note: Translator hasn't been included since it is under Hublet Admin menu

    const siteSpecificMenuItem = {
      disabled: !isSiteAdmin,
      disabledNote: 'Context needs to be in a specific site',
    }

    const result: SideMenuItem[] = [
      {
        Icon: InterestsRounded,
        label: 'Dashboard',
        visible: true,
        link: PATHS.dashboard,
      },
      {
        Icon: Face2Rounded,
        label: 'Profiles',
        visible: isSiteAdminOrAbove,
        link: PATHS.profiles,
        ...(!site && siteSpecificMenuItem),
      },
      {
        Icon: EditCalendarRounded,
        label: 'Posts',
        visible: isSiteAdminOrAbove,
        link: PATHS.posts,
        ...(!site && siteSpecificMenuItem),
      },
      {
        Icon: KeyRounded,
        label: 'Codes',
        visible: isSiteAdminOrAbove,
        link: PATHS.codes,
        ...(!site && siteSpecificMenuItem),
      },
      {
        Icon: AddToHomeScreenRounded,
        label: 'Loans',
        visible: isSiteAdminOrAbove,
        link: PATHS.loans,
        ...(!site && siteSpecificMenuItem),
      },
      {
        Icon: BarChartRounded,
        label: 'Analytics',
        visible: isSiteAdminOrAbove,
        link: PATHS.analytics,
      },
      {
        Icon: SettingsRounded,
        label: 'Settings',
        visible: true,
        subItems: [
          {
            label: 'Sites',
            link: PATHS.sites,
            visible: isOrgAdminOrAbove,
          },
          {
            label: 'Users',
            link: PATHS.users,
            visible: isSiteAdminOrAbove,
          },
          {
            label: 'Integrations',
            link: PATHS.integrations,
            visible: isSiteAdminOrAbove,
            ...(!site && siteSpecificMenuItem),
          },
          {
            label: 'Networks',
            link: PATHS.networks,
            visible: isSiteAdminOrAbove,
            ...(!site && siteSpecificMenuItem),
          },
          {
            label: 'Tablets',
            link: PATHS.tablets,
            visible: isSiteAdminOrAbove,
            ...(!site && siteSpecificMenuItem),
          },
          {
            label: 'Docks',
            link: PATHS.docks,
            visible: isSiteAdminOrAbove,
          },
        ],
      },
      {
        Icon: HelpRounded,
        label: 'Help Center',
        visible: true,
        link: PATHS.zendesk,
        external: true,
      },
    ]

    if (currentUserRole === UserRole.DistributorAdmin) {
      result.push({
        Icon: HandshakeRounded,
        label: 'Partner Admin',
        visible: true,
        subItems: [
          {
            label: 'Customers',
            link: PATHS.customers,
            visible: true,
          },
          {
            label: 'Distributor Settings',
            link: PATHS.distributorSettings,
            visible: true,
          },
        ],
      })
    }

    if (currentUserRole === UserRole.HubletAdmin) {
      result.push({
        Icon: SupportRounded,
        label: 'Hublet Admin',
        visible: true,
        subItems: [
          {
            label: 'Super-Admin',
            link: PATHS.admin,
            visible: true,
          },
          {
            label: 'Sales Partners',
            link: PATHS.distributors,
            visible: true,
          },
          {
            label: 'Customers',
            link: PATHS.customers,
            visible: true,
          },
          {
            label: 'Translations',
            link: PATHS.translations,
            visible: window.location.hostname === 'manager.hublet.fi',
          },
          {
            label: 'Top Apps',
            link: PATHS.topApps,
            visible: true,
          },
          {
            label: 'Tracking data',
            link: PATHS.trackingData,
            visible: true,
          },
          {
            label: 'Integration Statistics',
            link: PATHS.integrationStatistics,
            visible: true,
          },
          {
            label: 'Manufacturer Docks',
            link: PATHS.manufacturerDocks,
            visible: true,
          },
          {
            label: 'Manufacturer Tablets',
            link: PATHS.stockTablets,
            visible: true,
          },
        ],
      })
    }
    return result
  }, [currentUserRole, site])

  return (
    <StyledContainer>
      <LogoContainer>
        <Link to="/">
          <img src={logo} alt="Hublet" />
        </Link>
      </LogoContainer>

      <List component="nav">
        {menuItems.map((item) => (
          <SideMenu2Item item={item} key={item.label} />
        ))}
      </List>

      <Box padding={2} display="flex" flexDirection="column">
        <SelectLanguage />
      </Box>
    </StyledContainer>
  )
}

export default SideMenu2

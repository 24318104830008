import type React from 'react'
import { useEffect, useState } from 'react'
import { type File, type FileForm } from 'App/Categories/categories-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useTranslation } from 'react-i18next'
import {
  addFile,
  fetchContents,
  resetAddFile,
  resetUpdateFile,
  resetUploadFileCustomIcon,
  updateFile,
  uploadFileCustomIcon,
} from 'App/Categories/contents-state'
import { useAppDispatch, useAppSelector } from 'store'

export interface FileFormErrors {
  name?: string
  path?: string
}

const initialFileForm = {
  name: '',
  description: '',
  path: '',
}

const useFileForm = (
  profileId: number,
  categoryId: number,
  file?: File
): any => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { siteId, organizationId } = useCurrentAccount()
  const context = { categoryId, profileId, siteId, organizationId }
  const updatedFile = useAppSelector((state) => state.contents.updatedFile)
  const addedFile = useAppSelector((state) => state.contents.addedFile)
  const uploadedFileCustomIconUrl = useAppSelector(
    (state) => state.contents.uploadedFileCustomIconUrl
  )

  const [fileForm, setFileForm] = useState<FileForm>(initialFileForm)
  const [fieldErrors, setFieldErrors] = useState<FileFormErrors>({})

  useEffect(() => {
    if (file != null) {
      setFileForm(file)
    }
  }, [file])

  useEffect(() => {
    if (
      uploadedFileCustomIconUrl !== null &&
      uploadedFileCustomIconUrl !== fileForm.customIcon
    ) {
      setFileForm({ ...fileForm, customIcon: uploadedFileCustomIconUrl })
      dispatch(resetUploadFileCustomIcon())
    }
  }, [dispatch, uploadedFileCustomIconUrl, fileForm])

  useEffect(() => {
    if (updatedFile) {
      dispatch(resetUpdateFile())
      dispatch(fetchContents({ categoryId, profileId, siteId, organizationId }))
    }
  }, [dispatch, updatedFile, categoryId, profileId, siteId, organizationId])

  useEffect(() => {
    if (addedFile) {
      setFileForm(initialFileForm)
      dispatch(resetAddFile())
      dispatch(fetchContents({ categoryId, profileId, siteId, organizationId }))
    }
  }, [dispatch, addedFile, categoryId, profileId, siteId, organizationId])

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFileForm({ ...fileForm, [key]: e.target.value })
    }

  const validateForm = (): boolean => {
    let hasError = false
    const errors: FileFormErrors = {}

    if (fileForm.name === '') {
      hasError = true
      errors.name = t('contents.fileForm.errors.emptyField')
    }

    if (fileForm.path === '') {
      hasError = true
      errors.path = t('contents.fileForm.errors.emptyField')
    }

    setFieldErrors(errors)

    return hasError
  }

  const handleUpdate = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (!file?.id) {
      return
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(updateFile({ context, id: file.id, form: fileForm }))
    }
  }

  const handleAdd = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (!fileForm) {
      return false
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(addFile({ context, form: fileForm }))
    }
  }

  const handleUploadCustomIcon = (imageFile: any) => {
    dispatch(uploadFileCustomIcon({ context, imageFile }))
  }

  return {
    fileForm,
    fieldErrors,
    handleFieldChange,
    handleUploadCustomIcon,
    setFileForm,
    handleUpdate,
    handleAdd,
    updatedFile,
    addedFile,
  }
}

export default useFileForm

import React, { type PropsWithChildren, useEffect } from 'react'
import { Drawer, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ClearIcon from '@mui/icons-material/Clear'
import Tabs from 'common/components/Tabs'
import Tab from 'common/components/Tab'
import TabPanel from 'common/components/TabPanel'
import DrawerPaddingForHelp from 'common/components/DrawerPaddingForHelps'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  Drawer: {
    '& > .MuiDrawer-paper': {
      minWidth: '50%',
      width: '640px',
      maxWidth: '100%',
      boxShadow: 'none',
    },
  },
  DrawerContainer: {
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  DrawerHeader: {
    flex: 'auto',
    flexBasis: 'auto',
    padding: '31px 32px',
    paddingBottom: '0px',
    lineHeight: '1',
  },
  DrawerHeaderText: {
    flexGrow: 1,
    alignSelf: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '24px',
  },
  DrawerHeaderClose: {
    alignSelf: 'center',
    cursor: 'pointer',
    fontSize: '24px',
    marginBottom: '24px',
  },
  DrawerTabs: {
    padding: '0 32px',
  },
  DrawerBody: {
    height: '100%',
    overflow: 'hidden',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },

  DefaultHeader: {
    backgroundColor: '#fff',
  },
  DefaultHeaderText: {
    color: theme.palette.primary.main,
  },
  DefaultHeaderClose: {
    color: '#000',
  },

  HelpHeader: {
    backgroundColor: theme.palette.warning.main,
  },
  HelpHeaderText: {
    color: '#fff',
  },
  HelpHeaderClose: {
    color: '#fff',
  },

  TabPanel: {
    height: '100%',
    overflow: 'auto',
  },
}))

interface TabInfo {
  title: string
  component?: React.ReactNode
}

interface DrawerWithTabsProps extends PropsWithChildren {
  title: string
  open: boolean
  tabs: TabInfo[]
  onClose: () => void
  activeTab?: number
  color?: 'default' | 'help'
}

const DrawerWithTabs = ({
  tabs,
  title,
  open,
  onClose,
  activeTab,
  color = 'default',
}: DrawerWithTabsProps) => {
  const { classes, cx } = useStyles()

  const headerStyles = [classes.DrawerHeader]
  const headerTextStyles = [classes.DrawerHeaderText]
  const headerCloseStyles = [classes.DrawerHeaderClose]

  switch (color) {
    case 'default':
      headerStyles.push(classes.DefaultHeader)
      headerTextStyles.push(classes.DefaultHeaderText)
      headerCloseStyles.push(classes.DefaultHeaderClose)
      break
    case 'help':
      headerStyles.push(classes.HelpHeader)
      headerTextStyles.push(classes.HelpHeaderText)
      headerCloseStyles.push(classes.HelpHeaderClose)
      break
  }

  useEffect(() => setTabIndex(activeTab ?? 0), [activeTab])

  const [tabIndex, setTabIndex] = React.useState(0)
  const handleTabChange = (_: React.ChangeEvent, newIndex: any) => {
    setTabIndex(newIndex)
  }
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  return (
    <Drawer
      anchor={languageDirection === 'ltr' ? 'right' : 'left'}
      open={open}
      onClose={onClose}
      className={classes.Drawer}
    >
      <Grid className={classes.DrawerContainer} container>
        <Grid item xs={12} className={cx(headerStyles)}>
          <Grid container>
            <Grid className={cx(headerTextStyles)} item>
              {title}
            </Grid>
            <Grid className={cx(headerCloseStyles)} item>
              <ClearIcon onClick={onClose} />
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.DrawerTabs}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
          >
            {tabs.map(({ title }: TabInfo) => (
              <Tab key={title} label={title} />
            ))}
          </Tabs>
        </Grid>

        <Grid className={classes.DrawerBody} item xs={12}>
          {tabs.map(({ component }: TabInfo, index) => (
            <TabPanel
              key={index}
              value={tabIndex}
              index={index}
              className={classes.TabPanel}
            >
              {component}
            </TabPanel>
          ))}
        </Grid>
        <DrawerPaddingForHelp />
      </Grid>
    </Drawer>
  )
}

export default DrawerWithTabs

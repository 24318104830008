import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type {
  DebugLog,
  Integration,
  IntegrationTest,
  IntegrationTestAction,
} from 'App/Integrations/integrations-types'
import Panel, { EditState } from 'common/components/NewPanel'
import Table from 'common/components/TableNew'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import IconMenu from 'common/components/IconMenu'
import ActionDialog from 'common/components/ActionDialog'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import type { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  useAddIntegrationTestMutation,
  useGetIntegrationTestsQuery,
  useDeleteIntegrationTestMutation,
  useUpdateIntegrationTestMutation,
  useIntegrationTestCanBorrowMutation,
  useIntegrationTestDoBorrowMutation,
  useIntegrationTestDoReturnMutation,
  useIntegrationTestCheckoutMutation,
  useIntegrationTestCheckinMutation,
} from 'App/Integrations/integrations-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import { useTranslation } from 'react-i18next'

import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknownOutlined'
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendlyOutlined'
import ReturnIcon from '@mui/icons-material/KeyboardReturnOutlined'
import Button from 'common/components/Button'
import Dialog from 'common/components/Dialog'
import { fetchDebugLogs } from 'App/Integrations/integrations-state'
import { useAppDispatch, useAppSelector } from 'store'
import useProfiles from 'common/hooks/useProfiles'
import type { Profile } from 'App/Profiles/profiles-types'
import useApp from 'common/hooks/useApp'
import JsonView from 'common/components/JsonView'

const useStyles = makeStyles()((theme: Theme) => ({
  FormFields: {
    padding: `${theme.spacing(4)} 0`,
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
  TestBorrow: {
    padding: theme.spacing(3),
    '& > h4': {
      marginTop: 0,
    },
  },
  ServerResponse: {
    fontFamily: 'Courier New',
    fontSize: '10pt',
    wordBreak: 'break-all',
    '& > p': {
      margin: 0,
    },
  },
  timestamp: {
    backgroundColor: '#f0f0f0',
  },
  message: {
    whiteSpace: 'pre-wrap',
  },
}))

const useIntegrationTestBorrow = (
  integrationId: number,
  testAction?: IntegrationTestAction
) => {
  const { organizationId, siteId } = useCurrentAccount()

  const [integrationTestCanBorrow] = useIntegrationTestCanBorrowMutation()
  const [integrationTestDoBorrow] = useIntegrationTestDoBorrowMutation()
  const [integrationTestDoReturn] = useIntegrationTestDoReturnMutation()
  const [integrationTestCheckout] = useIntegrationTestCheckoutMutation()
  const [integrationTestCheckin] = useIntegrationTestCheckinMutation()

  useEffect(() => {
    if (testAction == null) return
    const data = {
      organizationId,
      siteId,
      integrationId,
      integrationTestId: testAction.test.id,
    }
    if (testAction.action === 'can-borrow') {
      integrationTestCanBorrow(data)
    } else if (testAction.action === 'do-borrow') {
      integrationTestDoBorrow(data)
    } else if (testAction.action === 'do-return') {
      integrationTestDoReturn(data)
    } else if (testAction.action === 'checkout') {
      integrationTestCheckout(data)
    } else if (testAction.action === 'checkin') {
      integrationTestCheckin(data)
    }
  }, [
    integrationTestCanBorrow,
    integrationTestDoBorrow,
    integrationTestDoReturn,
    testAction,
    organizationId,
    siteId,
    integrationId,
    integrationTestCheckout,
    integrationTestCheckin,
  ])
}

interface IntegrationTestDialogProps {
  integrationId: number
  open: boolean
  onAction: () => void
  onClose: () => void
  variant: 'add' | 'edit'
  integrationTest?: IntegrationTest
  profiles: Profile[]
}

const IntegrationTestDialog = ({
  integrationId,
  open,
  onAction,
  onClose,
  variant,
  integrationTest,
  profiles,
}: IntegrationTestDialogProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { organizationId, siteId } = useCurrentAccount()

  const [username, setUsername] = useState(integrationTest?.username ?? '')
  const [password, setPassword] = useState(integrationTest?.password ?? '')
  const [tabletSerialNumber, setTabletSerialNumber] = useState(
    integrationTest?.tabletSerialNumber ?? ''
  )
  const [profileId, setProfileId] = useState(integrationTest?.profileId ?? -1)
  const [isValid, setIsValid] = useState(integrationTest?.isValid ?? true)

  const [
    addIntegrationTest,
    { isSuccess: isAddIntegrationTestSuccess, error: addIntegrationTestError },
  ] = useAddIntegrationTestMutation()

  const [
    updateIntegrationTest,
    {
      isSuccess: isUpdateIntegrationTestSuccess,
      error: updateIntegrationTestError,
    },
  ] = useUpdateIntegrationTestMutation()

  const isMutationSuccess =
    isAddIntegrationTestSuccess || isUpdateIntegrationTestSuccess
  const mutationErrors = [addIntegrationTestError, updateIntegrationTestError]

  useEffect(() => {
    if (isMutationSuccess) {
      onAction()
    }
  }, [onAction, isMutationSuccess])

  const [hasApiMutationErrors, apiMutationErrorsMsg] =
    useApiErrors(mutationErrors)

  const handleAction = () => {
    const data = {
      organizationId,
      siteId,
      integrationId,
      username,
      password: password || undefined,
      tabletSerialNumber: tabletSerialNumber || undefined,
      profileId: profileId === -1 ? undefined : profileId,
      isValid,
    }

    if (variant === 'add') {
      addIntegrationTest(data)
    } else if (variant === 'edit' && integrationTest !== undefined) {
      updateIntegrationTest({
        ...data,
        integrationTestId: integrationTest.id,
      })
    }
  }

  const profileOptions = useMemo(() => {
    const noProfileOption = { label: '-', value: -1 }
    const profileOptions = profiles.map((p) => ({
      label: p.name,
      value: p.id,
    }))
    return [noProfileOption, ...profileOptions]
  }, [profiles])

  let title = ''
  let actionText = ''

  if (variant === 'add') {
    title = t('integrations.test.add.title')
    actionText = t('integrations.test.add.actionText')
  } else if (variant === 'edit') {
    title = t('integrations.test.edit.title')
    actionText = t('integrations.test.edit.actionText')
  }

  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      title={title}
      actionText={actionText}
      onAction={handleAction}
      closeOnAction={false}
      gray
    >
      {hasApiMutationErrors && (
        <ErrorBox spacingBottom={2}>{apiMutationErrorsMsg}</ErrorBox>
      )}
      <div className={classes.FormFields}>
        <FormField
          label={`${t('integrations.test.fields.username.label')} (${t(
            'common.formField.optional'
          )})`}
          placeholder={t('integrations.test.fields.username.placeholder')}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoFocus
        />
        <FormField
          label={`${t('integrations.test.fields.password.label')} (${t(
            'common.formField.optional'
          )})`}
          placeholder={t('integrations.test.fields.password.placeholder')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormField
          label={`${t('integrations.test.fields.tablet.label')} (${t(
            'common.formField.optional'
          )})`}
          placeholder={t('integrations.test.fields.tablet.placeholder')}
          value={tabletSerialNumber}
          onChange={(e) => setTabletSerialNumber(e.target.value)}
        />
        <FormSelect
          label={t('integrations.test.fields.profile.label')}
          options={profileOptions}
          value={profileId}
          onChange={(_e, v) => setProfileId(v as number)}
        />
        <FormSelect
          label={t('integrations.test.fields.assert.label')}
          value={isValid ? 'accept' : 'reject'}
          options={[
            { label: t('integrations.test.values.accept'), value: 'accept' },
            { label: t('integrations.test.values.reject'), value: 'reject' },
          ]}
          onChange={(_, v) => setIsValid(v === 'accept')}
        />
      </div>
    </ActionDialog>
  )
}

interface IntegrationTestsRowActionsProps {
  integrationTestId: number
  onCanBorrow: (integrationTestId: number) => void
  onDoBorrow: (integrationTestId: number) => void
  onDoReturn: (integrationTestId: number) => void
  onEdit: (integrationTestId: number) => void
  onDelete: (integrationTestId: number) => void
  onCheckout: (integrationTestId: number) => void
  onCheckin: (integrationTestId: number) => void
}

const IntegrationTestsRowActions = ({
  integrationTestId,
  onCanBorrow,
  onDoBorrow,
  onDoReturn,
  onEdit,
  onDelete,
  onCheckout,
  onCheckin,
}: IntegrationTestsRowActionsProps) => {
  const { adminMode } = useApp()

  const actions = [
    {
      local: 'integrations.test.table.actions.testBorrow',
      fn: () => onCanBorrow(integrationTestId),
      icon: DeviceUnknownIcon,
    },
  ]

  if (adminMode) {
    actions.push({
      local: 'Checkout',
      fn: () => onCheckout(integrationTestId),
      icon: MobileFriendlyIcon,
    })
    actions.push({
      local: 'integrations.test.table.actions.doBorrow',
      fn: () => onDoBorrow(integrationTestId),
      icon: MobileFriendlyIcon,
    })
    actions.push({
      local: 'Checkin',
      fn: () => onCheckin(integrationTestId),
      icon: ReturnIcon,
    })
    actions.push({
      local: 'integrations.test.table.actions.doReturn',
      fn: () => onDoReturn(integrationTestId),
      icon: ReturnIcon,
    })
  }

  actions.push({
    local: 'common.table.actions.edit',
    fn: () => onEdit(integrationTestId),
    icon: EditIcon,
  })

  actions.push({
    local: 'common.table.actions.delete',
    fn: () => onDelete(integrationTestId),
    icon: DeleteIcon,
  })

  return <IconMenu actions={actions} />
}

interface IntegrationTestsPanelProps {
  integration: Integration
}

const IntegrationTestsPanel = ({ integration }: IntegrationTestsPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const profiles = useProfiles()

  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  const [detailDialogObject, setDetailDialogObject] = useState<
    Record<any, any>
  >({})
  const [logTimestamp, setLogTimestamp] = useState<number | null>(null)

  const [isPolling, setIsPolling] = useState(false)
  const pollingId = useRef<ReturnType<typeof setInterval> | null>(null)

  const debugLogs = useAppSelector((state) => state.integrations.debugLogs)

  const { organizationId, siteId } = useCurrentAccount()
  const { data: integrationTests = [], refetch } = useGetIntegrationTestsQuery({
    organizationId,
    siteId,
    integrationId: integration.id,
  })

  const pollDebugLogs = useCallback(
    (timestamp: number) => {
      const intervalSeconds = 1
      const intervalId = setInterval(() => {
        dispatch(
          fetchDebugLogs({
            organizationId,
            siteId,
            integrationId: integration.id || 0,
            timestamp: `${timestamp}`,
          })
        )
      }, intervalSeconds * 1000)

      pollingId.current = intervalId
      setIsPolling(true)

      const timeoutSeconds = 30
      setTimeout(() => {
        clearInterval(intervalId)
        if (pollingId.current === intervalId) {
          pollingId.current = null
          setIsPolling(false)
        }
      }, timeoutSeconds * 1000)
    },
    [dispatch, integration.id, organizationId, siteId]
  )

  const startPollingDebugLogs = useCallback(() => {
    if (pollingId.current !== null) {
      clearInterval(pollingId.current)
      pollingId.current = null
    }
    const d = new Date().getTime()
    setLogTimestamp(d)
    pollDebugLogs(d)
  }, [pollDebugLogs])

  useEffect(() => {
    return () => {
      if (pollingId.current !== null) {
        clearInterval(pollingId.current)
        pollingId.current = null
        setIsPolling(false)
      }
    }
  }, [])

  const [editState, setEditState] = useState<EditState>(EditState.Default)

  const [testToDelete, setTestToDelete] = useState<
    IntegrationTest | undefined
  >()
  const [testToEdit, setTestToEdit] = useState<IntegrationTest | undefined>()
  const [testToAction, setTestToAction] = useState<
    IntegrationTestAction | undefined
  >()

  useIntegrationTestBorrow(integration.id, testToAction)

  const [deleteIntegrationTest, { isSuccess: isDeleteIntegrationTestSuccess }] =
    useDeleteIntegrationTestMutation()

  useEffect(() => {
    if (isDeleteIntegrationTestSuccess) {
      setEditState(EditState.Success)
      refetch()
    }
  }, [refetch, isDeleteIntegrationTestSuccess])

  const handleClickCanBorrowAction = useCallback(
    (integrationTestId: number) => {
      const integrationTest = integrationTests.find(
        (t) => t.id === integrationTestId
      )
      if (integrationTest === undefined) return
      startPollingDebugLogs()
      setTestToAction({ action: 'can-borrow', test: integrationTest })
    },
    [integrationTests, startPollingDebugLogs]
  )

  const handleCheckoutAction = useCallback(
    (integrationTestId: number) => {
      const integrationTest = integrationTests.find(
        (t) => t.id === integrationTestId
      )
      if (integrationTest === undefined) return
      startPollingDebugLogs()
      setTestToAction({ action: 'checkout', test: integrationTest })
    },
    [integrationTests, startPollingDebugLogs]
  )

  const handleCheckinAction = useCallback(
    (integrationTestId: number) => {
      const integrationTest = integrationTests.find(
        (t) => t.id === integrationTestId
      )
      if (integrationTest === undefined) return
      startPollingDebugLogs()
      setTestToAction({ action: 'checkin', test: integrationTest })
    },
    [integrationTests, startPollingDebugLogs]
  )

  const handleClickDoBorrowAction = useCallback(
    (integrationTestId: number) => {
      const integrationTest = integrationTests.find(
        (t) => t.id === integrationTestId
      )
      if (integrationTest === undefined) return
      startPollingDebugLogs()
      setTestToAction({ action: 'do-borrow', test: integrationTest })
    },
    [integrationTests, startPollingDebugLogs]
  )

  const handleClickDoReturnAction = useCallback(
    (integrationTestId: number) => {
      const integrationTest = integrationTests.find(
        (t) => t.id === integrationTestId
      )
      if (integrationTest === undefined) return
      startPollingDebugLogs()
      setTestToAction({ action: 'do-return', test: integrationTest })
    },
    [integrationTests, startPollingDebugLogs]
  )

  const handleClickEditAction = useCallback(
    (integrationTestId: number) => {
      const integrationTest = integrationTests.find(
        (t) => t.id === integrationTestId
      )
      if (integrationTest === undefined) return
      setTestToEdit(integrationTest)
    },
    [integrationTests]
  )

  const handleClickDeleteAction = useCallback(
    (integrationTestId: number) => {
      const integrationTest = integrationTests.find(
        (t) => t.id === integrationTestId
      )
      if (integrationTest === undefined) return
      setTestToDelete(integrationTest)
    },
    [integrationTests]
  )

  const handleDeleteIntegrationTestAction = () => {
    if (testToDelete === undefined) return
    deleteIntegrationTest({
      organizationId,
      siteId,
      integrationId: integration.id,
      integrationTestId: testToDelete.id,
    })
  }

  const handleAddIntegrationTestAction = () => {
    setEditState(EditState.Success)
    refetch()
  }

  const handleEditIntegrationTestAction = () => {
    setEditState(EditState.Success)
    setTestToEdit(undefined)
    refetch()
  }

  const columns = useMemo(
    () => [
      {
        title: t('integrations.test.fields.username.label'),
        render: ({ username }: IntegrationTest) => username ?? '-',
      },
      {
        title: t('integrations.test.fields.password.label'),
        render: ({ password }: IntegrationTest) => password ?? '-',
      },
      {
        title: t('integrations.test.table.columns.tablet'),
        render: ({ tabletSerialNumber }: IntegrationTest) =>
          tabletSerialNumber ?? '-',
      },
      {
        title: t('integrations.test.table.columns.profile'),
        render: ({ profileId }: IntegrationTest) => {
          if (profileId == null) {
            return '-'
          } else {
            return profiles.find((p) => p.id === profileId)?.name ?? profileId
          }
        },
      },
      {
        title: t('integrations.test.fields.assert.label'),
        render: ({ isValid }: IntegrationTest) =>
          isValid
            ? t('integrations.test.values.accept')
            : t('integrations.test.values.reject'),
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: ({ id }: IntegrationTest) => (
          <IntegrationTestsRowActions
            integrationTestId={id}
            // TODO: not showing checkout on the actions
            onCanBorrow={handleClickCanBorrowAction}
            onCheckout={handleCheckoutAction}
            onCheckin={handleCheckinAction}
            onDoBorrow={handleClickDoBorrowAction}
            onDoReturn={handleClickDoReturnAction}
            onEdit={handleClickEditAction}
            onDelete={handleClickDeleteAction}
          />
        ),
        style: { width: '24px' },
      },
    ],
    [
      t,
      profiles,
      handleClickCanBorrowAction,
      handleCheckoutAction,
      handleCheckinAction,
      handleClickDoBorrowAction,
      handleClickDoReturnAction,
      handleClickEditAction,
      handleClickDeleteAction,
    ]
  )

  return (
    <>
      <Panel
        title={t('integrations.test.container.label')}
        variant="table"
        editable
        editState={editState}
        onEdit={() => setEditState(EditState.Edit)}
        onCancel={() => setEditState(EditState.Default)}
        onSuccess={() => setEditState(EditState.Default)}
        renderEditMode={() => (
          <IntegrationTestDialog
            integrationId={integration.id}
            open={editState === EditState.Edit}
            onAction={handleAddIntegrationTestAction}
            onClose={() => setEditState(EditState.Default)}
            variant="add"
            profiles={profiles}
          />
        )}
      >
        <Table columns={columns} data={integrationTests} variant="panel" />
      </Panel>
      <Panel
        title={t('integrations.test.debug_logs')}
        topRight={
          <Button
            onClick={() => {
              dispatch(
                fetchDebugLogs({
                  organizationId,
                  siteId,
                  integrationId: integration.id || 0,
                  timestamp: `${
                    logTimestamp ?? new Date().getTime() - 1000 * 60
                  }`,
                })
              )
            }}
            extraSmall
            outlined
            disabled={isPolling}
          >
            {isPolling
              ? t('integrations.test.fetching_logs')
              : t('integrations.test.fetch_logs')}
          </Button>
        }
      >
        <div className={classes.ServerResponse}>
          {debugLogs.map((l: DebugLog, index: number) => {
            const t = new Date(l.timestamp ?? 0)
            const tString = `${String(t.getHours()).padStart(2, '0')}:${String(
              t.getMinutes()
            ).padStart(2, '0')}:${String(t.getSeconds()).padStart(
              2,
              '0'
            )}.${String(t.getMilliseconds()).padStart(2, '0')}`
            return (
              <p key={index}>
                <span className={classes.timestamp}>{tString}</span>{' '}
                <span className={classes.timestamp}>{l.level}</span>{' '}
                <span className={classes.message}>
                  {l.summary}{' '}
                  {l?.details?.endpoint
                    ? `(${String(l.details.endpoint)}) `
                    : ''}
                </span>
                {l?.details != null ? (
                  <Button
                    extraSmall
                    textOnly
                    onClick={() => {
                      setDetailDialogObject(l?.details ?? {})
                      setDetailDialogOpen(true)
                    }}
                  >
                    DETAILS
                  </Button>
                ) : null}
              </p>
            )
          })}
        </div>
      </Panel>
      <Dialog
        open={detailDialogOpen}
        title={t('integrations.test.details')}
        onClose={() => setDetailDialogOpen(false)}
      >
        <JsonView object={detailDialogObject} />
      </Dialog>
      {testToDelete != null && (
        <ActionDialog
          open={testToDelete !== undefined}
          title={t('integrations.test.delete.title')}
          description={t('integrations.test.delete.description')}
          actionText={t('integrations.test.delete.action')}
          onAction={handleDeleteIntegrationTestAction}
          onClose={() => setTestToDelete(undefined)}
        />
      )}
      {testToEdit != null && (
        <IntegrationTestDialog
          integrationId={integration.id}
          open={testToEdit !== undefined}
          onAction={handleEditIntegrationTestAction}
          onClose={() => setTestToEdit(undefined)}
          variant="edit"
          integrationTest={testToEdit}
          profiles={profiles}
        />
      )}
    </>
  )
}

interface IntegrationTestProps {
  integration: Integration
}

const IntegrationTestTab = ({ integration }: IntegrationTestProps) => {
  return (
    <>
      <IntegrationTestsPanel integration={integration} />
    </>
  )
}

export default IntegrationTestTab

const toString = (date: Date) => {
  const parts = [
    date.getFullYear(),
    // .getMonth() returns a value between 0-11, so we add 1 to get standard numeric months
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
  ]

  return parts.join('-')
}

export const toDateFieldValue = (date?: string | Date) => {
  if (date === undefined) {
    return undefined
  }

  if (typeof date === 'string') {
    return toString(new Date(date))
  }

  return toString(date)
}

export const getFirstDayOfCurrentMonth = () => {
  const today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), 1)
}

export const getTomorrow = () => {
  const today = new Date()
  today.setDate(today.getDate() + 1)
  return today
}

import React from 'react'
import { Autocomplete, Grid, TextField } from '@mui/material'
import { type SelectOption } from 'App/Analytics/Analytics.types'
import { useTranslation } from 'react-i18next'

interface SelectTargetGridProps {
  show?: boolean
  compact?: boolean
  options?: SelectOption[]
  selectedOption: SelectOption | null
  onChange: (newValue: SelectOption | null) => void
  label: string
}

const SelectTargetGrid = ({
  show,
  compact,
  options,
  selectedOption,
  onChange,
  label,
}: SelectTargetGridProps) => {
  const { t } = useTranslation()

  if (!show) {
    return null
  }

  return (
    <Grid
      item
      xs={12}
      sm={compact ? 6 : 12}
      md={compact ? 6 : 4}
      lg={compact ? 3 : 4}
    >
      <Autocomplete
        options={options ?? []}
        size="small"
        value={selectedOption}
        onChange={(event, newValue) => onChange(newValue)}
        getOptionLabel={(option) =>
          option.label ||
          (option.id
            ? `${t('analytics.unnamed')} (ID: ${option.id})`
            : t('analytics.unnamed'))
        }
        noOptionsText={t('analytics.noOptions')}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Grid>
  )
}

export default SelectTargetGrid

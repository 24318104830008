import React from 'react'
import { styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

const NoDataContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))

export const NoData = () => {
  const { t } = useTranslation()
  return <NoDataContainer>{t('analytics.noData')}</NoDataContainer>
}

export const Instruction = () => {
  const { t } = useTranslation()
  return <NoDataContainer>{t('analytics.instruction3')}</NoDataContainer>
}

import React, {
  useEffect,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material'
import WizardDialog from 'common/components/WizardDialog'
import Button from 'common/components/Button'
import { LockOutlined, WifiOutlined } from '@mui/icons-material'
import {
  NetworkSecurityMode,
  SSIDVisibility,
} from 'App/Networks/networks-types'
import WideForm, {
  FormItemType,
  type FormItemDef,
} from 'common/components/WideForm'
import StepDoneDialog from 'App/InstallationWizard/components/StepDoneDialog'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { addNetwork, resetAddNetwork } from 'App/Networks/networks-state'
import { useAppDispatch, useAppSelector } from 'store'

interface AddNetworkDialogProps {
  open: boolean
  onClose: () => void
}

const AddNetworkDialog = ({ open, onClose }: AddNetworkDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const addedNetwork = useAppSelector((state) => state.networks.addedNetwork)

  const secModes = [
    {
      value: NetworkSecurityMode.OPEN,
      label: t('networks.securityMode.open'),
      Icon: WifiOutlined,
    },
    {
      value: NetworkSecurityMode.WPA_PSK,
      label: t('networks.securityMode.wpaPersonal'),
      Icon: LockOutlined,
    },
    {
      value: NetworkSecurityMode.WPA2_PSK,
      label: t('networks.securityMode.wpa2Personal'),
      Icon: LockOutlined,
    },
    {
      value: NetworkSecurityMode.WPA_ENTERPRISE,
      label: t('networks.securityMode.wpaEnterprise'),
      Icon: LockOutlined,
    },
  ]

  const visibilities = [
    {
      value: SSIDVisibility.BROADCAST,
      label: t('networks.ssidVisibility.broadcast'),
    },
    {
      value: SSIDVisibility.HIDDEN,
      label: t('networks.ssidVisibility.hidden'),
    },
  ]

  const defaultNetworkValues = {
    hidden: visibilities[0].value,
  }

  type Values = Record<string, string | number>
  type Errors = Record<string, string>

  const [networkValues, setNetworkVaules] =
    useState<Values>(defaultNetworkValues)
  const [networkErrors, setNetworkErrors] = useState<Errors>({})
  const [enableCaptivePortal, setEnableCaptivePortal] = useState(false)
  const [captiveProtalValues, setCaptiveProtalVaules] = useState<Values>({})
  const [addNetworkSucceed, setAddNetworkSucceed] = useState<boolean>(false)

  useEffect(() => {
    if (networkValues?.securityMode === NetworkSecurityMode.OPEN) {
      clearError('password', networkErrors, setNetworkErrors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkValues?.securityMode])

  const networkItems: FormItemDef[] = [
    {
      type: FormItemType.SelectOne,
      key: 'securityMode',
      label: t('installationWizard.network.securityMode.form.label'),
      placeholder: t(
        'installationWizard.network.securityMode.form.placeholder'
      ),
      options: secModes,
    },
    {
      type: FormItemType.Field,
      key: 'ssid',
      label: t('installationWizard.network.ssid.form.label'),
      placeholder: t('installationWizard.network.ssid.form.placeholder'),
    },
    {
      type: FormItemType.Password,
      key: 'password',
      label: t('installationWizard.network.password.form.label'),
      placeholder: t('installationWizard.network.password.form.placeholder'),
      nullable: networkValues?.securityMode === NetworkSecurityMode.OPEN,
    },
    {
      type: FormItemType.SelectOne,
      key: 'hidden',
      label: t('installationWizard.network.hidden.form.label'),
      options: visibilities,
    },
  ]

  const captivePortalItems: FormItemDef[] = [
    {
      type: FormItemType.Field,
      key: 'captivePortalUsername',
      label: t('installationWizard.network.captivePortalUsername.form.label'),
      placeholder: t(
        'installationWizard.network.captivePortalUsername.form.placeholder'
      ),
      nullable: true,
    },
    {
      type: FormItemType.Password,
      key: 'captivePortalPassword',
      label: t('installationWizard.network.captivePortalPassword.form.label'),
      placeholder: t(
        'installationWizard.network.captivePortalPassword.form.placeholder'
      ),
      nullable: true,
    },
  ]

  const handleErrors = (
    items: FormItemDef[],
    values: Values,
    setErrors: Dispatch<SetStateAction<Errors>>
  ) => {
    let hasError = false

    items.forEach(({ key, nullable }) => {
      if (!nullable && (values[key] === undefined || values[key] === '')) {
        hasError = true

        setErrors((prev) => ({
          ...prev,
          [key]: t('installationWizard.error.message.empty'),
        }))
      }
    })

    return hasError
  }

  const clearError = (
    key: string,
    errors: Errors,
    setErrors: Dispatch<SetStateAction<Errors>>
  ) => {
    if (key in errors) {
      const { [key]: _, ...newErrors } = errors
      setErrors(newErrors)
    }
  }

  const handleNetworkValues = (key: string, value: string) => {
    if (value.length > 0) {
      clearError(key, networkErrors, setNetworkErrors)
    }
    setNetworkVaules((prev) => ({ ...prev, [key]: value }))
  }

  const handleCaptiveProtalValues = (key: string, value: string) => {
    setCaptiveProtalVaules((prev) => ({ ...prev, [key]: value }))
  }

  const handleClickDone = () => {
    const hasError = handleErrors(networkItems, networkValues, setNetworkErrors)

    if (!hasError) {
      let form = { ...networkValues, enableCaptivePortal }

      if (enableCaptivePortal) {
        form = { ...form, ...captiveProtalValues }
      }

      dispatch(addNetwork({ form, siteId, organizationId }))
    }
  }

  const handleCancel = () => {
    setNetworkVaules(defaultNetworkValues)
    setNetworkErrors({})
    setEnableCaptivePortal(false)
    setCaptiveProtalVaules({})
    setAddNetworkSucceed(false)
    onClose()
  }

  const handleClose = () => {
    dispatch(resetAddNetwork())
    handleCancel()
  }

  useEffect(() => {
    if (addedNetwork) {
      setAddNetworkSucceed(true)
    }
  }, [addedNetwork])

  return (
    <>
      <WizardDialog
        open={open && !addNetworkSucceed}
        onClose={onClose}
        wide
        bottomBackground
        height="800px"
      >
        <Stack
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
            <DialogTitle
              mt={3}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontSize: '2.2rem',
                fontWeight: 'bold',
              })}
            >
              {t('installationWizard.network.title')}
            </DialogTitle>
            <Typography py={1} fontSize="1.2rem">
              {t('installationWizard.network.descritpion')}
            </Typography>
            <Box px={2} pt={2} width="1112px">
              <WideForm
                items={networkItems}
                values={networkValues}
                errors={networkErrors}
                onChange={handleNetworkValues}
              />
            </Box>
            <Box
              px={2}
              pt={2}
              width="1112px"
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={enableCaptivePortal}
                    onChange={(_e, checked) => setEnableCaptivePortal(checked)}
                  />
                }
                label={t('networks.networkForm.enableCaptivePortal.label')}
              />
            </Box>
            {enableCaptivePortal && (
              <Box px={2} pb={2} width="1112px">
                <WideForm
                  items={captivePortalItems}
                  values={captiveProtalValues}
                  onChange={handleCaptiveProtalValues}
                />
              </Box>
            )}
          </Stack>
          <DialogActions
            sx={{
              pt: 4,
              pb: 6,
              pr: 6,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button small outlined onClick={handleCancel} sx={{ mr: 1 }}>
              {t('cancel')}
            </Button>
            <Button small onClick={handleClickDone}>
              {t('installationWizard.done')}
            </Button>
          </DialogActions>
        </Stack>
      </WizardDialog>

      <StepDoneDialog
        title={t('installationWizard.network.title')}
        description={t('installationWizard.network.done.description')}
        open={addNetworkSucceed}
        onClose={handleClose}
      />
    </>
  )
}

export default AddNetworkDialog

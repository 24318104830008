import React, { useEffect, useState } from 'react'

import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import Panel from 'common/components/Panel'
import Switch from 'common/components/Switch'

import {
  type Distributor,
  type DistributorForm,
} from 'App/Distributors/distributors-types'
import { FormControlLabel, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  fetchDistributors,
  resetUpdatedDistributor,
  updateDistributor,
} from 'App/Distributors/distributors-state'
import { renderIf } from 'common/utils/render-utils'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import { useAppDispatch, useAppSelector } from 'store'

interface EditDistributorDrawerProps {
  distributor?: Distributor
  open: boolean
  onClose: () => void
  updatedDistributor: boolean
}

interface FieldErrors {
  name?: string
  distributorId?: string
}

const initialDistributorForm = {
  name: '',
  isDistributor: true,
  isManufacturer: false,
}

const useStyles = makeStyles()((theme: Theme) => ({
  ManufacturerFlag: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  ManufacturerFlagDescription: {
    marginBottom: theme.spacing(2),
  },
  Field: {
    marginTop: theme.spacing(4),
    '&:last-child': {
      marginBottom: '0',
    },
  },
}))

const EditDistributorDrawer = ({
  distributor,
  open,
  onClose,
  updatedDistributor,
}: EditDistributorDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { classes } = useStyles()

  const [distributorForm, setDistributorForm] = useState<DistributorForm>(
    initialDistributorForm
  )
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  const distributors = useAppSelector((state) => state.app.distributors)
  useEffect(() => {
    dispatch(fetchDistributors())
  }, [dispatch])

  useEffect(() => {
    if (updatedDistributor) {
      onClose()
      setDistributorForm(initialDistributorForm)
      dispatch(resetUpdatedDistributor())
      dispatch(fetchDistributors())
    }
  }, [dispatch, onClose, updatedDistributor])

  useEffect(() => {
    if (distributor != null) {
      setDistributorForm(distributor)
    }
  }, [distributor])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setDistributorForm({ ...distributorForm, [key]: e.target.value })
    }

  const onChangeSwitch =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setDistributorForm({ ...distributorForm, [key]: e.target.checked })
    }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    if (distributor == null) {
      return false
    }

    let hasError = false
    const errors: FieldErrors = {}

    if (distributorForm.name === '') {
      hasError = true
      errors.name = t('distributors.editDistributor.errors.emptyField')
    }

    if (!hasError) {
      dispatch(
        updateDistributor({
          distributorId: distributor.id,
          form: distributorForm,
        })
      )
    }

    setFieldErrors(errors)
  }

  useEffect(() => {
    if (
      distributors.length > 0 &&
      (distributorForm.distributorId === undefined ||
        distributorForm.distributorId < 1)
    ) {
      const initialDistributorValue = distributors.find(
        (obj) => obj.id !== distributor?.id
      )?.id
      setDistributorForm({
        ...distributorForm,
        distributorId: initialDistributorValue,
      })
    }
  }, [distributor?.id, distributorForm, distributors])

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      title={t('distributors.editDistributor.title')}
      submitText={t('distributors.editDistributor.buttons.submit')}
      autoMargins
    >
      <FormField
        label={t('distributors.editDistributor.distributorNameField.label')}
        placeholder={t(
          'distributors.editDistributor.distributorNameField.placeholder'
        )}
        value={distributorForm.name}
        onChange={onChangeField('name')}
        autoFocus
        error={fieldErrors.name}
      />
      <FormField
        label={t('distributors.editDistributor.partnerIdField.label')}
        placeholder="-"
        value={distributor?.hubletId ?? '-'}
        readOnly
      />
      <div className={classes.ManufacturerFlag}>
        <Panel title={t('distributors.distributorFlag.title')}>
          <div className={classes.ManufacturerFlagDescription}>
            {t('distributors.distributorFlag.description')}
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={distributorForm.isDistributor}
                onChange={onChangeSwitch('isDistributor')}
              />
            }
            label={t('distributors.distributorFlag.enable')}
          />
          {renderIf(distributorForm.isDistributor !== true, () => (
            <FormSelect
              className={classes.Field}
              label={t(
                'distributors.addDistributor.distributorNameField.label'
              )}
              value={distributorForm.distributorId}
              onChange={onChangeField('distributorId')}
              error={fieldErrors.distributorId}
              options={distributors
                .map((distributor) => ({
                  value: distributor.id,
                  label: distributor.name,
                }))
                .filter((obj) => obj.value !== distributor?.id)}
            />
          ))}
        </Panel>
      </div>

      <div className={classes.ManufacturerFlag}>
        <Panel title={t('distributors.manufacturerFlag.title')}>
          <div className={classes.ManufacturerFlagDescription}>
            {t('distributors.manufacturerFlag.description')}
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={distributorForm.isManufacturer}
                onChange={onChangeSwitch('isManufacturer')}
              />
            }
            label={t('distributors.manufacturerFlag.enable')}
          />
        </Panel>
      </div>
    </DrawerForm>
  )
}

export default EditDistributorDrawer

import React, { useMemo } from 'react'
import { Alert, LinearProgress, Stack, Typography } from '@mui/material'
import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Table, {
  type TableColumn,
  TableColumnFilter,
} from 'common/components/TableNew'
import TableActionsBar from 'common/components/TableActionsBar'
import GroupAddIcon from '@mui/icons-material/GroupAddOutlined'
import {
  useGetAwsCognitoImportUsersQuery,
  useImportUsersToAwsCognitoMutation,
} from 'App/SuperAdmin/super-admin-rtk-api'

interface UsersTableActionsBarProps {
  selectedUserIds: number[]
  usersTotalCount: number
  usersSentCount: number
}

function UsersTableActionsBar({
  selectedUserIds,
  usersTotalCount,
  usersSentCount,
}: UsersTableActionsBarProps) {
  const [AwsCognitoimportUsers] = useImportUsersToAwsCognitoMutation()
  const selectedUserIdsActions = useMemo(() => {
    const actions = [
      {
        local: 'Import users',
        fn: () => {
          AwsCognitoimportUsers({ userIds: selectedUserIds })
        },
        icon: GroupAddIcon,
      },
    ]
    return actions
  }, [AwsCognitoimportUsers, selectedUserIds])

  const severity = useMemo(() => {
    if (usersSentCount === 0) {
      return 'info'
    }
    if (usersSentCount < usersTotalCount) {
      return 'warning'
    }
    return 'success'
  }, [usersSentCount, usersTotalCount])

  return (
    <TableActionsBar
      selectedRows={selectedUserIds}
      selectedRowsActions={selectedUserIdsActions}
    >
      <Alert severity={severity}>
        Imported {usersSentCount} / {usersTotalCount} users
      </Alert>
    </TableActionsBar>
  )
}

export interface AwsCognitoImportUser {
  id: number
  email: string
  createdAt: string
  lastLoginAt?: string
  preReleaseSent: boolean
  inviteSentAt?: string
  cognitoStatus?: string
}

const UsersTable = () => {
  const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>([])

  const handleSelectUsers = React.useCallback(
    (users: AwsCognitoImportUser[]) => {
      setSelectedUserIds(users.map((user) => user.id))
    },
    []
  )

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        title: 'Email',
        field: 'email',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Created at',
        field: 'createdAt',
        filter: TableColumnFilter.DateRange,
        canSort: true,
      },
      {
        title: 'Last login',
        field: 'lastLoginAt',
        render: (model: AwsCognitoImportUser) =>
          model.lastLoginAt ? model.lastLoginAt : '-',
        filter: TableColumnFilter.DateRange,
        canSort: true,
      },
      {
        title: 'Pre-release sent',
        field: 'preReleaseSent',
        render: (model: AwsCognitoImportUser) =>
          model.preReleaseSent ? 'Yes' : 'No',
        filter: TableColumnFilter.Boolean,
        canSort: true,
      },
      {
        title: 'Invite sent',
        field: 'inviteSentAt',
        render: (model: AwsCognitoImportUser) =>
          model.inviteSentAt ? model.inviteSentAt : '-',
        filter: TableColumnFilter.Action,
        canSort: true,
      },
      {
        title: 'Cognito status',
        field: 'cognitoStatus',
        filter: TableColumnFilter.Text,
        canSort: true,
        render: (model: AwsCognitoImportUser) =>
          model.cognitoStatus ? model.cognitoStatus : '-',
      },
    ]
  }, [])

  const { data: usersData } = useGetAwsCognitoImportUsersQuery()

  const usersTotalCount = usersData?.length ?? 0
  const usersSentCount = useMemo(() => {
    return usersData?.filter((user) => user.inviteSentAt != null).length ?? 0
  }, [usersData])

  if (usersData == null) {
    return <LinearProgress />
  }

  return (
    <>
      <Stack>
        <UsersTableActionsBar
          usersTotalCount={usersTotalCount}
          usersSentCount={usersSentCount}
          selectedUserIds={selectedUserIds}
        />
        <Table
          data={usersData}
          columns={columns}
          selectable
          onSelectionChange={handleSelectUsers}
        />
      </Stack>
    </>
  )
}

function AwsCognitoUserImport() {
  return (
    <Page>
      <PageHeader>
        <Stack>
          <Typography
            sx={(theme) => ({
              fontWeight: 600,
              color: theme.palette.secondary.main,
            })}
          >
            Super-admin
          </Typography>
          <PageTitle title="AWS Cognito: User import" />
        </Stack>
      </PageHeader>
      <PageBody>
        <Stack gap={2}>
          <Typography variant="h2">Users</Typography>
          <UsersTable />
        </Stack>
      </PageBody>
    </Page>
  )
}

export default AwsCognitoUserImport

import React, { useEffect, useMemo, useState } from 'react'
import { type TabletDataForAdminReview } from 'App/SuperAdmin/super-admin-types'
import DrawerForm from 'common/components/DrawerForm'
import { Stack } from '@mui/material'
import { useReviewTabletDataMutation } from 'App/SuperAdmin/super-admin-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import DataTagsField from 'common/components/DataTagsField'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import { StockState } from 'App/StockTablets/stock-tablets-types'

interface DataReviewTabletDrawerProps {
  open: boolean
  onClose: () => void
  onSaved: (data: TabletDataForAdminReview) => void
  data: TabletDataForAdminReview
}

const DataReviewTabletDrawer = ({
  open,
  onClose,
  onSaved,
  data,
}: DataReviewTabletDrawerProps) => {
  const [tags, setTags] = useState<string[]>(data.tags)
  const [tabletLocation, setTabletLocation] = useState(data.tabletLocation)
  const [tabletStockStatus, setTabletStockStatus] = useState(
    data.tabletStockStatus
  )

  const [
    reviewTabletData,
    {
      isLoading: isReviewingTabletData,
      error: reviewTabletDataError,
      isSuccess: isSiteDataReviewed,
      data: reviewedSiteData,
    },
  ] = useReviewTabletDataMutation()

  useEffect(() => {
    if (isSiteDataReviewed && reviewedSiteData) {
      onSaved(reviewedSiteData)
    }
  }, [isSiteDataReviewed, onSaved, reviewedSiteData])

  const [hasApiError, apiError] = useApiErrors([reviewTabletDataError])

  const handleSubmit = () => {
    const updatedTabletData: TabletDataForAdminReview = {
      id: data.id,
      tabletSerialNumber: data.tabletSerialNumber,
      isReviewed: true,
      tabletLocation,
      tabletStockStatus,
      tags,
    }

    reviewTabletData(updatedTabletData)
  }

  const tabletLocationOptions = useMemo(
    () => [
      { value: 'Customer', label: 'Customer' },
      { value: 'Stock', label: 'Stock' },
    ],
    []
  )

  const tabletStockOptions = useMemo(
    () => [
      { value: StockState.NEW, label: StockState.NEW },
      { value: StockState.ASSIGNED, label: StockState.ASSIGNED },
      { value: StockState.REMOVED, label: StockState.REMOVED },
      { value: StockState.RETURNED, label: StockState.RETURNED },
    ],
    []
  )

  return (
    <DrawerForm
      title="Review tablet data"
      open={open}
      onClose={onClose}
      submitText="Save"
      onSubmit={handleSubmit}
      disabledButton={isReviewingTabletData}
    >
      <Stack spacing={3}>
        {hasApiError && <ErrorBox>{apiError}</ErrorBox>}
        <Stack direction="row" spacing={2}>
          <FormField
            label="Tablet serial number"
            value={data.tabletSerialNumber}
            dense
            readOnly
          />
          <FormField
            label="Tablet MAC address"
            value={data.tabletMacAddress}
            dense
            readOnly
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormField
            label="Tablet OS version"
            value={data.tabletOsVersion}
            dense
            readOnly
          />
          <FormField
            label="Tablet app version"
            value={data.tabletAppVersion}
            dense
            readOnly
          />
        </Stack>
        <FormField
          label="Tablet active at"
          value={data.tabletActiveAt}
          dense
          readOnly
        />
        <FormSelect
          label="Tablet location"
          value={tabletLocation}
          options={tabletLocationOptions}
          onChange={(e) => setTabletLocation(e.target.value)}
          dense
        />
        {tabletLocation === 'Customer' && (
          <Stack direction="row" gap={2}>
            <FormField
              label="Organization"
              value={data.organizationName}
              dense
              readOnly
            />
            <FormField label="Site" value={data.siteName} dense readOnly />
          </Stack>
        )}
        {tabletLocation === 'Stock' && (
          <FormSelect
            label="Tablet stock status"
            value={tabletStockStatus}
            options={tabletStockOptions}
            onChange={(e) => setTabletStockStatus(e.target.value)}
            dense
          />
        )}
        <DataTagsField
          dataType="tablet"
          tags={tags}
          onChange={(tags) => setTags(tags)}
          disabled={isReviewingTabletData}
          dense
        />
      </Stack>
    </DrawerForm>
  )
}

export default DataReviewTabletDrawer

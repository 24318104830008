import React from 'react'
import { Tabs as MuiTabs, type TabsProps, type Theme } from '@mui/material'

import { withStyles } from 'tss-react/mui'

/*
  `StyledTabsProps` is hack to fix a Material UI type bug:
  https://github.com/mui-org/material-ui/issues/17454
*/

interface StyledTabsProps extends Omit<TabsProps, 'onChange'> {
  onChange: (event: React.ChangeEvent<any>, newValue: number) => void
}

const Tabs = withStyles(
  (props: StyledTabsProps) => (
    <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />
  ),
  (theme: Theme) => ({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height: '3px',
      '& > span': {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
)

export default Tabs

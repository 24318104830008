import React, { useEffect, useState } from 'react'
import { Box, Fade, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CheckIcon from '@mui/icons-material/CheckOutlined'

const useStyles = makeStyles()((theme: Theme) => ({
  LineToast: {
    display: 'inline-block',
    marginLeft: '16px',
    backgroundColor: '#5BC980',
    borderRadius: '30px',
    padding: '4px 12px',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    '& .MuiSvgIcon-root': {
      marginRight: '6px',
      verticalAlign: 'middle',
      fontSize: '12px',
    },
  },
}))

interface LineToastProps {
  messages: string[] // length == 0 -> hidden, length > 1 -> consecutive toasts
  onClose?: (messages: string[]) => void
}

const LineToast = ({ messages, onClose }: LineToastProps) => {
  const { classes } = useStyles()
  const [message, setMessage] = useState<string | undefined>()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (messages.length > 0) {
      setMessage(messages[0])
      setOpen(true)

      setTimeout(() => {
        if (onClose !== undefined) {
          onClose(messages.slice(1))
        }
        setOpen(false)
      }, 1250)
    } else {
      setOpen(false)
    }
  }, [messages, message, open, onClose])

  return (
    <Fade in={open} timeout={400} onExited={() => setMessage(undefined)}>
      <Box className={classes.LineToast}>
        <CheckIcon />
        {message}
      </Box>
    </Fade>
  )
}

export default LineToast

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography } from '@mui/material'
import WizardDialog from 'common/components/WizardDialog'
import Button from 'common/components/Button'
import HubletBanner from 'common/components/HubletBanner'

interface NotificationProps {
  open: boolean
  onAction: () => void
  onClose: () => void
}

const NotificationDialog = ({ open, onAction, onClose }: NotificationProps) => {
  const { t } = useTranslation()

  return (
    <WizardDialog open={open} onClose={onClose} width="1164px" height="734px">
      <HubletBanner />
      <Box
        sx={{
          position: 'absolute',
          top: '43px',
          left: '64px',
          width: '415px',
        }}
      >
        <Typography variant="h1" fontSize="4rem" fontWeight={900}>
          {t('installationWizard.notification.header')}
        </Typography>
      </Box>
      <Stack textAlign="center" alignItems="center" mt={2} gap={3}>
        <Typography variant="h1" fontSize="2rem" fontWeight={800}>
          {t('installationWizard.notification.title')}
        </Typography>
        <Typography variant="body1" width="637px">
          {t('installationWizard.notification.description')}
        </Typography>
        <Button onClick={onAction} small>
          {t('installationWizard.notification.start')}
        </Button>
      </Stack>
    </WizardDialog>
  )
}

export default NotificationDialog

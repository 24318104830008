export const API_BASE_URL = '/api/v2/web'

// TODO: there are a few other paths in the App.tsx which haven't been added here
export const PATHS = {
  /**
   * public paths
   */
  forceChangePassword: '/force-change-password',
  forgotPassword: '/forgot-password',
  login: '/login',
  root: '/',
  sso: '/sso',
  zendesk: '/zendesk',

  /**
   * paths for normal users and above
   */
  dashboard: '/dashboard',
  account: '/account',

  /**
   * paths for SiteAdmin and above
   */
  analytics: '/analytics',
  codes: '/codes',
  docks: '/docks',
  integrations: '/integrations',
  loans: '/loans',
  networks: '/networks',
  posts: '/posts',
  profiles: '/profiles',
  siteSettings: '/site-settings',
  tablets: '/tablets',
  users: '/users',

  /**
   * paths for OrgAdmin and above
   */
  customerSettings: '/customer-settings',
  sites: '/sites',

  /**
   * paths for DistributorAdmin and above
   */
  customers: '/customers',
  distributorSettings: '/distributor-settings', // TODO: change to Partner Settings?

  /**
   * paths specifically for manufacturer
   */
  manufacturerDocks: '/manufacturer/docks',
  stockTablets: '/stock-tablets',

  /**
   * paths specifically for translator
   */
  translations: '/translations',

  /**
   * paths for HubletAdmin and above
   */
  admin: '/admin',
  distributors: '/distributors', // TODO: change path to sales-partners?
  integrationStatistics: '/integration-statistics',
  trackingData: '/tracking-data',
  topApps: '/top-apps',
}

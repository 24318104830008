import { type Tablet } from 'App/Tablets/tablets-types'
import { type HubletServer } from 'common/types/hublet-server'

export enum DockStockStatus {
  Commissioned = 0,
  Manufactured = 1,
  Assigned = 2,
  Decommissioned = 3,
  Returned = 4,
}

export interface Dock {
  id: number
  name: string
  organizationId: number
  configKeepaliveInterval: number
  keepaliveLast?: Date
  siteId?: number
  profileId?: number
  slot1: string | null
  slot2: string | null
  slot3: string | null
  slot4: string | null
  slot5: string | null
  slot6: string | null
  tabletInSlot1: Tablet | null
  tabletInSlot2: Tablet | null
  tabletInSlot3: Tablet | null
  tabletInSlot4: Tablet | null
  tabletInSlot5: Tablet | null
  tabletInSlot6: Tablet | null
  color: string
  lcdIntensity: number
  model?: string
  stockStatus?: DockStockStatus
  serialNumber?: string
  manufactureDate?: Date
  manufacturePlant?: string
  hardwareRev?: string
  controlRev?: string
  boardInfo?: string
  masterPin?: string
  deletedAt?: number
  pcbSwRevision?: string
  tunnelRequestAt?: Date
  tunnelResponseAt?: Date
  server?: HubletServer
  ethernetAddressWired?: string
  ethernetAddressWlan?: string
  raspberryId?: string
}

export interface DockLocation {
  dockId: number
  distributorId: number
  distributorName: string
  customerId: number
  customerName: string
  siteId: number | null
  siteName: string
}

export interface DockLocationEditable {
  distributorId: number
  customerId: number
  siteId: number | null
}

export interface DockEditable {
  name?: string
  color?: string
}

export type DocksContext = 'site' | 'organization' | 'manufacturer'

import React, { useMemo, useState } from 'react'
import { Alert, LinearProgress, Stack, Typography } from '@mui/material'
import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Table, {
  type TableColumn,
  TableColumnFilter,
} from 'common/components/TableNew'
import TableActionsBar from 'common/components/TableActionsBar'
import { useGetOrganizationsDataForReviewQuery } from 'App/SuperAdmin/super-admin-rtk-api'
import { type OrganizationDataForAdminReview } from 'App/SuperAdmin/super-admin-types'
import IconMenu from 'common/components/IconMenu'
import { Edit as EditIcon } from '@mui/icons-material'
import DataReviewOrganizationDrawer from 'App/SuperAdmin/components/DataReviewOrganizationDrawer'
import Toast from 'common/components/Toast'
import { printAddress } from 'common/utils/print-address'

interface OrganizationsTableActionsBarProps {
  organizationsTotalCount: number
  organizationsReviewedCount: number
}

function OrganizationsTableActionsBar({
  organizationsTotalCount,
  organizationsReviewedCount,
}: OrganizationsTableActionsBarProps) {
  const severity = useMemo(() => {
    if (organizationsReviewedCount === 0) {
      return 'info'
    }
    if (organizationsReviewedCount < organizationsTotalCount) {
      return 'warning'
    }
    return 'success'
  }, [organizationsReviewedCount, organizationsTotalCount])

  return (
    <TableActionsBar>
      <Alert severity={severity}>
        Reviewed {organizationsReviewedCount} / {organizationsTotalCount}{' '}
        organizations
      </Alert>
    </TableActionsBar>
  )
}

interface OrganizationRowActionsProps {
  organization: OrganizationDataForAdminReview
  onClickEdit: (userId: number) => void
}

const OrganizationRowActions = ({
  organization,
  onClickEdit,
}: OrganizationRowActionsProps) => {
  const actions = [
    {
      local: 'Edit',
      fn: () => onClickEdit(organization.id),
      icon: EditIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

const OrganizationsTable = () => {
  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        title: 'Name',
        field: 'name',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Timezone',
        field: 'timezone',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Is distributor',
        field: 'isDistributor',
        filter: TableColumnFilter.Boolean,
        render: (model: OrganizationDataForAdminReview) =>
          model.isDistributor ? 'Yes' : 'No',
        canSort: true,
      },
      {
        title: 'Distributor',
        field: 'distributorName',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Is manufacturer',
        field: 'isManufacturer',
        filter: TableColumnFilter.Boolean,
        render: (model: OrganizationDataForAdminReview) =>
          model.isManufacturer ? 'Yes' : 'No',
        canSort: true,
      },
      {
        title: 'Hubspot ID',
        field: 'hubspotId',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'VAT ID Number',
        field: 'vatIdNumber',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'VAT ID Name',
        field: 'vatIdName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Billing address',
        filter: TableColumnFilter.Text,
        valueFn: (model: OrganizationDataForAdminReview) =>
          printAddress({
            street: model.billingAddressStreet1,
            street2: model.billingAddressStreet2,
            city: model.billingAddressCity,
            region: model.billingAddressRegion,
            zipcode: model.billingAddressZipcode,
          }),
        canSort: true,
      },
      {
        title: 'Country',
        field: 'billingAddressCountry',
        filter: TableColumnFilter.Select,
      },
      {
        title: 'Tags',
        field: 'tags',
        filter: TableColumnFilter.Text,
        render: (model: OrganizationDataForAdminReview) =>
          model.tags.join(', '),
        canSort: true,
      },
      {
        title: 'Is reviewed',
        field: 'isReviewed',
        filter: TableColumnFilter.Boolean,
        render: (model: OrganizationDataForAdminReview) =>
          model.isReviewed ? 'Yes' : 'No',
        canSort: true,
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (organization: OrganizationDataForAdminReview) => (
          <OrganizationRowActions
            organization={organization}
            onClickEdit={(organizationId: number) =>
              setToEditOrganizationId(organizationId)
            }
          />
        ),
      },
    ]
  }, [])

  const { data: organizationsData, refetch: refetchOrganizationsData } =
    useGetOrganizationsDataForReviewQuery()

  const [toEditOrganizationId, setToEditOrganizationId] = useState<
    number | null
  >(null)
  const toEditOrganizationData = useMemo(() => {
    if (toEditOrganizationId == null) {
      return null
    }

    return organizationsData?.find(
      (organization) => organization.id === toEditOrganizationId
    )
  }, [organizationsData, toEditOrganizationId])

  const [isSaveSuccess, setIsSaveSuccess] = useState(false)

  const organizationsTotalCount = organizationsData?.length ?? 0
  const organizationsReviewedCount = useMemo(() => {
    return (
      organizationsData?.filter((organization) => organization.isReviewed)
        .length ?? 0
    )
  }, [organizationsData])

  function handleSaved() {
    refetchOrganizationsData()
    setToEditOrganizationId(null)
    setIsSaveSuccess(true)
  }

  if (organizationsData == null) {
    return <LinearProgress />
  }

  return (
    <>
      <Stack>
        <OrganizationsTableActionsBar
          organizationsTotalCount={organizationsTotalCount}
          organizationsReviewedCount={organizationsReviewedCount}
        />
        <Table data={organizationsData} columns={columns} />
      </Stack>
      {toEditOrganizationData != null && (
        <DataReviewOrganizationDrawer
          open={toEditOrganizationData != null}
          onClose={() => setToEditOrganizationId(null)}
          onSaved={handleSaved}
          organizationData={toEditOrganizationData}
        />
      )}
      <Toast
        message="Organization data saved"
        open={isSaveSuccess}
        onClose={() => setIsSaveSuccess(false)}
      />
    </>
  )
}

function DataReviewOrganizations() {
  return (
    <Page>
      <PageHeader>
        <Stack>
          <Typography
            sx={(theme) => ({
              fontWeight: 600,
              color: theme.palette.secondary.main,
            })}
          >
            Super-admin
          </Typography>
          <PageTitle title="Data Review: Organizations" />
        </Stack>
      </PageHeader>
      <PageBody>
        <Stack gap={2}>
          <Typography variant="h2">Organizations</Typography>
          <OrganizationsTable />
        </Stack>
      </PageBody>
    </Page>
  )
}

export default DataReviewOrganizations

import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { PermissionType } from 'App/Codes/codes-types'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/x-date-pickers'
import useProfiles from 'common/hooks/useProfiles'
import DialogShowCode from 'App/Codes/DialogShowCode'
import { useAppDispatch, useAppSelector } from 'store'
import { addCode, fetchCodes, resetAddCode } from 'App/Codes/codes-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { getTomorrow } from 'common/utils/date-utils'

interface FieldErrors {
  name?: string
  expiration?: string
  profileId?: string
}

const AddSingleCodeForm = () => {
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [name, setName] = useState('')
  const [expiration, setExpiration] = useState<Date | null>(getTomorrow())
  const [profileId, setProfileId] = useState(0)
  const [permissionType, setPermissionType] = useState<PermissionType>(
    PermissionType.Single
  )
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})
  const { t } = useTranslation()
  const profiles = useProfiles()
  const dispatch = useAppDispatch()
  const { organizationId, siteId } = useCurrentAccount()
  const showCode = useAppSelector((state) => state.codes.showCode)
  const addedCode = useAppSelector((state) => state.codes.addedCode)

  const isValidInput = () => {
    setIsFormDirty(true)

    let hasError = false
    const errors: FieldErrors = {}

    if (!name) {
      hasError = true
      errors.name = t('codes.addCode.errors.emptyField')
    }

    if (!expiration || expiration.getTime() < Date.now()) {
      hasError = true
      errors.expiration = t('codes.addCode.errors.invalidField')
    }

    if (!profileId) {
      hasError = true
      errors.profileId = t('codes.addCode.errors.neglectedSelect')
    }

    setFieldErrors(errors)
    return !hasError
  }

  const resetForm = () => {
    setFieldErrors({})
    setName('')
    setExpiration(getTomorrow())
    setProfileId(0)
    setPermissionType(PermissionType.Single)
  }

  const handleSubmit = () => {
    if (!isValidInput()) {
      return
    }

    // TODO: we should add the code async/await
    // once it is completed and successful, we show the toast and refetch data in redux store
    // using dispatch(addCode(...)) here, we can't catch any error to show user
    dispatch(
      addCode({
        context: { siteId, organizationId },
        data: {
          name,
          expiration: expiration as Date | undefined, // TODO: improving type here
          profileId,
          permissionType,
        },
      })
    )

    resetForm()
  }

  useEffect(() => {
    if (addedCode) {
      dispatch(resetAddCode())
      dispatch(fetchCodes({ organizationId, siteId }))
    }
  }, [addedCode, dispatch, organizationId, siteId])

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <TextField
        variant="outlined"
        label={t('codes.addCode.nameField.label')}
        placeholder={t('codes.addCode.nameField.placeholder')}
        fullWidth
        value={name}
        onChange={(e) => {
          setFieldErrors((prev) => ({ ...prev, name: '' }))
          setName(e.target.value)
        }}
        error={Boolean(isFormDirty && fieldErrors.name)}
        helperText={fieldErrors.name}
      />

      <DatePicker
        label={t('codes.addCode.expirationField.label')}
        sx={{ width: '100%' }}
        value={expiration}
        onChange={(newValue) => setExpiration(newValue)}
        disablePast
      />

      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        error={Boolean(isFormDirty && fieldErrors.profileId)}
      >
        <InputLabel>{t('codes.addCode.profileField.label')}</InputLabel>
        <Select
          value={profileId}
          onChange={(e) => {
            setFieldErrors((prev) => ({ ...prev, profileId: '' }))
            setProfileId(Number(e.target.value))
          }}
        >
          {profiles.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {Boolean(isFormDirty && fieldErrors.profileId) && (
          <FormHelperText>{fieldErrors.profileId}</FormHelperText>
        )}
      </FormControl>

      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel>{t('codes.addCode.permissionTypeField.label')}</InputLabel>
        <Select
          value={permissionType}
          onChange={(e) => setPermissionType(e.target.value as PermissionType)}
        >
          <MenuItem value={PermissionType.Single}>
            {t('codes.addCode.permissionTypeField.options.single')}
          </MenuItem>
          <MenuItem value={PermissionType.Multi}>
            {t('codes.addCode.permissionTypeField.options.multi')}
          </MenuItem>
          <MenuItem value={PermissionType.Reusable}>
            {t('codes.addCode.permissionTypeField.options.reusable')}
          </MenuItem>
        </Select>
      </FormControl>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        marginTop={3}
      >
        <Button variant="contained" onClick={handleSubmit}>
          {t('codes.addCode.buttons.submit')}
        </Button>
      </Box>

      <DialogShowCode code={showCode} />
    </Box>
  )
}

export default AddSingleCodeForm

import rtkApi from 'rtk-api'
import { type Category } from 'App/Categories/categories-types'

interface ProfilesApi {
  organizationId: number
  siteId: number
}
type ProfileApi = ProfilesApi & { profileId: number }

interface UpdateLoansConfigReq {
  loanDuration: number
}

interface IntegrationParamsCountReq {
  organizationId: number
  siteId: number
  profileId: number
}

interface IntegrationParamsCountRes {
  count: number
}

const profilesRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    updateProfileLoansConfig: builder.mutation<
      null,
      ProfileApi & UpdateLoansConfigReq
    >({
      query: ({ organizationId, siteId, profileId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}/loans`,
        method: 'PUT',
        body,
      }),
    }),
    loadCategoriesBySite: builder.query<Category[], ProfilesApi>({
      query: ({ organizationId, siteId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/categories`,
      }),
    }),
    loadIntegrationParamsCountByProfileId: builder.query<
      IntegrationParamsCountRes,
      IntegrationParamsCountReq
    >({
      query: ({ organizationId, siteId, profileId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/params-count/${profileId}`,
      }),
    }),
  }),
})

export const {
  useUpdateProfileLoansConfigMutation,
  useLoadCategoriesBySiteQuery,
  useLazyLoadIntegrationParamsCountByProfileIdQuery,
} = profilesRtkApi

export default profilesRtkApi

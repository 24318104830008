import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

export interface ConfirmationDialogProps {
  open: boolean
  noKeepMounted?: boolean
  title?: string
  text: string
  cancelBtnText?: string
  cancelBtnAction: () => void
  confirmBtnText?: string
  confirmBtnAction: (...args: any[]) => any // Updated type
}

export const ConfirmationDialog = ({
  title,
  text,
  cancelBtnText,
  cancelBtnAction,
  confirmBtnText,
  confirmBtnAction,
  open,
  noKeepMounted,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} keepMounted={!noKeepMounted}>
      <DialogTitle>{title || 'Confirm your action?'}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelBtnAction}>{cancelBtnText || 'Cancel'}</Button>
        <Button onClick={confirmBtnAction}>
          {confirmBtnText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

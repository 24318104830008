import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import { useTranslation } from 'react-i18next'
import PageEmpty from 'common/components/PageEmpty'
import { resetInvitedUser } from 'App/Users/users-state'
import UsersTable from 'App/Users/components/UsersTable'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import InviteUserDrawer from 'App/Users/components/InviteUserDrawer'
import Toast from 'common/components/Toast'
import { useGetUsersQuery } from 'App/Users/users-rtk-api'
import { useAppDispatch, useAppSelector } from 'store'
import useCurrentUser from 'common/hooks/useCurrentUser'
import { Alert } from '@mui/material'

const Users = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [openInviteUserDrawer, setOpenInviteUserDrawer] = useState(false)

  const invitedUser = useAppSelector((state) => state.users.invitedUser)
  const updatedUser = useAppSelector((state) => state.users.updatedUser)
  const deletedUser = useAppSelector((state) => state.users.deletedUser)
  const reinvitedUser = useAppSelector((state) => state.users.reinvitedUser)

  const currentUser = useCurrentUser()

  const { organizationId, siteId } = useCurrentAccount()
  const {
    data: users = [],
    isSuccess: fetchedUsers,
    refetch: refetchUsers,
  } = useGetUsersQuery({ organizationId, siteId, withDeleted: true })

  useEffect(() => {
    if (invitedUser) {
      refetchUsers()
      dispatch(resetInvitedUser())
      setOpenInviteUserDrawer(false)
    }
  }, [dispatch, invitedUser, refetchUsers])

  if (!fetchedUsers) {
    return <Page />
  }

  return (
    <>
      {!users.length ? (
        <Page>
          <PageEmpty
            title={t('users.empty.title')}
            description={t('users.empty.description')}
            actionText={t('users.empty.action')}
            onClickAction={() => setOpenInviteUserDrawer(true)}
            buttonEnabled={!currentUser.isSsoUser}
          />
        </Page>
      ) : (
        <Page>
          <PageHeader>
            <PageTitle title={t('users.title')} />

            {!currentUser.isSsoUser ? (
              <PageActions>
                <Button onClick={() => setOpenInviteUserDrawer(true)} small>
                  {t('users.actions.inviteUser')}
                </Button>
              </PageActions>
            ) : (
              <Alert severity="info" sx={{ marginTop: '32px' }}>
                {t('users.actions.ssoUserActionInfo')}
              </Alert>
            )}
          </PageHeader>
          <PageBody>
            <UsersTable />
          </PageBody>
        </Page>
      )}

      <InviteUserDrawer
        open={openInviteUserDrawer}
        onClose={() => setOpenInviteUserDrawer(false)}
      />
      <Toast open={invitedUser} message={t('users.inviteUser.toast.success')} />
      <Toast open={updatedUser} message={t('users.editUser.toast.success')} />
      <Toast open={deletedUser} message={t('users.deleteUser.toast.success')} />
      <Toast
        open={reinvitedUser}
        message={t('users.reinviteUser.toast.success')}
      />
    </>
  )
}

export default Users

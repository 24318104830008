import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  type Integration,
  type IntegrationInputRule,
  VirtualKeyboard,
} from 'App/Integrations/integrations-types'
import Panel, { EditState } from 'common/components/NewPanel'
import PanelField from 'common/components/PanelField'
import FormField from 'common/components/FormField'
import useAuthMethodOptions from 'App/Integrations/hooks/useAuthMethodOptions'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import {
  useAddIntegrationInputRuleMutation,
  useDeleteIntegrationInputRuleMutation,
  useGetIntegrationInputRulesQuery,
  useGetIntegrationTemplatesQuery,
  useUpdateIntegrationConfigurationMutation,
  useUpdateIntegrationDebugModeMutation,
  useUpdateIntegrationInputRuleMutation,
  useUpdateIntegrationVirtualKeyboardsMutation,
} from 'App/Integrations/integrations-rtk-api'
import { type TooltipInfoContent } from 'common/components/TooltipInfo'
import FormSelect from 'common/components/FormSelect'
import useApiErrors from 'common/hooks/useApiErrors'
import { useTranslation } from 'react-i18next'
import { fetchIntegrations } from 'App/Integrations/integrations-state'
import FormSwitch from 'common/components/FormSwitch'
import { format, zonedTimeToUtc } from 'date-fns-tz'
import useUserTimeZone from 'common/hooks/useUserTimeZone'
import { isValid, parseISO } from 'date-fns'
import useRenderDate from 'common/hooks/useRenderDate'
import Table from 'common/components/TableNew'
import ActionDialog from 'common/components/ActionDialog'
import FormFieldGroup from 'common/components/FormFieldGroup'
import ErrorBox from 'common/components/ErrorBox'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import IconMenu from 'common/components/IconMenu'
import StatusText from 'common/components/StatusText'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import { useAppDispatch } from 'store'

interface ConfigurationPanelProps {
  integration: Integration
}

const fieldInfo: Record<string, TooltipInfoContent> = {
  url: {
    example: `https://${window.location.hostname}/integrations/http-json`,
  },
}

const ConfigurationPanel = ({ integration }: ConfigurationPanelProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [editState, setEditState] = useState<EditState>()

  const [name, setName] = useState(integration.name)
  const [authMethod, setAuthMethod] = useState(
    integration.templateKey ?? 'custom'
  )
  const [url, setUrl] = useState(integration.authApiUrl ?? '')
  const [isEnabled, setIsEnabled] = useState(integration.isEnabled)

  const { organizationId, siteId } = useCurrentAccount()

  const { data: integrationTemplates } = useGetIntegrationTemplatesQuery({
    organizationId,
    siteId,
  })

  const [
    updateIntegrationConfiguration,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateIntegrationConfigurationMutation()
  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchIntegrations({ organizationId, siteId }))
    }

    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    }
  }, [dispatch, isUpdateSuccess, hasApiMutationErrors, organizationId, siteId])

  const authenticationMethodOptions = useAuthMethodOptions(integrationTemplates)

  const isEnabledOptions = useMemo(() => {
    return [
      { label: t('common.status.enabled'), value: 'true' },
      { label: t('common.status.disabled'), value: 'false' },
    ]
  }, [t])

  const handleSelectAuthMethod = (key: string) => {
    setAuthMethod(key)

    let newUrl = ''
    if (key !== 'custom' && integrationTemplates !== undefined) {
      newUrl = integrationTemplates.find((it) => it.key === key)?.url ?? ''
    }
    setUrl(newUrl)
  }

  const handleSave = () => {
    setEditState(EditState.Pending)
    updateIntegrationConfiguration({
      organizationId,
      siteId,
      integrationId: integration.id,
      name,
      url,
      isEnabled,
    })
  }

  const renderEditMode = () => (
    <>
      <FormField
        label={t('integrations.integrationForm.fields.label.label')}
        info={fieldInfo.name}
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={t('integrations.integrationForm.fields.label.placeholder')}
        autoFocus
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('integrations.integrationForm.fields.authMode.label')}
        info={fieldInfo.authMethod}
        options={authenticationMethodOptions}
        placeholder={t(
          'integrations.integrationForm.fields.authMode.placeholder'
        )}
        value={authMethod}
        onChange={(_e, v) => handleSelectAuthMethod(v as string)}
        disabled={editState === EditState.Pending}
      />
      {authMethod === 'custom' && (
        <FormField
          type="url"
          label={t('integrations.integrationForm.fields.apiURL.label')}
          info={fieldInfo.url}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder={t(
            'integrations.integrationForm.fields.apiURL.placeholder'
          )}
          disabled={editState === EditState.Pending}
        />
      )}
      <FormSelect
        label={t('integrations.integrationForm.fields.status.label')}
        options={isEnabledOptions}
        value={String(isEnabled)}
        onChange={(_e, v) => setIsEnabled(v === 'true')}
        disabled={editState === EditState.Pending}
      />
    </>
  )

  return (
    <Panel
      title={t('integrations.integrationForm.container.label')}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(undefined)}
      onSave={handleSave}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('integrations.integrationForm.fields.label.label')}
        value={integration.name}
      />
      <PanelField
        title={t('integrations.integrationForm.fields.authMode.label')}
        value={
          integration.templateKey === undefined
            ? t('integrations.integrationForm.fields.authMode.values.custom')
            : t(integration.templateKey)
        }
      />
      <PanelField
        title={t('integrations.integrationForm.fields.status.label')}
        value={
          integration.isEnabled ? (
            <StatusText
              status="green"
              text={t('common.status.enabled')}
              withIcon
            />
          ) : (
            <StatusText
              status="red"
              text={t('common.status.disabled')}
              withIcon
            />
          )
        }
      />
    </Panel>
  )
}

interface InputManagementDialogProps {
  integrationId: number
  open: boolean
  onClose: () => void
  onAction: () => void
  variant: 'add' | 'edit'
  integrationInputRule?: IntegrationInputRule
}

const InputManagementDialog = ({
  integrationId,
  open,
  onClose,
  onAction,
  variant,
  integrationInputRule,
}: InputManagementDialogProps) => {
  const { t } = useTranslation()
  const { organizationId, siteId } = useCurrentAccount()

  const [match, setMatch] = useState(integrationInputRule?.match ?? '')
  const [matchError, setMatchError] = useState<string | undefined>()
  const [replaceWith, setReplaceWith] = useState(
    integrationInputRule?.replaceWith ?? ''
  )

  const [
    addIntegrationInputRule,
    {
      isSuccess: isAddIntegrationInputRuleSuccess,
      error: addIntegrationInputRuleError,
    },
  ] = useAddIntegrationInputRuleMutation()

  const [
    updateIntegrationInputRule,
    {
      isSuccess: isUpdateIntegrationInputRuleSuccess,
      error: updateIntegrationInputRuleError,
    },
  ] = useUpdateIntegrationInputRuleMutation()

  const mutationSuccess = useMemo(() => {
    return variant === 'add'
      ? isAddIntegrationInputRuleSuccess
      : isUpdateIntegrationInputRuleSuccess
  }, [
    variant,
    isAddIntegrationInputRuleSuccess,
    isUpdateIntegrationInputRuleSuccess,
  ])

  const mutationErrors = useMemo(() => {
    return variant === 'add'
      ? [addIntegrationInputRuleError]
      : [updateIntegrationInputRuleError]
  }, [variant, addIntegrationInputRuleError, updateIntegrationInputRuleError])

  const [hasApiMutationErrors, apiMutationErrorsMsg] =
    useApiErrors(mutationErrors)

  useEffect(() => {
    if (mutationSuccess) {
      onAction()
    }
  }, [onAction, mutationSuccess])

  const handleAction = () => {
    if (match === '') {
      setMatchError(t('common.errors.emptyField'))
      return
    }

    try {
      // eslint-disable-next-line no-new
      new RegExp(match)
    } catch (e) {
      setMatchError(
        t('integrations.integrationForm.input.errors.invalidRegExp')
      )
      return
    }

    setMatchError(undefined)

    const data = {
      organizationId,
      siteId,
      integrationId,
      match,
      replaceWith,
    }

    if (variant === 'add') {
      addIntegrationInputRule(data)
    } else if (variant === 'edit' && integrationInputRule !== undefined) {
      updateIntegrationInputRule({
        ...data,
        integrationInputRuleId: integrationInputRule.id,
      })
    }
  }

  const { title, actionText } = useMemo(() => {
    if (variant === 'add') {
      return {
        title: t('integrations.integrationForm.input.add.title'),
        actionText: t('integrations.integrationForm.input.add.actionText'),
      }
    } else {
      return {
        title: t('integrations.integrationForm.input.edit.title'),
        actionText: t('integrations.integrationForm.input.edit.actionText'),
      }
    }
  }, [variant, t])

  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      title={title}
      actionText={actionText}
      onAction={handleAction}
      closeOnAction={false}
      gray
    >
      {hasApiMutationErrors && (
        <ErrorBox spacingBottom={2}>{apiMutationErrorsMsg}</ErrorBox>
      )}
      <FormFieldGroup>
        <FormField
          label={t('integrations.integrationForm.input.fields.match.label')}
          placeholder={t(
            'integrations.integrationForm.input.fields.match.placeholder'
          )}
          value={match}
          onChange={(e) => setMatch(e.target.value)}
          autoFocus
          error={matchError}
        />
        <FormField
          label={t(
            'integrations.integrationForm.input.fields.replaceWith.label'
          )}
          placeholder={t(
            'integrations.integrationForm.input.fields.replaceWith.placeholder'
          )}
          value={replaceWith}
          onChange={(e) => setReplaceWith(e.target.value)}
        />
      </FormFieldGroup>
    </ActionDialog>
  )
}

interface IntegrationInputRulesRowActionsProps {
  integrationInputRule: IntegrationInputRule
  onEdit: (integrationTest: IntegrationInputRule) => void
  onDelete: (integrationTest: IntegrationInputRule) => void
}

const IntegrationInputRulesRowActions = ({
  integrationInputRule,
  onEdit,
  onDelete,
}: IntegrationInputRulesRowActionsProps) => {
  const actions = [
    {
      local: 'common.table.actions.edit',
      fn: () => onEdit(integrationInputRule),
      icon: EditIcon,
    },
    {
      local: 'common.table.actions.delete',
      fn: () => onDelete(integrationInputRule),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

interface InputManagementPanelProps {
  integration: Integration
}

const InputManagementPanel = ({ integration }: InputManagementPanelProps) => {
  const { t } = useTranslation()

  const [editState, setEditState] = useState(EditState.Default)
  const { organizationId, siteId } = useCurrentAccount()
  const { data: rawData, refetch } = useGetIntegrationInputRulesQuery({
    organizationId,
    siteId,
    integrationId: integration.id,
  })
  const data = useMemo(() => rawData ?? [], [rawData])
  const [
    deleteIntegrationInputRule,
    { isSuccess: isDeleteIntegrationInputRuleSuccess },
  ] = useDeleteIntegrationInputRuleMutation()

  useEffect(() => {
    if (isDeleteIntegrationInputRuleSuccess) {
      setEditState(EditState.Success)
      refetch()
    }
  }, [refetch, isDeleteIntegrationInputRuleSuccess])

  const [inputRuleToEdit, setInputRuleToEdit] = useState<
    IntegrationInputRule | undefined
  >()
  const [inputRuleToDelete, setInputRuleToDelete] = useState<
    IntegrationInputRule | undefined
  >()

  const [testStr, setTestStr] = useState('')

  const testsById = useMemo(() => {
    const byId: Record<number, string> = {}
    let currentTestStr = testStr
    for (const inputRule of data) {
      try {
        currentTestStr = currentTestStr.replace(
          new RegExp(inputRule.match, 'g'),
          inputRule.replaceWith
        )
      } catch (e) {
        console.error('Invalid regular expression used', inputRule.match)
      }
      byId[inputRule.id] = currentTestStr
    }
    return byId
  }, [testStr, data])

  const columns = useMemo(
    () => [
      {
        title: t('integrations.integrationForm.input.fields.match.label'),
        render: ({ match }: IntegrationInputRule) => match,
      },
      {
        title: t('integrations.integrationForm.input.fields.replaceWith.label'),
        render: ({ replaceWith }: IntegrationInputRule) => replaceWith,
      },
      {
        id: 'test',
        title: (
          <FormField
            value={testStr}
            onChange={(e) => setTestStr(e.target.value)}
            placeholder={t(
              'integrations.integrationForm.input.fields.test.placeholder'
            )}
            dense
          />
        ),
        render: ({ id }: IntegrationInputRule) => {
          return testsById[id]
        },
        style: { width: 'calc(100% / 3)' },
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (inputRule: IntegrationInputRule) => (
          <IntegrationInputRulesRowActions
            integrationInputRule={inputRule}
            onEdit={(inputRule) => setInputRuleToEdit(inputRule)}
            onDelete={(inputRule) => setInputRuleToDelete(inputRule)}
          />
        ),
        style: { width: '24px' },
      },
    ],
    [t, testStr, testsById]
  )

  const handleAction = useCallback(() => {
    setEditState(EditState.Success)
    setInputRuleToEdit(undefined)
    refetch()
  }, [refetch])

  const handleCloseEditDialog = useCallback(() => {
    setEditState(EditState.Default)
    setInputRuleToEdit(undefined)
  }, [])

  const renderEditMode = () => {
    return (
      <InputManagementDialog
        integrationId={integration.id}
        open={editState === EditState.Edit}
        onClose={() => setEditState(EditState.Default)}
        onAction={handleAction}
        variant="add"
      />
    )
  }

  const handleDeleteIntegrationInputRuleAction = () => {
    if (inputRuleToDelete === undefined) return
    deleteIntegrationInputRule({
      organizationId,
      siteId,
      integrationId: integration.id,
      integrationInputRuleId: inputRuleToDelete.id,
    })
  }

  return (
    <>
      <Panel
        title={t('integrations.integrationForm.input.label')}
        variant="table"
        editable
        editState={editState}
        onEdit={() => setEditState(EditState.Edit)}
        onCancel={() => setEditState(EditState.Default)}
        onSuccess={() => setEditState(EditState.Default)}
        renderEditMode={renderEditMode}
      >
        <Table columns={columns} data={data} variant="panel" />
      </Panel>
      {inputRuleToDelete != null && (
        <ActionDialog
          open={inputRuleToDelete !== undefined}
          title={t('integrations.integrationForm.input.delete.title')}
          description={t(
            'integrations.integrationForm.input.delete.description'
          )}
          actionText={t('integrations.integrationForm.input.delete.actionText')}
          onAction={handleDeleteIntegrationInputRuleAction}
          onClose={() => setInputRuleToDelete(undefined)}
        />
      )}
      {inputRuleToEdit != null && (
        <InputManagementDialog
          integrationId={integration.id}
          open={inputRuleToEdit !== undefined}
          onClose={handleCloseEditDialog}
          onAction={handleAction}
          variant="edit"
          integrationInputRule={inputRuleToEdit}
        />
      )}
    </>
  )
}

interface DebugModePanelProps {
  integration: Integration
}

const DebugModePanel = ({ integration }: DebugModePanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const renderDate = useRenderDate()

  const [editState, setEditState] = useState<EditState>()
  const [debugMode, setDebugMode] = useState(integration.debugMode)
  const [expiration, setExpiration] = useState(integration.debugModeExpiration)

  const userTimeZone = useUserTimeZone()
  const { organizationId, siteId } = useCurrentAccount()

  const [updateDebugMode, { isSuccess: isUpdateSuccess, error: updateError }] =
    useUpdateIntegrationDebugModeMutation()
  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchIntegrations({ organizationId, siteId }))
    }
  }, [dispatch, isUpdateSuccess, hasApiMutationErrors, organizationId, siteId])

  const localExpiration = useMemo(() => {
    if (expiration === undefined) return ''
    const expirationDate = parseISO(expiration)
    if (!isValid(expirationDate)) return ''
    return format(expirationDate, "yyyy-MM-dd'T'HH:mm", {
      timeZone: userTimeZone,
    })
  }, [expiration, userTimeZone])

  const handleChangeDebugMode = () => {
    const newDebugMode = !debugMode
    if (!newDebugMode) {
      setExpiration(undefined)
    }
    setDebugMode(newDebugMode)
  }

  const handleChangeExpiration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateTimeStr = e.target.value
    const utcTime = zonedTimeToUtc(dateTimeStr, userTimeZone)
    setExpiration(utcTime.toISOString())
  }

  const handleSave = () => {
    setEditState(EditState.Pending)
    updateDebugMode({
      organizationId,
      siteId,
      integrationId: integration.id,
      debugMode,
      debugModeExpiration: expiration,
    })
  }

  const renderEditMode = () => (
    <>
      <FormSwitch
        title={t('integrations.integrationForm.fields.enableDebugMode.label')}
        checked={debugMode}
        variant="drawer"
        onChange={handleChangeDebugMode}
        disabled={editState === EditState.Pending}
      />
      <FormField
        label={t(
          'integrations.integrationForm.fields.debugModeExpiration.label'
        )}
        type="datetime-local"
        disabled={!debugMode || editState === EditState.Pending}
        value={localExpiration}
        onChange={handleChangeExpiration}
      />
    </>
  )

  return (
    <Panel
      title={t('integrations.integrationForm.debugMode.label')}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(undefined)}
      onSave={handleSave}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('integrations.integrationForm.fields.enableDebugMode.label')}
        value={t(`common.boolean.${String(integration.debugMode)}`)}
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.debugModeExpiration.label'
        )}
        value={
          integration.debugModeExpiration &&
          renderDate(new Date(integration.debugModeExpiration))
        }
      />
    </Panel>
  )
}

interface VirtualKeyboardsPanelProps {
  integration: Integration
}

const VirtualKeyboardsPanel = ({ integration }: VirtualKeyboardsPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { organizationId, siteId } = useCurrentAccount()

  const [editState, setEditState] = useState(EditState.Default)

  const [usernameKeyboard, setUsernameKeyboard] = useState(
    integration.usernameKeyboard
  )
  const [passwordKeyboard, setPasswordKeyboard] = useState(
    integration.passwordKeyboard
  )

  const [
    updateIntegrationVirtualKeyboards,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateIntegrationVirtualKeyboardsMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchIntegrations({ organizationId, siteId }))
    }
  }, [dispatch, isUpdateSuccess, hasApiMutationErrors, organizationId, siteId])

  const usernameKeyboardOptions = useMemo(() => {
    return [
      { label: t('common.inputType.none'), value: VirtualKeyboard.None },
      { label: t('common.inputType.numeric'), value: VirtualKeyboard.Numeric },
      {
        label: t('common.inputType.alphanumeric'),
        value: VirtualKeyboard.Alphanumeric,
      },
    ]
  }, [t])

  const passwordKeyboardOptions = useMemo(() => {
    return [
      { label: t('common.inputType.numeric'), value: VirtualKeyboard.Numeric },
      {
        label: t('common.inputType.alphanumeric'),
        value: VirtualKeyboard.Alphanumeric,
      },
    ]
  }, [t])

  const handleSave = () => {
    setEditState(EditState.Pending)

    updateIntegrationVirtualKeyboards({
      organizationId,
      siteId,
      integrationId: integration.id,
      usernameKeyboard,
      passwordKeyboard,
    })
  }

  const renderEditMode = () => (
    <>
      <FormSelect
        label={t('integrations.keyboards.username.label')}
        options={usernameKeyboardOptions}
        value={usernameKeyboard}
        onChange={(_e, v) => setUsernameKeyboard(v as VirtualKeyboard)}
      />
      <FormSelect
        label={t('integrations.keyboards.password.label')}
        options={passwordKeyboardOptions}
        value={passwordKeyboard}
        onChange={(_e, v) => setPasswordKeyboard(v as VirtualKeyboard)}
      />
    </>
  )

  return (
    <Panel
      title={t('integrations.keyboards.title')}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(EditState.Default)}
      onSave={handleSave}
      onSuccess={() => setEditState(EditState.Success)}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('integrations.keyboards.username.label')}
        value={t(`common.inputType.${integration.usernameKeyboard}`)}
      />
      <PanelField
        title={t('integrations.keyboards.password.label')}
        value={t(`common.inputType.${integration.passwordKeyboard}`)}
      />
    </Panel>
  )
}

interface UsagePanelProps {
  integration: Integration
}

const UsagePanel = ({ integration }: UsagePanelProps) => {
  const { t } = useTranslation()

  return (
    <Panel title={t('integrations.integrationForm.usage.label')}>
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageCanBorrowSuccess.label'
        )}
        value={
          integration.canBorrowSuccess && (
            <DateWithRelativeTime
              datetime={new Date(integration.canBorrowSuccess)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageCanBorrowFailed.label'
        )}
        value={
          integration.canBorrowFailed && (
            <DateWithRelativeTime
              datetime={new Date(integration.canBorrowFailed)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageCheckoutSuccess.label'
        )}
        value={
          integration.checkoutSuccess && (
            <DateWithRelativeTime
              datetime={new Date(integration.checkoutSuccess)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageCheckoutFailed.label'
        )}
        value={
          integration.checkoutFailed && (
            <DateWithRelativeTime
              datetime={new Date(integration.checkoutFailed)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageCheckinSuccess.label'
        )}
        value={
          integration.checkinSuccess && (
            <DateWithRelativeTime
              datetime={new Date(integration.checkinSuccess)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageCheckinfailed.label'
        )}
        value={
          integration.checkinFailed && (
            <DateWithRelativeTime
              datetime={new Date(integration.checkinFailed)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageDidBorrowSuccess.label'
        )}
        value={
          integration.didBorrowSuccess && (
            <DateWithRelativeTime
              datetime={new Date(integration.didBorrowSuccess)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageDidBorrowFailed.label'
        )}
        value={
          integration.didBorrowFailed && (
            <DateWithRelativeTime
              datetime={new Date(integration.didBorrowFailed)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageDidReturnSuccess.label'
        )}
        value={
          integration.didReturnSuccess && (
            <DateWithRelativeTime
              datetime={new Date(integration.didReturnSuccess)}
            />
          )
        }
      />
      <PanelField
        title={t(
          'integrations.integrationForm.fields.usageDidReturnFailed.label'
        )}
        value={
          integration.didReturnFailed && (
            <DateWithRelativeTime
              datetime={new Date(integration.didReturnFailed)}
            />
          )
        }
      />
    </Panel>
  )
}

interface IntegrationGeneralProps {
  integration: Integration
}

const IntegrationGeneral = ({ integration }: IntegrationGeneralProps) => {
  return (
    <>
      <ConfigurationPanel integration={integration} />
      <InputManagementPanel integration={integration} />
      <VirtualKeyboardsPanel integration={integration} />
      <DebugModePanel integration={integration} />
      <UsagePanel integration={integration} />
    </>
  )
}

export default IntegrationGeneral

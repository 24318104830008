import { useEffect } from 'react'
import { fetchTablets } from 'App/Tablets/tablets-state'
import { type Tablet } from 'App/Tablets/tablets-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

const useTablets = (dockId?: number, withDeleted = false): Tablet[] => {
  const dispatch = useAppDispatch()

  const { siteId, organizationId } = useCurrentAccount()
  const tablets = useAppSelector((state) => state.tablets.tablets)

  useEffect(() => {
    dispatch(fetchTablets({ siteId, organizationId, dockId, withDeleted }))
  }, [dispatch, siteId, organizationId, dockId, withDeleted])

  return tablets
}

export default useTablets

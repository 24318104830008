import React, { useState } from 'react'
import { useForgetPasswordMutation } from 'App/CognitoLogin/cogonit-login-rtk-api'
import LoginPageWrapper from 'App/Login/components/LoginPageWrapper'
import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PasswordRecoveryStep2 from 'App/Login/components/PasswordRecoveryStep2'
import useTracking from 'common/hooks/useTracking'

const FormStyled = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const SeparatorBox = styled(Box)(({ theme }) => ({
  width: '14.5rem',
  margin: theme.spacing(3, 0),
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}))

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}))

const PasswordRecovery = () => {
  const [email, setEmail] = useState('')
  const [step, setStep] = useState(1)
  const [errorMsg, setErrorMsg] = useState('')
  const [inputError, setInputError] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { track } = useTracking()

  const [postForgetPassword, { reset: resetForgetPasswordData }] =
    useForgetPasswordMutation()

  const isValidInput = () => {
    let emailError = ''

    if (!email) {
      emailError = t('loginv2.error.requiredField')
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      emailError = t('loginv2.error.invalidEmail')
    }

    setInputError(emailError)

    if (!emailError) {
      return true
    }
    return false
  }

  const handleForgetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!isValidInput()) {
      return
    }

    postForgetPassword({ email })
      .unwrap()
      .then((res) => {
        if (res.isSuccess) {
          track({
            feature: 'Login',
            action: 'Password requested',
            payload: { email },
          })
          setStep(2)
          setErrorMsg('')
        } else {
          setErrorMsg(
            t(`loginv2.error.${res.error?.code ?? 'InternalErrorException'}`)
          )
        }
      })
      .catch((error) => {
        console.warn('Failed to request reset password', error)
        setErrorMsg(t('loginv2.error.InternalErrorException'))
      })
  }

  if (step === 1) {
    return (
      <LoginPageWrapper title={t('loginv2.forgotPassword')}>
        <FormStyled onSubmit={handleForgetPassword}>
          <Typography variant="body1" marginBottom={5} align="center">
            {t('loginv2.forgotPasswordInstruction1')}
          </Typography>
          {errorMsg && (
            <Alert severity="error" sx={{ marginBottom: 5 }}>
              {errorMsg}
            </Alert>
          )}
          <TextField
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              resetForgetPasswordData()
              setErrorMsg('')
            }}
            placeholder={t('loginv2.emailAddress')}
            label={t('loginv2.emailAddress')}
            size="small"
            autoFocus
            error={Boolean(inputError)}
            helperText={inputError}
          />
          <ButtonWrapper>
            <ButtonStyled variant="contained" color="primary" type="submit">
              {t('loginv2.resetPassword')}
            </ButtonStyled>
            <SeparatorBox>
              <Divider />
            </SeparatorBox>
            <ButtonStyled onClick={() => navigate('/login')} variant="outlined">
              {t('loginv2.goBack')}
            </ButtonStyled>
          </ButtonWrapper>
        </FormStyled>
      </LoginPageWrapper>
    )
  }

  if (step === 2) {
    return <PasswordRecoveryStep2 email={email} setStep={setStep} />
  }

  return null
}

export default PasswordRecovery

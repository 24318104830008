import React from 'react'
import { Grid, Typography } from '@mui/material'
import { type OrganizationDataForReview } from 'App/DataReview/data-review-rtk-api'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import { timezoneOptions } from 'common/timezones'
import { useTranslation } from 'react-i18next'

interface OrganizationDataReviewProps {
  organizationId: string
  data: OrganizationDataForReview
  onChange: (data: OrganizationDataForReview) => void
  errors: Record<string, string | undefined>
}

const OrganizationDataReview = ({
  organizationId,
  data,
  onChange,
  errors,
}: OrganizationDataReviewProps) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">
          {t('dataReview.organization.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField
          label={t('dataReview.organization.name.label')}
          placeholder={t('dataReview.organization.name.placeholder')}
          value={data.name}
          onChange={(e) => onChange({ ...data, name: e.target.value })}
          error={errors[`/organizations/${organizationId}/name`]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormSelect
          options={timezoneOptions}
          label={t('dataReview.organization.timezone.label')}
          value={data.timezone}
          onChange={(e) => onChange({ ...data, timezone: e.target.value })}
          placeholder={t('dataReview.organization.timezone.placeholder')}
          error={errors[`/organizations/${organizationId}/timezone`]}
        />
      </Grid>
    </Grid>
  )
}

export default OrganizationDataReview

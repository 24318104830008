import React, { useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { type SiteDataForReview } from 'App/DataReview/data-review-rtk-api'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import { useCountryOptions } from 'common/countries'
import { timezoneOptions } from 'common/timezones'
import { useTranslation } from 'react-i18next'
import { useGetCustomerSectorsQuery } from 'App/CustomerSectors/customer-sector-rtk-api'

interface SiteDataReviewProps {
  siteId: string
  data: SiteDataForReview
  onChange: (data: SiteDataForReview) => void
  errors: Record<string, string | undefined>
}

const SiteDataReview = ({
  siteId,
  data,
  onChange,
  errors,
}: SiteDataReviewProps) => {
  const { t } = useTranslation()

  const { data: customerSectors } = useGetCustomerSectorsQuery()
  const customerSectorOptions = useMemo(() => {
    return customerSectors
      ? customerSectors.map((sector: any) => ({
          label: t(sector.customerSectorName),
          value: sector.id,
        }))
      : []
  }, [customerSectors, t])
  const countryOptions = useCountryOptions()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{t('dataReview.site.title')}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField
          label={t('dataReview.site.name.label')}
          placeholder={t('dataReview.site.name.placeholder')}
          value={data.name}
          onChange={(e) => onChange({ ...data, name: e.target.value })}
          error={errors[`/sites/${siteId}/name`]}
          info={{
            description: t('dataReview.site.name.description'),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField
          label={t('dataReview.site.address.label')}
          placeholder={t('dataReview.site.address.placeholder')}
          value={data.addressStreet1}
          onChange={(e) =>
            onChange({ ...data, addressStreet1: e.target.value })
          }
          error={errors[`/sites/${siteId}/addressStreet1`]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField
          label={t('dataReview.site.address2.label')}
          placeholder={t('dataReview.site.address2.placeholder')}
          value={data.addressStreet2}
          onChange={(e) =>
            onChange({ ...data, addressStreet2: e.target.value })
          }
          error={errors[`/sites/${siteId}/addressStreet2`]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid xs={4} item>
            <FormField
              label={t('dataReview.site.postalCode.label')}
              placeholder={t('dataReview.site.postalCode.placeholder')}
              value={data.addressZipcode}
              onChange={(e) =>
                onChange({ ...data, addressZipcode: e.target.value })
              }
              error={errors[`/sites/${siteId}/addressZipcode`]}
            />
          </Grid>
          <Grid xs={8} item>
            <FormField
              label={t('dataReview.site.city.label')}
              placeholder={t('dataReview.site.city.placeholder')}
              value={data.addressCity}
              onChange={(e) =>
                onChange({ ...data, addressCity: e.target.value })
              }
              error={errors[`/sites/${siteId}/addressCity`]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormSelect
          options={countryOptions}
          label={t('dataReview.site.country.label')}
          value={data.addressCountry}
          onChange={(e) =>
            onChange({ ...data, addressCountry: e.target.value })
          }
          placeholder={t('dataReview.site.country.placeholder')}
          error={errors[`/sites/${siteId}/addressCountry`]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormSelect
          options={timezoneOptions}
          label={t('dataReview.site.timezone.label')}
          value={data.tzdbTimezone}
          onChange={(e) => onChange({ ...data, tzdbTimezone: e.target.value })}
          placeholder={t('dataReview.site.timezone.placeholder')}
          error={errors[`/sites/${siteId}/timezone`]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormSelect
          options={customerSectorOptions}
          label={t('dataReview.site.industry.label')}
          value={data.customerSectorId}
          onChange={(e) =>
            onChange({ ...data, customerSectorId: e.target.value })
          }
          placeholder={t('dataReview.site.industry.placeholder')}
          error={errors[`/sites/${siteId}/customerSectorId`]}
        />
      </Grid>
    </Grid>
  )
}

export default SiteDataReview

import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  type Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { type PropsWithChildren, type ReactNode } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogPaper: {
    width: '505px',
    maxWidth: '100%',
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    background: theme.palette.background.default,
  },
  DialogTitleContainer: {
    padding: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  DialogTitle: {
    flexBasis: 0,
    flexGrow: 1,
    padding: 0,
    '& > h2': {
      lineHeight: 1,
      fontSize: '24px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  DialogHeadClose: {
    textAlign: 'right',
    color: '#000',
  },
  DialogCloseIcon: {
    cursor: 'pointer',
  },
  DialogContent: {
    padding: '36px',
    paddingTop: 0,
    '& > p': {
      fontSize: '16px',
      color: '#000',
    },
  },
  DialogActions: {
    padding: '24px 36px',
  },
  DialogTabs: {
    padding: '0 36px',
    backgroundColor: '#fff',
    borderTop: `1px solid #F8F8FB`,
  },
}))

interface DialogProps extends PropsWithChildren {
  open: boolean
  onClose: () => void
  title?: ReactNode
  tabs?: ReactNode
  actions?: ReactNode
  classes?: {
    container?: string
    title?: string
    content?: string
    actions?: string
  }
}

const Dialog = ({
  open,
  onClose,
  title,
  tabs,
  children,
  actions,
  classes,
}: DialogProps) => {
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )
  const { classes: styles, cx } = useStyles()

  const containerClasses = [styles.DialogPaper, classes?.container ?? '']
  const titleClasses = [styles.DialogTitleContainer, classes?.title ?? '']
  const contentClasses = [styles.DialogContent, classes?.content ?? '']
  const actionsClasses = [styles.DialogActions, classes?.actions ?? '']

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: cx(containerClasses) }}
      dir={languageDirection}
    >
      <Grid className={cx(titleClasses)}>
        <DialogTitle className={styles.DialogTitle}>{title}</DialogTitle>
        <Grid className={styles.DialogHeadClose}>
          <ClearIcon className={styles.DialogCloseIcon} onClick={onClose} />
        </Grid>
      </Grid>
      {tabs && <div className={styles.DialogTabs}>{tabs}</div>}
      <DialogContent className={cx(contentClasses)}>{children}</DialogContent>
      {actions && (
        <DialogActions className={cx(actionsClasses)}>{actions}</DialogActions>
      )}
    </MuiDialog>
  )
}

export default Dialog

import {
  disableFeature,
  disableFeaturesInBulk,
  enableFeature,
  enableFeaturesInBulk,
  getAllFeatures,
} from 'common/utils/localStorage'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useFeature = (featureFlagName: string) => {
  const [isEnabled, setIsEnabled] = useState(false)
  const location = useLocation()

  const enable = useCallback(() => {
    setIsEnabled(true)
    enableFeature(featureFlagName)
  }, [featureFlagName])

  const disable = useCallback(() => {
    setIsEnabled(false)
    disableFeature(featureFlagName)
  }, [featureFlagName])

  useEffect(() => {
    // features from URL are prioritized and will override features stored in localStorage
    const searchParams = new URLSearchParams(location.search)
    const featuresFromUrl = searchParams.get('features')
    const urlFeatures = (featuresFromUrl ?? '').split(',')
    enableFeaturesInBulk(urlFeatures)

    // disabledFeature has the highest priority
    const featuresToDisableFromUrl = searchParams.get('disabledFeatures')
    const featuresToDisable = (featuresToDisableFromUrl ?? '').split(',')
    disableFeaturesInBulk(featuresToDisable)

    const featuresInStorage = getAllFeatures()

    if (
      !featuresToDisable.includes(featureFlagName) &&
      (featuresInStorage[featureFlagName] ||
        urlFeatures.includes(featureFlagName))
    ) {
      enable()
    } else {
      disable()
    }
  }, [disable, enable, featureFlagName, location.search])

  return { isEnabled, enable, disable }
}

import React from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import { TrackingDataComponent } from 'App/TrackingData/TrackingData.component'

const TrackingData = () => {
  return (
    <Page>
      <PageHeader>
        <PageTitle title="Tracking data" />
      </PageHeader>
      <PageBody>
        <TrackingDataComponent />
      </PageBody>
    </Page>
  )
}

export default TrackingData

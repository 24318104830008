import { Grid, Stack, Typography } from '@mui/material'
import { type UserDataForReview } from 'App/DataReview/data-review-rtk-api'
import FormCheckbox from 'common/components/FormCheckbox'
import FormField from 'common/components/FormField'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface UserDataReviewProps {
  userId: string
  data: UserDataForReview
  onChange: (data: UserDataForReview) => void
  errors: Record<string, string | undefined>
}

const UserDataReview = ({
  userId,
  data,
  onChange,
  errors,
}: UserDataReviewProps) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{t('dataReview.user.title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormField
                label={t('dataReview.user.firstName.label')}
                value={data.firstName}
                onChange={(e) =>
                  onChange({ ...data, firstName: e.target.value })
                }
                error={errors[`/users/${userId}/firstName`]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                label={t('dataReview.user.lastName.label')}
                value={data.lastName}
                onChange={(e) =>
                  onChange({ ...data, lastName: e.target.value })
                }
                error={errors[`/users/${userId}/lastName`]}
              />
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography textTransform="uppercase" fontSize={14} fontWeight={700}>
          {t('dataReview.user.subscribe.title')}
        </Typography>
        <Stack mt={1}>
          {data.isSubscribedToExpiredLoans != null && (
            <FormCheckbox
              label={t('dataReview.user.subscribe.expiredLoans')}
              checked={data.isSubscribedToExpiredLoans}
              onToggle={(checked) =>
                onChange({
                  ...data,
                  isSubscribedToExpiredLoans: checked,
                })
              }
            />
          )}
          <FormCheckbox
            label={t('dataReview.user.subscribe.releaseNotes')}
            checked={data.isSubscribedToReleaseNotes}
            onToggle={(checked) =>
              onChange({
                ...data,
                isSubscribedToReleaseNotes: checked,
              })
            }
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default UserDataReview

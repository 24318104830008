import React, { useEffect, useMemo, useState } from 'react'
import { type DockDataForAdminReview } from 'App/SuperAdmin/super-admin-types'
import FormField from 'common/components/FormField'
import DrawerForm from 'common/components/DrawerForm'
import { Stack } from '@mui/material'
import { useReviewDockDataMutation } from 'App/SuperAdmin/super-admin-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import DataTagsField from 'common/components/DataTagsField'
import FormSelect from 'common/components/FormSelect'
import { DockStockStatus } from 'App/Docks/docks-types'
import { renderDockStockStatus } from 'common/utils/render-utils'

interface DataReviewDockDrawerProps {
  open: boolean
  onClose: () => void
  onSaved: (data: DockDataForAdminReview) => void
  data: DockDataForAdminReview
}

const DataReviewDockDrawer = ({
  open,
  onClose,
  onSaved,
  data,
}: DataReviewDockDrawerProps) => {
  const [dockName, setDockName] = useState(data.dockName)
  const [dockStockStatus, setDockStockStatus] = useState(data.dockStockStatus)
  const [tags, setTags] = useState<string[]>(data.tags)

  const [
    reviewDockData,
    {
      isLoading: isReviewingDockData,
      error: reviewDockDataError,
      isSuccess: isSiteDataReviewed,
      data: reviewedSiteData,
    },
  ] = useReviewDockDataMutation()

  useEffect(() => {
    if (isSiteDataReviewed && reviewedSiteData) {
      onSaved(reviewedSiteData)
    }
  }, [isSiteDataReviewed, onSaved, reviewedSiteData])

  const [hasApiError, apiError] = useApiErrors([reviewDockDataError])

  const handleSubmit = () => {
    const updatedDockData: DockDataForAdminReview = {
      id: data.id,
      dockName,
      dockStockStatus,
      isReviewed: true,
      tags,
    }

    reviewDockData(updatedDockData)
  }

  const dockStockStatusOptions = useMemo(
    () =>
      Object.values(DockStockStatus)
        .filter((status) => !isNaN(Number(status)))
        .map((status) => ({
          value: status,
          label: renderDockStockStatus(status as DockStockStatus),
        })),
    []
  )

  return (
    <DrawerForm
      title="Review dock data"
      open={open}
      onClose={onClose}
      submitText="Save"
      onSubmit={handleSubmit}
      disabledButton={isReviewingDockData}
    >
      <Stack spacing={4}>
        {hasApiError && <ErrorBox>{apiError}</ErrorBox>}
        <FormField
          label="Dock name"
          value={dockName}
          disabled={isReviewingDockData}
          onChange={(e) => setDockName(e.target.value)}
        />
        <FormSelect
          label="Dock stock status"
          value={dockStockStatus}
          options={dockStockStatusOptions}
          disabled={isReviewingDockData}
          onChange={(e) => setDockStockStatus(e.target.value)}
          placeholder="Select dock stock status"
        />
        <DataTagsField
          dataType="dock"
          tags={tags}
          onChange={(tags) => setTags(tags)}
          disabled={isReviewingDockData}
        />
      </Stack>
    </DrawerForm>
  )
}

export default DataReviewDockDrawer

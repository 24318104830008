import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { type Dock } from 'App/Docks/docks-types'
import StatusText from 'common/components/StatusText'

export const isDockOnline = (dock: Dock): boolean => {
  if (!dock.keepaliveLast) {
    return false
  }

  const keepaliveInterval = dock.configKeepaliveInterval
  const keepaliveLast = dock.keepaliveLast

  const timeoutSeconds = keepaliveInterval ? keepaliveInterval * 4 : 90
  const timeSinceLastKeepalive = Date.now() - keepaliveLast.valueOf()

  return timeSinceLastKeepalive < timeoutSeconds * 1000
}

interface DockStatusProps {
  dock: Dock
}

const DockStatus = ({ dock }: DockStatusProps) => {
  const { t } = useTranslation()

  const dockIsOnline = useMemo(() => isDockOnline(dock), [dock])

  if (dockIsOnline) {
    return (
      <StatusText
        status="green"
        text={t('docks.listDocks.fields.status.online')}
      />
    )
  }

  return (
    <StatusText
      status="red"
      text={t('docks.listDocks.fields.status.offline')}
    />
  )
}

export default DockStatus

export enum NetworkSecurityMode {
  OPEN = 'open',
  WPA_PSK = 'WPA-PSK',
  WPA2_PSK = 'WPA2-PSK',
  WPA_ENTERPRISE = '802.1X-EAP',
}

export enum SSIDVisibility {
  BROADCAST = 0,
  HIDDEN = 1,
}

export enum EAPMethod {
  PEAP = 'PEAP',
}

export enum EAPAuth {
  MSCHAPv2 = 'MSCHAPV2',
  MD5 = 'MD5',
  GTC = 'GTC',
}

export interface Network {
  id: number
  organizationId: number
  siteId: number

  ssid: string
  securityMode: NetworkSecurityMode
  visibility: SSIDVisibility
  username?: string
  password?: string
  eapMethod?: EAPMethod
  eapAuth?: EAPAuth
  enableCaptivePortal?: boolean
  captivePortalUsername?: string
  captivePortalPassword?: string
}

export interface NetworkForm {
  ssid?: string
  securityMode?: NetworkSecurityMode
  visibility?: SSIDVisibility
  username?: string
  password?: string
  eapMethod?: EAPMethod
  eapAuth?: EAPAuth
  enableCaptivePortal?: boolean
  captivePortalUsername?: string
  captivePortalPassword?: string
}

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useNetworks from 'common/hooks/useNetworks'
import { Box, Fade, FormControlLabel, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CheckIcon from '@mui/icons-material/CheckOutlined'
import Checkbox from 'common/components/Checkbox'
import Panel from 'common/components/Panel'
import Button from 'common/components/Button'
import Switch from 'common/components/Switch'
import AddNetworkPopup from 'App/Profiles/ProfilesView/AddNetworkPopup'

import { type Profile } from 'App/Profiles/profiles-types'
import useProfileNetworks from 'common/hooks/useProfileNetworks'
import {
  addProfileNetwork,
  deleteProfileNetwork,
  fetchProfileNetworks,
  resetAddProfileNetwork,
  resetDeleteProfileNetwork,
} from 'App/Profiles/ProfilesView/NetworkSettings/network-settings-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import {
  resetUpdateProfile,
  fetchProfiles,
  updateProfile,
} from 'App/Profiles/profiles-state'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  NetworkSettings: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  NetworkSettingsDescription: {
    marginBottom: theme.spacing(2),
  },
  NetworkTakeHomeDescription: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '0.8em',
  },
  NetworkFormControlLabel: {
    display: 'block',
  },
  BoldFontWeight: {
    fontWeight: 'bold',
    display: 'inline',
  },
  NetworkListItem: {
    display: 'flex',
    alignItems: 'center',
  },
  ListItemLabel: {
    display: 'inline-block',
    flexGrow: 1,
  },
  SavedNotification: {
    display: 'inline-block',
    marginLeft: '16px',
    backgroundColor: '#5BC980',
    borderRadius: '30px',
    padding: '4px 12px',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    '& .MuiSvgIcon-root': {
      marginRight: '6px',
      verticalAlign: 'middle',
      fontSize: '12px',
    },
  },
}))

interface SavedNotificationProps {
  saved?: number
  profileNetworkId?: number
}

const SavedNotification = ({ saved }: SavedNotificationProps) => {
  const { classes } = useStyles()
  const [show, setShow] = useState<undefined | number>()
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (show !== saved && saved !== undefined) {
      // This handles automatic fading out of the `Saved` notification.
      // It also is able to handle multiple sequential `Saved` notifications properly.

      setShow(saved)

      if (timer != null) {
        clearTimeout(timer)
      }

      const newTimer = setTimeout(() => {
        setTimer(null)
      }, 2000)

      setTimer(newTimer)
    }
  }, [show, saved, timer])

  return (
    <Fade in={timer !== null} timeout={400}>
      <Box className={classes.SavedNotification}>
        <CheckIcon />
        Saved
      </Box>
    </Fade>
  )
}

interface NetworkItem {
  networkId: number
  ssid: string
  profileNetworkId?: number
  profileId: number
}

interface NetworkListItemProps {
  networkItem: NetworkItem
}

const NetworkListItem = ({
  networkItem: { networkId, profileId, profileNetworkId, ssid },
}: NetworkListItemProps) => {
  const dispatch = useAppDispatch()
  const [saved, setSaved] = useState<undefined | number>()

  const { organizationId, siteId } = useCurrentAccount()
  const addedProfileNetwork = useAppSelector(
    (state) => state.profileNetworks.addedProfileNetwork
  )
  const deletedProfileNetwork = useAppSelector(
    (state) => state.profileNetworks.deletedProfileNetwork
  )

  const context = useMemo(
    () => ({ organizationId, siteId, profileId }),
    [organizationId, siteId, profileId]
  )

  const onToggleProfileNetwork: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (e.target.checked) {
      dispatch(addProfileNetwork({ context, networkId }))
    } else {
      dispatch(deleteProfileNetwork({ context, networkId }))
    }
  }

  useEffect(() => {
    if (addedProfileNetwork[networkId]) {
      if (profileNetworkId === undefined) {
        dispatch(fetchProfileNetworks(context))
      } else {
        dispatch(resetAddProfileNetwork(networkId))
        setSaved(Date.now())
      }
    }
  }, [addedProfileNetwork, dispatch, context, networkId, profileNetworkId])

  useEffect(() => {
    if (deletedProfileNetwork[networkId]) {
      if (profileNetworkId !== undefined) {
        dispatch(fetchProfileNetworks(context))
      } else {
        dispatch(resetDeleteProfileNetwork(networkId))
        setSaved(Date.now())
      }
    }
  }, [deletedProfileNetwork, dispatch, context, networkId, profileNetworkId])

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={profileNetworkId !== undefined}
            onChange={onToggleProfileNetwork}
          />
        }
        label={
          <>
            {ssid}
            <SavedNotification
              profileNetworkId={profileNetworkId}
              saved={saved}
            />
          </>
        }
      />
    </div>
  )
}

interface NetworkSettingsProps {
  profile: Profile
}

const NetworkSettings = ({ profile }: NetworkSettingsProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const dispatch = useAppDispatch()
  const updatedProfile = useAppSelector(
    (state) => state.profiles.updatedProfile
  )
  const updatingProfile = useAppSelector(
    (state) => state.profiles.updatingProfile
  )

  useEffect(() => {
    if (profile && updatedProfile) {
      dispatch(resetUpdateProfile())
      dispatch(
        fetchProfiles({
          organizationId: profile.organizationId,
          siteId: profile.siteId,
        })
      )
    }
  }, [dispatch, profile, updatedProfile])

  const networks = useNetworks()
  const profileNetworks = useProfileNetworks(profile.id)

  const networkItems: NetworkItem[] = useMemo(() => {
    const networkItems: NetworkItem[] = networks.map((network) => {
      const profileNetwork = profileNetworks.find(
        (pn) => pn.networkId === network.id
      )

      const networkItem: NetworkItem = {
        networkId: network.id,
        ssid: network.ssid,
        profileNetworkId: profileNetwork?.id,
        profileId: profile.id,
      }

      return networkItem
    })

    networkItems.sort((a, b) => {
      // Sort by `ssid` ASC
      const aSsid = a.ssid.toLowerCase()
      const bSsid = b.ssid.toLowerCase()

      if (aSsid < bSsid) {
        return -1
      } else if (aSsid > bSsid) {
        return 1
      } else {
        return 0
      }
    })

    return networkItems
  }, [networks, profile.id, profileNetworks])

  const [openPopup, setOpenPopup] = useState(false)

  const onChange4G = (checked: boolean) => {
    if (!updatingProfile) {
      dispatch(
        updateProfile({
          context: {
            organizationId: profile.organizationId,
            siteId: profile.siteId,
          },
          form: { enableTelephony: checked },
          profileId: profile.id,
        })
      )
    }
  }

  const onChangePrivateNetworks = (checked: boolean) => {
    if (!updatingProfile) {
      dispatch(
        updateProfile({
          context: {
            organizationId: profile.organizationId,
            siteId: profile.siteId,
          },
          form: { enablePrivateNetworks: checked },
          profileId: profile.id,
        })
      )
    }
  }

  if (!profile) {
    return null
  }

  return (
    <div className={classes.NetworkSettings}>
      <Panel
        title={
          <Grid container style={{ alignItems: 'center' }}>
            <Grid item style={{ flexGrow: 1 }}>
              {t('profiles.editProfile.networks.wifi.title')}
            </Grid>
            <Grid item>
              <Button
                small
                flat
                onClick={() => {
                  setOpenPopup(true)
                }}
              >
                {t('profiles.editProfile.networks.wifi.addButton')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <div className={classes.NetworkSettingsDescription}>
          {t('profiles.editProfile.networks.wifi.description')}
        </div>
        {networkItems.map((networkItem) => (
          <NetworkListItem
            key={networkItem.networkId}
            networkItem={networkItem}
          />
        ))}
      </Panel>
      <Panel title={t('profiles.editProfile.networks.takeHomeSettings')}>
        <FormControlLabel
          className={classes.NetworkFormControlLabel}
          control={
            <Switch
              checked={profile.enablePrivateNetworks}
              onChange={(_e, checked) => onChangePrivateNetworks(checked)}
            />
          }
          label={
            <p className={classes.BoldFontWeight}>
              {t('profiles.editProfile.networks.wifi.enable')}
            </p>
          }
        />
        <FormControlLabel
          className={classes.NetworkFormControlLabel}
          control={
            <Switch
              checked={profile.enableTelephony}
              onChange={(_e, checked) => onChange4G(checked)}
            />
          }
          label={
            <p className={classes.BoldFontWeight}>
              {t('profiles.editProfile.networks.simCard.enable')}
            </p>
          }
        />
        <div className={classes.NetworkTakeHomeDescription}>
          <p className={classes.BoldFontWeight}>
            {t('profiles.editProfile.networks.wifi.info')}
          </p>{' '}
          {t('profiles.editProfile.networks.wifi.explaination')}
        </div>
        <div className={classes.NetworkTakeHomeDescription}>
          <p className={classes.BoldFontWeight}>
            {t('profiles.editProfile.networks.simCard.info')}
          </p>
          {' - '}
          {t('profiles.editProfile.networks.simCard.explaination')}
        </div>
      </Panel>
      <AddNetworkPopup open={openPopup} onClose={() => setOpenPopup(false)} />
    </div>
  )
}

export default NetworkSettings

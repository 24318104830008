import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  type Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ClearIcon from '@mui/icons-material/Clear'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import {
  fetchInstallQrcode,
  resetInstallQrcode,
} from 'App/Tablets/tablets-state'
import useNetworks from 'common/hooks/useNetworks'
import FormSelect, { type FormSelectOption } from 'common/components/FormSelect'
import useApp from 'common/hooks/useApp'
import FormFieldGroup from 'common/components/FormFieldGroup'
import { useAppDispatch, useAppSelector } from 'store'
import { useGetTabletsPackagesQuery } from 'App/Tablets/tablets-rtk-api'
import { TabletPackageKind } from 'App/Tablets/tablets-types'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogPaper: {
    width: '505px',
    maxWidth: '100%',
    padding: '36px',
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  DialogTitle: {
    padding: 0,
    '& > h2': {
      lineHeight: 1,
      fontSize: '24px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  DialogHeadClose: {
    textAlign: 'right',
    color: '#000',
  },
  DialogCloseIcon: {
    cursor: 'pointer',
  },
  DialogContent: {
    padding: 0,
    marginTop: '16px',
    marginBottom: '20px',
    '& > p': {
      fontSize: '16px',
      color: '#000',
    },
  },

  Loader: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(5),
  },
}))

interface AddTabletPopupProps {
  open: boolean
  onClose: () => void
}

const AddTabletPopup = ({ open, onClose }: AddTabletPopupProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { adminMode } = useApp()
  const { siteId, organizationId } = useCurrentAccount()

  const qrcode = useAppSelector((state) => state.tablets.installQrcode)

  const [selectedNetworkId, setSelectedNetworkId] = useState<string>('')
  const networks = useNetworks()
  const networkOptions = useMemo(() => {
    return networks.map((network) => ({
      label: network.ssid,
      value: String(network.id),
    }))
  }, [networks])

  const { data: tabletPackages } = useGetTabletsPackagesQuery(
    {
      organizationId,
      siteId,
    },
    {
      skip: !adminMode,
    }
  )

  const [selectedVersion, setSelectedVersion] = useState<string | undefined>()

  useEffect(() => {
    if (tabletPackages == null) {
      return
    }

    if (tabletPackages.length > 0 && selectedVersion == null) {
      setSelectedVersion(tabletPackages[0].name)
    }
  }, [tabletPackages, selectedVersion])

  const versionOptions = useMemo(() => {
    if (tabletPackages == null) {
      return []
    }

    const masterOptions: FormSelectOption[] = []
    const featureOptions: FormSelectOption[] = []

    for (const pkg of tabletPackages) {
      if (pkg.kind === TabletPackageKind.Master) {
        masterOptions.push({
          startGroup:
            masterOptions.length === 0 ? TabletPackageKind.Master : undefined,
          label: pkg.name,
          value: pkg.name,
        })
      } else if (pkg.kind === TabletPackageKind.Feature) {
        featureOptions.push({
          startGroup:
            featureOptions.length === 0 ? TabletPackageKind.Feature : undefined,
          label: pkg.name,
          value: pkg.name,
        })
      }
    }

    const options = [...masterOptions, ...featureOptions]

    return options
  }, [tabletPackages])

  useEffect(() => {
    if (networks.length > 0 && selectedNetworkId === undefined) {
      setSelectedNetworkId(String(networks[0].id))
    }
  }, [selectedNetworkId, networks])

  useEffect(() => {
    if (selectedNetworkId === undefined) {
      return
    }

    if (open) {
      const tabletPackage = !adminMode
        ? undefined
        : tabletPackages?.find((pkg) => pkg.name === selectedVersion)
      dispatch(
        fetchInstallQrcode({
          siteId,
          organizationId,
          networkId:
            selectedNetworkId === '' ? undefined : Number(selectedNetworkId),
          tabletPackage,
        })
      )
    } else {
      dispatch(resetInstallQrcode())
    }
  }, [
    dispatch,
    open,
    siteId,
    organizationId,
    selectedNetworkId,
    selectedVersion,
    adminMode,
    tabletPackages,
  ])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.DialogPaper,
      }}
    >
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid className={classes.DialogHeadClose} item xs={6}>
          <ClearIcon className={classes.DialogCloseIcon} onClick={onClose} />
        </Grid>
      </Grid>
      <DialogContent className={classes.DialogContent}>
        {qrcode ? (
          <div dangerouslySetInnerHTML={{ __html: qrcode }} />
        ) : (
          <CircularProgress className={classes.Loader} />
        )}
        <FormFieldGroup>
          {adminMode && (
            <FormSelect
              label={t('tablets.addTablet.selectVersion')}
              value={selectedVersion}
              options={versionOptions}
              onChange={(_e, v) => setSelectedVersion(v as string)}
            />
          )}
          <FormSelect
            label={t('tablets.addTablet.selectNetwork')}
            options={networkOptions}
            onChange={(_e, value) => setSelectedNetworkId(String(value))}
            value={selectedNetworkId}
            emptyLabel="-"
          />
        </FormFieldGroup>
        <br />
        <DialogContentText>
          {t('tablets.addTablet.description')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default AddTabletPopup

import { FileDownload } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { type GridApiCommunity } from '@mui/x-data-grid/internals'
import React from 'react'

interface DownloadButtonProps {
  apiRef: React.MutableRefObject<GridApiCommunity>
  disabled: boolean
  title: string
  fileName: string
  ariaLabel?: string
}

// DownloadButton is commonly used in DataGrid for download DataGrid to CSV file
const DownloadButton = ({
  apiRef,
  disabled,
  title,
  ariaLabel,
  fileName,
}: DownloadButtonProps) => {
  return (
    <IconButton
      color="primary"
      title={title}
      aria-label={ariaLabel ?? ''}
      onClick={() => apiRef.current.exportDataAsCsv({ fileName })}
      disabled={disabled}
    >
      <FileDownload />
    </IconButton>
  )
}

export default DownloadButton

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import PageEmpty from 'common/components/PageEmpty'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import AddTabletPopup from 'App/Tablets/components/AddTabletPopup'
import useTablets from 'common/hooks/useTablets'
import { useLocation } from 'react-router-dom'
import { type Tablet } from 'App/Tablets/tablets-types'
import TabletTable from 'App/Tablets/components/TabletTable'
import {
  fetchTablets,
  removeTablet as removeTabletAction,
  rebootTablet as rebootTabletAction,
  resetLockTablet,
  resetRebootTablet,
  resetRemoveTablet,
} from 'App/Tablets/tablets-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import ActionDialog from 'common/components/ActionDialog'
import EditSettings from 'App/Tablets/components/EditSettings'
import PageRequirement from 'common/components/PageRequirement'
import useProfiles from 'common/hooks/useProfiles'
import useNetworks from 'common/hooks/useNetworks'
import TabletsTableActionsBar from 'App/Tablets/components/TabletsTableActionsBar'
import useSearch, { type UseSearchOptions } from 'common/hooks/useSearch'
import ResetTabletPopup from 'App/Tablets/components/ResetTabletPopup'
import useRenderDate from 'common/hooks/useRenderDate'

const useDockId = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const qDockId = searchParams.get('dockId')
  const dockId = qDockId ? parseInt(qDockId) : undefined

  return dockId
}

const TabletsList = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const renderDate = useRenderDate()

  const dockId = useDockId()

  const { siteId, organizationId } = useCurrentAccount()

  const tablets = useTablets(dockId, true)
  const addedTablet = useAppSelector((state) => state.tablets.addedTablet)
  const lockedTablet = useAppSelector((state) => state.tablets.lockedTablet)
  const rebootedTablet = useAppSelector((state) => state.tablets.rebootedTablet)
  const removedTablet = useAppSelector((state) => state.tablets.removedTablet)

  const fetchedTablets = useAppSelector((state) => state.tablets.fetchedTablets)

  const profiles = useProfiles()
  const networks = useNetworks()

  useEffect(() => {
    if (removedTablet) {
      dispatch(resetRemoveTablet())
      dispatch(fetchTablets({ siteId, organizationId }))
    }
  }, [dispatch, removedTablet, siteId, organizationId])

  useEffect(() => {
    if (lockedTablet) {
      dispatch(resetLockTablet())
      dispatch(fetchTablets({ siteId, organizationId }))
    }
  }, [dispatch, lockedTablet, siteId, organizationId])

  useEffect(() => {
    if (rebootedTablet) {
      dispatch(resetRebootTablet())
      dispatch(fetchTablets({ siteId, organizationId }))
    }
  }, [dispatch, rebootedTablet, siteId, organizationId])

  const [activeTablets, setActiveTablets] = useState<Tablet[]>([])

  const activeTabletSNs = useMemo(() => {
    return activeTablets.map((tablet) => tablet.serialNumber).join(', ')
  }, [activeTablets])

  const [addOpen, setAddOpen] = useState(false)
  const onClickAdd = () => setAddOpen(true)
  const onCloseAddPopup = () => setAddOpen(false)

  const [rebootOpen, setRebootOpen] = useState(false)
  const onClickReboot = useCallback((tablets: Tablet[]) => {
    setRebootOpen(true)
    setActiveTablets(tablets)
  }, [])
  const onCloseRebootDialog = () => setRebootOpen(false)
  const onActionReboot = () => {
    dispatch(rebootTabletAction(activeTablets))
    setRebootOpen(false)
  }

  const [showResetTabletQRCodeForTablet, setShowResetTabletQRCodeForTablet] =
    useState<Tablet>()

  const [removeOpen, setRemoveOpen] = useState(false)
  const onClickRemove = useCallback((tablets: Tablet[]) => {
    setRemoveOpen(true)
    setActiveTablets(tablets)
  }, [])
  const onCloseRemoveDialog = () => setRemoveOpen(false)
  const onActionRemove = () => {
    dispatch(removeTabletAction(activeTablets))
    setRemoveOpen(false)
  }

  const [selectedTablets, setSelectedTablets] = useState<Tablet[]>([])
  const onSelectTablets = (tablets: Tablet[]) => {
    setSelectedTablets(tablets)
  }

  const [openSettings, setOpenSettings] = useState(false)

  // Table actions bar
  const [showDeleted, setShowDeleted] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const tabletsFilteredByShowDeleted = useMemo(() => {
    if (showDeleted) return tablets
    return tablets.filter((tablet) => tablet.deletedAt === undefined)
  }, [tablets, showDeleted])

  const searchOptions: UseSearchOptions<Tablet> = useMemo(
    () => ({
      data: tabletsFilteredByShowDeleted,
      fields: ['serialNumber', 'macAddress', 'activeAt'],
      query: searchQuery,
      renderers: {
        activeAt: (activeAt?: number) =>
          activeAt === undefined ? '-' : renderDate(new Date(activeAt)),
      },
    }),
    [tabletsFilteredByShowDeleted, searchQuery, renderDate]
  )

  const tabletsFilteredBySearchQuery = useSearch(searchOptions)

  if (!fetchedTablets) {
    return <Page />
  }

  return (
    <>
      <Page>
        <PageRequirement
          criteria={profiles.length > 0}
          title="Create profile first"
          description="You need to have at least 1 profile before you can manage tablets."
          actionText="Go to profiles"
          actionTo="/profiles"
        >
          <PageRequirement
            criteria={networks.length > 0}
            title="Create network first"
            description="You need to have at least 1 network before you can manage tablets."
            actionText="Go to networks"
            actionTo="/networks"
          >
            {tablets.length === 0 ? (
              <PageEmpty
                title={t('tablets.empty.title')}
                description={t('tablets.empty.description')}
                actionText={t('tablets.empty.action')}
                onClickAction={onClickAdd}
              />
            ) : (
              <>
                <PageHeader>
                  <PageTitle title={t('tablets.title')} />
                  <PageActions>
                    <Button
                      small
                      outlined
                      onClick={() => setOpenSettings(true)}
                    >
                      {t('tablets.actions.settings')}
                    </Button>
                    <Button onClick={onClickAdd} small>
                      {t('tablets.actions.addTablet')}
                    </Button>
                  </PageActions>
                </PageHeader>
                <PageBody>
                  <TabletsTableActionsBar
                    showDeleted={showDeleted}
                    onChangeShowDeleted={setShowDeleted}
                    selectedTablets={selectedTablets}
                    searchQuery={searchQuery}
                    onSearch={setSearchQuery}
                  />
                  <TabletTable
                    tablets={tabletsFilteredBySearchQuery}
                    onClickReboot={(tablet: Tablet) => onClickReboot([tablet])}
                    onClickReset={(tablet: Tablet) =>
                      setShowResetTabletQRCodeForTablet(tablet)
                    }
                    onClickRemove={(tablet: Tablet) => onClickRemove([tablet])}
                    onSelectTablets={onSelectTablets}
                  />
                </PageBody>
              </>
            )}
          </PageRequirement>
        </PageRequirement>
      </Page>
      <AddTabletPopup open={addOpen} onClose={onCloseAddPopup} />
      <Toast
        open={addedTablet}
        message={t('tablets.addTablet.toast.success')}
      />

      <ActionDialog
        open={rebootOpen}
        title={t('tablets.rebootTablet.title')}
        description={t('tablets.rebootTablet.description', {
          tablet: activeTabletSNs,
        })}
        actionText={t('tablets.rebootTablet.action')}
        onAction={onActionReboot}
        onClose={onCloseRebootDialog}
      />
      <Toast
        open={rebootedTablet}
        message={t('tablets.rebootTablet.toast.success')}
      />
      <ActionDialog
        open={removeOpen}
        title={t('tablets.removeTablet.title')}
        description={t('tablets.removeTablet.description', {
          tablet: activeTabletSNs,
        })}
        actionText={t('tablets.removeTablet.action')}
        onAction={onActionRemove}
        onClose={onCloseRemoveDialog}
      />
      <Toast
        open={removedTablet}
        message={t('tablets.removeTablet.toast.success')}
      />

      <EditSettings
        open={openSettings}
        onClose={() => setOpenSettings(false)}
      />

      {showResetTabletQRCodeForTablet != null && (
        <ResetTabletPopup
          tablet={showResetTabletQRCodeForTablet}
          onClose={() => setShowResetTabletQRCodeForTablet(undefined)}
        />
      )}
    </>
  )
}

export default TabletsList

import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material'
import { StyledMenuItemButton } from './SideMenu2.styled'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuItemBase } from 'common/components/SideMenu2/SideMenu2.types'
import { HelpOutlineRounded } from '@mui/icons-material'

interface SubMenuItemProps {
  item: MenuItemBase
}

const SubMenuItem = ({ item }: SubMenuItemProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { link, label } = item

  return (
    <ListItem
      disablePadding
      secondaryAction={
        item.disabled ? (
          <Tooltip title={item.disabledNote} placement="left">
            <IconButton edge="end" size="small">
              <HelpOutlineRounded />
            </IconButton>
          </Tooltip>
        ) : null
      }
    >
      <StyledMenuItemButton
        isSubMenu
        onClick={() => navigate(link!)}
        selected={location.pathname.startsWith(link!)}
        disabled={Boolean(item.disabled)}
      >
        <ListItemText sx={{ fontSize: '1rem' }}>{label}</ListItemText>
      </StyledMenuItemButton>
    </ListItem>
  )
}

export default SubMenuItem

import React, { type PropsWithChildren } from 'react'
import { Dialog } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import BackgroundSVG from 'common/graphics/cloud.svg'
import BottomSVG from 'common/graphics/bottom.svg'
import { useAppSelector } from 'store'

const useStyles = makeStyles()(() => ({
  Dialog: {
    maxWidth: '100%',
    width: '720px',
    height: '428px',
  },
  NarrowDialog: {
    width: '614px',
    height: '671px',
  },
  WideDialog: {
    width: '1200px',
    height: '671px',
  },
  FullBackground: {
    backgroundColor: '#FFFCE0',
    backgroundImage: `url(${BackgroundSVG})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 100%',
    backgroundSize: '100% 40%',
  },
  BottomBackground: {
    backgroundImage: `url(${BottomSVG})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 105%',
  },
  NarrowBottomBackground: {
    backgroundSize: '115% 60%',
  },
  WideBottomBackground: {
    backgroundSize: '100% 60%',
  },
}))

interface PaperStyleProps {
  width?: string
  height?: string
}

interface WizardDialogProps extends PropsWithChildren<PaperStyleProps> {
  open: boolean
  onClose: () => void
  narrow?: boolean
  wide?: boolean
  fullBackground?: boolean
  bottomBackground?: boolean
}

const WizardDialog = ({
  children,
  open,
  onClose,
  narrow = false,
  wide = false,
  fullBackground = false,
  bottomBackground = false,
  ...paperStyles
}: WizardDialogProps) => {
  const { classes, cx } = useStyles()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  const dialogClassNames = [classes.Dialog]

  if (fullBackground) {
    dialogClassNames.push(classes.FullBackground)
  }

  if (bottomBackground) {
    dialogClassNames.push(classes.BottomBackground)
  }

  if (narrow) {
    dialogClassNames.push(classes.NarrowDialog)
    if (bottomBackground) {
      dialogClassNames.push(classes.NarrowBottomBackground)
    }
  }

  if (wide) {
    dialogClassNames.push(classes.WideDialog)
    if (bottomBackground) {
      dialogClassNames.push(classes.WideBottomBackground)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: cx(dialogClassNames),
        style: { ...paperStyles },
      }}
      dir={languageDirection}
    >
      {children}
    </Dialog>
  )
}

export default WizardDialog

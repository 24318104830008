import React from 'react'
import { Box, IconButton, Divider, styled } from '@mui/material'
import {} from '@mui/material/colors'
import MenuIcon from '@mui/icons-material/Menu'
import { useAppSelector } from 'store'
import AccountSelect from 'common/components/AccountSelect'
import UserAccount from 'common/components/UserAccount'

interface PageTopMenuProps {
  toggleShowSideMenu: () => void
}

const TopMenuContainer = styled(Box)(({ theme }) => ({
  minHeight: theme.spacing(10),
  maxHeight: theme.spacing(10),
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  color: '#fff',
}))

const PageTopMenu = ({ toggleShowSideMenu }: PageTopMenuProps) => {
  const currentUser = useAppSelector((state) => state.app.currentUser)

  return (
    <TopMenuContainer>
      <IconButton
        aria-label="Menu"
        onClick={() => toggleShowSideMenu()}
        sx={{ color: '#fff' }}
      >
        <MenuIcon />
      </IconButton>
      {currentUser && (
        <Box display="flex" gap={3} alignItems="center">
          <AccountSelect user={currentUser} />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ backgroundColor: 'rgba(255,255,255,0.2)' }}
          />
          <UserAccount currentUser={currentUser} />
        </Box>
      )}
    </TopMenuContainer>
  )
}

export default PageTopMenu

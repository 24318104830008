import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  type Theme,
  TableFooter,
  TablePagination,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useRowSelect, useTable, useSortBy, usePagination } from 'react-table'
import Checkbox from 'common/components/Checkbox'
import { useTranslation } from 'react-i18next'
import { renderIf } from 'common/utils/render-utils'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpOutlined'
import SortIcon from '@mui/icons-material/SortOutlined'

const useStyles = makeStyles()((theme: Theme) => ({
  Table: {
    borderRadius: '10px',
    overflow: 'auto',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    color: '#000',
    fontSize: '16px',
    '& .MuiTableRow-root': {
      borderBottom: `1px solid #e0e0e0`,
      '&:last-child': {
        borderBottom: 0,
      },
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(3),
      fontSize: '16px',
    },
    '& .MuiTableCell-head': {
      padding: theme.spacing(3),
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  RowSelected: {
    backgroundColor: '#F8F4F8',
  },
  DrawerTable: {
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
  },
  PanelTable: {
    boxShadow: 'none',
    border: 0,
    borderRadius: 0,
  },
  HeaderCell: {
    verticalAlign: 'middle',
  },
  SortByIcon: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle',
  },
  CanSortIcon: {
    fontSize: '1rem',
  },
}))

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef<any>()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      if (resolvedRef?.current) {
        resolvedRef.current.indeterminate = indeterminate
      }
    }, [resolvedRef, indeterminate])

    return <Checkbox ref={resolvedRef} {...rest} />
  }
)

interface TableColumn {
  id?: string
  title?: React.ReactNode
  field?: string
  render?: (model: any) => React.ReactNode
  style?: any
  canSort?: boolean
}

export interface SortBy {
  id: string
  desc: boolean
}

interface TableProps {
  data: any[]
  columns: TableColumn[]
  count: number
  rowsPerPage: number
  pageIndex: number
  onPageChange: (pageIndex: number) => void
  onRowsPerPageChange: (rowsPerPage: number) => void
  onSelectionChange?: (rows: any) => void
  onSortChange?: (sortBy: SortBy[]) => void
  selectable?: boolean
  pagination?: boolean
  variant?: 'page' | 'drawer' | 'panel'
}

const Table = ({
  data,
  columns,
  count,
  rowsPerPage,
  pageIndex,
  onPageChange,
  onRowsPerPageChange,
  // TODO: check this old code if it is needed
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelectionChange = () => {},
  // TODO: check this old code if it is needed
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSortChange = () => {},
  selectable = false,
  pagination = true,
  variant = 'page',
}: TableProps) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const rowsPerPageOptions = [10, 25, 50, 100]

  const _columns = useMemo(
    () =>
      columns.map((item: TableColumn): any => ({
        id: item.id,
        Header: item.title ?? '',
        Cell: ({ row }: any) =>
          item.render != null
            ? item.render(row.original)
            : row.original[item.field ?? ''],
        accessor: item.field,
        style: item.style,
        disableSortBy: item.canSort !== true,
      })),
    [columns]
  )

  const [controlledSortBy, setControlledSortBy] = useState<SortBy[]>([])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-expect-error: old code, to check later
    page: rows,
    // @ts-expect-error: old code, to check later
    setPageSize,
    // @ts-expect-error: old code, to check later
    selectedFlatRows,
    // @ts-expect-error: old code, to check later
    state: { sortBy },
  } = useTable<Record<string, unknown>>(
    {
      columns: _columns,
      data,
      initialState: { sortBy: controlledSortBy } as any,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!selectable) {
        return
      }
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          // eslint-disable-next-line react/display-name
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // eslint-disable-next-line react/display-name
          Cell: ({ row }: any) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          style: { width: '24px' },
        },
        ...columns,
      ])
    }
  )

  useEffect(
    () => {
      if (selectable) {
        onSelectionChange(selectedFlatRows.map((row: any) => row.original))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectable, selectedFlatRows]
  )

  useEffect(() => {
    setControlledSortBy(sortBy)
    if (onSortChange) {
      onSortChange(sortBy)
    }
  }, [sortBy, onSortChange])

  const tableClasses = [classes.Table]

  if (variant === 'drawer') {
    tableClasses.push(classes.DrawerTable)
  } else if (variant === 'panel') {
    tableClasses.push(classes.PanelTable)
  }

  return (
    <TableContainer component={Paper} className={cx(tableClasses)}>
      <MuiTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // eslint-disable-next-line react/jsx-key
                <TableCell
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      className: column.className,
                      style: column.style,
                      title: column.canSort
                        ? t('common.table.sortBy')
                        : undefined,
                    })
                  )}
                >
                  <span className={classes.HeaderCell}>
                    {column.render('Header')}
                  </span>
                  {column.canSort ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDropDownIcon className={classes.SortByIcon} />
                      ) : (
                        <ArrowDropUpIcon className={classes.SortByIcon} />
                      )
                    ) : (
                      <SortIcon
                        className={cx([
                          classes.SortByIcon,
                          classes.CanSortIcon,
                        ])}
                      />
                    )
                  ) : (
                    ''
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <TableRow
                {...row.getRowProps()}
                className={row.isSelected ? classes.RowSelected : ''}
              >
                {row.cells.map((cell: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                    ])}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
        {renderIf(pagination, () => (
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ padding: 0 }}
                count={count}
                rowsPerPageOptions={rowsPerPageOptions}
                rowsPerPage={rowsPerPage}
                page={pageIndex}
                onPageChange={(_, p) => onPageChange(p)}
                onRowsPerPageChange={(e) => {
                  setPageSize(parseInt(e.target.value))
                  onRowsPerPageChange(parseInt(e.target.value))
                }}
                labelRowsPerPage={t('common.table.pagination.rowsPerPage')}
                labelDisplayedRows={(props) =>
                  t('common.table.pagination.displayedRows', props)
                }
                getItemAriaLabel={(type) => {
                  switch (type) {
                    case 'previous':
                      return t('common.table.pagination.previousPage')
                    case 'next':
                      return t('common.table.pagination.nextPage')
                    default:
                      return ''
                  }
                }}
              />
            </TableRow>
          </TableFooter>
        ))}
      </MuiTable>
    </TableContainer>
  )
}

export default Table

import { Print } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { type GridApiCommunity } from '@mui/x-data-grid/internals'
import React from 'react'

interface PrintButtonProps {
  apiRef: React.MutableRefObject<GridApiCommunity>
  disabled: boolean
  title: string
  fileName: string
  ariaLabel?: string
}

const PrintButton = ({
  apiRef,
  disabled,
  title,
  fileName,
  ariaLabel,
}: PrintButtonProps) => {
  return (
    <IconButton
      color="primary"
      title={title}
      aria-label={ariaLabel ?? ''}
      onClick={() =>
        apiRef.current.exportDataAsPrint({
          fileName,
          pageStyle:
            '@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; min-width: 1200px; } }',
        })
      }
      disabled={disabled}
    >
      <Print />
    </IconButton>
  )
}

export default PrintButton

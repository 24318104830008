import { Tooltip, IconButton } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { RemoveRedEyeRounded } from '@mui/icons-material'

export const columns: GridColDef<any>[] = [
  {
    field: 'feature',
    headerName: 'Feature',
    type: 'string',
    width: 150,
    flex: 1,
  },
  {
    field: 'action',
    headerName: 'Action',
    type: 'string',
    width: 180,
    flex: 1,
  },
  {
    field: 'orgId',
    headerName: 'Org ID',
    type: 'number',
    width: 80,
  },
  {
    field: 'userId',
    headerName: 'User ID',
    type: 'number',
    width: 80,
  },
  {
    field: 'browser',
    headerName: 'Browser',
    type: 'string',
    width: 120,
  },
  {
    field: 'isMobile',
    headerName: 'Mobile',
    type: 'boolean',
    width: 120,
  },
  {
    field: 'city',
    headerName: 'City',
    type: 'string',
    width: 140,
  },
  {
    field: 'country',
    headerName: 'Country',
    type: 'string',
    width: 140,
  },
  {
    field: 'payload',
    headerName: 'Payload',
    type: 'string',
    width: 80,
    flex: 1,
    renderCell: ({ value }) => {
      return (
        <>
          <Tooltip
            arrow
            placement="left"
            title={<pre>{JSON.stringify(value, null, 2)}</pre>}
          >
            <IconButton>
              <RemoveRedEyeRounded />
            </IconButton>
          </Tooltip>
        </>
      )
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    type: 'dateTime',
    width: 170,
    valueGetter: (value: string) => new Date(value),
  },
]

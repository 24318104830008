import React, { useEffect, useMemo, useState } from 'react'
import Panel, { EditState } from 'common/components/NewPanel'
import PanelField from 'common/components/PanelField'
import { useTranslation } from 'react-i18next'
import FormSelect, { type FormSelectOption } from 'common/components/FormSelect'
import ScheduleIcon from '@mui/icons-material/ScheduleOutlined'
import CreateIcon from '@mui/icons-material/CreateOutlined'
import { Grid } from '@mui/material'
import FormField from 'common/components/FormField'
import { type Profile } from 'App/Profiles/profiles-types'
import { useUpdateProfileLoansConfigMutation } from 'App/Profiles/profiles-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { fetchProfiles } from 'App/Profiles/profiles-state'
import { useAppDispatch } from 'store'

enum LoanDuration {
  ThirtyMinutes = 30,
  OneHour = 1 * 60,
  TwoHours = 2 * 60,
  ThreeHours = 3 * 60,
  SixHours = 6 * 60,
  Custom = -1,
}

enum LoanDurationUnit {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
}

interface LoanSettingsProps {
  profile: Profile
}

const LoanSettings = ({ profile }: LoanSettingsProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const tBase = 'profiles.editProfile.loans'

  const { organizationId, siteId } = useCurrentAccount()

  const [
    updateProfileLoansConfig,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateProfileLoansConfigMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchProfiles({ organizationId, siteId }))
    }

    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    }
  }, [isUpdateSuccess, hasApiMutationErrors, organizationId, siteId, dispatch])

  const [editState, setEditState] = useState(EditState.Default)

  const loanDurationOptions: FormSelectOption[] = useMemo(
    () => [
      {
        Icon: ScheduleIcon,
        label: t(`${tBase}.loanDuration.labels.minutes`, { count: 30 }),
        value: LoanDuration.ThirtyMinutes,
      },
      {
        Icon: ScheduleIcon,
        label: t(`${tBase}.loanDuration.labels.hours`, { count: 1 }),
        value: LoanDuration.OneHour,
      },
      {
        Icon: ScheduleIcon,
        label: t(`${tBase}.loanDuration.labels.hours`, { count: 2 }),
        value: LoanDuration.TwoHours,
      },
      {
        Icon: ScheduleIcon,
        label: t(`${tBase}.loanDuration.labels.hours`, { count: 3 }),
        value: LoanDuration.ThreeHours,
      },
      {
        Icon: ScheduleIcon,
        label: t(`${tBase}.loanDuration.labels.hours`, { count: 6 }),
        value: LoanDuration.SixHours,
      },
      {
        Icon: CreateIcon,
        label: t(`${tBase}.loanDuration.durations.custom`),
        value: LoanDuration.Custom,
      },
    ],
    [t]
  )

  const loanDurationUnitOptions: FormSelectOption[] = [
    {
      Icon: ScheduleIcon,
      label: t(`${tBase}.loanDuration.units.minutes`),
      value: LoanDurationUnit.Minutes,
    },
    {
      Icon: ScheduleIcon,
      label: t(`${tBase}.loanDuration.units.hours`),
      value: LoanDurationUnit.Hours,
    },
    {
      Icon: ScheduleIcon,
      label: t(`${tBase}.loanDuration.units.days`),
      value: LoanDurationUnit.Days,
    },
  ]

  const [loanDuration, setLoanDuration] = useState<number>()
  const [loanDurationUnit, setLoanDurationUnit] = useState(
    loanDurationUnitOptions[0].value?.toString()
  )
  const [customLoanDuration, setCustomLoanDuration] = useState<number>()

  const [customLoanDurationError, setCustomLoanDurationError] =
    useState<string>()

  const getLoanDurationUnitAndValue = (loanDurationMinutes: number) => {
    let unit
    let value
    if (loanDurationMinutes % (60 * 24) === 0) {
      unit = LoanDurationUnit.Days
      value = loanDurationMinutes / (60 * 24)
    } else if (loanDurationMinutes % 60 === 0) {
      unit = LoanDurationUnit.Hours
      value = loanDurationMinutes / 60
    } else {
      unit = LoanDurationUnit.Minutes
      value = loanDurationMinutes
    }
    return { unit, value }
  }

  useEffect(() => {
    if (profile === undefined || loanDuration !== undefined) {
      return
    }

    if (profile.loanDuration !== undefined) {
      let customValue = true
      loanDurationOptions.forEach(({ value }) => {
        if (value === profile.loanDuration) {
          setLoanDuration(profile.loanDuration)
          customValue = false
        }
      })

      if (customValue) {
        const { unit, value } = getLoanDurationUnitAndValue(
          profile.loanDuration
        )
        setLoanDurationUnit(unit)
        setCustomLoanDuration(value)
        setLoanDuration(LoanDuration.Custom)
      }
    } else if (typeof loanDurationOptions[0].value === 'number') {
      setLoanDuration(loanDurationOptions[0].value)
    }
  }, [profile, loanDurationOptions, loanDuration])

  const loanDurationPanelFieldValue = useMemo(() => {
    if (profile.loanDuration === undefined) return undefined

    const label = loanDurationOptions.find(
      (opt) => opt.value === profile.loanDuration
    )?.label
    if (label !== undefined) return label

    const { unit, value } = getLoanDurationUnitAndValue(profile.loanDuration)
    switch (unit) {
      case LoanDurationUnit.Minutes:
        return t(`${tBase}.loanDuration.labels.minutes`, { count: value })
      case LoanDurationUnit.Hours:
        return t(`${tBase}.loanDuration.labels.hours`, { count: value })
      case LoanDurationUnit.Days:
        return t(`${tBase}.loanDuration.labels.days`, { count: value })
      default:
        return undefined
    }
  }, [t, loanDurationOptions, profile.loanDuration])

  const handleSave = () => {
    let timeInMinutes

    if (loanDuration === LoanDuration.Custom) {
      if (
        customLoanDuration === undefined ||
        !Number.isInteger(customLoanDuration) ||
        customLoanDuration <= 0
      ) {
        setCustomLoanDurationError(t(`${tBase}.errors.customLoanDuration`))
        return
      }
      switch (loanDurationUnit) {
        case 'minutes':
          timeInMinutes = customLoanDuration
          break
        case 'hours':
          timeInMinutes = customLoanDuration * 60
          break
        case 'days':
          timeInMinutes = customLoanDuration * 60 * 24
          break
      }
    } else if (loanDuration !== undefined) {
      timeInMinutes = loanDuration
    }

    setCustomLoanDurationError(undefined)

    if (timeInMinutes === undefined) {
      // Should not happen
      console.error('`timeInMinutes` is `undefined`.')
      return
    }

    updateProfileLoansConfig({
      organizationId,
      siteId,
      profileId: profile.id,
      loanDuration: timeInMinutes,
    })
  }

  const handleChangeLoanDuration = (
    e: React.ChangeEvent<{ value: number }>
  ) => {
    const value = e.target.value

    if (
      // TODO: update for better enum comparison
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      value === LoanDuration.Custom &&
      loanDuration !== undefined &&
      // TODO: update for better enum comparison
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      loanDuration !== LoanDuration.Custom
    ) {
      // By default, use the selected loan duration as custom loan duration value
      const { unit, value } = getLoanDurationUnitAndValue(loanDuration)
      setLoanDurationUnit(unit)
      setCustomLoanDuration(value)
    }

    setLoanDuration(value)
  }

  const handleChangeLoanDurationUnit = (
    e: React.ChangeEvent<{ value: string }>
  ) => {
    setLoanDurationUnit(e.target.value)
  }

  const handleChangeCustomLoanDuration = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const numberValue = parseInt(e.target.value)
    setCustomLoanDuration(Number.isNaN(numberValue) ? undefined : numberValue)
  }

  const renderEditMode = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormSelect
          label={t(`${tBase}.loanDuration.name`)}
          options={loanDurationOptions}
          value={loanDuration}
          onChange={handleChangeLoanDuration}
          disabled={editState === EditState.Pending}
        />
      </Grid>
      {loanDuration === LoanDuration.Custom && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormSelect
                options={loanDurationUnitOptions}
                value={loanDurationUnit}
                onChange={handleChangeLoanDurationUnit}
                disabled={editState === EditState.Pending}
              />
            </Grid>
            <Grid item xs={8}>
              <FormField
                type="number"
                placeholder={t(`${tBase}.loanDuration.enterValue`)}
                value={customLoanDuration}
                onChange={handleChangeCustomLoanDuration}
                disabled={editState === EditState.Pending}
                error={customLoanDurationError}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )

  return (
    <Panel
      title={t(`${tBase}.title`)}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(EditState.Default)}
      onSuccess={() => setEditState(EditState.Success)}
      onSave={handleSave}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t(`${tBase}.loanDuration.name`)}
        value={loanDurationPanelFieldValue}
      />
    </Panel>
  )
}

export default LoanSettings

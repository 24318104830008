import {
  type StockTablet,
  type NewStockTablet,
} from 'App/StockTablets/stock-tablets-types'
import rtkApi from 'rtk-api'

interface PaginatedTabletsRes {
  pageSize: number
  currentPage: number
  total: number
  rows: StockTablet[]
}

export interface PaginatedTabletsReq {
  query: string
  pageSize: number
  pageIndex: number
  sortBy?: string
  sortDesc?: boolean
  _?: number // Dummy parameter to bypass cache
}

interface UploadTabletsReq {
  tablets: NewStockTablet[]
}

interface RemoveTabletsReq {
  serialNumbers: string[]
}

interface ReturnTabletsReq extends RemoveTabletsReq {
  returnState: string
}

const stockTabletsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getStockTablets: builder.query<PaginatedTabletsRes, PaginatedTabletsReq>({
      query: ({ pageSize, pageIndex, query, sortBy, sortDesc }) => ({
        url: `/stock-tablets`,
        params: {
          pageSize,
          pageIndex,
          query,
          sortBy,
          sortDesc,
        },
      }),
    }),
    postStockTablets: builder.mutation<void, UploadTabletsReq>({
      query: ({ tablets }) => ({
        url: `/stock-tablets`,
        method: 'POST',
        body: tablets,
      }),
    }),
    postRemoveTablets: builder.mutation<void, RemoveTabletsReq>({
      query: ({ serialNumbers }) => ({
        url: `/stock-tablets/remove`,
        method: 'POST',
        body: serialNumbers,
      }),
    }),
    postReturnTablets: builder.mutation<void, ReturnTabletsReq>({
      query: ({ serialNumbers, returnState }) => ({
        url: `/stock-tablets/return`,
        method: 'POST',
        body: { serialNumbers, returnState },
      }),
    }),
  }),
})

export const {
  useGetStockTabletsQuery,
  usePostStockTabletsMutation,
  usePostRemoveTabletsMutation,
  usePostReturnTabletsMutation,
} = stockTabletsRtkApi

export default stockTabletsRtkApi

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import SitesApi from 'api/sites-api'
import TabletsApi from 'api/tablets-api'
import {
  type Tablet,
  type TabletAppSettings,
  type TabletEmmInfo,
  type TabletPackage,
  type UpdateManagedConfiguration,
} from 'App/Tablets/tablets-types'

interface TabletsState {
  tablets: Tablet[]
  siteEnrolled: boolean
  fetchingTablets: boolean
  fetchedTablets: boolean
  addingTablet: boolean
  addedTablet: boolean
  updatingTablet: boolean
  updatedTablet: boolean
  removingTablet: boolean
  removedTablet: boolean
  lockingTablet: boolean
  lockedTablet: boolean
  rebootingTablet: boolean
  rebootedTablet: boolean
  installQrcode: string | undefined
  fetchingInstallQrcode: boolean
  fetchedInstallQrcode: boolean
  fetchedManagedConfiguration?: number
  managedConfiguration: TabletAppSettings[]
  addedAppSettings: boolean
  deletedAppSettings: boolean
  updatedManagedConfiguration: boolean
  emmInfo?: TabletEmmInfo
  fetchingEmmInfo: boolean
}

const initialState: TabletsState = {
  tablets: [],
  siteEnrolled: false,
  fetchingTablets: false,
  fetchedTablets: false,
  addingTablet: false,
  addedTablet: false,
  updatingTablet: false,
  updatedTablet: false,
  removingTablet: false,
  removedTablet: false,
  lockingTablet: false,
  lockedTablet: false,
  rebootingTablet: false,
  rebootedTablet: false,
  installQrcode: undefined,
  fetchingInstallQrcode: false,
  fetchedInstallQrcode: false,
  fetchedManagedConfiguration: undefined,
  managedConfiguration: [],
  addedAppSettings: false,
  deletedAppSettings: false,
  updatedManagedConfiguration: false,
  emmInfo: undefined,
  fetchingEmmInfo: false,
}

export const fetchSiteEnrolled = createAsyncThunk(
  'tablets/fetchSiteEnrolled',
  async ({
    siteId,
    organizationId,
  }: {
    siteId?: number
    organizationId?: number
  }) => {
    if (siteId === undefined) {
      throw new Error('siteId should not be undefined')
    }

    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }

    const site = await SitesApi.fetchSite(organizationId, siteId)
    return { enrolled: site.enrolled ?? false }
  }
)

export const fetchTablets = createAsyncThunk(
  'tablets/fetchTablets',
  async ({
    siteId,
    organizationId,
    dockId,
    withDeleted = true,
  }: {
    siteId?: number
    organizationId?: number
    dockId?: number
    withDeleted?: boolean
  }) => {
    if (siteId === undefined) {
      throw new Error('siteId should not be undefined')
    }
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }

    return TabletsApi.fetchTablets(siteId, organizationId, dockId, withDeleted)
  }
)

export const addTablet = createAsyncThunk(
  'tablets/addTablet',
  async ({
    siteId,
    organizationId,
  }: {
    siteId?: number
    organizationId?: number
  }) => {
    if (siteId === undefined) {
      throw new Error('siteId should not be undefined')
    }
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }

    // return TabletsApi.createTablet(siteId, organizationId, form)
  }
)

export const removeTablet = createAsyncThunk(
  'tablets/removeTablet',
  async (
    items: Array<{
      id: number
      siteId: number
      organizationId: number
    }>
  ) => {
    items.forEach(({ id, siteId, organizationId }) => {
      TabletsApi.removeTablet(id, siteId, organizationId)
    })
  }
)

export const lockTablet = createAsyncThunk(
  'tablets/lockTablet',
  async (
    items: Array<{
      id: number
      siteId: number
      organizationId: number
    }>
  ) => {
    items.forEach(({ id, siteId, organizationId }) => {
      TabletsApi.lockTablet(id, siteId, organizationId)
    })
  }
)

export const rebootTablet = createAsyncThunk(
  'tablets/rebootTablet',
  async (
    items: Array<{
      id: number
      siteId: number
      organizationId: number
    }>
  ) => {
    items.forEach(({ id, siteId, organizationId }) => {
      TabletsApi.rebootTablet(id, siteId, organizationId)
    })
  }
)

export const fetchInstallQrcode = createAsyncThunk(
  'tablets/fetchInstallQrcode',
  async ({
    siteId,
    organizationId,
    networkId,
    tabletPackage,
  }: {
    siteId: number
    organizationId: number
    networkId?: number
    tabletPackage?: TabletPackage
  }) => {
    return TabletsApi.fetchInstallQrcode(
      siteId,
      organizationId,
      networkId,
      tabletPackage
    )
  }
)

export const fetchManagedConfiguration = createAsyncThunk(
  'tablets/fetchManagedConfiguration',
  async ({
    organizationId,
    siteId,
    tabletId,
  }: {
    organizationId: number
    siteId: number
    tabletId: number
  }) => {
    return TabletsApi.fetchManagedConfiguration(
      organizationId,
      siteId,
      tabletId
    )
  }
)

export const updateManagedConfiguration = createAsyncThunk(
  'tablets/updateManagedConfiguration',
  async ({
    organizationId,
    siteId,
    tabletId,
    data,
  }: {
    organizationId: number
    siteId: number
    tabletId: number
    data: UpdateManagedConfiguration
  }) => {
    return TabletsApi.updateManagedConfiguration(
      organizationId,
      siteId,
      tabletId,
      data
    )
  }
)

export const fetchEmmInfo = createAsyncThunk(
  'tablets/fetchEmmInfo',
  async ({
    organizationId,
    siteId,
    tabletId,
  }: {
    organizationId: number
    siteId: number
    tabletId: number
  }) => {
    return TabletsApi.fetchEmmInfo(organizationId, siteId, tabletId)
  }
)

export const tabletsSlice = createSlice({
  name: 'tablets',
  initialState,
  reducers: {
    resetAddTablet: (state) => {
      state.addingTablet = false
      state.addedTablet = false
      return state
    },
    resetRemoveTablet: (state) => {
      state.removingTablet = false
      state.removedTablet = false
      return state
    },
    resetLockTablet: (state) => {
      state.lockingTablet = false
      state.lockedTablet = false
      return state
    },
    resetRebootTablet: (state) => {
      state.rebootingTablet = false
      state.rebootedTablet = false
      return state
    },
    resetInstallQrcode: (state) => {
      state.fetchedInstallQrcode = false
      state.fetchingInstallQrcode = false
      state.installQrcode = undefined
      return state
    },
    resetUpdateManagedConfiguration: (state) => {
      state.updatedManagedConfiguration = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTablets.pending, (state) => {
      state.fetchingTablets = true
      return state
    })
    builder.addCase(fetchTablets.fulfilled, (state, { payload }) => {
      state.fetchingTablets = false
      state.fetchedTablets = true
      state.tablets = payload
      return state
    })
    builder.addCase(fetchTablets.rejected, (state) => {
      state.fetchingTablets = false
      state.fetchedTablets = false
      return state
    })

    builder.addCase(addTablet.pending, (state) => {
      state.addingTablet = true
      return state
    })
    builder.addCase(addTablet.fulfilled, (state) => {
      state.addingTablet = false
      state.addedTablet = true
      return state
    })
    builder.addCase(addTablet.rejected, (state) => {
      state.addingTablet = false
      state.addedTablet = false
      return state
    })

    builder.addCase(removeTablet.pending, (state) => {
      state.removingTablet = true
      return state
    })
    builder.addCase(removeTablet.fulfilled, (state) => {
      state.removingTablet = false
      state.removedTablet = true
      return state
    })
    builder.addCase(removeTablet.rejected, (state) => {
      state.removingTablet = false
      state.removedTablet = false
      return state
    })

    // We don't provide lockTablet functionality in UI anymore
    // so below functions can be removed
    // but we need to double-check if the state.lockedTablet is used anywhere else before removing
    builder.addCase(lockTablet.pending, (state) => {
      state.lockingTablet = true
      return state
    })
    builder.addCase(lockTablet.fulfilled, (state) => {
      state.lockingTablet = false
      state.lockedTablet = true
      return state
    })
    builder.addCase(lockTablet.rejected, (state) => {
      state.lockingTablet = false
      state.lockedTablet = false
      return state
    })

    builder.addCase(rebootTablet.pending, (state) => {
      state.rebootingTablet = true
      return state
    })
    builder.addCase(rebootTablet.fulfilled, (state) => {
      state.rebootingTablet = false
      state.rebootedTablet = true
      return state
    })
    builder.addCase(rebootTablet.rejected, (state) => {
      state.rebootingTablet = false
      state.rebootedTablet = false
      return state
    })

    builder.addCase(fetchInstallQrcode.pending, (state) => {
      state.fetchingInstallQrcode = true
      return state
    })
    builder.addCase(fetchInstallQrcode.fulfilled, (state, { payload }) => {
      state.fetchingInstallQrcode = false
      state.fetchedInstallQrcode = true
      state.installQrcode = payload
      return state
    })
    builder.addCase(fetchInstallQrcode.rejected, (state) => {
      state.fetchingInstallQrcode = false
      state.fetchedInstallQrcode = false
      return state
    })

    builder.addCase(fetchSiteEnrolled.fulfilled, (state, { payload }) => {
      state.siteEnrolled = payload.enrolled
    })

    builder.addCase(
      fetchManagedConfiguration.fulfilled,
      (state, { payload }) => {
        state.fetchedManagedConfiguration = Date.now()
        state.managedConfiguration = payload
        return state
      }
    )

    builder.addCase(updateManagedConfiguration.fulfilled, (state) => {
      state.updatedManagedConfiguration = true
      return state
    })

    builder.addCase(fetchEmmInfo.pending, (state) => {
      state.fetchingEmmInfo = true
      return state
    })
    builder.addCase(fetchEmmInfo.fulfilled, (state, { payload }) => {
      state.fetchingEmmInfo = false
      state.emmInfo = payload
      return state
    })
    builder.addCase(fetchEmmInfo.rejected, (state) => {
      state.fetchingEmmInfo = false
      state.emmInfo = undefined
      return state
    })
  },
})

export const {
  resetAddTablet,
  resetRemoveTablet,
  resetLockTablet,
  resetRebootTablet,
  resetInstallQrcode,
  resetUpdateManagedConfiguration,
} = tabletsSlice.actions

export default tabletsSlice.reducer

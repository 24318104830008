import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Network, NetworkSecurityMode } from 'App/Networks/networks-types'
import {
  fetchNetworks,
  deleteNetwork as deleteNetworkAction,
  resetDeletedNetwork,
} from 'App/Networks/networks-state'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import Table from 'common/components/TableNew'
import EditNetworkDrawer from 'App/Networks/NetworksList/EditNetworkDrawer'
import ActionDialog from 'common/components/ActionDialog'
import IconMenu from 'common/components/IconMenu'
import AvatarWithText from 'common/components/AvatarWithText'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import Dialog from 'common/components/Dialog'
import ScanNetworkQRPopup from 'App/Networks/NetworksList/ScanNetworkQRPopup'
import { useAppDispatch, useAppSelector } from 'store'

interface NetworksRowActionsProps {
  network: Network
  onClickEdit: (network: Network) => void
  onClickDelete: (network: Network) => void
  onClickScanQRCode: (network: Network) => void
}

const NetworksRowActions = ({
  network,
  onClickEdit,
  onClickDelete,
  onClickScanQRCode,
}: NetworksRowActionsProps) => {
  const actions = [
    {
      local: 'networks.listNetworks.actions.edit',
      fn: () => onClickEdit(network),
      icon: EditIcon,
    },
    {
      local: 'networks.listNetworks.actions.delete',
      fn: () => onClickDelete(network),
      icon: DeleteIcon,
    },
  ]

  if (network.securityMode !== NetworkSecurityMode.WPA_ENTERPRISE) {
    actions.push({
      local: 'networks.listNetworks.actions.scanQrCode',
      fn: () => onClickScanQRCode(network),
      icon: QrCodeScannerIcon,
    })
  }

  return <IconMenu actions={actions} />
}

interface NetworksTableProps {
  networks: Network[]
}

const NetworksTable = ({ networks }: NetworksTableProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { siteId, organizationId } = useCurrentAccount()

  const deletedNetwork = useAppSelector(
    (state) => state.networks.deletedNetwork
  )

  useEffect(() => {
    if (deletedNetwork) {
      dispatch(resetDeletedNetwork())
      dispatch(fetchNetworks({ siteId, organizationId }))
    }
  }, [dispatch, deletedNetwork, siteId, organizationId])

  const [editNetwork, setEditNetwork] = useState<Network | undefined>()
  const [deleteNetwork, setDeleteNetwork] = useState<Network | undefined>()
  const [scanQROpen, setScanQROpen] = useState(false)
  const [qrNetwork, setQrNetwork] = useState<Network | undefined>()

  const onClickEdit = (network: Network) => setEditNetwork(network)
  const onClickDelete = (network: Network) => setDeleteNetwork(network)
  const onClickScanQRCode = (network: Network) => {
    setScanQROpen(true)
    setQrNetwork(network)
  }

  const onCloseDrawer = () => setEditNetwork(undefined)
  const onCloseDialog = () => setDeleteNetwork(undefined)
  const onCloseScanQRPopup = () => {
    setScanQROpen(false)
    setQrNetwork(undefined)
  }

  const onActionDelete = () => {
    if (deleteNetwork != null) {
      dispatch(deleteNetworkAction(deleteNetwork))
      setDeleteNetwork(undefined)
    }
  }

  const columns = [
    {
      title: t('networks.table.columns.ssid'),
      // eslint-disable-next-line react/display-name
      render: ({ ssid }: Network) => <AvatarWithText text={ssid} />,
      style: { width: 'auto' },
    },
    {
      title: t('networks.table.columns.securityMode'),
      // eslint-disable-next-line react/display-name
      render: ({ securityMode }: Network) => {
        switch (securityMode) {
          case NetworkSecurityMode.OPEN:
            return <>{t('networks.securityMode.open')}</>
          case NetworkSecurityMode.WPA_PSK:
            return <>{t('networks.securityMode.wpaPersonal')}</>
          case NetworkSecurityMode.WPA2_PSK:
            return <>{t('networks.securityMode.wpa2Personal')}</>
          case NetworkSecurityMode.WPA_ENTERPRISE:
            return <>{t('networks.securityMode.wpaEnterprise')}</>
          default:
            return <>{securityMode}</>
        }
      },
      style: { width: '180px' },
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (network: Network) => {
        return (
          <NetworksRowActions
            network={network}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            onClickScanQRCode={onClickScanQRCode}
          />
        )
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={networks} />
      <EditNetworkDrawer
        network={editNetwork}
        open={editNetwork !== undefined}
        onClose={onCloseDrawer}
      />
      {deleteNetwork != null && networks.length > 1 && (
        <ActionDialog
          open={deleteNetwork !== undefined}
          title={t('networks.deleteNetwork.title')}
          description={t('networks.deleteNetwork.description', {
            network: deleteNetwork.ssid,
          })}
          actionText={t('networks.deleteNetwork.action')}
          onAction={onActionDelete}
          onClose={onCloseDialog}
        />
      )}
      <ScanNetworkQRPopup
        open={scanQROpen}
        onClose={onCloseScanQRPopup}
        network={qrNetwork}
      />
      {deleteNetwork != null && networks.length === 1 && (
        <Dialog
          open={deleteNetwork !== undefined}
          title={t('networks.deleteNetwork.title')}
          onClose={onCloseDialog}
        >
          You can not delete this network, because you must have at least one
          network.
        </Dialog>
      )}
    </>
  )
}

export default NetworksTable

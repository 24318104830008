import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import {
  useLoadStateQuery,
  useUpdateStateMutation,
} from 'App/InstallationWizard/installation-wizard-rtk-api'
import useCurrentUser from 'common/hooks/useCurrentUser'
import NotificationDialog from 'App/InstallationWizard/components/NotificationDialog'
import ManagerSetupListDialog from 'App/InstallationWizard/components/ManagerSetupListDialog'
import InstallationProgress from 'App/InstallationWizard/components/InstallationProgress'
import AddAdminDialog from 'App/InstallationWizard/components/AddAdminDialog'
import SaveDetailsDailog from './InstallationWizard/components/SaveDetailsDialog'
import SetupGoogleDialog from './InstallationWizard/components/SetupGoogleDialog'
import AddNetworkDialog from 'App/InstallationWizard/components/AddNetworkDialog'
import AddIntegrationDialog from './InstallationWizard/components/AddIntegrationDialog'
import CreateProfileDialog from './InstallationWizard/components/CreateProfileDialog'
import DeviceSetupListDialog from 'App/InstallationWizard/components/DeviceSetupListDialog'
import AddTabletDialog from 'App/InstallationWizard/components/AddTabletDialog'
import AddDockDialog from 'App/InstallationWizard/components/AddDockDialog'
import StepDoneDialog from 'App/InstallationWizard/components/StepDoneDialog'
import AllDoneDialog from 'App/InstallationWizard/components/AllDoneDialog'
import Toast from 'common/components/Toast'
import useApiErrors from 'common/hooks/useApiErrors'
import { resetInvitedUser } from 'App/Users/users-state'
import { UserRole } from 'App/app-state'
import { useAppDispatch, useAppSelector } from 'store'

const InstallationWizardButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '10px',
  ...(theme.direction === 'ltr' ? { right: '150px' } : { left: '20px' }),
}))

interface InstallationWizardProps {
  organizationId: number
  siteId: number
}

const InstallationWizard = ({
  organizationId,
  siteId,
}: InstallationWizardProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [showNotification, setShowNotification] = useState<boolean>(false)

  const [showManagerSetupList, setShowManagerSetupList] =
    useState<boolean>(false)
  const [showManagerMiniList, setShowManagerMiniList] = useState<boolean>(false)
  const [showAddAdmin, setShowAddAdmin] = useState<boolean>(false)
  const [showAddDetails, setShowAddDetails] = useState<boolean>(false)
  const [showSetupGoogle, setShowSetupGoogle] = useState<boolean>(false)
  const [showAddNetwork, setShowAddNetwork] = useState<boolean>(false)
  const [showAddIntegration, setShowAddIntegration] = useState<boolean>(false)
  const [showCreateProfile, setShowCreateProfile] = useState<boolean>(false)

  const [showDeviceSetupList, setShowDeviceSetupList] = useState<boolean>(false)
  const [showDeviceMiniList, setShowDeviceMiniList] = useState<boolean>(false)
  const [showAddTablet, setShowAddTablet] = useState<boolean>(false)
  const [showAddDock, setShowAddDock] = useState<boolean>(false)

  const user = useCurrentUser()

  useEffect(() => {
    if (
      showNotification ||
      showManagerSetupList ||
      showAddAdmin ||
      showAddDetails ||
      showAddNetwork ||
      showAddIntegration
    ) {
      setShowManagerMiniList(false)
    } else {
      setShowManagerMiniList(true)
    }
  }, [
    showNotification,
    showManagerSetupList,
    showAddAdmin,
    showAddDetails,
    showAddNetwork,
    showAddIntegration,
    setShowManagerMiniList,
  ])

  useEffect(() => {
    if (
      showNotification ||
      showDeviceSetupList ||
      showAddTablet ||
      showAddDock
    ) {
      setShowDeviceMiniList(false)
    } else {
      setShowDeviceMiniList(true)
    }
  }, [
    showNotification,
    showDeviceSetupList,
    showAddTablet,
    showAddDock,
    setShowDeviceMiniList,
  ])

  const siteReq = {
    organizationId,
    siteId,
  }

  const {
    data: state,
    error: loadStateError,
    refetch: refetchState,
  } = useLoadStateQuery(siteReq)

  const [hasLoadStateErrors, apiLoadStateErrorsMsg] = useApiErrors([
    loadStateError,
  ])

  const invitedUser = useAppSelector((state) => state.users.invitedUser)

  useEffect(() => {
    if (invitedUser) {
      refetchState()
      dispatch(resetInvitedUser())
    }
  }, [dispatch, invitedUser, refetchState])

  const hasState = state !== undefined && state !== null

  useEffect(() => {
    if (
      user.loginSuccessTotal <= 2 &&
      user.role === UserRole.SiteAdmin &&
      hasState &&
      !state.hasSeenWelcome
    ) {
      setShowNotification(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, hasState, state?.hasSeenWelcome])

  const [
    updateState,
    {
      isSuccess: isUpdateStateSuccess,
      error: updateStateError,
      reset: resetStateUpdate,
    },
  ] = useUpdateStateMutation()

  const [hasUpdateStateErrors, apiUpdateStateErrorsMsg] = useApiErrors([
    updateStateError,
  ])

  useEffect(() => {
    if (isUpdateStateSuccess) {
      resetStateUpdate()
      refetchState()
    }
  }, [isUpdateStateSuccess, resetStateUpdate, refetchState])

  const isConfigDone =
    hasState &&
    state.hasAdmin &&
    state.hasSettings &&
    state.hasGoogleEmm &&
    state.hasNetworks &&
    state.hasIntegrations &&
    state.hasProfiles

  const [prevConfigDone, setPrevConfigDone] = useState(true)

  useEffect(() => {
    if (state !== null && state !== undefined && !isConfigDone) {
      setPrevConfigDone(false)
    }
  }, [state, isConfigDone])

  const handleCloseConfigDone = () => {
    setPrevConfigDone(true)
    if (!isDeviceDone) {
      setShowDeviceSetupList(true)
    }
  }

  const configProcess =
    (100 *
      (1 +
        +(state?.hasAdmin ?? false) +
        +(state?.hasSettings ?? false) +
        +(state?.hasGoogleEmm ?? false) +
        +(state?.hasNetworks ?? false) +
        +(state?.hasIntegrations ?? false) +
        +(state?.hasProfiles ?? false))) /
    7

  const addedApp = useAppSelector((state) => state.contents.addedApp)
  const addedWebsite = useAppSelector((state) => state.contents.addedWebsite)
  const addedFile = useAppSelector((state) => state.contents.addedFile)

  const addedContent = addedApp || addedWebsite || addedFile

  useEffect(() => {
    if (addedContent) {
      refetchState()
    }
  }, [addedContent, refetchState])

  const [prevProfileState, setPrevProfileState] = useState(true)

  useEffect(() => {
    if (state !== null && state !== undefined && !state.hasProfiles) {
      setPrevProfileState(false)
    }
  }, [state, state?.hasProfiles])

  const handleCloseStepDone = () => {
    setPrevProfileState(true)
  }

  const isDeviceDone = hasState && state.hasTablets && state.hasDocks

  const [prevDeviceDone, setPrevDeviceDone] = useState(true)

  useEffect(() => {
    if (state !== null && state !== undefined && !isDeviceDone) {
      setPrevDeviceDone(false)
    }
  }, [state, isDeviceDone])

  const handleCloseDeviceDone = () => {
    setPrevDeviceDone(true)
  }

  const deviceProcess =
    (100 * (+(state?.hasTablets ?? false) + +(state?.hasDocks ?? false))) / 2

  const onActionNotification = () => {
    setShowNotification(false)
    setShowManagerSetupList(true)
  }

  const onCloseNotification = () => {
    updateState({ organizationId, siteId, hasSeenWelcome: true })
    setShowNotification(false)
  }

  const onActionAddAdmin = () => {
    setShowManagerSetupList(false)
    setShowAddAdmin(true)
  }

  const onCloseAddAdmin = () => {
    setShowAddAdmin(false)
    setShowManagerSetupList(true)
    refetchState()
  }

  const onActionAddDetails = () => {
    setShowManagerSetupList(false)
    setShowAddDetails(true)
  }

  const onCloseAddDetails = () => {
    setShowAddDetails(false)
    setShowManagerSetupList(true)
    refetchState()
  }

  const onActionSetupGoogle = () => {
    setShowManagerSetupList(false)
    setShowSetupGoogle(true)
  }

  const onCloseSetupGoogle = () => {
    setShowSetupGoogle(false)
    setShowManagerSetupList(true)
    refetchState()
  }

  const onActionAddNetwork = () => {
    setShowManagerSetupList(false)
    setShowAddNetwork(true)
  }

  const onCloseAddNetwork = () => {
    setShowAddNetwork(false)
    setShowManagerSetupList(true)
    refetchState()
  }

  const onActionAddIntegration = () => {
    setShowManagerSetupList(false)
    setShowAddIntegration(true)
  }

  const onCloseAddIntegration = () => {
    setShowAddIntegration(false)
    setShowManagerSetupList(true)
    refetchState()
  }

  const onActionCreateProfile = () => {
    setShowManagerSetupList(false)
    setShowCreateProfile(true)
  }

  const onCloseCreateProfile = () => {
    setShowCreateProfile(false)
    setShowManagerMiniList(true)
    refetchState()
  }

  const onActionAddTablet = () => {
    setShowDeviceSetupList(false)
    setShowAddTablet(true)
  }

  const onCloseAddTablet = () => {
    setShowAddTablet(false)
    setShowDeviceMiniList(true)
    refetchState()
  }

  const onActionAddDock = () => {
    setShowDeviceSetupList(false)
    setShowAddDock(true)
  }

  const onCloseAddDock = () => {
    setShowAddDock(false)
    setShowDeviceMiniList(true)
    refetchState()
  }

  return hasState ? (
    <>
      {!isConfigDone && !isDeviceDone && (
        <NotificationDialog
          open={showNotification}
          onAction={onActionNotification}
          onClose={onCloseNotification}
        />
      )}

      {!isConfigDone && (
        <>
          <ManagerSetupListDialog
            open={showManagerSetupList}
            onClose={() => setShowManagerSetupList(false)}
            addAdminFulfilled={state.hasAdmin}
            onActionAddAdmin={onActionAddAdmin}
            addDetailFulfilled={state.hasSettings}
            onActionAddDetail={onActionAddDetails}
            setupGoogleFulfilled={state.hasGoogleEmm}
            onActionSetupGoogle={onActionSetupGoogle}
            addNetworkFulfilled={state.hasNetworks}
            onActionAddNetwork={onActionAddNetwork}
            setupIntegrationFulfilled={state.hasIntegrations}
            onActionSetupIntegration={onActionAddIntegration}
            createProfileFulfilled={state.hasProfiles}
            onActionCreateProfile={onActionCreateProfile}
          />
          {showManagerMiniList && (
            <InstallationWizardButton>
              <InstallationProgress
                progress={configProcess}
                onClick={() => setShowManagerSetupList(true)}
              />
            </InstallationWizardButton>
          )}
        </>
      )}

      <AddAdminDialog open={showAddAdmin} onClose={onCloseAddAdmin} />
      <SaveDetailsDailog open={showAddDetails} onClose={onCloseAddDetails} />
      <SetupGoogleDialog open={showSetupGoogle} onClose={onCloseSetupGoogle} />
      <AddNetworkDialog open={showAddNetwork} onClose={onCloseAddNetwork} />
      <AddIntegrationDialog
        open={showAddIntegration}
        onClose={onCloseAddIntegration}
        state={state?.integrationState}
      />
      <CreateProfileDialog
        open={showCreateProfile}
        onClose={onCloseCreateProfile}
      />

      {isConfigDone && !isDeviceDone && (
        <>
          <DeviceSetupListDialog
            open={showDeviceSetupList}
            onClose={() => setShowDeviceSetupList(false)}
            addTabletFulfilled={!!state?.hasTablets}
            onActionAddTablet={onActionAddTablet}
            addDockFulfilled={!!state?.hasDocks}
            onActionAddDock={onActionAddDock}
          />
          {showDeviceMiniList && (
            <InstallationWizardButton>
              <InstallationProgress
                progress={deviceProcess}
                onClick={() => setShowDeviceSetupList(true)}
              />
            </InstallationWizardButton>
          )}
        </>
      )}

      <AddTabletDialog open={showAddTablet} onClose={onCloseAddTablet} />
      <AddDockDialog
        open={showAddDock}
        hasDock={state?.hasDocks}
        onClose={onCloseAddDock}
      />

      <StepDoneDialog
        title={t('installationWizard.profile.done.title')}
        description={t('installationWizard.profile.done.description')}
        open={state?.hasProfiles && !prevProfileState}
        onClose={handleCloseStepDone}
      />

      <AllDoneDialog
        title={t('installationWizard.all.done.title')}
        description={t('installationWizard.config.done.description')}
        open={
          !showAddAdmin &&
          !showAddDetails &&
          !showSetupGoogle &&
          !showAddNetwork &&
          !showAddIntegration &&
          prevProfileState &&
          isConfigDone &&
          !prevConfigDone
        }
        onClose={handleCloseConfigDone}
      />

      <AllDoneDialog
        title={t('installationWizard.all.done.title')}
        open={!showAddDock && !showAddTablet && isDeviceDone && !prevDeviceDone}
        onClose={handleCloseDeviceDone}
      />

      <Toast open={hasLoadStateErrors} message={apiLoadStateErrorsMsg} />

      <Toast
        open={hasUpdateStateErrors}
        message={apiUpdateStateErrorsMsg}
        onClose={resetStateUpdate}
      />
    </>
  ) : null
}

export default InstallationWizard

import React from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import { useTranslation } from 'react-i18next'
import { DockingStationsComponent } from 'App/DockingStations/DockingStations.component'

const DockingStations = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <PageHeader>
        <PageTitle title={t('docks.title')} />
      </PageHeader>
      <PageBody>
        <DockingStationsComponent />
      </PageBody>
    </Page>
  )
}

export default DockingStations

import React, { useState, useMemo, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { useTranslation } from 'react-i18next'
import Papa from 'papaparse'

import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  TextField,
  type Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Table from 'common/components/TableNew'
import {
  StockState,
  type NewStockTablet,
} from 'App/StockTablets/stock-tablets-types'
import { usePostStockTabletsMutation } from 'App/StockTablets/stock-tablets-rtk-api'
import { setUploadSuccess } from 'App/StockTablets/stock-tablets-state'

interface AddTabletsPopupProps {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles()((theme: Theme) => ({
  Button: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}))

type Tablet = [string, string, string]

const AddTabletsPopup = ({ open, onClose }: AddTabletsPopupProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  const [fileContent, setFileContent] = useState<NewStockTablet[]>([])

  const [postStockTablets, { isSuccess }] = usePostStockTabletsMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUploadSuccess())
      setFileContent([])
    }
  }, [dispatch, isSuccess])

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const content = e.target?.result as string

        Papa.parse(content, {
          complete: (result: any) => {
            const fileData = result.data
              .map(([serialNumber, model, type]: Tablet) => ({
                serialNumber,
                model,
                type,
              }))
              .filter((tablet: any) => tablet?.serialNumber)
            setFileContent(fileData)
          },
        })
      }
      reader.readAsText(file)
    }
  }

  const columns = useMemo(
    () => {
      return [
        {
          title: t('tablets.table.columns.serialNumber'),
          field: 'serialNumber',
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.modelCode'),
          field: 'model',
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.type'),
          field: 'type',
          style: { width: 'auto' },
        },
      ]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onUpload = async () => {
    await postStockTablets({
      tablets: fileContent.map((tablet: NewStockTablet) => ({
        ...tablet,
        stockStatus: StockState.NEW,
      })),
    })
  }

  const onCancel = () => {
    setFileContent([])
  }

  const Instruction = () => (
    <p>{`
      Instruction:
      Please upload a text file using a comma as the separator.
      The text should be organized into three columns: Serial Number, Model, and Type, in that specific order.
      Do not include a heading line.
    `}</p>
  )

  return (
    <Dialog open={open} onClose={onClose} dir={languageDirection}>
      <DialogContent>
        {fileContent.length === 0 ? (
          <>
            <Instruction />
            <TextField
              variant="outlined"
              type="file"
              InputProps={{
                inputProps: {
                  accept: '.txt,.csv',
                },
              }}
              onChange={handleFileUpload}
            />
          </>
        ) : (
          <Grid container>
            <Table data={fileContent} columns={columns} />
            <Grid item className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onUpload}
                className={classes.Button}
              >
                {t('upload')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onCancel}
                className={classes.Button}
              >
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AddTabletsPopup

import React from 'react'
import FormSelect from 'common/components/FormSelect'
import { useTranslation } from 'react-i18next'

const stateOptions = [
  'Returned',
  'Warranty return',
  'Broken',
  'Lost',
  'Stolen',
].map((state) => ({
  label: state,
  value: state,
}))

interface ReaturnStateFormSelectProps {
  state: string
  onChange: (v: string) => void
}

const ReaturnStateFormSelect = ({
  state,
  onChange,
}: ReaturnStateFormSelectProps) => {
  const { t } = useTranslation()

  return (
    <FormSelect
      placeholder={t('tablets.returnStockTablet.action.state')}
      options={stateOptions}
      value={state}
      onChange={(_e, v) => onChange(v as string)}
    />
  )
}

export default ReaturnStateFormSelect

import React from 'react'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import ButtonBase from '@mui/material/ButtonBase'
import { ReactComponent as MiniBotSVG } from 'common/graphics/minibot.svg'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  overlayContainer: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '0%',
    left: '0%',
  },
}))

interface InstallationProgressProps {
  progress: number
  onClick: () => void
}

const InstallationProgress = ({
  progress,
  onClick,
}: InstallationProgressProps) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.overlayContainer}>
      <svg width="84" height="84" viewBox="0 0 84 84">
        <clipPath id="circleView">
          <circle cx="42" cy="42" r="42" />
        </clipPath>

        <circle cx="42" cy="42" r="42" fill="#FFFAD0" />

        <g clipPath="url(#circleView)">
          <MiniBotSVG x="10" y="20" />
        </g>
      </svg>

      <ButtonBase className={classes.buttonProgress} onClick={onClick}>
        <CircularProgress
          variant="determinate"
          value={progress}
          size={84}
          style={{ color: '#72D3Bc' }}
        />{' '}
      </ButtonBase>
    </Box>
  )
}

export default InstallationProgress

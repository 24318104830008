import React from 'react'
import { Box } from '@mui/material'
import { useAppSelector } from 'store'

const PageBody = ({ children }: { children: React.ReactNode }) => {
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  return (
    <Box
      sx={{ width: '100%', marginTop: '32px', direction: languageDirection }}
    >
      {children}
    </Box>
  )
}

export default PageBody

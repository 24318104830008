import React, { useMemo } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import { useTranslation } from 'react-i18next'
import LoansData from 'App/Dashboard/components/LoansData'
import PageActions from 'common/components/PageActions'
import FormSelect, { type FormSelectOption } from 'common/components/FormSelect'
import { Grid, type Theme, Link } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import {
  addMonths,
  format,
  addWeeks,
  addYears,
  min,
  endOfWeek,
  endOfMonth,
  endOfYear,
  subWeeks,
  subMonths,
  subYears,
  isSameDay,
  startOfDay,
  endOfDay,
} from 'date-fns'
import startOfWeek from 'date-fns/startOfWeek'
import startOfYear from 'date-fns/startOfYear'
import startOfMonth from 'date-fns/startOfMonth'
import getDateFnsLocale from 'common/utils/get-date-fns-locale'
import { type TFunction } from 'i18next'
import ScheduledTaskResult from 'App/Dashboard/components/ScheduledTaskResult'
import useApp from 'common/hooks/useApp'
import { DataLevel, TimeUnit } from 'App/Dashboard/dashboard-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { UserRole } from 'App/app-state'
import useCurrentUser from 'common/hooks/useCurrentUser'
import Checkbox from 'common/components/Checkbox'
import { utcToZonedTime } from 'date-fns-tz'
import useUserTimeZone from 'common/hooks/useUserTimeZone'

import { usePrivileges } from 'common/hooks/usePrivileges'
import { PrivilegeSubject, Action } from 'common/utils/privileges'

const useStyles = makeStyles()((theme: Theme) => ({
  TimeRange: {
    alignItems: 'baseline',
    marginBottom: theme.spacing(3),
  },
  TimeRangePrev: {
    flexGrow: 1,
    textAlign: 'left',
  },
  TimeRangeCurr: {
    flexGrow: 1,
    textAlign: 'center',
    '& h2': {
      margin: 0,
      fontSize: 20,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  TimeRangeNext: {
    flexGrow: 1,
    textAlign: 'right',
  },
  TimeRangeNav: {
    color: theme.palette.grey['300'],
    fontWeight: 'bold',
    '&a': {
      color: theme.palette.primary.main,
    },
  },
  TimeRangeNavIcon: {
    verticalAlign: 'middle',
    fontSize: 14,
  },
  IncludeOvertime: {
    display: 'inline-block',
    '& > span': {
      verticalAlign: 'middle',
    },
  },
  IncludeOvertimeText: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
}))

export const getTimeRangeStart = (unit: TimeUnit, end: Date) => {
  /*
    Íf the `end` matches e.g. the last day of the month,
    then we simply want to show data from the beginning of the month.
    Otherwise, we want to fill missing data for the month with data
    from the end of the last month.
  */

  let d: Date
  switch (unit) {
    case TimeUnit.Week:
      d = isSameDay(end, endOfWeek(end)) ? startOfWeek(end) : subWeeks(end, 1)
      break
    case TimeUnit.Month:
      d = isSameDay(end, endOfMonth(end))
        ? startOfMonth(end)
        : subMonths(end, 1)
      break
    case TimeUnit.Year:
      d = isSameDay(end, endOfYear(end)) ? startOfYear(end) : subYears(end, 1)
      break
  }

  return startOfDay(d)
}

export const getTimeRangeEnd = (
  unit: TimeUnit,
  relative: number,
  timeZone: string
) => {
  const d = utcToZonedTime(new Date(), timeZone)
  let end: Date

  switch (unit) {
    case TimeUnit.Week:
      end = addWeeks(endOfWeek(d), relative)
      break
    case TimeUnit.Month:
      end = addMonths(endOfMonth(d), relative)
      break
    case TimeUnit.Year:
      end = addYears(endOfYear(d), relative)
      break
  }

  return endOfDay(relative <= 0 ? min([d, end]) : end)
}

const formatTimeRange = (
  unit: TimeUnit,
  d: Date,
  lng: string,
  t: TFunction
) => {
  const opts = {
    locale: getDateFnsLocale(lng),
  }

  switch (unit) {
    case TimeUnit.Week:
      return `${t('dashboard.timeUnit.week')} ${format(d, 'w, y', opts)}`
    case TimeUnit.Month:
      return format(d, 'LLLL, y', opts)
    case TimeUnit.Year:
      return format(d, 'y', opts)
    default:
      break
  }
}

interface TimeRangeProps {
  unit: TimeUnit
  relative: number
  dataLevel: DataLevel
  includeOvertime: boolean
}

const TimeRange = ({
  unit,
  relative,
  dataLevel,
  includeOvertime,
}: TimeRangeProps) => {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()
  const timeZone = useUserTimeZone()
  const timeRangeStartInTz = useMemo(() => {
    return {
      prev: getTimeRangeEnd(unit, relative - 1, timeZone),
      curr: getTimeRangeEnd(unit, relative, timeZone),
      next: getTimeRangeEnd(unit, relative + 1, timeZone),
    }
  }, [unit, relative, timeZone])

  const renderNext = () => (
    <>
      {formatTimeRange(unit, timeRangeStartInTz.next, i18n.language, t)}
      <ChevronRightIcon className={classes.TimeRangeNavIcon} />
    </>
  )

  return (
    <Grid className={classes.TimeRange} container>
      <Grid className={cx([classes.TimeRangePrev, classes.TimeRangeNav])} item>
        <Link
          component={RouterLink}
          to={`/dashboard?r=${relative - 1}&u=${unit}&l=${dataLevel}&o=${String(
            includeOvertime
          )}`}
          underline="hover"
        >
          <ChevronLeftIcon className={classes.TimeRangeNavIcon} />
          {formatTimeRange(unit, timeRangeStartInTz.prev, i18n.language, t)}
        </Link>
      </Grid>
      <Grid className={classes.TimeRangeCurr} item>
        <h2>
          {formatTimeRange(unit, timeRangeStartInTz.curr, i18n.language, t)}
        </h2>
      </Grid>
      <Grid className={cx([classes.TimeRangeNext, classes.TimeRangeNav])} item>
        {relative >= 0 ? (
          renderNext()
        ) : (
          <Link
            component={RouterLink}
            to={`/dashboard?r=${
              relative + 1
            }&u=${unit}&l=${dataLevel}&o=${String(includeOvertime)}`}
            underline="hover"
          >
            {renderNext()}
          </Link>
        )}
      </Grid>
    </Grid>
  )
}

const Dashboard = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { can } = usePrivileges()
  const { adminMode } = useApp()

  const { role: currentUserRole } = useCurrentUser()
  const { organizationId, siteId } = useCurrentAccount()

  const [searchParams, setSearchParams] = useSearchParams()

  const r = searchParams.get('r') // relative time point
  const u = searchParams.get('u') // time unit
  const l = searchParams.get('l') // data level
  const o = searchParams.get('o') // include overtime

  const timeUnitOptions = [
    { label: t('dashboard.timeUnit.week'), value: TimeUnit.Week },
    { label: t('dashboard.timeUnit.month'), value: TimeUnit.Month },
    { label: t('dashboard.timeUnit.year'), value: TimeUnit.Year },
  ]

  const timeUnit: TimeUnit = useMemo(() => {
    const newTimeUnit = parseInt(u ?? '')
    const defaultTimeUnit = TimeUnit.Month
    return isNaN(newTimeUnit) ? defaultTimeUnit : newTimeUnit
  }, [u])

  const dataLevelOptions = useMemo(() => {
    const hubletOrgId = 1
    const options: FormSelectOption[] = []

    if (siteId !== undefined && siteId !== -1) {
      options.push({
        label: t('dashboard.dataLevel.site'),
        value: DataLevel.Site,
      })
      return options
    }

    if (
      organizationId === hubletOrgId &&
      currentUserRole === UserRole.HubletAdmin
    ) {
      options.push({
        label: t('dashboard.dataLevel.global'),
        value: DataLevel.Global,
      })
    }

    if (can(Action.View, PrivilegeSubject.Distributor)) {
      options.push({
        label: t('dashboard.dataLevel.distributor'),
        value: DataLevel.Distributor,
      })
    }

    options.push({
      label: t('dashboard.dataLevel.organization'),
      value: DataLevel.Organization,
    })

    return options
  }, [t, organizationId, siteId, currentUserRole, can])

  const dataLevel: DataLevel = useMemo(() => {
    const newDataLevel = parseInt(l ?? '')
    const defaultDataLevel = dataLevelOptions[0].value as DataLevel
    return isNaN(newDataLevel) ? defaultDataLevel : newDataLevel
  }, [l, dataLevelOptions])

  // relative to the current time, e.g. 0 = this month, -1 = prev month
  const timeRelative = useMemo(() => {
    const newTimeRelative = parseInt(r ?? '')
    const defaultTimeRelative = 0
    return isNaN(newTimeRelative) ? defaultTimeRelative : newTimeRelative
  }, [r])

  const includeOvertime = useMemo(() => {
    return o === 'true'
  }, [o])

  return (
    <Page>
      <PageHeader>
        <PageTitle title={t('dashboard.title')} />
        <PageActions>
          <label className={classes.IncludeOvertime}>
            <Checkbox
              checked={includeOvertime}
              onChange={(e) => {
                setSearchParams({
                  r: String(timeRelative),
                  u: String(timeUnit),
                  l: String(dataLevel),
                  o: e.target.checked ? 'true' : 'false',
                })
              }}
            />
            <span className={classes.IncludeOvertimeText}>
              {t('dashboard.settings.includeOvertime')}
            </span>
          </label>
          {dataLevelOptions && dataLevelOptions.length > 1 && (
            <FormSelect
              options={dataLevelOptions}
              onChange={(e) =>
                setSearchParams({
                  r: String(timeRelative),
                  u: String(timeUnit),
                  l: e.target.value,
                  o: String(includeOvertime),
                })
              }
              value={dataLevel}
              rounded
              small
              highlight
            />
          )}
          <FormSelect
            options={timeUnitOptions}
            onChange={(e) =>
              setSearchParams({
                r: '0',
                u: e.target.value,
                l: String(dataLevel),
                o: String(includeOvertime),
              })
            }
            value={timeUnit}
            rounded
            small
            highlight
          />
        </PageActions>
      </PageHeader>
      <PageBody>
        <TimeRange
          unit={timeUnit}
          relative={timeRelative}
          dataLevel={dataLevel}
          includeOvertime={includeOvertime}
        />
        <LoansData
          unit={timeUnit}
          relative={timeRelative}
          dataLevel={dataLevel}
          includeOvertime={includeOvertime}
        />
        {adminMode && <ScheduledTaskResult />}
      </PageBody>
    </Page>
  )
}

export default Dashboard

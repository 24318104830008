import React from 'react'
import FormField from 'common/components/FormField'
import { Divider, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import IconTooltip from 'common/components/IconTooltip'
import { useTranslation } from 'react-i18next'
import { type WebsiteForm } from 'App/Categories/categories-types'
import { type WebsiteFormErrors } from 'common/hooks/useWebsiteForm'
import FormImageFile from 'common/components/FormImageFile'
import SettingsEntry from 'App/Profiles/ProfilesView/SettingsEntry'
import Switch from 'common/components/Switch'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  FormField: {
    marginBottom: '32px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  SubtitleRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  Subtitle: {
    margin: 0,
    marginRight: theme.spacing(1),
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  websiteForm: WebsiteForm
  fieldErrors: WebsiteFormErrors
  onSwitchChange: (key: string) => (e: any, checked: boolean) => void
  onFieldChange: (
    key: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
  onUploadCustomIcon: (imageFile: File) => void
}

const WebsiteFields = ({
  websiteForm,
  fieldErrors,
  onSwitchChange,
  onFieldChange,
  onUploadCustomIcon,
}: Props) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const uploadWebsiteCustomIconError = useAppSelector(
    (state) => state.contents.uploadWebsiteCustomIconError
  )

  return (
    <>
      <FormField
        type="url"
        className={classes.FormField}
        label={t('contents.websiteForm.urlField.label')}
        placeholder={t('contents.websiteForm.urlField.placeholder')}
        value={websiteForm.url}
        onChange={onFieldChange('url')}
        error={fieldErrors.url}
        info={{ example: 'https://www.gethublet.com/' }}
      />

      <SettingsEntry property="contents.websiteForm.fullscreenSwitch">
        <Switch
          checked={websiteForm.fullscreen}
          onChange={onSwitchChange('fullscreen')}
        />
      </SettingsEntry>

      <Divider />

      <Grid className={classes.SubtitleRow}>
        <h1 className={classes.Subtitle}>
          {t('contents.websiteForm.subtitle')}
        </h1>
        <IconTooltip title={t('contents.websiteForm.tooltip')} />
      </Grid>

      <FormField
        className={classes.FormField}
        label={t('contents.websiteForm.nameField.label')}
        placeholder={t('contents.websiteForm.nameField.placeholder')}
        value={websiteForm.name}
        onChange={onFieldChange('name')}
        error={fieldErrors.name}
      />
      <FormField
        className={classes.FormField}
        label={t('contents.websiteForm.descriptionField.label')}
        placeholder={t('contents.websiteForm.descriptionField.placeholder')}
        value={websiteForm.description}
        onChange={onFieldChange('description')}
      />
      <Divider />
      <Grid className={classes.SubtitleRow}>
        <h1 className={classes.Subtitle}>
          {t('contents.websiteForm.customIconField.label')}
        </h1>
      </Grid>
      <FormImageFile
        title={t('contents.websiteForm.customIconField.info.title')}
        description={t('contents.websiteForm.customIconField.info.description')}
        imageUrl={websiteForm.customIcon}
        onChangeFile={onUploadCustomIcon}
        error={uploadWebsiteCustomIconError}
      />
    </>
  )
}

export default WebsiteFields

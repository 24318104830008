import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...rest } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box display="flex" flexDirection="column" flex={1}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface CustomTab {
  label: string
  component: React.ReactNode
}

interface CustomTabsProps {
  tabs: CustomTab[]
}

const CustomTabs = ({ tabs }: CustomTabsProps) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation()
    setValue(newValue)
  }

  if (!tabs?.length) {
    return null
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ marginBottom: '24px' }}>
        <Tabs value={value} onChange={handleChange} aria-label="custom tabs">
          {tabs.map((item, index) => (
            <Tab label={item.label} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </Box>
      {tabs.map(({ component }, index) => (
        <CustomTabPanel value={value} index={index} key={index}>
          {component}
        </CustomTabPanel>
      ))}
    </Box>
  )
}

export default CustomTabs

import { useMemo } from 'react'
import { getBrowser, checkIsMobile } from 'common/utils/tracking-utils'

const useDeviceDetector = () => {
  const isMobile = useMemo(() => checkIsMobile(), [])
  const browser = getBrowser()
  return { isMobile, browser: browser.name, browserVersion: browser.version }
}

export default useDeviceDetector

import React, { type PropsWithChildren } from 'react'
import { Drawer as MuiDrawer, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ClearIcon from '@mui/icons-material/Clear'
import DrawerPaddingForHelp from 'common/components/DrawerPaddingForHelps'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DrawerForm: {
    '& > .MuiDrawer-paper': {
      minWidth: '50%',
      width: '640px',
      maxWidth: '100%',
      boxShadow: 'none',
    },
  },
  DrawerFormForm: {
    height: '100%',
  },
  DrawerFormContainer: {
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  DrawerFormHeader: {
    flex: 'auto',
    padding: '31px 32px',
    backgroundColor: '#fff',
    lineHeight: '1',
  },
  DrawerFormHeaderText: {
    flexGrow: 1,
    alignSelf: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  DrawerFormHeaderClose: {
    alignSelf: 'center',
    cursor: 'pointer',
    color: '#000',
    fontSize: '24px',
  },
  DrawerFormBody: {
    padding: '32px 32px',
    backgroundColor: theme.palette.background.default,
  },
}))

interface DrawerFormProps extends PropsWithChildren {
  title: string
  open: boolean
  onClose: () => void
}

const Drawer = ({ children, title, open, onClose }: DrawerFormProps) => {
  const { classes } = useStyles()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  return (
    <MuiDrawer
      anchor={languageDirection === 'ltr' ? 'right' : 'left'}
      open={open}
      onClose={onClose}
      className={classes.DrawerForm}
    >
      <Grid className={classes.DrawerFormContainer} container>
        <Grid className={classes.DrawerFormHeader} item xs={12}>
          <Grid container>
            <Grid className={classes.DrawerFormHeaderText} item>
              {title}
            </Grid>
            <Grid className={classes.DrawerFormHeaderClose} item>
              <ClearIcon onClick={onClose} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.DrawerFormBody} item xs={12}>
          {children}
        </Grid>
      </Grid>
      <DrawerPaddingForHelp />
    </MuiDrawer>
  )
}

export default Drawer

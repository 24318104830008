import React from 'react'
import {
  Drawer,
  type DrawerProps,
  Box,
  Typography,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAppSelector } from 'store'
import DrawerPaddingForHelp from 'common/components/DrawerPaddingForHelps'

interface CustomDrawerProps extends DrawerProps {
  title?: string
  width?: number
}

const CustomDrawer = ({
  title,
  width,
  open,
  onClose,
  children,
  ...rest
}: CustomDrawerProps) => {
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={languageDirection === 'ltr' ? 'right' : 'left'}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: width ? `${width}px` : '640px', // TODO: width = 100vw in small screen
          padding: '32px', // TODO: reduce padding in small screen
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '32px',
            justifyContent: title ? 'space-between' : 'flex-end',
          }}
        >
          {title && <Typography variant="h5">{title}</Typography>}
          <IconButton
            aria-label="close"
            onClick={(e) => onClose?.(e, 'backdropClick')}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {children}

        <DrawerPaddingForHelp />
      </Box>
    </Drawer>
  )
}

export default CustomDrawer

import React, { type PropsWithChildren } from 'react'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from 'common/components/Button'

const useStyles = makeStyles()((theme: Theme) => ({
  PageRequirement: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999,
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(6),
  },
  RequirementDialog: {
    maxWidth: '100%',
    width: '505px',
    margin: '0 auto',
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  RequirementDialogTitle: {
    marginTop: 0,
    color: theme.palette.primary.main,
    fontSize: '24px',
  },
  RequirementDialogAction: {
    marginTop: 20,
    textAlign: 'right',
  },
  PageBlur: {
    filter: 'blur(3px)',
  },
}))

interface PageRequirementProps {
  criteria: boolean
  title: string
  description: string
  actionText: string
  actionTo: string
}

const PageRequirement = ({
  criteria,
  title,
  description,
  actionText,
  actionTo,
  children,
}: PropsWithChildren<PageRequirementProps>) => {
  const { classes } = useStyles()

  if (criteria) {
    return <>{children}</>
  }

  return (
    <>
      <div className={classes.PageRequirement}>
        <div className={classes.RequirementDialog}>
          <h2 className={classes.RequirementDialogTitle}>{title}</h2>
          <p>{description}</p>
          <div className={classes.RequirementDialogAction}>
            <Button to={actionTo} small>
              {actionText}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.PageBlur}>{children}</div>
    </>
  )
}

export default PageRequirement

import React, { useState, useEffect, useMemo } from 'react'
import {
  type Product,
  ProductCategory,
  BusinessModel,
} from 'App/SuperAdmin/super-admin-types'
import DrawerForm from 'common/components/DrawerForm'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import { Stack } from '@mui/material'
import { useAddProductMutation } from 'App/SuperAdmin/super-admin-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import { toDateFieldValue } from 'common/utils/date-utils'

interface AddProductDrawerProps {
  open: boolean
  onClose: () => void
  onSaved: () => void
}

const AddProductDrawer = ({
  open,
  onClose,
  onSaved,
}: AddProductDrawerProps) => {
  const [sku, setSku] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [publicUrl, setPublicUrl] = useState('')
  const [isActive, setIsActive] = useState('')
  const [endOfLifeDate, setEndOfLifeDate] = useState<string | null>(null)
  const [category, setCategory] = useState('')
  const [businessModel, setBusinessModel] = useState('')

  const IsActiveOptions = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ]

  const ProductCategoryOptions = useMemo(
    () =>
      Object.keys(ProductCategory).map((key) => {
        return {
          label: ProductCategory[key as keyof typeof ProductCategory],
          value: ProductCategory[key as keyof typeof ProductCategory],
        }
      }),
    []
  )

  const BusinessModelOptions = useMemo(
    () =>
      Object.keys(BusinessModel).map((key) => {
        return {
          label: BusinessModel[key as keyof typeof BusinessModel],
          value: BusinessModel[key as keyof typeof BusinessModel],
        }
      }),
    []
  )

  const [
    addProductData,
    {
      isLoading: isAddingProduct,
      error: addProductError,
      isSuccess: isProductAdded,
      data: addedProductData,
    },
  ] = useAddProductMutation()

  useEffect(() => {
    if (isProductAdded && addedProductData) {
      onSaved()
    }
  }, [isProductAdded, addedProductData, onSaved])

  const [hasApiError, apiError] = useApiErrors([addProductError])

  const handleSubmit = () => {
    const product: Partial<Product> = {
      sku,
      name,
      description,
      publicUrl,
      isActive: isActive === 'active',
      endOfLifeDate,
      category,
      businessModel,
    }

    addProductData(product)
  }

  return (
    <DrawerForm
      title="Add product"
      open={open}
      onClose={onClose}
      submitText="Save"
      onSubmit={handleSubmit}
      disabledButton={isAddingProduct}
    >
      <Stack spacing={4}>
        {hasApiError && <ErrorBox>{apiError}</ErrorBox>}
        <FormField
          label="SKU"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
          placeholder="Enter SKU"
          disabled={isAddingProduct}
        />
        <FormField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter name"
          disabled={isAddingProduct}
        />
        <FormField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description"
          disabled={isAddingProduct}
        />
        <FormField
          label="Public URL"
          value={publicUrl}
          onChange={(e) => setPublicUrl(e.target.value)}
          placeholder="Enter Public URL"
          disabled={isAddingProduct}
        />
        <FormSelect
          label="Status"
          value={isActive}
          onChange={(e) => setIsActive(e.target.value)}
          options={IsActiveOptions}
          placeholder="Select status"
          disabled={isAddingProduct}
        />
        <FormField
          type="date"
          label="End of life date"
          value={endOfLifeDate ? toDateFieldValue(new Date(endOfLifeDate)) : ''}
          onChange={(e) => setEndOfLifeDate(e.target.value || null)}
          placeholder="Enter end of life date"
          disabled={isAddingProduct}
        />
        <FormSelect
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          options={ProductCategoryOptions}
          placeholder="Select category"
          disabled={isAddingProduct}
        />
        <FormSelect
          label="Business model"
          value={businessModel}
          onChange={(e) => setBusinessModel(e.target.value)}
          options={BusinessModelOptions}
          placeholder="Select business model"
          disabled={isAddingProduct}
        />
      </Stack>
    </DrawerForm>
  )
}

export default AddProductDrawer

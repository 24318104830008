import React, { useCallback, useMemo, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import { Stack } from '@mui/system'
import { Alert, LinearProgress, Typography } from '@mui/material'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import Table, {
  TableColumnFilter,
  type TableColumn,
} from 'common/components/TableNew'
import TableActionsBar from 'common/components/TableActionsBar'
import { useGetDocksDataForReviewQuery } from 'App/SuperAdmin/super-admin-rtk-api'
import FormSelect from 'common/components/FormSelect'
import {
  ViewWeekOutlined as ViewWeekIcon,
  FactCheckOutlined as FactCheckIcon,
} from '@mui/icons-material'
import { type DockDataForAdminReview } from 'App/SuperAdmin/super-admin-types'
import IconMenu from 'common/components/IconMenu'
import DataReviewDockDrawer from 'App/SuperAdmin/components/DataReviewDockDrawer'
import Toast from 'common/components/Toast'
import { renderDockStockStatus } from 'common/utils/render-utils'

interface DocksTableActionsBarProps {
  docksTotalCount: number
  docksReviewedCount: number

  columns: TableColumn[]
  selectedColumns: string[]
  onChangeSelectedColumns: (selectedColumns: string[]) => void
}

const DocksTableActionsBar = ({
  docksTotalCount,
  docksReviewedCount,
  columns,
  selectedColumns,
  onChangeSelectedColumns,
}: DocksTableActionsBarProps) => {
  const severity = useMemo(() => {
    if (docksReviewedCount === 0) {
      return 'info'
    }
    if (docksReviewedCount < docksTotalCount) {
      return 'warning'
    }
    return 'success'
  }, [docksReviewedCount, docksTotalCount])

  const columnOptions = useMemo(
    () =>
      columns
        .filter((column) => column.field != null)
        .map((column) => ({
          value: String(column.field),
          label: String(column.title),
        })),
    [columns]
  )

  return (
    <TableActionsBar>
      <Stack direction="row" gap={2} alignItems="flex-end">
        <Alert severity={severity} sx={{ flexGrow: 1 }}>
          Reviewed {docksReviewedCount} / {docksTotalCount} docks
        </Alert>
        <FormSelect
          options={columnOptions}
          renderValue={() => <ViewWeekIcon />}
          multiple
          checkmarks
          value={selectedColumns}
          onChange={(e) => onChangeSelectedColumns(e.target.value)}
          small
        />
      </Stack>
    </TableActionsBar>
  )
}

interface DockRowActionsProps {
  dock: DockDataForAdminReview
  onClickEdit: (dockId: number) => void
}

const DockRowActions = ({ dock, onClickEdit }: DockRowActionsProps) => {
  const actions = [
    {
      local: 'Review',
      fn: () => onClickEdit(dock.id),
      icon: FactCheckIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

const DocksTable = () => {
  const columns: TableColumn[] = useMemo(
    () => [
      {
        title: 'Distributor',
        field: 'distributorName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Organization',
        field: 'organizationName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Site',
        field: 'siteName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Country',
        field: 'country',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Name',
        field: 'dockName',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Serial number',
        field: 'dockSerialNumber',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Stock status',
        field: 'dockStockStatus',
        filter: TableColumnFilter.Text,
        canSort: true,
        render: (dock: DockDataForAdminReview) =>
          dock.dockStockStatus == null
            ? '-'
            : renderDockStockStatus(dock.dockStockStatus),
      },
      {
        title: 'Tags',
        field: 'tags',
        filter: TableColumnFilter.Text,
        valueFn: (model: DockDataForAdminReview) => model.tags.join(', '),
        canSort: true,
      },
      {
        title: 'Is reviewed',
        field: 'isReviewed',
        filter: TableColumnFilter.Boolean,
        render: (dock: DockDataForAdminReview) =>
          dock.isReviewed ? 'Yes' : 'No',
        canSort: true,
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (dock: DockDataForAdminReview) => (
          <DockRowActions
            dock={dock}
            onClickEdit={(dockId: number) => setToEditDockId(dockId)}
          />
        ),
      },
    ],
    []
  )

  const { data, refetch: refetchDocksData } = useGetDocksDataForReviewQuery()

  const [toEditDockId, setToEditDockId] = useState<number | null>(null)
  const toEditDock = useMemo(() => {
    if (toEditDockId == null) {
      return null
    }

    return data?.find((dock) => dock.id === toEditDockId)
  }, [data, toEditDockId])

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'organizationName',
    'siteName',
    'country',
    'dockName',
    'dockSerialNumber',
    'dockStockStatus',
    'tags',
    'isReviewed',
  ])
  const visibleColumns = useMemo(
    () =>
      columns.filter(
        (column) =>
          selectedColumns.includes(String(column.field)) ||
          column.id === 'actions'
      ),
    [columns, selectedColumns]
  )

  const [isSaveSuccess, setIsSaveSuccess] = useState(false)
  const handleSaved = useCallback(() => {
    refetchDocksData()
    setToEditDockId(null)
    setIsSaveSuccess(true)
  }, [refetchDocksData])

  const docksTotalCount = data?.length ?? 0
  const docksReviewedCount = useMemo(() => {
    return data?.filter((dock) => dock.isReviewed).length ?? 0
  }, [data])

  if (data == null) {
    return <LinearProgress />
  }

  return (
    <>
      <Stack>
        <DocksTableActionsBar
          docksTotalCount={docksTotalCount}
          docksReviewedCount={docksReviewedCount}
          columns={columns}
          selectedColumns={selectedColumns}
          onChangeSelectedColumns={setSelectedColumns}
        />
        <Table data={data} columns={visibleColumns} />
      </Stack>
      {toEditDock != null && (
        <DataReviewDockDrawer
          open={toEditDock != null}
          onClose={() => setToEditDockId(null)}
          onSaved={handleSaved}
          data={toEditDock}
        />
      )}
      <Toast
        message="Dock data saved"
        open={isSaveSuccess}
        onClose={() => setIsSaveSuccess(false)}
      />
    </>
  )
}

const DataReviewDocks = () => {
  return (
    <Page>
      <PageHeader>
        <Stack>
          <Typography
            sx={(theme) => ({
              fontWeight: 600,
              color: theme.palette.secondary.main,
            })}
          >
            Super-admin
          </Typography>
          <PageTitle title="Data Review: Docks" />
        </Stack>
      </PageHeader>
      <PageBody>
        <Stack gap={2}>
          <Typography variant="h2">Docks</Typography>
          <DocksTable />
        </Stack>
      </PageBody>
    </Page>
  )
}

export default DataReviewDocks

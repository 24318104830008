import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type CommunicationChannel,
  type CommunicationConfiguration,
  type Integration,
  type IntegrationEditable,
  type IntegrationParameter,
  type IntegrationPlugin,
  type DebugLog,
  VirtualKeyboard,
} from 'App/Integrations/integrations-types'

export const toIntegrationModel = (data: any): Integration => ({
  id: data.id,
  organizationId: data.organizationId,
  siteId: data.siteId,
  name: data.name,
  communicationConfigurationId: data.communicationConfigurationId,
  authApiUrl: data.authApiUrl,
  integrationParams: data.integrationParams,
  templateKey: data.templateKey ?? undefined,
  debugMode: data.debugMode ?? false,
  debugModeExpiration: data.debugModeExpiration ?? undefined,
  proxyType: data.proxyType,
  isEnabled: data.isEnabled,
  usernameKeyboard: data.usernameKeyboard ?? VirtualKeyboard.None,
  passwordKeyboard: data.passwordKeyboard ?? VirtualKeyboard.None,
  canBorrowSuccess: data.canBorrowSuccess ?? null,
  canBorrowFailed: data.canBorrowFailed ?? null,
  didBorrowSuccess: data.didBorrowSuccess ?? null,
  didBorrowFailed: data.didBorrowFailed ?? null,
  didReturnSuccess: data.didReturnSuccess ?? null,
  didReturnFailed: data.didReturnFailed ?? null,
})

const toCommunicationConfigurationModel = (
  data: any
): CommunicationConfiguration => ({
  id: data.id,
  integrationId: data.siteId,
  proxyType: data.proxyType,
  debugModeExpiration: data.debugModeExpiration,
})

const toCommunicationChannelModel = (data: any): CommunicationChannel => ({
  id: data.id,
  communicationConfigurationId: data.communicationConfigurationId,
  proxyServerAddress: data.proxyServerAddress,
  proxyServerPort: data.proxyServerPort,
  proxyPass: data.proxyPass,
  proxyRootCertificate: data.proxyRootCertificate,
  proxyClientCertificate: data.proxyClientCertificate,
  proxyClientKey: data.proxyClientKey,
  proxyProtocol: data.proxyProtocol,
  proxyTlsVersion: data.proxyTlsVersion,
  number: data.number,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
})

const toIntegrationPluginModel = (data: any): IntegrationPlugin => ({
  id: data.id,
  pluginName: data.pluginName,
})

class IntegrationsApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchIntegrations(
    organizationId: number,
    siteId: number
  ): Promise<Integration[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/integrations`
    const { data } = await axios.get(apiUrl)
    return data.map(toIntegrationModel)
  }

  static async fetchPilotVersion(): Promise<string> {
    const apiUrl = `${API_BASE_URL}/pilot-version`
    try {
      const { data } = await axios.get(apiUrl)
      if (data.version) {
        return data.version
      }
      return data?.message ?? 'error'
    } catch (e) {
      return ''
    }
  }

  static async fetchDebugLogs(
    organizationId: number,
    siteId: number,
    integrationId: number,
    timestamp: string
  ): Promise<DebugLog[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/fetch-logs/${timestamp}`
    const { data } = await axios.get(apiUrl)
    return data.map((d: Record<any, any>) => d as DebugLog)
  }

  static async fetchCommunicationConfigurations(
    organizationId: number,
    siteId: number
  ): Promise<CommunicationConfiguration[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/communicationconfigurations`
    const { data } = await axios.get(apiUrl)
    return data.map(toCommunicationConfigurationModel)
  }

  static async fetchIntegrationParameters(
    organizationId: number,
    siteId: number,
    integrationId: number,
    url: string
  ): Promise<IntegrationParameter[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/params-for-integration/${btoa(
      url
    )}`
    const res = await axios.get(apiUrl)
    const params: IntegrationParameter[] = []
    for (const d of res.data) {
      params.push(d as IntegrationParameter)
    }
    return params
  }

  static async fetchCommunicationConfiguration(
    organizationId: number,
    siteId: number,
    communicationConfigurationId: number
  ): Promise<CommunicationConfiguration> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/communicationconfigurations/${communicationConfigurationId}`
    const { data } = await axios.get(apiUrl)
    return toCommunicationConfigurationModel(data)
  }

  static async updateCommunicationConfiguration(
    organizationId: number,
    siteId: number,
    communicationConfigurationId: number,
    communicationConfiguration: CommunicationConfiguration
  ): Promise<CommunicationConfiguration> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/communicationconfigurations/${communicationConfigurationId}`
    const { data } = await axios.put(apiUrl, communicationConfiguration)
    return toCommunicationConfigurationModel(data)
  }

  static async fetchCommunicationChannels(
    organizationId: number,
    siteId: number,
    communicationConfigurationId: number
  ): Promise<CommunicationChannel[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/communicationconfigurations/${communicationConfigurationId}/channels`
    const { data } = await axios.get(apiUrl)
    return data.map(toCommunicationChannelModel)
  }

  static async fetchIntegrationPlugins(
    organizationId: number,
    siteId: number
  ): Promise<IntegrationPlugin[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/integrations/plugins`
    const { data } = await axios.get(apiUrl)
    return data.map(toIntegrationPluginModel)
  }

  static async createCommunicationChannel(
    organizationId: number,
    siteId: number,
    communicationChannel: CommunicationChannel
  ): Promise<CommunicationChannel> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/communicationconfigurations/${communicationChannel.communicationConfigurationId}/channel`
    const res = await axios.post(apiUrl, communicationChannel)
    return res.data as CommunicationChannel
  }

  static async updateCommunicationChannel(
    organizationId: number,
    siteId: number,
    communicationChannel: CommunicationChannel
  ): Promise<CommunicationChannel> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/communicationconfigurations/${
      communicationChannel.communicationConfigurationId
    }/channel/${communicationChannel.id ?? 0}`
    const res = await axios.put(apiUrl, communicationChannel)
    return res.data as CommunicationChannel
  }

  static async removeCommunicationChannel(
    organizationId: number,
    siteId: number,
    communicationChannel: CommunicationChannel
  ): Promise<CommunicationChannel> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/communicationconfigurations/${
      communicationChannel.communicationConfigurationId
    }/channel/${communicationChannel.id ?? 0}`
    const res = await axios.delete(apiUrl)
    return res.data as CommunicationChannel
  }

  static async updateIntegration(
    organizationId: number,
    siteId: number,
    integrationId: number,
    form: IntegrationEditable
  ): Promise<Integration> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}`
    const res = await axios.put(apiUrl, form)
    return res.data as Integration
  }

  static async deleteIntegration(
    organizationId: number,
    siteId: number,
    integrationId: number
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}`
    await axios.delete(apiUrl)
  }
}

export default IntegrationsApi

import React, {
  useState,
  useMemo,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Stack,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material'
import FormField from 'common/components/FormField'
import WizardDialog from 'common/components/WizardDialog'
import WideForm, {
  FormItemType,
  type FormItemDef,
} from 'common/components/WideForm'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import { timezoneOptions } from 'common/timezones'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import StepDoneDialog from 'App/InstallationWizard/components/StepDoneDialog'
import {
  useGetSiteSettingsQuery,
  useUpdateSiteGeneralMutation,
  useUpdateSiteLoansMutation,
} from 'App/Sites/sites-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import { fetchSite } from 'App/Sites/sites-state'
import { isValidReturnCode, generateReturnCode } from 'App/Sites/SitesView'
import { useAppSelector, useAppDispatch } from 'store'

interface SaveDetailsDailogProps {
  open: boolean
  onClose: () => void
}

const SaveDetailsDailog = ({ open, onClose }: SaveDetailsDailogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const languages = useAppSelector((state) => state.app.languages)
  const site = useAppSelector((state) => state.sites.site)

  type Values = Record<string, string | number>
  type Errors = Record<string, string>

  const [detailsValues, setDetailsVaules] = useState<Values>({})
  const [detailsErrors, setDetailsErrors] = useState<Errors>({})

  const [allowAuthenticateFromTablet, setAllowAuthenticateFromTablet] =
    useState(false)
  const [enableReturnCode, setEnableReturnCode] = useState(false)
  const [returnCode, setReturnCode] = useState<string | undefined>()

  const [
    updateSiteGeneral,
    {
      isSuccess: isGeneralUpdateSuccess,
      error: updateGeneralError,
      reset: resetGeneralUpdate,
    },
  ] = useUpdateSiteGeneralMutation()

  const { data: siteSettings } = useGetSiteSettingsQuery({
    organizationId,
    siteId,
  })

  const [
    updateSiteLoans,
    {
      isSuccess: isSiteLoanUpdateSuccess,
      error: updateSiteLoanError,
      reset: resetSiteLoanUpdate,
    },
  ] = useUpdateSiteLoansMutation()

  const [hasGeneralApiMutationErrors, apiGeneralMutationErrorsMsg] =
    useApiErrors([updateGeneralError])

  const [hasSiteLoanApiMutationErrors, apiSiteLoanMutationErrorsMsg] =
    useApiErrors([updateSiteLoanError])

  useEffect(() => {
    if (site !== null) {
      setDetailsVaules({
        tzdbTimezone: site?.tzdbTimezone ?? '',
        defaultLanguage: site?.defaultLanguage ?? '',
      })
    }
  }, [site, setDetailsVaules])

  useEffect(() => {
    if (site === null) {
      dispatch(fetchSite({ organizationId, siteId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (siteSettings?.allowAuthenticateFromTablet !== undefined) {
      setAllowAuthenticateFromTablet(siteSettings.allowAuthenticateFromTablet)
    }
  }, [siteSettings?.allowAuthenticateFromTablet])

  useEffect(() => {
    if (siteSettings?.enableReturnCode !== undefined) {
      setEnableReturnCode(siteSettings.enableReturnCode)
    }
  }, [siteSettings?.enableReturnCode])

  useEffect(() => {
    if (siteSettings != null) {
      const newReturnCode = isValidReturnCode(siteSettings.returnCode)
        ? siteSettings.returnCode
        : generateReturnCode()

      setReturnCode(newReturnCode)
    }
  }, [siteSettings])

  const languageOptions = useMemo(
    () =>
      languages.map((lng) => ({
        label: t(`languages.${lng}`),
        value: lng,
      })),
    [t, languages]
  )

  const detailsItems: FormItemDef[] = [
    {
      type: FormItemType.SelectOne,
      key: 'tzdbTimezone',
      label: t('installationWizard.details.form.timezone.label'),
      placeholder: t('installationWizard.details.form.timezone.description'),
      options: timezoneOptions,
    },
    {
      type: FormItemType.SelectOne,
      key: 'defaultLanguage',
      label: t('installationWizard.details.form.defaultLanguage.label'),
      placeholder: t(
        'installationWizard.details.form.defaultLanguage.placeholder'
      ),
      options: languageOptions,
    },
  ]

  const handleErrors = (
    items: FormItemDef[],
    values: Values,
    setErrors: Dispatch<SetStateAction<Errors>>
  ) => {
    let hasError = false

    items.forEach(({ key, nullable }) => {
      if (!nullable && (values[key] === undefined || values[key] === '')) {
        hasError = true

        setErrors((prev) => ({
          ...prev,
          [key]: t('installationWizard.error.message.empty'),
        }))
      }
    })

    return hasError
  }

  const clearError = (
    key: string,
    errors: Errors,
    setErrors: Dispatch<SetStateAction<Errors>>
  ) => {
    if (key in errors) {
      const { [key]: _, ...newErrors } = errors
      setErrors(newErrors)
    }
  }

  const handleDetailsValues = (key: string, value: string) => {
    if (value.length > 0) {
      clearError(key, detailsErrors, setDetailsErrors)
    }

    setDetailsVaules((prev) => ({ ...prev, [key]: value }))
  }

  const handleClickDone = () => {
    const hasError = handleErrors(detailsItems, detailsValues, setDetailsErrors)

    if (site !== null && !hasError) {
      updateSiteGeneral({
        ...site,
        siteId,
        tzdbTimezone: detailsValues.tzdbTimezone as string,
      })

      updateSiteLoans({
        ...site,
        siteId,
        defaultLanguage: detailsValues.defaultLanguage as string,
        allowAuthenticateFromTablet,
        enableReturnCode,
        returnCode: returnCode ?? '',
      })
    }
  }

  const handleCancel = () => {
    if (site !== null) {
      setDetailsVaules({
        tzdbTimezone: site?.tzdbTimezone ?? '',
        defaultLanguage: site?.defaultLanguage ?? '',
      })
    } else {
      setDetailsVaules({})
    }

    if (siteSettings?.allowAuthenticateFromTablet !== undefined) {
      setAllowAuthenticateFromTablet(siteSettings.allowAuthenticateFromTablet)
    } else {
      setAllowAuthenticateFromTablet(false)
    }

    if (siteSettings?.enableReturnCode !== undefined) {
      setEnableReturnCode(siteSettings.enableReturnCode)
    } else {
      setEnableReturnCode(false)
    }

    if (siteSettings != null) {
      const newReturnCode = isValidReturnCode(siteSettings.returnCode)
        ? siteSettings.returnCode
        : generateReturnCode()

      setReturnCode(newReturnCode)
    } else {
      setReturnCode(undefined)
    }

    onClose()
  }

  const handelClose = () => {
    resetGeneralUpdate()
    resetSiteLoanUpdate()
    onClose()
  }

  return (
    <>
      <WizardDialog
        open={open && (!isGeneralUpdateSuccess || !isSiteLoanUpdateSuccess)}
        onClose={onClose}
        wide
        bottomBackground
        height="750px"
      >
        <Stack
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
            <DialogTitle
              mt={3}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontSize: '2.2rem',
                fontWeight: 'bold',
              })}
            >
              {t('installationWizard.details.title')}
            </DialogTitle>
            <Typography py={1} fontSize="1.2rem">
              {t('installationWizard.details.description')}
            </Typography>
            <Box px={2} py={4} width="1112px">
              <WideForm
                items={detailsItems}
                values={detailsValues}
                errors={detailsErrors}
                onChange={handleDetailsValues}
              />
              <Typography
                fontSize="14px"
                fontWeight="bold"
                textTransform="uppercase"
                mt={4}
                sx={(theme) => ({ color: '#000' })}
              >
                {t('installationWizard.details.borrowTablet')}
              </Typography>
              <Stack m={1} width="50%">
                <FormControlLabel
                  control={
                    <Switch
                      checked={allowAuthenticateFromTablet}
                      onChange={(_e, checked) =>
                        setAllowAuthenticateFromTablet(checked)
                      }
                    />
                  }
                  label={t(
                    'installationWizard.details.form.borrowTablet.label'
                  )}
                />
                {allowAuthenticateFromTablet && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          allowAuthenticateFromTablet && enableReturnCode
                        }
                        onChange={(_e, checked) => setEnableReturnCode(checked)}
                      />
                    }
                    disabled={!allowAuthenticateFromTablet}
                    label={t(
                      'installationWizard.details.form.returnCode.label'
                    )}
                  />
                )}
              </Stack>
              {allowAuthenticateFromTablet && enableReturnCode && (
                <Grid container mt={1}>
                  <Grid item xs={6} mr={1}>
                    <FormField
                      label={t('Return code')}
                      value={returnCode}
                      readOnly
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Stack>
          <DialogActions
            sx={{
              pt: 4,
              pb: 6,
              pr: 6,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button small outlined onClick={handleCancel} sx={{ mr: 1 }}>
              {t('cancel')}
            </Button>
            <Button small onClick={handleClickDone}>
              {t('installationWizard.done')}
            </Button>
          </DialogActions>
        </Stack>
      </WizardDialog>

      <Toast
        open={hasGeneralApiMutationErrors}
        message={apiGeneralMutationErrorsMsg}
        onClose={resetGeneralUpdate}
      />
      <Toast
        open={hasSiteLoanApiMutationErrors}
        message={apiSiteLoanMutationErrorsMsg}
        onClose={resetSiteLoanUpdate}
      />

      <StepDoneDialog
        title={t('installationWizard.details.done.title')}
        description={t('installationWizard.details.done.description')}
        open={isGeneralUpdateSuccess && isSiteLoanUpdateSuccess}
        onClose={handelClose}
      />
    </>
  )
}

export default SaveDetailsDailog

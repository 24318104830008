import { type ImportedCode, type CodeEditable } from './codes-types'
import rtkApi from 'rtk-api'

interface TryImportCodesApi {
  organizationId: number
  siteId: number
  codes: string[]
}

interface DoImportCodesApi extends TryImportCodesApi, CodeEditable {}

interface DeleteGroupApi {
  organizationId: number
  siteId: number
  groupName: string
}

const codesRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    tryImportCodes: builder.mutation<ImportedCode[], TryImportCodesApi>({
      query: ({ organizationId, siteId, codes }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/codes/try-import`,
        method: 'POST',
        body: codes,
      }),
    }),
    doImportCodes: builder.mutation<void, DoImportCodesApi>({
      query: ({
        organizationId,
        siteId,
        codes,
        profileId,
        expiration,
        permissionType,
        name,
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/codes/do-import`,
        method: 'POST',
        body: { codes, profileId, expiration, permissionType, name },
      }),
    }),
    deleteGroup: builder.mutation<void, DeleteGroupApi>({
      query: ({ organizationId, siteId, groupName }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/codes/${groupName}/usage`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useTryImportCodesMutation,
  useDoImportCodesMutation,
  useDeleteGroupMutation,
} = codesRtkApi

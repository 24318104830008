import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DialogTitle,
  DialogActions,
  Typography,
  Stack,
  Button as MuiButton,
} from '@mui/material'
import WizardDialog from 'common/components/WizardDialog'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import FormField from 'common/components/FormField'
import Button from 'common/components/Button'
import StepDoneDialog from 'App/InstallationWizard/components/StepDoneDialog'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { inviteSiteUser } from 'App/Users/users-state'
import { useAppDispatch } from 'store'
import { UserRole } from 'App/app-state'
import { useGetUsersQuery } from 'App/Users/users-rtk-api'
import { useUpdateStateMutation } from 'App/InstallationWizard/installation-wizard-rtk-api'
import Toast from 'common/components/Toast'
import useApiErrors from 'common/hooks/useApiErrors'
import { type User } from 'App/Users/users-types'

interface AddAdminDialogProps {
  open: boolean
  onClose: () => void
}

const AddAdminDialog = ({ open, onClose }: AddAdminDialogProps) => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const [invited, setInvited] = useState<boolean>(false)

  const [emails, setEmails] = useState<string[]>([''])
  const [emailErrors, setEmailErrors] = useState<Array<string | undefined>>([
    undefined,
  ])

  const [existingEmails, setExistingEmails] = useState<string[]>([])

  const { data: users, error: getApiError } = useGetUsersQuery({
    organizationId,
    siteId,
    withDeleted: false,
  })

  const [hasGetApiErrors, getApiErrorsMsg] = useApiErrors([getApiError])

  useEffect(() => {
    if (users !== undefined && users.length > 0) {
      setExistingEmails(users.map((user: User) => user.email))
    }
  }, [users, setExistingEmails])

  const isValidEmail = (email: string) =>
    email.length !== 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

  const getInvalidEmailError = (email: string) =>
    isValidEmail(email)
      ? undefined
      : t('installationWizard.admin.error.invalidEmail')

  const getEmailExistsError = (email: string) =>
    existingEmails.includes(email)
      ? t('installationWizard.admin.error.existingEmail')
      : undefined

  const updateEmail = (email: string, index: number) => {
    setEmails((currentEmails) => {
      const newEmails = [...currentEmails]
      newEmails[index] = email
      return newEmails
    })
    if (
      getInvalidEmailError(email) === undefined &&
      getEmailExistsError(email) === undefined
    ) {
      setEmailErrors((currentErrors) => {
        const newErrors = [...currentErrors]
        newErrors[index] = undefined
        return newErrors
      })
    }
  }

  const addEmail = () => {
    setEmails([...emails, ''])
  }

  const deleteEmail = (index: number) => {
    setEmails((currentEmails) => currentEmails.filter((_, i) => i !== index))
  }

  const onClickSentInvites = () => {
    const invalidEmailErrors = emails.map((email: string) =>
      getInvalidEmailError(email)
    )
    const emailExistsErrors = emails.map((email: string) =>
      getEmailExistsError(email)
    )
    const emailErrors = emails.map(
      (_, i) => invalidEmailErrors[i] ?? emailExistsErrors[i]
    )
    setEmailErrors(emailErrors)

    const hasErrors = emailErrors.some((error) => error !== undefined)
    if (!hasErrors) {
      emails.forEach((email) => {
        dispatch(
          inviteSiteUser({
            organizationId,
            siteId,
            email,
            language: i18n.language,
            role: UserRole.SiteAdmin,
          })
        )
      })
      setEmails([''])
      setInvited(true)
    }
  }

  const [
    updateState,
    {
      isSuccess: isUpdateStateSuccess,
      error: updateStateError,
      reset: resetStateUpdate,
    },
  ] = useUpdateStateMutation()

  const [hasUpdateStateErrors, apiUpdateStateErrorsMsg] = useApiErrors([
    updateStateError,
  ])

  const onExit = () => {
    updateState({ organizationId, siteId, hasAdmin: true })
  }

  const handleCancel = () => {
    setEmails([''])
    onClose()
  }

  useEffect(() => {
    if (isUpdateStateSuccess) {
      setInvited(false)
      resetStateUpdate()
      onClose()
    }
  }, [isUpdateStateSuccess, setInvited, resetStateUpdate, onClose])

  return (
    <>
      <WizardDialog
        open={open && !invited}
        onClose={onClose}
        narrow
        bottomBackground
      >
        <Stack
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
            <DialogTitle
              mt={3}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontSize: '2.2rem',
                fontWeight: 'bold',
              })}
            >
              {t('installationWizard.admin.addAdmin.title')}
            </DialogTitle>
            <Typography
              width="519px"
              textAlign="center"
              fontSize="1.1rem"
              mt={1}
            >
              {t('installationWizard.admin.addAdmin.description')}
            </Typography>
            <Stack width="400px" spacing={1} mt={8}>
              {emails.map((email, index) => (
                <FormField
                  key={index}
                  label={
                    index === 0
                      ? t('installationWizard.admin.addAdmin.form.label')
                      : undefined
                  }
                  type={'email'}
                  placeholder={t(
                    'installationWizard.admin.addAdmin.form.placeholder'
                  )}
                  value={email}
                  onChange={(e) => updateEmail(e.target.value, index)}
                  endIcon={index !== 0 ? <DeleteOutline /> : null}
                  onClickEndIcon={() => deleteEmail(index)}
                  error={emailErrors[index]}
                />
              ))}
            </Stack>
            <MuiButton
              variant="text"
              onClick={addEmail}
              sx={{ textTransform: 'none', mt: 2, fontSize: '1.1rem' }}
            >
              {t('installationWizard.admin.addAdmin.addMore')}
            </MuiButton>
          </Stack>
          <DialogActions
            sx={{
              pt: 4,
              pb: 8,
              pr: 6,
              display: 'flex',
              justifyContent: 'flex-end',
              alignContent: 'flex-end',
            }}
          >
            <Button small outlined onClick={handleCancel} sx={{ mr: 1 }}>
              {t('cancel')}
            </Button>
            <Button small onClick={onClickSentInvites}>
              {t('installationWizard.admin.addAdmin.sentInvites')}
            </Button>
          </DialogActions>
        </Stack>
      </WizardDialog>

      <StepDoneDialog
        title={t('installationWizard.admin.done.title')}
        description={t('installationWizard.admin.done.description')}
        open={invited}
        onClose={onExit}
      />

      <Toast
        open={hasUpdateStateErrors}
        message={apiUpdateStateErrorsMsg}
        onClose={resetStateUpdate}
      />

      <Toast open={open && hasGetApiErrors} message={getApiErrorsMsg} />
    </>
  )
}

export default AddAdminDialog

import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import { useTranslation } from 'react-i18next'
import Panel, { EditState } from 'common/components/NewPanel'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import { Divider } from '@mui/material'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { fetchDistributor } from 'App/Distributors/distributors-state'
import useApp from 'common/hooks/useApp'
import { setOrganization } from 'App/app-state'
import PanelField from 'common/components/PanelField'
import FormField from 'common/components/FormField'
import {
  useUpdateDistributorSettingsGeneralMutation,
  useUpdateDistributorSettingsSupportMutation,
} from 'App/DistributorSettings/distributor-settings-rtk-api'
import { type Distributor } from 'App/Distributors/distributors-types'
import useApiErrors from 'common/hooks/useApiErrors'
import FormSelect from 'common/components/FormSelect'
import { useAppDispatch, useAppSelector } from 'store'
import { timezoneOptions } from 'common/timezones'

interface DistributorSettingsGeneralPanelProps {
  distributor: Distributor
}

const DistributorSettingsGeneralPanel = ({
  distributor,
}: DistributorSettingsGeneralPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [editState, setEditState] = useState(EditState.Default)

  const [name, setName] = useState(distributor.name)
  const [timezone, setTimezone] = useState(distributor.timezone)

  const [
    updateDistributorSettingsGeneral,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateDistributorSettingsGeneralMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      dispatch(fetchDistributor({ distributorId: distributor.id }))
      setEditState(EditState.Success)
    }
  }, [isUpdateSuccess, hasApiMutationErrors, dispatch, distributor.id])

  const handleSave = () => {
    updateDistributorSettingsGeneral({
      distributorId: distributor.id,
      name,
      timezone,
    })
  }

  const renderEditMode = () => (
    <>
      <FormField
        label={t('distributorSettings.general.fields.name')}
        placeholder="Enter distributor support website"
        onChange={(e) => setName(e.target.value)}
        value={name}
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('distributorSettings.general.form.timezone.label')}
        placeholder={t('distributorSettings.general.form.timezone.placeholder')}
        options={timezoneOptions}
        value={timezone}
        onChange={(_e, v) => setTimezone(v as string)}
      />
    </>
  )

  if (distributor === null) return null

  return (
    <Panel
      title={t('distributorSettings.general.title')}
      variant="page"
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(EditState.Default)}
      onSave={handleSave}
      onSuccess={() => setEditState(EditState.Success)}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('distributorSettings.general.fields.name')}
        value={distributor.name}
        variant="page"
      />
      <Divider light />
      <PanelField
        title={t('distributorSettings.general.fields.timezone')}
        value={distributor.timezone}
        variant="page"
      />
      <Divider light />
      <PanelField
        title={t('distributorSettings.general.fields.partnerId')}
        value={distributor.hubletId}
        variant="page"
      />
    </Panel>
  )
}

interface DistributorSettingsSupportPanelProps {
  distributor: Distributor
}

const DistributorSettingsSupportPanel = ({
  distributor,
}: DistributorSettingsSupportPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [editState, setEditState] = useState(EditState.Default)

  const [supportWebsite, setSupportWebsite] = useState(
    distributor.supportWebsite ?? ''
  )
  const [supportEmail, setSupportEmail] = useState(
    distributor.supportEmail ?? ''
  )

  const [
    updateDistributorSettingsSupport,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateDistributorSettingsSupportMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      dispatch(fetchDistributor({ distributorId: distributor.id }))
      setEditState(EditState.Success)
    }
  }, [isUpdateSuccess, hasApiMutationErrors, dispatch, distributor.id])

  const handleSave = () => {
    updateDistributorSettingsSupport({
      distributorId: distributor.id,
      supportWebsite: supportWebsite === '' ? undefined : supportWebsite,
      supportEmail: supportEmail === '' ? undefined : supportEmail,
    })
  }

  const renderEditMode = () => (
    <>
      <FormField
        label={t('distributorSettings.support.supportWebsite.label')}
        type="url"
        placeholder={t(
          'distributorSettings.support.supportWebsite.placeholder'
        )}
        onChange={(e) => setSupportWebsite(e.target.value)}
        value={supportWebsite}
        disabled={editState === EditState.Pending}
      />
      <FormField
        label={t('distributorSettings.support.supportEmail.label')}
        type="email"
        placeholder={t('distributorSettings.support.supportEmail.placeholder')}
        onChange={(e) => setSupportEmail(e.target.value)}
        value={supportEmail}
        disabled={editState === EditState.Pending}
      />
    </>
  )

  if (distributor === null) return null

  return (
    <Panel
      title={t('distributorSettings.support.title')}
      variant="page"
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(EditState.Default)}
      onSave={handleSave}
      onSuccess={() => setEditState(EditState.Success)}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('distributorSettings.support.supportWebsite.label')}
        value={distributor.supportWebsite}
        variant="page"
      />
      <Divider light />
      <PanelField
        title={t('distributorSettings.support.supportEmail.label')}
        value={distributor.supportEmail}
        variant="page"
      />
    </Panel>
  )
}

const DistributorSettings = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const distributor = useAppSelector((state) => state.distributors.distributor)
  const { organizationId } = useCurrentAccount()
  const { organization: activeDistributor } = useApp()

  useEffect(() => {
    if (distributor === null || distributor.id !== organizationId) {
      dispatch(fetchDistributor({ distributorId: organizationId }))
    }
  }, [dispatch, organizationId, distributor])

  useEffect(() => {
    if (
      activeDistributor !== undefined &&
      activeDistributor.id === distributor?.id
    ) {
      // Update active distributor data if distributor data has changed
      if (
        activeDistributor.name !== distributor.name ||
        activeDistributor.timezone !== distributor.timezone
      ) {
        dispatch(
          setOrganization({
            id: distributor.id,
            name: distributor.name,
            timezone: distributor.timezone,
          })
        )
      }
    }
  }, [distributor, dispatch, activeDistributor])

  if (distributor === null) {
    return <Page />
  }

  return (
    <>
      <Page>
        <PageTitle title={t('distributorSettings.title')} />
        <PageBody>
          <DistributorSettingsGeneralPanel distributor={distributor} />
          <DistributorSettingsSupportPanel distributor={distributor} />
        </PageBody>
      </Page>
    </>
  )
}

export default DistributorSettings

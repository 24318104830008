import { Collapse, List } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SideMenuItem } from './SideMenu2.types'
import MainMenuItem from 'common/components/SideMenu2/MainMenuItem'
import SubMenuItem from 'common/components/SideMenu2/SubMenuItem'

interface PageSideMenuItem2Props {
  item: SideMenuItem
}

const SideMenu2Item = ({ item }: PageSideMenuItem2Props) => {
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (item.subItems?.length && !collapsed) {
      item.subItems.forEach((subItem) => {
        if (location.pathname.startsWith(subItem.link!)) {
          setCollapsed(true)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  const { label, link, subItems, external } = item

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  const handleNavigate = () => {
    if (external) {
      window.open(`/ui${link}`, '_blank')
    } else {
      navigate(link!)
    }
  }

  if (subItems?.length) {
    return (
      <>
        <MainMenuItem
          item={item}
          onClick={handleToggleCollapse}
          selected={false}
          collapsed={collapsed}
        />

        <Collapse in={collapsed} timeout="auto" unmountOnExit>
          <List component="nav" dense disablePadding>
            {subItems.map((subItem) => (
              <SubMenuItem item={subItem} key={`${label}.${subItem.label}`} />
            ))}
          </List>
        </Collapse>
      </>
    )
  }

  return (
    <MainMenuItem
      item={item}
      onClick={handleNavigate}
      selected={location.pathname.startsWith(link!)}
    />
  )
}

export default SideMenu2Item

import React from 'react'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { useLoadLastScheduledTaskResultQuery } from 'App/Dashboard/scheduled-task-result-rtk-api'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'

const useStyles = makeStyles()((theme: Theme) => ({
  ScheduledTaskResult: {
    padding: theme.spacing(4),
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 5%)',
    fontSize: 16,
    marginTop: theme.spacing(4),
    '& h3': {
      margin: 0,
      color: theme.palette.primary.main,
      fontSize: 20,
    },
    '& > div': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  Label: {
    fontWeight: 'bold',
  },
  Success: {
    fontWeight: 'bold',
    color: '#64AB43',
  },
  Error: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
}))

const ScheduledTaskResult = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { data: lastResult } = useLoadLastScheduledTaskResultQuery(null)

  return (
    <div className={classes.ScheduledTaskResult}>
      <h3>{t('dashboard.scheduledTaskResult.title')}</h3>
      <div>
        <span className={classes.Label}>
          {t('dashboard.scheduledTaskResult.startedTime')}:{' '}
        </span>
        {lastResult?.startedAt === undefined ? (
          '-'
        ) : (
          <DateWithRelativeTime datetime={new Date(lastResult?.startedAt)} />
        )}
      </div>
      <div>
        <span className={classes.Label}>
          {t('dashboard.scheduledTaskResult.finishedTime')}:{' '}
        </span>
        {lastResult?.finishedAt === undefined ? (
          '-'
        ) : (
          <DateWithRelativeTime datetime={new Date(lastResult?.finishedAt)} />
        )}
      </div>
      <div>
        <span className={classes.Label}>
          {t('dashboard.scheduledTaskResult.result')}:{' '}
        </span>
        {lastResult?.success === true ? (
          <span className={classes.Success}>
            {t('dashboard.scheduledTaskResult.result.success')}
          </span>
        ) : (
          <span className={classes.Error}>
            {t('dashboard.scheduledTaskResult.result.error')}
          </span>
        )}
      </div>
      <div>
        <span className={classes.Label}>
          {t('dashboard.scheduledTaskResult.message')}:{' '}
        </span>
        {lastResult?.errorMessage ?? '-'}
      </div>
    </div>
  )
}

export default ScheduledTaskResult

import { type GridColDef } from '@mui/x-data-grid'
import { type GROUP_BY_OPTIONS } from './Analytics.types'
import { formatDuration, intervalToDuration, getWeek } from 'date-fns'
import getDateFnsLocale from 'common/utils/get-date-fns-locale'
import i18n from 'locales'

export const secondsToHumanReadable = (seconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  // Convert years and months to days
  const totalDays =
    (duration.years ?? 0) * 365 +
    (duration.months ?? 0) * 30 +
    (duration.days ?? 0)
  const days = totalDays
    ? formatDuration(
        { days: totalDays },
        {
          format: ['days'],
          locale: getDateFnsLocale(i18n.language),
        }
      ) + ', '
    : ''

  const hours = String(duration.hours).padStart(2, '0')
  const minutes = String(duration.minutes).padStart(2, '0')
  const secs = String(duration.seconds).padStart(2, '0')

  return `${days}${hours}:${minutes}:${secs}`
}

const COMMON_COLUMNS: GridColDef[] = [
  {
    field: 'loanCount',
    headerName: 'analytics.loans',
    width: 100,
    type: 'number',
    valueGetter: (value?: number) => {
      if (!value) {
        return 0
      }
      return Number(value)
    },
  },
  {
    field: 'minDuration',
    headerName: 'analytics.minLoanTime',
    minWidth: 170,
    flex: 1,
    type: 'number',
    valueGetter: (value?: number) => {
      if (!value) {
        return 0
      }
      return Number(value)
    },
    valueFormatter: (value?: number) => {
      if (!value) {
        return ''
      }
      return secondsToHumanReadable(value)
    },
  },
  {
    field: 'maxDuration',
    headerName: 'analytics.maxLoanTime',
    minWidth: 170,
    flex: 1,
    type: 'number',
    valueGetter: (value?: number) => {
      if (!value) {
        return 0
      }
      return Number(value)
    },
    valueFormatter: (value?: number) => {
      if (!value) {
        return ''
      }
      return secondsToHumanReadable(value)
    },
  },
  {
    field: 'avgDuration',
    headerName: 'analytics.avgLoanTime',
    minWidth: 170,
    flex: 1,
    type: 'number',
    valueGetter: (value?: number) => {
      if (!value) {
        return 0
      }
      return Number(value)
    },
    valueFormatter: (value?: number) => {
      if (!value) {
        return ''
      }
      return secondsToHumanReadable(value)
    },
  },
  {
    field: 'medianDuration',
    headerName: 'analytics.medianLoanTime',
    minWidth: 170,
    flex: 1,
    type: 'number',
    valueGetter: (value?: number) => {
      if (!value) {
        return 0
      }
      return Number(value)
    },
    valueFormatter: (value?: number) => {
      if (!value) {
        return ''
      }
      return secondsToHumanReadable(value)
    },
  },
  {
    field: 'totalDuration',
    headerName: 'analytics.totalLoanTime',
    minWidth: 170,
    flex: 1,
    type: 'number',
    valueGetter: (value?: number) => {
      if (!value) {
        return 0
      }
      return Number(value)
    },
    valueFormatter: (value?: number) => {
      if (!value) {
        return ''
      }
      return secondsToHumanReadable(value)
    },
  },
]

export const getCommonColumns = (
  groupByTime?: GROUP_BY_OPTIONS
): GridColDef[] => {
  if (!groupByTime) {
    return COMMON_COLUMNS
  }

  switch (groupByTime) {
    case 'day':
      return [
        {
          field: 'tgroup',
          headerName: 'analytics.day',
          width: 100,
          type: 'string',
          valueGetter: (value?: string) => {
            if (!value) {
              return '-'
            }
            return value.slice(0, 10)
          },
        },
        ...COMMON_COLUMNS,
      ]
    case 'week':
      return [
        {
          field: 'tgroup',
          headerName: 'analytics.week',
          width: 100,
          type: 'string',
          valueGetter: (value?: string) => {
            if (!value) {
              return '-'
            }
            return `${value.slice(0, 4)}/${getWeek(new Date(value))}`
          },
        },
        ...COMMON_COLUMNS,
      ]
    case 'month':
      return [
        {
          field: 'tgroup',
          headerName: 'analytics.month',
          width: 100,
          type: 'string',
          valueGetter: (value?: string) => {
            if (!value) {
              return '-'
            }
            return value.slice(0, 7)
          },
        },
        ...COMMON_COLUMNS,
      ]
    default:
      return COMMON_COLUMNS
  }
}

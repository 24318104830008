import rtkApi from 'rtk-api'
import { type AwsCognitoImportUser } from 'App/SuperAdmin/AwsCognitoUserImport'
import {
  type AwsCognitoUpdatePasswordTipsEmailUser,
  type AwsCognitoPreReleaseEmailUser,
  type OrganizationDataForAdminReview,
  type SiteDataForAdminReview,
  type Product,
  type DockDataForAdminReview,
  type TabletDataForAdminReview,
} from 'App/SuperAdmin/super-admin-types'

const adminEmailsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getAwsCognitoPreReleaseEmailUsers: builder.query<
      AwsCognitoPreReleaseEmailUser[],
      void
    >({
      query: () => ({
        url: '/admin/aws-cognito/pre-release/users',
        method: 'GET',
      }),
    }),
    sendAwsCognitoPreReleaseEmail: builder.mutation<
      void,
      { userIds: number[] }
    >({
      query: ({ userIds }) => ({
        url: '/admin/aws-cognito/pre-release/send',
        method: 'POST',
        body: { userIds },
      }),
    }),
    getAwsCognitoImportUsers: builder.query<AwsCognitoImportUser[], void>({
      query: () => ({
        url: '/admin/aws-cognito/import/users',
        method: 'GET',
      }),
    }),
    importUsersToAwsCognito: builder.mutation<void, { userIds: number[] }>({
      query: ({ userIds }) => ({
        url: '/admin/aws-cognito/import',
        method: 'POST',
        body: { userIds },
      }),
    }),
    getAwsCognitoUpdatePasswordTipsEmailUsers: builder.query<
      AwsCognitoUpdatePasswordTipsEmailUser[],
      void
    >({
      query: () => ({
        url: '/admin/aws-cognito/update-password-tips/users',
        method: 'GET',
      }),
    }),
    sendAwsCognitoUpdatePasswordTipsEmail: builder.mutation<
      void,
      { userIds: number[] }
    >({
      query: ({ userIds }) => ({
        url: '/admin/aws-cognito/update-password-tips/send',
        method: 'POST',
        body: { userIds },
      }),
    }),
    getOrganizationsDataForReview: builder.query<
      OrganizationDataForAdminReview[],
      void
    >({
      query: () => ({
        url: '/admin/data-review/organizations',
        method: 'GET',
      }),
    }),
    reviewOrganizationData: builder.mutation<
      OrganizationDataForAdminReview,
      OrganizationDataForAdminReview
    >({
      query: (organizationData) => ({
        url: `/admin/data-review/organizations/${organizationData.id}`,
        method: 'POST',
        body: organizationData,
      }),
      invalidatesTags: ['DataTag'],
    }),
    getSitesDataForReview: builder.query<SiteDataForAdminReview[], void>({
      query: () => ({
        url: '/admin/data-review/sites',
        method: 'GET',
      }),
    }),
    reviewSiteData: builder.mutation<
      SiteDataForAdminReview,
      SiteDataForAdminReview
    >({
      query: (siteData) => ({
        url: `/admin/data-review/sites/${siteData.id}`,
        method: 'POST',
        body: siteData,
      }),
      invalidatesTags: ['DataTag'],
    }),
    getTabletsDataForReview: builder.query<TabletDataForAdminReview[], void>({
      query: () => ({
        url: '/admin/data-review/tablets',
        method: 'GET',
      }),
    }),
    reviewTabletData: builder.mutation<
      TabletDataForAdminReview,
      TabletDataForAdminReview
    >({
      query: (tabletData) => ({
        url: `/admin/data-review/tablets/${tabletData.id}`,
        method: 'POST',
        body: tabletData,
      }),
      invalidatesTags: ['DataTag'],
    }),
    getDocksDataForReview: builder.query<DockDataForAdminReview[], void>({
      query: () => ({
        url: '/admin/data-review/docks',
        method: 'GET',
      }),
    }),
    reviewDockData: builder.mutation<
      DockDataForAdminReview,
      DockDataForAdminReview
    >({
      query: (dockData) => ({
        url: `/admin/data-review/docks/${dockData.id}`,
        method: 'POST',
        body: dockData,
      }),
      invalidatesTags: ['DataTag'],
    }),
    getProducts: builder.query<Product[], void>({
      query: () => ({
        url: '/admin/products',
        method: 'GET',
      }),
    }),
    addProduct: builder.mutation<Product, Partial<Product>>({
      query: (productData) => ({
        url: '/admin/products',
        method: 'POST',
        body: productData,
      }),
    }),
    updateProduct: builder.mutation<Product, Product>({
      query: (productData) => ({
        url: `/admin/products/${productData.id}`,
        method: 'PUT',
        body: productData,
      }),
    }),
    deleteProduct: builder.mutation<void, number>({
      query: (productId) => ({
        url: `/admin/products/${productId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAwsCognitoPreReleaseEmailUsersQuery,
  useSendAwsCognitoPreReleaseEmailMutation,
  useGetAwsCognitoImportUsersQuery,
  useImportUsersToAwsCognitoMutation,
  useGetAwsCognitoUpdatePasswordTipsEmailUsersQuery,
  useSendAwsCognitoUpdatePasswordTipsEmailMutation,
  useGetOrganizationsDataForReviewQuery,
  useReviewOrganizationDataMutation,
  useGetSitesDataForReviewQuery,
  useReviewSiteDataMutation,
  useGetDocksDataForReviewQuery,
  useReviewDockDataMutation,
  useGetTabletsDataForReviewQuery,
  useReviewTabletDataMutation,
  useGetProductsQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = adminEmailsRtkApi

import React, { useState, useEffect, useMemo } from 'react'
import {
  type Product,
  ProductCategory,
  BusinessModel,
} from 'App/SuperAdmin/super-admin-types'
import DrawerForm from 'common/components/DrawerForm'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import { Stack } from '@mui/material'
import { useUpdateProductMutation } from 'App/SuperAdmin/super-admin-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import { toDateFieldValue } from 'common/utils/date-utils'

interface EditProductDrawerProps {
  open: boolean
  onClose: () => void
  onSaved: () => void
  productData: Product
}

const EditProductDrawer = ({
  open,
  onClose,
  onSaved,
  productData,
}: EditProductDrawerProps) => {
  const [sku, setSku] = useState(productData.sku)
  const [name, setName] = useState(productData.name)
  const [description, setDescription] = useState(productData.description)
  const [publicUrl, setPublicUrl] = useState(productData.publicUrl)
  const [isActive, setIsActive] = useState(
    productData.isActive ? 'active' : 'inactive'
  )
  const [endOfLifeDate, setEndOfLifeDate] = useState(productData.endOfLifeDate)
  const [category, setCategory] = useState(productData.category)
  const [businessModel, setBusinessModel] = useState(productData.businessModel)

  const IsActiveOptions = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ]

  const ProductCategoryOptions = useMemo(
    () =>
      Object.keys(ProductCategory).map((key) => {
        return {
          label: ProductCategory[key as keyof typeof ProductCategory],
          value: ProductCategory[key as keyof typeof ProductCategory],
        }
      }),
    []
  )

  const BusinessModelOptions = useMemo(
    () =>
      Object.keys(BusinessModel).map((key) => {
        return {
          label: BusinessModel[key as keyof typeof BusinessModel],
          value: BusinessModel[key as keyof typeof BusinessModel],
        }
      }),
    []
  )

  const [
    updateProductData,
    {
      isLoading: isUpdatingProduct,
      error: updateProductError,
      isSuccess: isProductUpdated,
      data: updatedProductData,
    },
  ] = useUpdateProductMutation()

  useEffect(() => {
    if (isProductUpdated && updatedProductData) {
      onSaved()
    }
  }, [isProductUpdated, updatedProductData, onSaved])

  const [hasApiError, apiError] = useApiErrors([updateProductError])

  const handleSubmit = () => {
    const product: Product = {
      id: productData.id,
      sku,
      name,
      description,
      publicUrl,
      isActive: isActive === 'active',
      endOfLifeDate,
      category,
      businessModel,
    }

    updateProductData(product)
  }

  return (
    <DrawerForm
      title="Edit product"
      open={open}
      onClose={onClose}
      submitText="Save"
      onSubmit={handleSubmit}
      disabledButton={isUpdatingProduct}
    >
      <Stack spacing={4}>
        {hasApiError && <ErrorBox>{apiError}</ErrorBox>}
        <FormField
          label="SKU"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
          placeholder="Enter SKU"
          disabled={isUpdatingProduct}
        />
        <FormField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter name"
          disabled={isUpdatingProduct}
        />
        <FormField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description"
          disabled={isUpdatingProduct}
        />
        <FormField
          label="Public URL"
          value={publicUrl}
          onChange={(e) => setPublicUrl(e.target.value)}
          placeholder="Enter Public URL"
          disabled={isUpdatingProduct}
        />
        <FormSelect
          label="Status"
          value={isActive}
          onChange={(e) => setIsActive(e.target.value)}
          options={IsActiveOptions}
          placeholder="Select status"
          disabled={isUpdatingProduct}
        />
        <FormField
          type="date"
          label="End of life date"
          value={endOfLifeDate ? toDateFieldValue(new Date(endOfLifeDate)) : ''}
          onChange={(e) => setEndOfLifeDate(e.target.value)}
          placeholder="Enter end of life date"
          disabled={isUpdatingProduct}
        />
        <FormSelect
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          options={ProductCategoryOptions}
          placeholder="Select category"
          disabled={isUpdatingProduct}
        />
        <FormSelect
          label="Business model"
          value={businessModel}
          onChange={(e) => setBusinessModel(e.target.value)}
          options={BusinessModelOptions}
          placeholder="Select business model"
          disabled={isUpdatingProduct}
        />
      </Stack>
    </DrawerForm>
  )
}

export default EditProductDrawer

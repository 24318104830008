import { createSlice } from '@reduxjs/toolkit'

interface TabletsState {
  isUploadSuccess: boolean
  removedTablet: boolean
  returnedTablet: boolean
  returnState: string
}

const initialState: TabletsState = {
  isUploadSuccess: false,
  removedTablet: false,
  returnedTablet: false,
  returnState: 'Returned',
}

export const stockTabletsSlice = createSlice({
  name: 'stock-tablets',
  initialState,
  reducers: {
    setUploadSuccess: (state) => {
      state.isUploadSuccess = true
    },
    resetUploadSuccess: (state) => {
      state.isUploadSuccess = false
    },
    setRemoveTablet: (state) => {
      state.removedTablet = true
    },
    resetRemoveTablet: (state) => {
      state.removedTablet = false
    },
    setReturnTablet: (state) => {
      state.returnedTablet = true
    },
    resetReturnTablet: (state) => {
      state.returnedTablet = false
    },
    setReturnState: (state, { payload }) => {
      state.returnState = payload
    },
    resetReturnState: (state) => {
      state.returnState = 'Returned'
    },
  },
})

export const {
  setUploadSuccess,
  resetUploadSuccess,
  setRemoveTablet,
  resetRemoveTablet,
  setReturnTablet,
  resetReturnTablet,
  setReturnState,
  resetReturnState,
} = stockTabletsSlice.actions

export default stockTabletsSlice.reducer

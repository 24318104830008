import countries from 'i18n-iso-countries'
import caCountryNames from 'i18n-iso-countries/langs/ca.json'
import cyCountryNames from 'i18n-iso-countries/langs/cy.json'
import daCountryNames from 'i18n-iso-countries/langs/da.json'
import deCountryNames from 'i18n-iso-countries/langs/de.json'
import elCountryNames from 'i18n-iso-countries/langs/el.json'
import enCountryNames from 'i18n-iso-countries/langs/en.json'
import etCountryNames from 'i18n-iso-countries/langs/et.json'
import esCountryNames from 'i18n-iso-countries/langs/es.json'
import euCountryNames from 'i18n-iso-countries/langs/eu.json'
import fiCountryNames from 'i18n-iso-countries/langs/fi.json'
import frCountryNames from 'i18n-iso-countries/langs/fr.json'
import heCountryNames from 'i18n-iso-countries/langs/he.json'
import hiCountryNames from 'i18n-iso-countries/langs/hi.json'
import itCountryNames from 'i18n-iso-countries/langs/it.json'
import jaCountryNames from 'i18n-iso-countries/langs/ja.json'
import koCountryNames from 'i18n-iso-countries/langs/ko.json'
import nlCountryNames from 'i18n-iso-countries/langs/nl.json'
import noCountryNames from 'i18n-iso-countries/langs/no.json'
import ptCountryNames from 'i18n-iso-countries/langs/pt.json'
import ruCountryNames from 'i18n-iso-countries/langs/ru.json'
import svCountryNames from 'i18n-iso-countries/langs/sv.json'
import ukCountryNames from 'i18n-iso-countries/langs/uk.json'
import zhCountryNames from 'i18n-iso-countries/langs/zh.json'

import gaCountryNames from 'common/langs/ga.json'
import zhTWCountryNames from 'common/langs/zh-TW.json'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

countries.registerLocale(caCountryNames)
countries.registerLocale(cyCountryNames)
countries.registerLocale(daCountryNames)
countries.registerLocale(deCountryNames)
countries.registerLocale(elCountryNames)
countries.registerLocale(enCountryNames)
countries.registerLocale(esCountryNames)
countries.registerLocale(euCountryNames)
countries.registerLocale(fiCountryNames)
countries.registerLocale(frCountryNames)
countries.registerLocale(heCountryNames)
countries.registerLocale(hiCountryNames)
countries.registerLocale(itCountryNames)
countries.registerLocale(nlCountryNames)
countries.registerLocale(noCountryNames)
countries.registerLocale(ptCountryNames)
countries.registerLocale(svCountryNames)
countries.registerLocale(koCountryNames)
countries.registerLocale(zhCountryNames)
countries.registerLocale(ukCountryNames)
countries.registerLocale(ruCountryNames)
countries.registerLocale(gaCountryNames)
countries.registerLocale(jaCountryNames)
countries.registerLocale(etCountryNames)

export const i18nIsoCountries = countries

// i18n-iso-countries use ISO 639-1 codes (two-letter)
// Some special cases for 'pt-PT' and 'zh-TW' need to handle

const useStoredLng = () => {
  const { i18n } = useTranslation()
  const storedLngRaw = i18n.language
  const storedLng = storedLngRaw === 'pt-PT' ? 'pt' : storedLngRaw

  return storedLng
}

const getCountrieNames = (lng: string) => {
  let countryNames

  if (lng === 'zh-TW') {
    countryNames = zhTWCountryNames.countries as ReturnType<
      typeof i18nIsoCountries.getNames
    >
  } else {
    countryNames = i18nIsoCountries.getNames(lng)
  }

  return countryNames
}

export const useCountryOptions = () => {
  const storedLng = useStoredLng()
  const countryNames = useMemo(() => getCountrieNames(storedLng), [storedLng])

  const countryOptions = useMemo(() => {
    return Object.keys(countryNames)
      .map((code) => ({
        value: code,
        label: countryNames[code],
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [countryNames])

  return countryOptions
}

export const useGetCountryNameInLocale = () => {
  const storedLng = useStoredLng()
  const countryNames = useMemo(() => getCountrieNames(storedLng), [storedLng])

  const getCountryNameInLocale = (countryCode: string) =>
    countryNames[countryCode]

  return getCountryNameInLocale
}

import React, { useState } from 'react'
import { useAppDispatch } from 'store'
import {
  useEmailPasswordLoginMutation,
  useGetUserPoolDataQuery,
} from 'App/CognitoLogin/cogonit-login-rtk-api'
import { fetchCurrentUser } from 'App/app-state'
import {
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Divider,
  Link,
  styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import LoginPageWrapper from 'App/Login/components/LoginPageWrapper'
import { useTranslation } from 'react-i18next'
import { PATHS } from 'common/constants'
import useTracking from 'common/hooks/useTracking'

const FormStyled = styled('form')({
  width: '100%',
})

const SeparatorBox = styled(Box)(({ theme }) => ({
  width: '14.5rem',
  margin: theme.spacing(5, 0),
}))

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}))

interface InputError {
  email: string
  password: string
}

const EmailPasswordLogin = () => {
  const { data: userPoolData } = useGetUserPoolDataQuery()
  const dispatch = useAppDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [inputError, setInputError] = useState<InputError>({
    email: '',
    password: '',
  })
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { track } = useTracking()

  const [postLogin, { reset: resetLogin }] = useEmailPasswordLoginMutation()

  const isValidInput = () => {
    let emailError = ''
    let passwordError = ''
    if (!email) {
      emailError = t('loginv2.error.requiredField')
    }
    if (!password) {
      passwordError = t('loginv2.error.requiredField')
    }
    if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      emailError = t('loginv2.error.invalidEmail')
    }

    setInputError({
      email: emailError,
      password: passwordError,
    })

    if (!emailError && !passwordError) {
      return true
    }
    return false
  }

  const resetInputState = () => {
    setInputError({ email: '', password: '' })
    setErrorMsg('')
    resetLogin()
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isValidInput()) {
      return
    }

    postLogin({ username: email, password })
      .unwrap()
      .then((res) => {
        if (res.isSuccess) {
          track({
            feature: 'Login',
            action: 'Success',
            payload: { email, sso: false },
          })
          if (res.data) {
            const { session } = res.data
            navigate(PATHS.forceChangePassword, { state: { email, session } })
            return
          }
          dispatch(fetchCurrentUser())
          setErrorMsg('')
        } else {
          track({
            feature: 'Login',
            action: 'Failed',
            payload: { email, sso: false, reason: 'Invalid email or password' },
          })
          setErrorMsg(t('loginv2.invalidEmailOrPassword'))
        }
      })
      .catch((error) => {
        console.warn('Error when trying to login', error)
        track({
          feature: 'Login',
          action: 'Failed',
          payload: { email, sso: false, reason: JSON.stringify(error) },
        })
        setErrorMsg(t('loginv2.InternalErrorException'))
      })
  }

  return (
    <LoginPageWrapper title={t('loginv2.signInTitle')}>
      {errorMsg && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {errorMsg}
        </Alert>
      )}
      <FormStyled onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          label={t('loginv2.emailAddress')}
          placeholder={t('loginv2.emailAddress')}
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
            resetInputState()
          }}
          error={Boolean(inputError.email)}
          helperText={inputError.email}
        />
        <TextField
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          name="password"
          label={t('loginv2.password')}
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
            resetInputState()
          }}
          placeholder={t('loginv2.password')}
          error={Boolean(inputError.password)}
          helperText={inputError.password}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <SubmitButton type="submit" variant="contained" color="primary">
            {t('loginv2.signIn')}
          </SubmitButton>
          <Typography variant="body2" align="center" fontWeight={500}>
            <Link
              onClick={() => navigate(PATHS.forgotPassword)}
              sx={{ cursor: 'pointer' }}
            >
              {t('loginv2.iForgotMyPassword')}
            </Link>
          </Typography>

          {userPoolData?.clientId && (
            <>
              <SeparatorBox>
                <Divider />
              </SeparatorBox>
              <Button variant="outlined" onClick={() => navigate(PATHS.sso)}>
                {t('loginv2.signInSSO')}
              </Button>
            </>
          )}
        </Box>
      </FormStyled>
    </LoginPageWrapper>
  )
}

export default EmailPasswordLogin

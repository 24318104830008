import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import { type Network } from 'App/Networks/networks-types'
import NetworkFields from 'App/Networks/NetworkFields'
import useNetworkForm from 'common/hooks/useNetworkForm'

interface EditNetworkDrawerProps {
  network?: Network
  open: boolean
  onClose: () => void
}

const EditNetworkDrawer = ({
  network,
  open,
  onClose,
}: EditNetworkDrawerProps) => {
  const { t } = useTranslation()

  const {
    networkForm,
    fieldErrors,
    handleFieldChange,
    handleSwitchToggle,
    handleUpdate,
    updatedNetwork,
  } = useNetworkForm(network)

  useEffect(() => {
    if (updatedNetwork) {
      onClose()
    }
  }, [onClose, updatedNetwork])

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={handleUpdate}
      title={t('networks.editNetwork.title')}
      submitText={t('networks.editNetwork.buttons.submit')}
    >
      <NetworkFields
        networkForm={networkForm}
        fieldErrors={fieldErrors}
        onChangeField={handleFieldChange}
        onToggleSwitch={handleSwitchToggle}
      />
    </DrawerForm>
  )
}

export default EditNetworkDrawer

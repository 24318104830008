import {
  type ForcedSubject,
  createMongoAbility,
  subject,
  createAliasResolver,
} from '@casl/ability'
import { type CurrentUser } from 'App/app-state'

// TODO: contains some duplicate logic from the backend. Consider refactoring.

export enum PrivilegeSubject {
  Distributors = 'Distributors',
  Customers = 'Customers',
  Sites = 'Sites',
  Users = 'Users',
  Posts = 'Posts',
  Networks = 'Networks',
  Tablets = 'Tablets',
  Profiles = 'Profiles',
  Categories = 'Categories',
  Contents = 'Contents',
  Docks = 'Docks',
  Codes = 'Codes',
  Loans = 'Loans',
  DistributorSettings = 'DistributorSettings',
  CustomerSettings = 'CustomerSettings',
  SiteSettings = 'SiteSettings',
  Integrations = 'Integrations',
  Distributor = 'Distributor',
  Organization = 'Organization',
  IntegrationStatistics = 'IntegrationStatistics',
  Analytics = 'Analytics',
  SuperAdmin = 'SuperAdmin',
  Translations = 'Translations',
  TopApps = 'TopApps',
  Customer = 'Customer',
  Site = 'Site',
  User = 'User',
  Post = 'Post',
  Network = 'Network',
  Tablet = 'Tablet',
  Profile = 'Profile',
  Category = 'Category',
  Content = 'Content',
  Dock = 'Dock',
  Code = 'Code',
  Loan = 'Loan',
  Translation = 'Translation',
  Manufacturer = 'Manufacturer',
}

export enum Action {
  View = 'view',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  CRUD = 'crud',
}

/**
 * Generate all possible combinations of CRUD actions
 * to support shorthand aliases like 'crud' or 'crd' in ability rules,
 * where 'crud' is equivalent to ['create', 'read', 'update', 'delete']
 */
function generateCrudCombinations(): Record<string, Action[]> {
  const actions: Record<string, Action> = {
    c: Action.Create,
    r: Action.Read,
    u: Action.Update,
    d: Action.Delete,
  }

  const allCombinations = (str: string): string[] => {
    if (str.length === 1) {
      return [str]
    }

    const partialCombinations = allCombinations(str.slice(1))
    return partialCombinations.concat(
      partialCombinations.map((combination) => str[0] + combination),
      str[0]
    )
  }

  const crudCombinations = allCombinations(Action.CRUD)
  const crudMap: Record<string, Action[]> = {}

  for (const combination of crudCombinations.reverse()) {
    crudMap[combination] = combination.split('').map((char) => actions[char])
  }

  return crudMap
}

const crudCombinations = generateCrudCombinations()
const resolveAction = createAliasResolver(crudCombinations)

export function defineAbilityWith(rules: any) {
  const ability = createMongoAbility(rules, {
    resolveAction,
  })

  // Binding required for destructuring to work:
  // https://github.com/stalniy/casl/issues/462
  ability.can = ability.can.bind(ability)
  ability.cannot = ability.cannot.bind(ability)

  return ability
}

export function defineAbilityFor(currentUser: CurrentUser) {
  return defineAbilityWith(currentUser.rules)
}

export function defineGuestAbility() {
  const ability = createMongoAbility([])

  // Binding required for destructuring to work:
  // https://github.com/stalniy/casl/issues/462
  ability.can = ability.can.bind(ability)
  ability.cannot = ability.cannot.bind(ability)

  return ability
}

/**
 * Defines the data type for privilege checks.
 * Custom function needed because `subject` function
 * does not work with non-extensible objects.
 */
export function as<
  T extends PrivilegeSubject,
  U extends Record<PropertyKey, any>,
>(type: T, object: U): U & ForcedSubject<T> {
  if (!Object.isExtensible(object)) {
    object = Object.assign({}, object)
  }

  return subject(type, object)
}

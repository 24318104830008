import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type Site,
  fetchSites,
  deleteSite as deleteSiteAction,
  resetDeletedSite,
} from 'App/Sites/sites-state'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import Table from 'common/components/TableNew'
import EditSiteDrawer from 'App/Sites/SitesList/EditSiteDrawer'
import ActionDialog from 'common/components/ActionDialog'
import IconMenu from 'common/components/IconMenu'
import AvatarWithText from 'common/components/AvatarWithText'
import useApp from 'common/hooks/useApp'
import { useAppDispatch, useAppSelector } from 'store'
import { AbilityContext } from 'common/contexts/AbilityContext'
import { PrivilegeSubject, Action, as } from 'common/utils/privileges'
import { useAbility } from '@casl/react'

interface SitesRowActionsProps {
  site: Site
  onClickEdit: (site: Site) => void
  onClickDelete: (site: Site) => void
}

const SitesRowActions = ({
  site,
  onClickEdit,
  onClickDelete,
}: SitesRowActionsProps) => {
  const userAbility = useAbility(AbilityContext)
  const actions = [
    {
      local: 'sites.listSites.actions.edit',
      fn: () => onClickEdit(site),
      icon: EditIcon,
    },
  ]

  if (userAbility.can(Action.Delete, as(PrivilegeSubject.Site, site))) {
    actions.push({
      local: 'sites.listSites.actions.delete',
      fn: () => onClickDelete(site),
      icon: DeleteIcon,
    })
  }

  return <IconMenu actions={actions} />
}

interface SitesTableProps {
  sites: Site[]
}

const SitesTable = ({ sites }: SitesTableProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organization } = useApp()

  const updatedSite = useAppSelector((state) => state.sites.updatedSite)
  const deletedSite = useAppSelector((state) => state.sites.deletedSite)

  useEffect(() => {
    if (deletedSite) {
      dispatch(resetDeletedSite())
      dispatch(fetchSites({ organizationId: organization.id }))
    }
  }, [dispatch, deletedSite, organization])

  const [editSite, setEditSite] = useState<Site | undefined>()
  const [deleteSite, setDeleteSite] = useState<Site | undefined>()

  const onClickEdit = (site: Site) => setEditSite(site)
  const onClickDelete = (site: Site) => setDeleteSite(site)

  const onCloseDrawer = () => setEditSite(undefined)
  const onCloseDialog = () => setDeleteSite(undefined)

  const onActionDelete = () => {
    if (deleteSite != null) {
      dispatch(deleteSiteAction(deleteSite))
      setDeleteSite(undefined)
    }
  }

  const columns = [
    {
      title: t('sites.table.columns.name'),
      field: 'name',
      // eslint-disable-next-line react/display-name
      render: ({ name }: Site) => <AvatarWithText text={name} />,
      style: { width: 'auto' },
    },
    {
      title: t('sites.table.columns.docks'),
      field: 'numDocks',
      style: { width: '120px' },
    },
    {
      title: t('sites.table.columns.tablets'),
      field: 'numTablets',
      style: { width: '120px' },
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (site: Site) => {
        return (
          <SitesRowActions
            site={site}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
          />
        )
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={sites} />
      <EditSiteDrawer
        site={editSite}
        open={editSite !== undefined}
        onClose={onCloseDrawer}
        updatedSite={updatedSite}
      />
      {deleteSite != null && (
        <ActionDialog
          open={deleteSite !== undefined}
          title={t('sites.deleteSite.title')}
          description={t('sites.deleteSite.description', {
            site: deleteSite.name,
          })}
          actionText={t('sites.deleteSite.action')}
          onAction={onActionDelete}
          onClose={onCloseDialog}
        />
      )}
    </>
  )
}

export default SitesTable

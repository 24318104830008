import { useEffect, useState } from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import { getCookieValue } from 'common/utils/get-cookie-value'
import axios from 'axios'
import { useAppSelector } from 'store'

const DEFAULT_LANGUAGE = 'en'

async function updateUserLanguageInDB(lng: string): Promise<void> {
  await axios
    .put('/api/v2/web/user/language', {
      languageCode: lng,
    })
    .catch((error) => {
      console.error(error)
    })
}

function getUserLanguage(): string {
  const locationQuery = new URLSearchParams(window.location.search)
  const queryLng = locationQuery.get('lng')
  const localStoreLng = localStorage.getItem('i18nextLng')
  const languageInCookie = getCookieValue('i18nextLng')

  let lng

  // language in URL is prioritized
  if (queryLng != null && queryLng.length > 0) {
    lng = queryLng
  } else if (localStoreLng != null && localStoreLng.length > 0) {
    lng = localStoreLng
  } else if (languageInCookie != null && languageInCookie.length > 0) {
    lng = languageInCookie
  } else {
    lng = DEFAULT_LANGUAGE
  }

  // update localStorage to be in sync
  if (localStoreLng !== lng) {
    localStorage.setItem('i18nextLng', lng)
  }

  return lng
}

export function useLanguage(): [string, (lng: string) => void] {
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE)
  const currentUser = useAppSelector((state) => state.app.currentUser)

  useEffect(() => {
    const lang = getUserLanguage()
    setLanguage(lang)
  }, [])

  function setLng(lng: string) {
    setLanguage(lng)
    i18n.changeLanguage(lng)
    localStorage.setItem('i18nextLng', lng)
    //  don't call this API when user is not logged in
    if (currentUser) {
      updateUserLanguageInDB(lng)
    }
  }

  return [language, setLng]
}

let loadPath = '/api/v2/web/main/strings/web/{{lng}}'
const i18nDebug = new URL(window.location.href).searchParams.get('i18nDebug')
if (i18nDebug !== null) {
  loadPath = '/api/v2/web/strings/web/{{lng}}'
}

i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: getUserLanguage(),
    fallbackLng: DEFAULT_LANGUAGE,
    keySeparator: '',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  })

export default i18n

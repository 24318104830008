import React, { useMemo, useState } from 'react'
import Panel from 'common/components/NewPanel'
import FormField from 'common/components/FormField'
import { Box, Divider, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from 'common/components/Button'
import JsonView from 'common/components/JsonView'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import ErrorBox from 'common/components/ErrorBox'
import useApiErrors from 'common/hooks/useApiErrors'
import { useTranslation } from 'react-i18next'
import { type Tablet } from 'App/Tablets/tablets-types'
import { useTestLoanTabletMutation } from 'App/Tablets/tablets-rtk-api'
import useProfiles from 'common/hooks/useProfiles'
import FormSelect from 'common/components/FormSelect'

const useStyles = makeStyles()((theme: Theme) => ({
  TabletPanelContent: {
    '& > hr': {
      marginTop: theme.spacing(3),
    },
    '& > .MuiFormControl-root:not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
}))

interface TabletTestProps {
  tablet: Tablet
}

const TabletTest = ({ tablet }: TabletTestProps) => {
  const { t } = useTranslation()
  const { classes: styles } = useStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [language, setLanguage] = useState('en')
  const [profileId, setProfileId] = useState(-1)

  const profiles = useProfiles()

  const profileOptions = useMemo(() => {
    const noProfileOption = { label: '-', value: -1 }
    const profileOptions = profiles.map((p) => ({
      label: p.name,
      value: p.id,
    }))
    return [noProfileOption, ...profileOptions]
  }, [profiles])

  const [
    testLoanTablet,
    {
      data: loanTabletRes,
      isSuccess: isLoanTabletSuccess,
      error: loanTabletError,
    },
  ] = useTestLoanTabletMutation()

  const { organizationId, siteId } = useCurrentAccount()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    loanTabletError,
  ])

  const handleSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    const data = {
      organizationId,
      siteId,
      tabletId: tablet.id,
      username,
      password,
      language,
      profileId: profileId === -1 ? null : profileId,
    }

    testLoanTablet(data)
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Panel
        title={t('tablets.viewTablet.test.loanTablet.title')}
        classes={{ content: styles.TabletPanelContent }}
      >
        <FormField
          label={t('tablets.viewTablet.test.username.label')}
          placeholder={t('tablets.viewTablet.test.username.placeholder')}
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          autoFocus
        />
        <FormField
          label={t('tablets.viewTablet.test.password.label')}
          placeholder={t('tablets.viewTablet.test.password.placeholder')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <FormField
          label={t('tablets.viewTablet.test.language.label')}
          placeholder={t('tablets.viewTablet.test.language.placeholder')}
          onChange={(e) => setLanguage(e.target.value)}
          value={language}
        />
        <FormSelect
          label={t('tablets.viewTablet.test.profile.label')}
          options={profileOptions}
          value={profileId}
          onChange={(_e, v) => setProfileId(v as number)}
        />
        <Divider light />
        <Button type="submit" small>
          {t('common.submit')}
        </Button>
        {hasApiMutationErrors && (
          <>
            <Divider light />
            <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>
          </>
        )}
        {isLoanTabletSuccess && (
          <>
            <Divider light />
            <Box sx={{ overflow: 'auto' }}>
              <JsonView object={loanTabletRes} />
            </Box>
          </>
        )}
      </Panel>
    </form>
  )
}

export default TabletTest

import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useRenderDate from 'common/hooks/useRenderDate'
import { renderRelativeDate } from 'common/utils/render-utils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
  DateWithRelativeTime: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  RelativeTime: {
    fontSize: '12px',
    color: theme.palette.secondary.main,
  },
}))

interface DateWithRelativeTimeProps {
  datetime: Date
  pastTimeText?: string // show this if `datetime` is is in the past
}

const DateWithRelativeTime = ({
  datetime,
  pastTimeText,
}: DateWithRelativeTimeProps) => {
  const { i18n } = useTranslation()
  const { classes } = useStyles()
  const renderDate = useRenderDate()

  const [now, setNow] = useState<Date>(new Date())

  useEffect(() => {
    // updates `now` every 1000ms
    const timeUpdater = setInterval(() => setNow(new Date()), 1000)
    return () => clearInterval(timeUpdater)
  }, [])

  return (
    <div className={classes.DateWithRelativeTime}>
      <div>{renderDate(datetime)}</div>
      <div className={classes.RelativeTime}>
        {renderRelativeDate(
          now,
          typeof datetime === 'string' ? new Date(datetime) : datetime,
          pastTimeText,
          i18n.language
        )}
      </div>
    </div>
  )
}

export default DateWithRelativeTime

import { type DockStockStatus } from 'App/Docks/docks-types'

export interface EmailUser {
  id: number
  email: string
  template: string
  sentAt?: string
}

export enum EmailTemplateFormat {
  HTML = 'HTML',
  TEXT = 'TEXT',
}

export interface EmailTemplate {
  id: number
  category: string
  name: string
  variant: string
  subject?: string
  format: EmailTemplateFormat
}

export interface AwsCognitoImportUser {
  id: number
  email: string
  createdAt: string
  lastLoginAt?: string
  preReleaseSent: boolean
  inviteSentAt?: string
  cognitoStatus?: string
}

export interface AwsCognitoPreReleaseEmailUser extends EmailUser {
  lastLoginAt?: string
  createdAt: string
  isRegistrationComplete: boolean
  hasDuplicateEmail: boolean
}

export interface AwsCognitoUpdatePasswordTipsEmailUser extends EmailUser {
  lastLoginAt?: string
  invitationSentAt?: string
  cognitoUserStatus?: string
}

export interface OrganizationDataForAdminReview {
  id: number
  name?: string
  timezone?: string
  isDistributor: boolean
  distributorId?: number
  distributorName?: string
  isManufacturer: boolean
  hubspotId?: string
  vatIdNumber?: string
  vatIdName?: string
  billingAddressStreet1?: string
  billingAddressStreet2?: string
  billingAddressCity?: string
  billingAddressRegion?: string
  billingAddressZipcode?: string
  billingAddressCountry?: string
  tags: string[]
  isReviewed: boolean
}

export interface SiteDataForAdminReview {
  id: number
  distributorName?: string
  organizationName?: string
  name?: string
  addressStreet1?: string
  addressStreet2?: string
  addressZipcode?: string
  addressCity?: string
  addressRegion?: string
  addressCountry?: string
  tzdbTimezone?: string
  customerSectorId?: number
  customerSectorName?: string
  tags: string[]
  isReviewed: boolean
}

export interface DockDataForAdminReview {
  id: number
  distributorName?: string
  organizationName?: string
  siteName?: string
  dockName?: string
  dockSerialNumber?: string
  dockStockStatus?: DockStockStatus
  tags: string[]
  isReviewed: boolean
}

export interface TabletDataForAdminReview {
  id: number
  tabletSerialNumber: string
  distributorName?: string
  organizationName?: string
  siteName?: string
  tabletActiveAt?: string
  tabletMacAddress?: string
  tabletOsVersion?: string
  tabletAppVersion?: string
  tabletLocation?: string
  tabletStockStatus?: any
  tags: string[]
  isReviewed: boolean
}

export interface Product {
  id: number
  sku?: string
  name?: string
  description?: string
  publicUrl?: string
  endOfLifeDate?: string | null
  category?: string
  businessModel?: string
  isActive?: boolean
}

export enum ProductCategory {
  HubletAddOnFeature = 'Hublet Add-On Feature',
  HubletDeliveryProduct = 'Hublet Delivery Product',
  HubletAddOnProduct = 'Hublet Add-On Product',
  HubletProfessionalServiceProduct = 'Hublet Professional Service Product',
  HubletSolutionTermLicense = 'Hublet Solution Term License',
  HubletSpareParts = 'Hublet Spare Parts',
  HubletSWLicense = 'Hublet SW License',
  PartnerAddOnHWProduct = 'Partner Add-On HW product',
  PartnerAddOnSWProduct = 'Partner Add-On SW product',
}

export enum BusinessModel {
  AnnualRecurringRevenue = 'Annual Recurring Revenue',
  NonRecurring = 'Non-recurring',
}

import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from 'store'
import 'locales'
import { BrowserRouter as Router } from 'react-router-dom'
import LocalizedAppWithThemeProvider from 'LocalizedAppWithThemeProvider'
import { Box, CircularProgress } from '@mui/material'
import createCache from '@emotion/cache'
import { TssCacheProvider } from 'tss-react'

const tssCache = createCache({
  key: 'tss',
})

const Index = () => (
  <Router basename="/ui">
    <ReduxProvider store={store}>
      <TssCacheProvider value={tssCache}>
        <Suspense
          fallback={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          <LocalizedAppWithThemeProvider />
        </Suspense>
      </TssCacheProvider>
    </ReduxProvider>
  </Router>
)

const container = document.getElementById('root')

if (container === null) {
  console.error('App container `#root` not found.')
} else {
  const root = createRoot(container)
  root.render(<Index />)
}

import React, { useMemo, useState } from 'react'

import FormField from 'common/components/FormField'

import { type CodeEditable, PermissionType } from 'App/Codes/codes-types'
import { type Profile } from 'App/Profiles/profiles-types'
import { useTranslation } from 'react-i18next'
import FormSelect from 'common/components/FormSelect'
import Button from 'common/components/Button'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { toDateFieldValue } from 'common/utils/date-utils'
import ImportCodesDialog from 'App/Codes/components/ImportCodesDialog'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  AddCodeDrawerFields: {
    '& > *': {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}))

interface AddCodeFormProps {
  profiles: Profile[]
  onClose: () => void
}

interface FieldErrors {
  name?: string
  expiration?: string
  profileId?: string
}

const initialCodeForm: CodeEditable = {
  name: '',
  permissionType: PermissionType.Single,
  expiration: (() => {
    // Set "tomorrow" as the default expiration date
    const d = new Date()
    d.setDate(d.getDate() + 1)
    return d
  })(),
}

const AddMultiCodesForm = ({ profiles, onClose }: AddCodeFormProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [codeForm, setCodeForm] = useState<CodeEditable>(initialCodeForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  const codes = useAppSelector((state) => state.codes.codes)
  const groupNames = Array.from(
    new Set(codes.map((c) => c.groupName).filter(Boolean))
  )

  const onChangeField =
    (key: string | number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (key === 'name' && codeForm.name !== '') {
        setFieldErrors({ ...fieldErrors, name: undefined })
      }

      setCodeForm({ ...codeForm, [key]: e.target.value })
    }

  const onChangePermissionType = (value: string) => {
    let permissionType

    switch (value) {
      case 'single':
        permissionType = PermissionType.Single
        break
      case 'multi':
        permissionType = PermissionType.Multi
        break
      case 'reusable':
        permissionType = PermissionType.Reusable
        break
      default:
        permissionType = undefined
    }

    setCodeForm({ ...codeForm, permissionType })
  }

  const onChangeExpiration = (value: string) => {
    let expiration: Date | undefined = new Date(value)

    // If `value` is invalid datetime string, `expiration.getTime()` will return `NaN`
    if (isNaN(expiration.getTime())) {
      expiration = initialCodeForm.expiration
    }

    if (expiration !== undefined && expiration.getTime() > Date.now()) {
      setFieldErrors({ ...fieldErrors, expiration: undefined })
    }

    setCodeForm({ ...codeForm, expiration })
  }

  const onSelectProfile = (profileId: number) => {
    setFieldErrors({ ...fieldErrors, profileId: undefined })
    setCodeForm({ ...codeForm, profileId })
  }

  const [openDialog, setOpenDialog] = useState(false)

  const handleOpenDialog = () => {
    let hasError = false
    const errors: FieldErrors = {}

    if (codeForm.name === '') {
      hasError = true
      errors.name = t('codes.addCode.errors.emptyField')
    }

    if (groupNames.includes(codeForm.name)) {
      hasError = true
      errors.name = t('codes.addCode.errors.duplicateGroupName')
    }

    if (
      codeForm.expiration === undefined ||
      codeForm.expiration.getTime() < Date.now()
    ) {
      hasError = true
      errors.expiration = t('codes.addCode.errors.invalidField')
    }

    if (codeForm.profileId === undefined) {
      hasError = true
      errors.profileId = t('codes.addCode.errors.neglectedSelect')
    }

    if (!hasError) {
      setOpenDialog(true)
    }

    setFieldErrors(errors)
  }

  const profileOptions = useMemo(() => {
    return profiles.map((p) => ({ label: p.name, value: p.id }))
  }, [profiles])

  const handleCloseDrawer = () => {
    onClose()
    setOpenDialog(false)
  }

  return (
    <div className={classes.AddCodeDrawerFields}>
      <FormField
        label={t('codes.addCode.groupNameField.label')}
        placeholder={t('codes.addCode.groupNameField.placeholder')}
        value={codeForm.name}
        onChange={onChangeField('name')}
        error={fieldErrors.name}
        autoFocus
      />
      <FormField
        type="date"
        label={t('codes.addCode.expirationField.label')}
        value={toDateFieldValue(codeForm.expiration)}
        onChange={(e) => onChangeExpiration(e.target.value)}
        error={fieldErrors.expiration}
      />
      <FormSelect
        label={t('codes.addCode.profileField.label')}
        value={codeForm.profileId}
        onChange={(_e, v) => onSelectProfile(v as number)}
        options={profileOptions}
        placeholder={t('codes.addCode.profileField.placeholder')}
        error={fieldErrors.profileId}
      />
      <FormSelect
        label={t('codes.addCode.permissionTypeField.label')}
        value={codeForm.permissionType}
        onChange={(e) => onChangePermissionType(e.target.value)}
        options={[
          {
            label: t('codes.addCode.permissionTypeField.options.single'),
            value: PermissionType.Single,
          },
          {
            label: t('codes.addCode.permissionTypeField.options.multi'),
            value: PermissionType.Multi,
          },
          {
            label: t('codes.addCode.permissionTypeField.options.reusable'),
            value: PermissionType.Reusable,
          },
        ]}
      />
      <Grid container>
        <Grid item xs={6}>
          <Button small outlined onClick={onClose}>
            {t('customers.addCustomer.buttons.cancel')}
          </Button>
        </Grid>
        <Grid style={{ textAlign: 'right' }} item xs={6}>
          <Button small onClick={handleOpenDialog}>
            {t('codes.addMultiCodes.buttons.import')}
          </Button>
        </Grid>
      </Grid>

      <ImportCodesDialog
        open={openDialog}
        onClose={handleCloseDrawer}
        codeForm={codeForm}
      />
    </div>
  )
}

export default AddMultiCodesForm

import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { fetchSiteEnrolled } from 'App/Tablets/tablets-state'

const useSiteEnrolled = (fetch = true): boolean => {
  const dispatch = useAppDispatch()

  const { siteId, organizationId } = useCurrentAccount()
  const siteEnrolled = useAppSelector((state) => state.tablets.siteEnrolled)

  useEffect(() => {
    if (fetch) {
      dispatch(fetchSiteEnrolled({ siteId, organizationId }))
    }
  }, [fetch, dispatch, siteId, organizationId])

  return siteEnrolled
}

export default useSiteEnrolled

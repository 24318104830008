import { FormControlLabel } from '@mui/material'
import Checkbox from 'common/components/Checkbox'
import React from 'react'

interface FormCheckboxProps {
  label: string | React.ReactNode
  checked: boolean
  onToggle: (checked: boolean) => void
}

const FormCheckbox = ({ label, checked, onToggle }: FormCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onToggle(e.target.checked)}
        />
      }
      label={label}
    />
  )
}

export default FormCheckbox

import React, { useEffect, useState } from 'react'

import FormField from 'common/components/FormField'
import Panel from 'common/components/Panel'
import Switch from 'common/components/Switch'

import { type DistributorForm } from 'App/Distributors/distributors-types'
import { FormControlLabel, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  addDistributor,
  fetchDistributors,
  resetAddDistributor,
} from 'App/Distributors/distributors-state'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import { useAppDispatch } from 'store'
import FormSelect from 'common/components/FormSelect'
import { useCountryOptions } from 'common/countries'

interface AddDistributorDrawerProps {
  open: boolean
  onClose: () => void
  addedDistributor: boolean
}

interface FieldErrors {
  name?: string
  billingAddressCountry?: string
}

const initialDistributorForm = {
  name: '',
  isDistributor: true,
  isManufacturer: false,
}

const useStyles = makeStyles()((theme: Theme) => ({
  ManufacturerFlag: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  ManufacturerFlagDescription: {
    marginBottom: theme.spacing(2),
  },
}))

const AddDistributorDrawer = ({
  open,
  onClose,
  addedDistributor,
}: AddDistributorDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { classes } = useStyles()

  const countryOptions = useCountryOptions()

  const [distributorForm, setDistributorForm] = useState<DistributorForm>(
    initialDistributorForm
  )
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  useEffect(() => {
    if (addedDistributor) {
      onClose()
      setDistributorForm(initialDistributorForm)
      dispatch(resetAddDistributor())
      dispatch(fetchDistributors())
    }
  }, [dispatch, onClose, addedDistributor])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setDistributorForm({ ...distributorForm, [key]: e.target.value })
    }

  const onChangeSwitch =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setDistributorForm({ ...distributorForm, [key]: e.target.checked })
    }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    let hasError = false
    const errors: FieldErrors = {}

    if (distributorForm.name === '') {
      hasError = true
      errors.name = t('distributors.addDistributor.errors.emptyField')
    }

    if (
      distributorForm.billingAddressCountry == null ||
      distributorForm.billingAddressCountry === ''
    ) {
      hasError = true
      errors.billingAddressCountry = t('common.errors.emptyField')
    }

    if (!hasError) {
      dispatch(addDistributor(distributorForm))
    }

    setFieldErrors(errors)
  }

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      title={t('distributors.addDistributor.title')}
      submitText={t('distributors.addDistributor.buttons.submit')}
      autoMargins
    >
      <FormField
        label={t('distributors.addDistributor.distributorNameField.label')}
        placeholder={t(
          'distributors.addDistributor.distributorNameField.placeholder'
        )}
        value={distributorForm.name}
        onChange={onChangeField('name')}
        autoFocus
        error={fieldErrors.name}
      />
      <FormSelect
        label={t(
          'distributors.addDistributor.billingAddressCountryField.label'
        )}
        placeholder={t(
          'distributors.addDistributor.billingAddressCountryField.placeholder'
        )}
        options={countryOptions}
        value={distributorForm.billingAddressCountry}
        onChange={onChangeField('billingAddressCountry')}
        error={fieldErrors.billingAddressCountry}
      />
      <div className={classes.ManufacturerFlag}>
        <Panel title={t('distributors.manufacturerFlag.title')}>
          <div className={classes.ManufacturerFlagDescription}>
            {t('distributors.manufacturerFlag.description')}
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={distributorForm.isManufacturer}
                onChange={onChangeSwitch('isManufacturer')}
              />
            }
            label={t('distributors.manufacturerFlag.enable')}
          />
        </Panel>
      </div>
    </DrawerForm>
  )
}

export default AddDistributorDrawer

import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import { useTranslation } from 'react-i18next'
import PageActions from 'common/components/PageActions'
import { Button } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store'
import CustomDrawer from 'common/components/Drawer2'
import CustomTabs from 'common/components/Tabs2'
import AddSingleCodeForm from './AddSingleCodeForm'
import ImportCodesForm from './ImportCodesForm'
import { CodesTable } from 'App/Codes'
import { fetchCodes } from 'App/Codes/codes-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import PageRequirement from 'common/components/PageRequirement'
import useProfiles from 'common/hooks/useProfiles'

const Codes = () => {
  const [isOpenedDrawer, setIsOpenedDrawer] = useState(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { organizationId, siteId } = useCurrentAccount()
  const profiles = useProfiles()
  const codes = useAppSelector((state) => state.codes.codes)

  useEffect(() => {
    dispatch(fetchCodes({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  const openDrawer = () => setIsOpenedDrawer(true)
  const closeDrawer = () => setIsOpenedDrawer(false)

  return (
    <Page>
      <PageRequirement
        criteria={profiles.length > 0}
        title={t('codes.requirement.title')}
        description={t('codes.requirement.description')}
        actionText={t('codes.requirement.action')}
        actionTo="/profiles"
      >
        <PageHeader>
          <PageTitle title={t('codes.title')} />
          <PageActions>
            <Button onClick={openDrawer} variant="contained">
              {t('codes.actions.addCode')}
            </Button>
          </PageActions>
        </PageHeader>
        <PageBody>
          <CodesTable codes={codes} />

          <CustomDrawer
            open={isOpenedDrawer}
            onClose={closeDrawer}
            title={t('codes.addCode.title')}
            width={540}
          >
            <CustomTabs
              tabs={[
                {
                  label: t('codes.addCode.title'),
                  component: <AddSingleCodeForm />,
                },
                {
                  label: t('codes.addMultiCodes.title'),
                  component: <ImportCodesForm />,
                },
              ]}
            />
          </CustomDrawer>

          {/* Snackbar (we only need one) */}

          {/* Dialog */}
        </PageBody>
      </PageRequirement>
    </Page>
  )
}

export default Codes

import React, { type PropsWithChildren, useEffect, useState } from 'react'
import { Divider, Grid, Paper, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from 'common/components/Button'
import LineToast from 'common/components/LineToast'
import ErrorBox from 'common/components/ErrorBox'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
  Panel: {
    color: '#000',
    fontSize: '16px',
    marginTop: theme.spacing(4),
    '&:first-child': {
      marginTop: 0,
    },
  },
  PanelHeader: {
    padding: theme.spacing(3),
    fontSize: '18px',
    fontWeight: 'bold',
  },
  PanelError: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  PanelBodyWithPadding: {
    padding: theme.spacing(3),
    '&> hr': {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
  },
  PanelBodyEditMode: {
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
  PanelFooter: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  ExtraAction: {
    flexGrow: 1,
    textAlign: 'right',
  },
  PagePanel: {
    border: 0,
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
  },
  PagePanelBody: {
    '& > hr': {
      marginBottom: theme.spacing(1),
    },
  },
}))

export enum EditState {
  Default,
  Edit,
  Pending,
  Success,
  Error,
}

interface PanelProps extends PropsWithChildren {
  title?: React.ReactNode
  classes?: {
    container?: string
    title?: string
    content?: string
  }
  editable?: boolean
  editState?: EditState
  onEdit?: () => void
  onCancel?: () => void
  onSave?: () => void
  onSuccess?: () => void
  renderEditMode?: () => React.ReactNode
  error?: string
  extraAction?: React.ReactNode
  topRight?: React.ReactNode
  variant?: 'default' | 'table' | 'page'
}

const Panel = ({
  title,
  children,
  classes,
  editable = false,
  editState = EditState.Default,
  onEdit,
  onCancel,
  onSave,
  onSuccess,
  renderEditMode,
  error,
  extraAction,
  topRight,
  variant = 'default',
}: PanelProps) => {
  const { t } = useTranslation()
  const { classes: styles, cx } = useStyles()
  const [toasts, setToasts] = useState<string[]>([])

  const savedToast = t('common.panel.toasts.saved')

  useEffect(() => {
    if (editState === EditState.Success) {
      setToasts((stateToasts) => [...stateToasts, savedToast])
    }
  }, [editState, savedToast])

  useEffect(() => {
    if (editState === EditState.Success && onSuccess !== undefined) {
      onSuccess()
    }
  }, [editState, onSuccess])

  // When to show the editable
  const editMode =
    editState === EditState.Edit ||
    editState === EditState.Pending ||
    editState === EditState.Error

  const containerClasses = [
    styles.Panel,
    classes?.container ?? '',
    variant === 'page' ? styles.PagePanel : '',
  ]
  const titleClasses = [styles.PanelHeader, classes?.title ?? '']
  const contentClasses = [
    variant === 'default' || variant === 'page'
      ? styles.PanelBodyWithPadding
      : '',
    variant === 'page' ? styles.PagePanelBody : '',
    editMode ? styles.PanelBodyEditMode : '',
    classes?.content ?? '',
  ]

  return (
    <Paper className={cx(containerClasses)} variant="outlined">
      {title && (
        <>
          <Grid className={cx(titleClasses)}>
            <Grid container style={{ alignItems: 'center' }}>
              <Grid item style={{ flexGrow: 1 }}>
                {title}
                {editable && (
                  <LineToast messages={toasts} onClose={setToasts} />
                )}
              </Grid>
              <Grid item>
                <Grid container columnSpacing={2} alignItems="center">
                  {topRight != null && <Grid item>{topRight}</Grid>}
                  {editable && (
                    <Grid item>
                      {editMode && (
                        <Button small textOnly onClick={onCancel}>
                          {t('common.panel.actions.cancel')}
                        </Button>
                      )}
                      {!editMode && (
                        <Button small textOnly onClick={onEdit}>
                          {variant === 'table'
                            ? t('common.panel.actions.add')
                            : t('common.panel.actions.edit')}
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider light />
        </>
      )}
      {editable && editState === EditState.Error && (
        <div className={styles.PanelError}>
          <ErrorBox>{error ?? t('common.panel.error')}</ErrorBox>
        </div>
      )}
      <Grid className={cx(contentClasses)}>
        {editable && editMode
          ? renderEditMode === undefined
            ? null
            : variant === 'default' || variant === 'page'
              ? renderEditMode()
              : [renderEditMode(), children]
          : children}
      </Grid>
      {editable &&
        editMode &&
        (variant === 'default' || variant === 'page') && (
          <>
            <Divider light />
            <Grid className={styles.PanelFooter}>
              <Grid container>
                <Grid item>
                  <Button
                    disabled={editState === EditState.Pending}
                    small
                    onClick={onSave}
                  >
                    {t('common.panel.actions.save')}
                  </Button>
                </Grid>
                <Grid item className={styles.ExtraAction}>
                  {extraAction}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
    </Paper>
  )
}

export default Panel

import React, { type PropsWithChildren } from 'react'
import { type ButtonBaseProps, type Theme, ButtonBase } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles()((theme: Theme) => ({
  Button: {
    width: '100%',
    minWidth: '100px',
    padding: '16px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '30px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  ButtonSmall: {
    width: 'auto',
    minWidth: 0,
    padding: '9px 23px',
    fontSize: '14px',
  },
  ButtonExtraSmall: {
    width: 'auto',
    minWidth: 0,
    padding: '8px 12px',
    fontSize: '12px',
  },
  ButtonWide: {
    width: 'auto',
    padding: theme.spacing(2, 7),
  },
  ButtonOutlined: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  ButtonFlat: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  ButtonNegative: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  ButtonFlatNegative: {
    backgroundColor: 'transparent',
    color: theme.palette.error.main,
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  ButtonDisabled: {
    backgroundColor: '#DCD0DE',
    borderColor: '#DCD0DE',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#DCD0DE',
    },
  },
  ButtonTextOnly: {
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

export interface ButtonProps extends PropsWithChildren<ButtonBaseProps> {
  small?: boolean
  extraSmall?: boolean
  outlined?: boolean
  flat?: boolean
  negative?: boolean
  disabled?: boolean
  textOnly?: boolean
  to?: string
  externalTo?: string // `to` uses react router while `external` uses `<a />`
  download?: string | boolean
  wide?: boolean
}

const Button = ({
  children,
  className,
  type,
  onClick,
  small = false,
  extraSmall = false,
  outlined = false,
  flat = false,
  negative = false,
  disabled = false,
  textOnly = false,
  to,
  externalTo,
  download,
  wide = false,
  ...buttonProps
}: ButtonProps) => {
  const { classes, cx } = useStyles()
  const buttonClassNames = [classes.Button]

  const getComponent = () => {
    if (externalTo !== undefined) {
      return 'a'
    } else if (to !== undefined) {
      return RouterLink
    } else {
      return 'button'
    }
  }

  if (small) {
    buttonClassNames.push(classes.ButtonSmall)
  }

  if (extraSmall) {
    buttonClassNames.push(classes.ButtonExtraSmall)
  }

  if (disabled) {
    buttonClassNames.push(classes.ButtonDisabled)
  }

  if (outlined) {
    buttonClassNames.push(classes.ButtonOutlined)
  }

  if (flat && !negative) {
    buttonClassNames.push(classes.ButtonFlat)
  }

  if (negative && !flat) {
    buttonClassNames.push(classes.ButtonNegative)
  }

  if (flat && negative) {
    buttonClassNames.push(classes.ButtonFlatNegative)
  }

  if (textOnly) {
    buttonClassNames.push(classes.ButtonTextOnly)
  }

  if (wide) {
    buttonClassNames.push(classes.ButtonWide)
  }

  if (className) {
    buttonClassNames.push(className)
  }

  return (
    <ButtonBase
      type={type}
      onClick={onClick}
      className={cx(buttonClassNames)}
      component={getComponent()}
      to={to}
      href={externalTo}
      download={download}
      disabled={disabled}
      disableRipple={textOnly}
      {...buttonProps}
    >
      {children}
    </ButtonBase>
  )
}

export default Button

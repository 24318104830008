import React from 'react'
import {
  Autocomplete,
  Chip,
  FormControl,
  FormLabel,
  TextField,
  type Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import rtkApi from 'rtk-api'
import useApp from 'common/hooks/useApp'

const adminDataTagsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getDistinctDataTags: builder.query<string[], string>({
      query: (dataType: string) => `/admin/data-tags/${dataType}/distinct`,
      providesTags: ['DataTag'],
    }),
  }),
})

const { useGetDistinctDataTagsQuery } = adminDataTagsRtkApi

const useStyles = makeStyles()((theme: Theme) => ({
  FormLabel: {
    marginBottom: theme.spacing(2),
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
  },
  FormField: {
    width: '100%',
    padding: 0,
    backgroundColor: '#fff',
    border: `1px solid #e0e0e0`,
    borderRadius: '5px',
    fontSize: '16px',
    color: '#000',
    '& > .MuiInputBase-root': {
      padding: '10px 26px',
      height: 'auto',
    },
    '& fieldset': {
      border: 0,
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.light,
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },
  FormFieldDense: {
    '& > .MuiInputBase-root': {
      padding: '8px 13px',
      height: 'auto',
    },
  },
  FormFieldLabelDense: {
    marginBottom: theme.spacing(1),
  },
}))

interface DataTagsFieldProps {
  dataType: string
  tags: string[]
  onChange: (tags: string[]) => void
  disabled?: boolean
  dense?: boolean
}

const DataTagsField = ({
  dataType,
  tags,
  onChange,
  disabled,
  dense,
}: DataTagsFieldProps) => {
  const { classes, cx } = useStyles()
  const { adminMode } = useApp()
  const { data: dataTags } = useGetDistinctDataTagsQuery(dataType, {
    skip: !adminMode,
  })

  const formFieldClassNames = [classes.FormField]
  const formFieldLabelClassNames = [classes.FormLabel]

  if (dense) {
    formFieldClassNames.push(classes.FormFieldDense)
    formFieldLabelClassNames.push(classes.FormFieldLabelDense)
  }

  if (!adminMode) {
    return null
  }

  return (
    <FormControl>
      <FormLabel className={cx(formFieldLabelClassNames)}>Tags</FormLabel>
      <Autocomplete
        multiple
        id="tags-filled"
        options={dataTags ?? []}
        defaultValue={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Add tag"
            className={cx(formFieldClassNames)}
          />
        )}
        onChange={(_e, value) => {
          // Only allow lowercase letters, and underscores in-between
          const regex = /^[a-z]+(_[a-z]+)*$/
          const processedValue = value.map((tag) => tag.trim().toLowerCase())
          const filteredValue = processedValue.filter((tag) => regex.test(tag))
          onChange(filteredValue)
        }}
        value={tags}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default DataTagsField

import { timezones } from 'common/timezones'
import { useAppSelector } from 'store'

const useUserTimeZone = () => {
  let orgTimezone = useAppSelector((state) => state.app.organization?.timezone)
  orgTimezone =
    orgTimezone != null && timezones.includes(orgTimezone)
      ? orgTimezone
      : undefined

  let siteTimezone = useAppSelector((state) => state.app.site?.timezone)
  siteTimezone =
    siteTimezone != null && timezones.includes(siteTimezone)
      ? siteTimezone
      : undefined

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return siteTimezone ?? orgTimezone ?? localTimeZone
}

export default useUserTimeZone

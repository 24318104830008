import React, { useEffect, useMemo } from 'react'
import { Grid, Popover, styled, type Theme, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  type CurrentUser,
  fetchCustomers,
  fetchDistributors,
  fetchSites,
  setOrganizationDistributorId,
  setOrganization,
  setSite,
  setRole,
  UserRole,
  type ContextEntity,
  setAccountSelectActivePage,
  setAccountSelectActiveDistributor,
  setAccountSelectActiveCustomer,
  setAccountSelectSearchQuery,
  setIsAccountSelectDone,
} from 'App/app-state'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { useTranslation } from 'react-i18next'
import Button from 'common/components/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import useApp from 'common/hooks/useApp'
import useCurrentUser from 'common/hooks/useCurrentUser'
import Avatar from 'common/components/Avatar'

import SplitIcon from '@mui/icons-material/CallSplitOutlined'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectoryOutlined'
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined'
import { roleShouldHaveAccess } from 'common/utils/roles'
import useSearch, { type UseSearchOptions } from 'common/hooks/useSearch'
import FormField from 'common/components/FormField'
import SearchIcon from '@mui/icons-material/SearchTwoTone'
import { type Site } from 'App/Sites/sites-state'
import { type Distributor } from 'App/Distributors/distributors-types'
import { type Customer } from 'App/Customers/customers-types'
import { useAppDispatch, useAppSelector } from 'store'

const AccountSelectWrapper = styled(Grid)(({ theme }) => ({
  minWidth: '200px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#fff',
}))

const useStyles = makeStyles()((theme: Theme) => ({
  SelectedOrganization: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '21px',
  },
  SelectedSite: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: theme.palette.warning.main,
    lineHeight: '16px',
  },
  AccountSelectPopup: {
    minWidth: '400px',
    marginTop: theme.spacing(2),
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    background: '#fff',
  },
  AccountSelectHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid #e0e0e0`,
    display: 'flex',
    alignItems: 'center',
  },
  AccountSelectHeaderTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'center',
    flexGrow: 1,
  },
  AccountSelectList: {},
  AccountSelectListItem: {
    padding: theme.spacing(2),
    borderBottom: `1px solid #e0e0e0`,

    display: 'flex',
    alignItems: 'center',

    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#F8F8FB',
    },
  },
  AccountSelectListItemText: {
    flexGrow: 1,
  },
  AccountSelectListItemIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    fontSize: '20px',
  },
  AccountAvatar: {
    background: '#fff',
  },
  BackButton: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  IconButton: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
  },
  AccountInfo: {
    marginRight: '12px',
  },
  SearchField: {
    maxWidth: 300,
    height: 39,
    verticalAlign: 'middle',
    '& .MuiInput-root': {
      height: '100%',
      borderColor: theme.palette.primary.light,
    },
    '& .MuiInputAdornment-root': {
      color: theme.palette.primary.main,
    },
  },
  SearchFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(1)} 0`,
    borderBottom: `1px solid #e0e0e0`,
  },
}))

export enum AccountSelectPage {
  Distributors = 0,
  Customers = 1,
  Sites = 2,
}

interface SearchFieldProps {
  query: string
  onChange: (query: string) => void
}

const SearchField = ({ query, onChange }: SearchFieldProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <div className={classes.SearchFieldContainer}>
      <FormField
        className={classes.SearchField}
        placeholder={t('tableActionsBar.search')}
        value={query}
        onChange={(e) => onChange(e.target.value)}
        endIcon={<SearchIcon />}
        rounded
        dense
        autoFocus
      />
    </div>
  )
}

interface Props {
  user: CurrentUser
}

const AccountSelect = ({ user }: Props) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isHubletAdmin || isDistributorAdmin || isOrganizationAdmin) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { role } = useCurrentUser()
  const isHubletAdmin = role === UserRole.HubletAdmin
  const isDistributorAdmin = role === UserRole.DistributorAdmin
  const isOrganizationAdmin = role === UserRole.OrgAdmin
  const isSiteAdmin = role === UserRole.SiteAdmin

  const activePage = useAppSelector(
    (state) => state.app.accountSelectActivePage
  )
  const activeDistributor = useAppSelector(
    (state) => state.app.accountSelectActiveDistributor
  )
  const activeCustomer = useAppSelector(
    (state) => state.app.accountSelectActiveCustomer
  )
  const isAccountSelectDone = useAppSelector(
    (state) => state.app.isAccountSelectDone
  )

  useEffect(() => {
    if (isAccountSelectDone) {
      // using `window.location.href` forces a page refresh for a clean app state
      window.location.href = '/ui/dashboard'
    }
  }, [isAccountSelectDone])

  useEffect(() => {
    if (activePage !== undefined) return
    dispatch(
      setAccountSelectActiveDistributor(
        isDistributorAdmin
          ? {
              id: user.organizationId,
              name: user.orgName,
              timezone: user.orgTimezone,
            }
          : undefined
      )
    )
    dispatch(
      setAccountSelectActiveCustomer(
        isSiteAdmin || isOrganizationAdmin
          ? {
              id: user.organizationId,
              name: user.orgName,
              timezone: user.orgTimezone,
            }
          : undefined
      )
    )

    dispatch(
      setAccountSelectActivePage(
        isHubletAdmin
          ? AccountSelectPage.Distributors
          : isDistributorAdmin
            ? AccountSelectPage.Customers
            : AccountSelectPage.Sites
      )
    )
  }, [
    dispatch,
    activePage,
    isHubletAdmin,
    isDistributorAdmin,
    isOrganizationAdmin,
    isSiteAdmin,
    user,
  ])

  const searchQuery = useAppSelector(
    (state) => state.app.accountSelectSearchQuery
  )

  const {
    organization: selectedOrganization,
    site: selectedSite,
    role: activeRole,
  } = useApp()

  const distributors = useAppSelector((state) => state.app.distributors)
  useEffect(() => {
    if (isHubletAdmin && roleShouldHaveAccess(role, 'distributors')) {
      dispatch(fetchDistributors())
    }
  }, [dispatch, isHubletAdmin, role])

  const customers = useAppSelector((state) => state.app.customers)
  useEffect(() => {
    if (activeDistributor?.id && roleShouldHaveAccess(role, 'customers')) {
      dispatch(fetchCustomers(activeDistributor.id))
    }
  }, [dispatch, activeDistributor, role])

  const sites = useAppSelector((state) => state.app.sites)
  useEffect(() => {
    if (activeCustomer?.id && roleShouldHaveAccess(role, 'sites')) {
      dispatch(fetchSites(activeCustomer.id))
    }
  }, [dispatch, activeCustomer, role])

  const handleDistributorSelection = (distributor: ContextEntity) => {
    dispatch(setOrganizationDistributorId(undefined))
    dispatch(setOrganization(distributor))
    dispatch(setSite(undefined))

    dispatch(setRole(UserRole.DistributorAdmin))
    dispatch(setIsAccountSelectDone(true))

    handleClose()
    window.location.href = '/ui/dashboard'
  }

  const handleOrganizationSelection = (organization: ContextEntity) => {
    dispatch(setOrganizationDistributorId(activeDistributor?.id))
    dispatch(setOrganization(organization))
    dispatch(setSite(undefined))

    dispatch(setRole(UserRole.OrgAdmin))
    dispatch(setIsAccountSelectDone(true))

    handleClose()
    window.location.href = '/ui/dashboard'
  }

  const handleSiteSelection = (site: ContextEntity) => {
    dispatch(setOrganizationDistributorId(activeDistributor?.id))
    dispatch(setOrganization(activeCustomer))
    dispatch(setSite(site))

    dispatch(setRole(UserRole.SiteAdmin))
    dispatch(setIsAccountSelectDone(true))

    handleClose()
    window.location.href = '/ui/dashboard'
  }

  const handleChangeActivePage = (newActivePage: AccountSelectPage) => {
    dispatch(setAccountSelectActivePage(newActivePage))
    dispatch(setAccountSelectSearchQuery('')) // Reset search query after changing active page
  }

  const distributorSearchOptions: UseSearchOptions<Distributor> = useMemo(
    () => ({
      data: distributors,
      fields: ['name'],
      query: searchQuery,
    }),
    [distributors, searchQuery]
  )

  const distributorsFilteredBySearchQuery = useSearch(distributorSearchOptions)

  const distributorsList = distributorsFilteredBySearchQuery.map(
    (distributor) => (
      <Grid
        key={distributor.id}
        className={classes.AccountSelectListItem}
        onClick={() => {
          dispatch(
            setAccountSelectActiveDistributor({
              id: distributor.id,
              name: distributor.name,
              timezone: distributor.timezone,
            })
          )
          handleChangeActivePage(AccountSelectPage.Customers)
        }}
      >
        <span title={t('accountSelect.icons.distributor')}>
          <SplitIcon className={classes.AccountSelectListItemIcon} />
        </span>
        <Typography className={classes.AccountSelectListItemText}>
          {distributor.name}
        </Typography>
        <ArrowRightIcon className={classes.IconButton} />
      </Grid>
    )
  )

  const customerSearchOptions: UseSearchOptions<Customer> = useMemo(
    () => ({
      data: customers,
      fields: ['name'],
      query: searchQuery,
    }),
    [customers, searchQuery]
  )

  const customersFilteredBySearchQuery = useSearch(customerSearchOptions)

  const customersList = customersFilteredBySearchQuery.map((customer) => (
    <Grid
      key={customer.id}
      className={classes.AccountSelectListItem}
      onClick={() => {
        dispatch(
          setAccountSelectActiveCustomer({
            id: customer.id,
            name: customer.name,
            timezone: customer.timezone,
          })
        )
        handleChangeActivePage(AccountSelectPage.Sites)
      }}
    >
      <span title={t('accountSelect.icons.customer')}>
        <StoreMallDirectoryIcon className={classes.AccountSelectListItemIcon} />
      </span>
      <Typography className={classes.AccountSelectListItemText}>
        {customer.name}
      </Typography>
      <ArrowRightIcon className={classes.IconButton} />
    </Grid>
  ))

  const handleSelectOrg = () => {
    const payload = {
      id: activeCustomer?.id ?? 0,
      name: activeCustomer?.name ?? '',
      timezone: activeCustomer?.timezone,
    }
    if (activeCustomer?.id === activeDistributor?.id) {
      handleDistributorSelection(payload)
    } else {
      handleOrganizationSelection(payload)
    }
  }

  const orgSelector = (
    <>
      {(isHubletAdmin || isDistributorAdmin || isOrganizationAdmin) && (
        <Grid
          key={activeCustomer?.id}
          className={classes.AccountSelectListItem}
          onClick={handleSelectOrg}
        >
          <span title={t('accountSelect.icons.customer')}>
            <StoreMallDirectoryIcon
              className={classes.AccountSelectListItemIcon}
            />
          </span>
          <Typography className={classes.AccountSelectListItemText}>
            {activeCustomer?.name}
          </Typography>
          {activeRole !== UserRole.SiteAdmin &&
          selectedOrganization?.id === activeCustomer?.id ? (
            t('accountSelect.loggedIn')
          ) : (
            <Button extraSmall>{t('accountSelect.login')}</Button>
          )}
        </Grid>
      )}
    </>
  )

  const siteSearchOptions: UseSearchOptions<Site> = useMemo(
    () => ({
      data: sites,
      fields: ['name'],
      query: searchQuery,
    }),
    [sites, searchQuery]
  )

  const sitesFilteredBySearchQuery = useSearch(siteSearchOptions)

  const sitesList =
    sitesFilteredBySearchQuery.length > 0 ? (
      sitesFilteredBySearchQuery.map((site) => (
        <Grid
          key={site.id}
          className={classes.AccountSelectListItem}
          onClick={() =>
            handleSiteSelection({
              id: site.id,
              name: site.name,
              timezone: site.tzdbTimezone,
            })
          }
        >
          <span title={t('accountSelect.icons.site')}>
            <LocationOnIcon className={classes.AccountSelectListItemIcon} />
          </span>
          <Typography className={classes.AccountSelectListItemText}>
            {site.name}
          </Typography>
          {activeRole === UserRole.SiteAdmin && selectedSite?.id === site.id ? (
            t('accountSelect.loggedIn')
          ) : (
            <Button extraSmall>{t('accountSelect.login')}</Button>
          )}
        </Grid>
      ))
    ) : (
      <Grid className={classes.AccountSelectListItem}>
        {t('accountSelect.noSites')}
      </Grid>
    )

  const distributorsPage = (
    <>
      <Grid className={classes.AccountSelectHeader}>
        <Typography className={classes.AccountSelectHeaderTitle}>
          {t('accountSelect.chooseAccount')}
        </Typography>
      </Grid>
      {isHubletAdmin && (
        <SearchField
          key="distributors-search-field"
          query={searchQuery}
          onChange={(q) => dispatch(setAccountSelectSearchQuery(q))}
        />
      )}
      <Grid
        className={classes.AccountSelectList}
        sx={{ direction: languageDirection }}
      >
        {distributorsList}
      </Grid>
    </>
  )

  const customersPage = (
    <>
      <Grid className={classes.AccountSelectHeader}>
        {isHubletAdmin && (
          <ArrowBackIcon
            className={classes.BackButton}
            onClick={() =>
              handleChangeActivePage(AccountSelectPage.Distributors)
            }
          />
        )}
        <Typography className={classes.AccountSelectHeaderTitle}>
          {isOrganizationAdmin
            ? t('accountSelect.chooseAccount')
            : t('accountSelect.customerAccounts')}
        </Typography>
      </Grid>
      {isHubletAdmin && (
        <SearchField
          key="customs-search-field"
          query={searchQuery}
          onChange={(q) => dispatch(setAccountSelectSearchQuery(q))}
        />
      )}
      <Grid
        className={classes.AccountSelectList}
        sx={{ direction: languageDirection }}
      >
        {customersList}
      </Grid>
    </>
  )

  const sitesPage = (
    <>
      <Grid className={classes.AccountSelectHeader}>
        {(isHubletAdmin || isDistributorAdmin) && (
          <ArrowBackIcon
            className={classes.BackButton}
            onClick={() => handleChangeActivePage(AccountSelectPage.Customers)}
          />
        )}
        <Typography className={classes.AccountSelectHeaderTitle}>
          {activeCustomer?.name}
        </Typography>
      </Grid>
      {isHubletAdmin && (
        <SearchField
          key="sites-search-field"
          query={searchQuery}
          onChange={(q) => dispatch(setAccountSelectSearchQuery(q))}
        />
      )}
      <Grid
        className={classes.AccountSelectList}
        sx={{ direction: languageDirection }}
      >
        {orgSelector}
        {sitesList}
      </Grid>
    </>
  )

  return (
    <>
      <AccountSelectWrapper onClick={handleOpen}>
        <Avatar
          name={selectedOrganization.name}
          className={classes.AccountAvatar}
        />
        <Grid className={classes.AccountInfo}>
          <Typography className={classes.SelectedOrganization}>
            {selectedOrganization.name}
          </Typography>
          <Typography className={classes.SelectedSite}>
            {activeRole === UserRole.SiteAdmin ? selectedSite?.name : ''}
          </Typography>
        </Grid>
        {(isHubletAdmin || isDistributorAdmin || isOrganizationAdmin) && (
          <ArrowDropDownIcon />
        )}
      </AccountSelectWrapper>

      <Popover
        open={activePage !== undefined && anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ className: classes.AccountSelectPopup }}
      >
        {activePage === AccountSelectPage.Distributors
          ? distributorsPage
          : activePage === AccountSelectPage.Customers
            ? customersPage
            : sitesPage}
      </Popover>
    </>
  )
}

export default AccountSelect

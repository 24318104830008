import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Stack,
  DialogTitle,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Grid,
  CircularProgress,
  type Theme,
} from '@mui/material'
import { type Network } from 'App/Networks/networks-types'
import WizardDialog from 'common/components/WizardDialog'
import FormSelect from 'common/components/FormSelect'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import useApiErrors from 'common/hooks/useApiErrors'
import WomanSVG from 'common/graphics/woman.svg'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useLoadSiteNetworksQuery } from 'App/InstallationWizard/installation-wizard-rtk-api'
import {
  fetchInstallQrcode,
  resetInstallQrcode,
} from 'App/Tablets/tablets-state'
import HandSVG from 'common/graphics/hand.svg'
import { useAppDispatch, useAppSelector } from 'store'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  StepperLabel: {
    '& .MuiStepLabel-label': {
      color: theme.palette.primary.main,
    },
  },
}))

interface SelectNetworkBlockProps {
  nextStep: number
  networks: Network[]
  setActiveStep: () => void
  onNextStep: (data: any) => void
}

const SelectNetworkBlock = ({
  nextStep,
  networks,
  setActiveStep,
  onNextStep,
}: SelectNetworkBlockProps) => {
  const { t } = useTranslation()

  const [selectedNetworkId, setSelectedNetworkId] = useState<string>('')

  const networkOptions = useMemo(() => {
    return networks.map((network) => ({
      label: network.ssid,
      value: String(network.id),
    }))
  }, [networks])

  useEffect(() => {
    if (nextStep === 1) {
      onNextStep(
        selectedNetworkId === '' ? undefined : Number(selectedNetworkId)
      )
      setActiveStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep])

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack width="40%">
        <FormSelect
          label={t('installationWizard.tablet.network.form.label')}
          placeholder={t('installationWizard.tablet.network.form.placeholder')}
          options={networkOptions}
          onChange={(_e, v) => setSelectedNetworkId(v as string)}
          value={selectedNetworkId}
          emptyLabel="-"
        />
        <Typography
          mt={2}
          sx={(theme) => ({ color: theme.palette.primary.main })}
        >
          {t('installationWizard.tablet.network.tip')}
        </Typography>
      </Stack>
    </Box>
  )
}

interface ScanQrCodeBlockProps {
  nextStep: number
  networkId: number | undefined
  resetNextStep: () => void
  setActiveStep: () => void
}

const ScanQrCodeBlock = ({
  nextStep,
  networkId,
  resetNextStep,
  setActiveStep,
}: ScanQrCodeBlockProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const qrcode = useAppSelector((state) => state.tablets.installQrcode)

  const { siteId, organizationId } = useCurrentAccount()

  const [qrcodeError, setQrcodeError] = useState<string>('')

  useEffect(() => {
    dispatch(
      fetchInstallQrcode({
        siteId,
        organizationId,
        networkId,
        tabletPackage: undefined,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (nextStep === 2) {
      if (qrcode === undefined) {
        resetNextStep()
        setQrcodeError(t('installationWizard.tablet.qrcode.error.message'))
      } else {
        resetInstallQrcode()
        setActiveStep()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep])

  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          width="70%"
          height="250px"
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={7}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={HandSVG} alt="Hand" />
          </Grid>
          <Grid
            item
            xs={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {qrcode !== undefined ? (
              <Box width="75%">
                <div dangerouslySetInnerHTML={{ __html: qrcode }} />
              </Box>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid
            item
            xs={7}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack textAlign="center">
              <Typography fontSize="0.8rem">{'1'}</Typography>
              <Typography fontSize="0.8rem">
                {'Tap Hublet Tablet 6 times.'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack textAlign="center">
              <Typography fontSize="0.8rem">{'2'}</Typography>
              <Typography fontSize="0.8rem">
                {t('installationWizard.tablet.qrcode.description.scan')}
              </Typography>
              <Typography fontSize="0.8rem">
                {t('installationWizard.tablet.qrcode.description.follow')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Toast open={qrcodeError !== ''} message={qrcodeError} />
    </>
  )
}

interface TestQrCodeBlockProps {
  nextStep: number
  setActiveStep: () => void
}

const TestLoanBlock = ({ nextStep, setActiveStep }: TestQrCodeBlockProps) => {
  const { t } = useTranslation()

  useEffect(
    () => {
      if (nextStep === 3) {
        setActiveStep()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nextStep]
  )

  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width="25%">
          <Typography>
            {t('installationWizard.tablet.test.description')}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

interface AddTabletDialogProps {
  open: boolean
  onClose: () => void
}

const AddTabletDialog = ({ open, onClose }: AddTabletDialogProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const siteReq = useCurrentAccount()

  const { data: networks, error: loadNetworksError } =
    useLoadSiteNetworksQuery(siteReq)

  const [hasLoadNetworksErrors, apiLoadNetworksErrorsMsg] = useApiErrors([
    loadNetworksError,
  ])

  const steps = [
    t('installationWizard.tablet.step.network'),
    t('installationWizard.tablet.step.qrcode'),
    t('installationWizard.tablet.step.test'),
  ]

  const [activeStep, setActiveStep] = useState(0)
  const [nextStep, setNextStep] = useState(0)

  const [networkId, setNetworkId] = useState<number | undefined>()

  const handleClickBack = () => {
    if (activeStep === 0) {
      onClose()
    } else {
      setActiveStep(activeStep - 1)
      setNextStep(activeStep - 1)
    }
  }

  const handleClickFinish = () => {
    setActiveStep(0)
    setNextStep(0)
    setNetworkId(undefined)
    onClose()
  }

  return (
    <>
      <WizardDialog
        open={open && activeStep < 3}
        onClose={onClose}
        wide
        bottomBackground
      >
        <Stack
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
            <DialogTitle
              mt={3}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontSize: '2.2rem',
                fontWeight: 'bold',
              })}
            >
              {t('installationWizard.tablet.title')}
            </DialogTitle>
            <Typography pb={3} fontSize="1.2rem">
              {t('installationWizard.tablet.description')}
            </Typography>
            <Box width="1112px">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel className={classes.StepperLabel}>
                        {label}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </Box>
            <Box px={2} py={4} width="1112px">
              {activeStep === 0 && (
                <SelectNetworkBlock
                  nextStep={nextStep}
                  networks={networks ?? []}
                  setActiveStep={() => setActiveStep(1)}
                  onNextStep={setNetworkId}
                />
              )}
              {activeStep === 1 && (
                <ScanQrCodeBlock
                  nextStep={nextStep}
                  networkId={networkId}
                  resetNextStep={() => setNextStep(1)}
                  setActiveStep={() => setActiveStep(2)}
                />
              )}
              {activeStep === 2 && (
                <TestLoanBlock
                  nextStep={nextStep}
                  setActiveStep={() => setActiveStep(3)}
                />
              )}
            </Box>
          </Stack>
          <Grid container pt={4} pb={6} width="100%">
            <Grid
              container
              xs={6}
              pl={6}
              justifyContent="flex-start"
              alignContent="flex-end"
            ></Grid>
            <Grid
              container
              xs={6}
              pr={6}
              justifyContent="flex-end"
              alignContent="flex-end"
            >
              <Button small outlined onClick={handleClickBack} sx={{ mr: 1 }}>
                {activeStep === 0 ? t('cancel') : t('installationWizard.back')}
              </Button>
              <Button small onClick={() => setNextStep(activeStep + 1)}>
                {t('installationWizard.continue')}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </WizardDialog>

      <WizardDialog
        open={open && activeStep === 3}
        onClose={onClose}
        fullBackground
      >
        <Grid container height="100%">
          <Grid xs={5} container justifyContent="center" alignContent="center">
            <img src={WomanSVG} alt="WomanSVG" style={{ height: '80%' }} />
          </Grid>
          <Grid xs={7}>
            <Stack
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <Stack
                mt={14}
                mr={6}
                sx={{ flexGrow: 1, alignItems: 'flex-start' }}
              >
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  })}
                >
                  {t('installationWizard.tablet.done.title')}
                </Typography>
                <Typography mt={2}>
                  {t('installationWizard.tablet.done.description')}
                </Typography>
              </Stack>
              <Box
                my={6}
                mr={6}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button small onClick={handleClickFinish}>
                  {t('installationWizard.done')}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </WizardDialog>

      <Toast open={hasLoadNetworksErrors} message={apiLoadNetworksErrorsMsg} />
    </>
  )
}

export default AddTabletDialog

import { useEffect, useMemo, useState } from 'react'
import { type GridColDef } from '@mui/x-data-grid'
import {
  type RowData,
  type GROUP_BY_OPTIONS,
  type SelectOption,
} from './Analytics.types'
import { getCommonColumns } from './Analytics.utils'
import SitesApi from 'api/sites-api'
import DocksApi from 'api/docks-api'
import { useTranslation } from 'react-i18next'
import useUserTimeZone from 'common/hooks/useUserTimeZone'
import LoansApi from 'api/loans-api'
import { type FetchLoansParams } from 'App/Loans/loans-types'
import { UserRole } from 'App/app-state'

export const useAnalyticsData = ({
  distributorId,
  orgId,
  siteId,
  dockId,
  startDate,
  endDate,
  groupByTime,
  excludeDeleted,
  role,
}: {
  distributorId?: number
  orgId?: number
  siteId?: number
  dockId?: number
  startDate?: Date | null
  endDate?: Date | null
  groupByTime?: GROUP_BY_OPTIONS
  excludeDeleted?: boolean
  role: UserRole
}) => {
  const [rows, setRows] = useState<RowData[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const timeZone = useUserTimeZone()
  const { t } = useTranslation()

  const columns: Array<GridColDef<any>> = useMemo(() => {
    if (dockId) {
      return [
        {
          field: 'name',
          headerName: t('analytics.tabletSerial'),
          valueGetter: (value: string, row) => {
            let result = ''
            result += row.deleted ? `${t('analytics.deleted')} ` : ''
            result +=
              value ||
              (row.id
                ? // remove the '#{tgroup}' part from ID if there is any for better readability
                  `${t('analytics.unnamed')} (ID: ${
                    String(row.id).split('#')[0]
                  })`
                : t('analytics.unnamed'))
            return result
          },
          flex: 1,
          minWidth: 150,
        },
        ...getCommonColumns(groupByTime).map(({ headerName, ...rest }) => ({
          headerName: headerName ? t(headerName) : '',
          ...rest,
        })),
      ]
    } else if (siteId) {
      return [
        {
          field: 'name',
          headerName: t('analytics.dockName'),
          valueGetter: (value: string, row) => {
            let result = ''
            result += row.deleted ? `${t('analytics.deleted')} ` : ''
            // there are cases where there is no dock for devices
            result += value || t('analytics.unnamed')
            return result
          },
          flex: 1,
          minWidth: 150,
        },
        ...getCommonColumns(groupByTime).map(({ headerName, ...rest }) => ({
          headerName: headerName ? t(headerName) : '',
          ...rest,
        })),
      ]
    } else if (orgId) {
      return [
        {
          field: 'name',
          headerName: t('analytics.siteName'),
          valueGetter: (value: string, row) => {
            let result = ''
            result += row.deleted ? `${t('analytics.deleted')} ` : ''
            result +=
              value ||
              (row.id
                ? // remove the '#{tgroup}' part from ID if there is any for better readability
                  `${t('analytics.unnamed')} (ID: ${
                    String(row.id).split('#')[0]
                  })`
                : t('analytics.unnamed'))
            return result
          },
          flex: 1,
          minWidth: 150,
        },
        ...getCommonColumns(groupByTime).map(({ headerName, ...rest }) => ({
          headerName: headerName ? t(headerName) : '',
          ...rest,
        })),
      ]
    } else {
      return [
        {
          field: 'name',
          headerName: t('analytics.orgName'),
          valueGetter: (value: string, row) => {
            let result = ''
            result += row.deleted ? `${t('analytics.deleted')} ` : ''
            result +=
              value ||
              (row.id
                ? `${t('analytics.unnamed')} (ID: ${String(row.id)})`
                : t('analytics.unnamed'))
            return result
          },
          flex: 1,
          minWidth: 150,
        },
        ...getCommonColumns(groupByTime).map(({ headerName, ...rest }) => ({
          headerName: headerName ? t(headerName) : '',
          ...rest,
        })),
      ]
    }
  }, [dockId, siteId, orgId, t, groupByTime])

  useEffect(() => {
    const fetchRowsData = async (
      timeRangeStartInTz: Date,
      timeRangeEndInTz: Date
    ) => {
      // skip fetching data if there is not enough params
      const skipFetchingData =
        (role === UserRole.OrgAdmin && !orgId) ||
        (role === UserRole.SiteAdmin && (!orgId || !siteId)) ||
        (role === UserRole.DistributorAdmin && !distributorId)

      if (skipFetchingData) {
        return
      }

      const params: FetchLoansParams = {
        timeRangeStartInTz,
        timeRangeEndInTz,
        // there might be some conflict with SelectDate and timezone
        timeZone,
        distributorId,
        orgId,
        siteId,
        dockId,
        excludeDeleted,
      }

      if (groupByTime) {
        params.groupByTime = groupByTime
      }

      setIsLoading(true)
      try {
        const data = await LoansApi.fetchLoansStatisticsWithParams(params)
        setRows(data)
      } catch (error) {
        console.log('Error fetching analytics', error)
        setRows([])
      }
      setIsLoading(false)
    }

    // only need startDate and endDate to start getting analytics data
    if (startDate && endDate) {
      fetchRowsData(startDate, endDate)
    }
  }, [
    orgId,
    siteId,
    dockId,
    startDate,
    endDate,
    timeZone,
    groupByTime,
    excludeDeleted,
    role,
    distributorId,
  ])

  return { columns, rows, isLoading }
}

export const useSites = (
  orgId?: number,
  excludeDeleted = true,
  skip = false
) => {
  const [sites, setSites] = useState<SelectOption[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchSites = async (oid: number) => {
      setIsLoading(true)
      try {
        const result = await SitesApi.fetchSites(oid, excludeDeleted)
        setSites(result.map(({ id, name }) => ({ id, label: name })))
      } catch (error) {
        console.log('Error fetching sites', error)
        setSites([])
      }
      setIsLoading(false)
    }

    if (orgId && !skip) {
      fetchSites(orgId)
    } else {
      setSites([])
    }
  }, [excludeDeleted, orgId, skip])

  return { sites, isLoading }
}

export const useDocks = (
  orgId?: number,
  siteId?: number,
  excludeDeleted = true
) => {
  const [docks, setDocks] = useState<SelectOption[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchDocks = async (oid: number, sid: number) => {
      setIsLoading(true)
      let result
      try {
        if (excludeDeleted) {
          result = await DocksApi.fetchDocks(oid, sid, 'site', false)
          setDocks(result.map(({ id, name }) => ({ id, label: name })))
        } else {
          // use another endpoint to get docks (including deleted ones in loan table as well)
          result = await DocksApi.fetchDocksBySiteIdWithDeleted(oid, sid)
          setDocks(result ?? [])
        }
      } catch (error) {
        console.log('Error fetching docks', error)
        setDocks([])
      }
      setIsLoading(false)
    }

    if (orgId && siteId) {
      fetchDocks(orgId, siteId)
    } else {
      setDocks([])
    }
  }, [excludeDeleted, orgId, siteId])

  return { docks, isLoading }
}

import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { type File } from 'App/Categories/categories-types'
import FileFields from 'App/Categories/components/FileFields'
import UploadFile from 'App/Categories/components/UploadFile'
import { resetUploadFile } from 'App/Categories/contents-state'
import Avatar from 'common/components/Avatar'
import Button from 'common/components/Button'
import Dialog from 'common/components/Dialog'
import Toast from 'common/components/Toast'
import useFileForm from 'common/hooks/useFileForm'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogTitle: {
    background: '#fff',
    padding: '24px 36px',
  },
  DialogContent: {
    paddingTop: '36px',
  },
  DialogActions: {
    background: '#fff',
  },
  TitleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  TitleIcon: {},
  Title: {
    flexGrow: 1,
  },
  TitleText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  TitleType: {
    fontSize: '12px',
    color: '#000',
  },
  Buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

interface EditFileProps {
  open: boolean
  onClose: () => void
  profileId: number
  categoryId: number
  file?: File
}

const EditFile = ({
  open,
  onClose,
  profileId,
  categoryId,
  file,
}: EditFileProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const {
    fileForm,
    fieldErrors,
    handleFieldChange,
    handleUploadCustomIcon,
    setFileForm,
    handleUpdate,
  } = useFileForm(profileId, categoryId, file)

  const uploadedFilePath = useAppSelector(
    (state) => state.contents.uploadedFilePath
  )
  const updatedFile = useAppSelector((state) => state.contents.updatedFile)

  const handleClose = () => {
    // TODO should reset form?
    onClose()
  }

  useEffect(() => {
    if (updatedFile) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFile])

  const [uploadOpen, setUploadOpen] = useState(false)
  const handleUploadClose = () => {
    dispatch(resetUploadFile())
    setUploadOpen(false)
  }

  useEffect(() => {
    if (uploadedFilePath) {
      setFileForm({ ...fileForm, path: uploadedFilePath })
      handleUploadClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFileForm, uploadedFilePath])

  return (
    <>
      <UploadFile
        open={open && uploadOpen}
        onClose={handleUploadClose}
        profileId={profileId}
        categoryId={categoryId}
      />

      <Dialog
        open={open && !uploadOpen}
        onClose={handleClose}
        title={
          <Grid className={classes.TitleContainer}>
            <Grid className={classes.TitleIcon}>
              <Avatar name={fileForm.name} />
            </Grid>
            <Grid className={classes.Title}>
              <Grid className={classes.TitleText}>{fileForm.name}</Grid>
              <Grid className={classes.TitleType}>
                {t('contents.type.file')}
              </Grid>
            </Grid>
          </Grid>
        }
        actions={
          <Grid className={classes.Buttons}>
            <Button small outlined onClick={handleClose}>
              {t('contents.cancelButton')}
            </Button>
            <Button small onClick={handleUpdate}>
              {t('contents.saveButton')}
            </Button>
          </Grid>
        }
        classes={{
          title: classes.DialogTitle,
          content: classes.DialogContent,
          actions: classes.DialogActions,
        }}
      >
        <FileFields
          fileForm={fileForm}
          fieldErrors={fieldErrors}
          onFieldChange={handleFieldChange}
          onUploadCustomIcon={handleUploadCustomIcon}
          onUploadOpen={() => setUploadOpen(true)}
        />
      </Dialog>

      <Toast
        open={updatedFile}
        message={t('contents.edit.file.toast.success')}
      />
    </>
  )
}

export default EditFile

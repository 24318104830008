import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UsersApi from 'api/users-api'
import CustomersApi from 'api/customers-api'
import DistributorsApi from 'api/distributors-api'
import SitesApi from 'api/sites-api'
import { type Customer } from 'App/Customers/customers-types'
import { type Distributor } from 'App/Distributors/distributors-types'
import { type Site } from 'App/Sites/sites-state'
import TranslationsApi from 'api/translations-api'
import { type AccountSelectPage } from 'common/components/AccountSelect'

export enum UserRole {
  HubletAdmin = 'hublet-admin',
  DistributorAdmin = 'distributor-admin',
  OrgAdmin = 'org-admin',
  SiteAdmin = 'site-admin',
  SiteUser = 'site-user',
  Translator = 'translator',
  Manufacturer = 'manufacturer',
}

function getStoredStateData() {
  const storedRole = localStorage.getItem('hublet-user-role')
  const storedOrganizationDistributorId = localStorage.getItem(
    'hublet-user-organization-distributor-id'
  )
  const storedOrganization = localStorage.getItem('hublet-user-organization')
  const storedSite = localStorage.getItem('hublet-user-site')
  const storedAdmin = localStorage.getItem('hublet-user-admin')

  let role: UserRole | undefined

  if (storedRole !== null) {
    switch (storedRole) {
      case 'hublet-admin':
        role = UserRole.HubletAdmin
        break
      case 'distributor-admin':
        role = UserRole.DistributorAdmin
        break
      case 'org-admin':
        role = UserRole.OrgAdmin
        break
      case 'site-admin':
        role = UserRole.SiteAdmin
        break
      case 'site-user':
        role = UserRole.SiteUser
        break
      default:
        break
    }
  }

  let organizationDistributorId: number | undefined

  if (storedOrganizationDistributorId !== null) {
    try {
      organizationDistributorId = JSON.parse(storedOrganizationDistributorId)
    } catch (e) {
      console.log(
        'Invalid stored user organization distributor id',
        storedOrganizationDistributorId
      )
    }
  }

  let organization: ContextEntity | undefined

  if (storedOrganization !== null) {
    try {
      organization = JSON.parse(storedOrganization)
    } catch (e) {
      console.log('Invalid stored user organization', storedOrganization)
    }
  }

  let site: ContextEntity | undefined

  if (storedSite !== null) {
    try {
      site = JSON.parse(storedSite)
    } catch (e) {
      console.log('Invalid stored user site', storedSite)
    }
  }

  const adminMode = storedAdmin === 'true'

  return {
    role,
    organizationDistributorId,
    organization,
    site,
    adminMode,
  }
}

export interface UserPrivilege {
  id: number
  userId: number
  name: string
  organizationId?: number
  siteId?: number
}

export interface CurrentUserContext {
  role: UserRole
  organizationDistributorId?: number
  organizationId: number
  siteId?: number
  adminMode?: boolean
}

export interface CurrentUser {
  id: number
  login: string
  firstName: string
  lastName: string
  organizationId: number
  orgName: string
  isSsoUser?: boolean
  orgTimezone?: string
  siteId?: number
  siteTimezone?: string
  siteName?: string
  role: UserRole
  isDistributor: boolean
  distributorId?: number
  isManufacturer: boolean
  loginSuccessTotal: number
  expiresAt: number // timestamp
  rules: any[]
}

export interface ContextEntity {
  id: number
  name: string
  timezone?: string
}

interface AppState {
  currentUser?: CurrentUser
  fetchingCurrentUser: boolean
  fetchedCurrentUser: boolean
  fetchCurrentUserError: boolean

  role?: UserRole
  organizationDistributorId?: number
  organization?: ContextEntity
  site?: ContextEntity

  timezone?: string
  ipAddress: string
  city: string
  country: string

  adminMode: boolean

  distributors: Distributor[]
  customers: Customer[]
  sites: Site[]

  languages: string[]

  accountSelectActivePage?: AccountSelectPage
  accountSelectActiveDistributor?: ContextEntity
  accountSelectActiveCustomer?: ContextEntity
  accountSelectSearchQuery: string
  isAccountSelectDone: boolean

  languageDirection: 'rtl' | 'ltr'
}

const initialState: AppState = {
  currentUser: undefined,
  fetchingCurrentUser: false,
  fetchedCurrentUser: false,
  fetchCurrentUserError: false,

  adminMode: false,

  distributors: [],
  customers: [],
  sites: [],

  languages: [],

  accountSelectSearchQuery: '',
  isAccountSelectDone: false,

  languageDirection: 'ltr',
  ipAddress: '',
  city: '',
  country: '',
}

export const fetchCurrentUser = createAsyncThunk(
  'app/fetchCurrentUser',
  async (userContext: CurrentUserContext | undefined) =>
    UsersApi.currentUser(userContext)
)

export const fetchDistributors = createAsyncThunk(
  'app/fetchDistributors',
  async () => DistributorsApi.fetchDistributors()
)

export const fetchCustomers = createAsyncThunk(
  'app/fetchCustomers',
  async (distributorId: number) => CustomersApi.fetchCustomers(distributorId)
)

export const fetchSites = createAsyncThunk(
  'app/fetchSites',
  async (orgId: number) => SitesApi.fetchSites(orgId)
)

export const fetchLanguages = createAsyncThunk('app/fetchLanguages', async () =>
  TranslationsApi.fetchLanguages()
)

const removeLocalStorage = () => {
  localStorage.removeItem('hublet-user-organization-distributor-id')
  localStorage.removeItem('hublet-user-organization')
  localStorage.removeItem('hublet-user-site')
  localStorage.removeItem('hublet-user-role')
  localStorage.removeItem('hublet-user-admin')
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setOrganizationDistributorId: (
      state,
      { payload }: { payload: number | undefined }
    ) => {
      localStorage.setItem(
        'hublet-user-organization-distributor-id',
        JSON.stringify(payload)
      )
      state.organizationDistributorId = payload
      return state
    },
    setOrganization: (
      state,
      { payload }: { payload: ContextEntity | undefined }
    ) => {
      localStorage.setItem('hublet-user-organization', JSON.stringify(payload))
      state.organization = payload
      return state
    },
    setSite: (state, { payload }: { payload: ContextEntity | undefined }) => {
      localStorage.setItem('hublet-user-site', JSON.stringify(payload))
      state.site = payload
      return state
    },
    setRole: (state, { payload }) => {
      localStorage.setItem('hublet-user-role', payload)
      state.role = payload
      return state
    },
    switchToAdmin: (state) => {
      localStorage.setItem('hublet-user-admin', 'true')
      state.adminMode = true
      return state
    },
    switchToNormal: (state) => {
      localStorage.setItem('hublet-user-admin', 'false')
      state.adminMode = false
      return state
    },
    setAccountSelectActivePage: (
      state,
      { payload }: { payload: AccountSelectPage }
    ) => {
      state.accountSelectActivePage = payload
      return state
    },
    setAccountSelectActiveDistributor: (
      state,
      { payload }: { payload: ContextEntity | undefined }
    ) => {
      state.accountSelectActiveDistributor = payload
      return state
    },
    setAccountSelectActiveCustomer: (
      state,
      { payload }: { payload: ContextEntity | undefined }
    ) => {
      state.accountSelectActiveCustomer = payload
      return state
    },
    setAccountSelectSearchQuery: (state, { payload }: { payload: string }) => {
      state.accountSelectSearchQuery = payload
      return state
    },
    setIsAccountSelectDone: (state, { payload }: { payload: boolean }) => {
      state.isAccountSelectDone = payload
      return state
    },
    invalidateCurrentUser: (state) => {
      state.currentUser = undefined

      state.fetchingCurrentUser = false
      state.fetchedCurrentUser = true
      state.fetchCurrentUserError = true

      removeLocalStorage()

      return state
    },
    setLanguageDirection: (state, { payload }: { payload: 'rtl' | 'ltr' }) => {
      state.languageDirection = payload
      return state
    },
    setIpAddress: (state, { payload }: { payload: string }) => {
      state.ipAddress = payload
      return state
    },
    setCity: (state, { payload }: { payload: string }) => {
      state.city = payload
      return state
    },
    setCountry: (state, { payload }: { payload: string }) => {
      state.country = payload
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.pending, (state) => {
      state.fetchingCurrentUser = true
      return state
    })

    builder.addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
      state.fetchingCurrentUser = false
      state.fetchedCurrentUser = true
      state.fetchCurrentUserError = false
      state.currentUser = payload

      const storedUserId = localStorage.getItem('hublet-user-id')
      if (payload.id !== parseInt(storedUserId ?? '-1')) {
        removeLocalStorage()
        localStorage.setItem('hublet-user-id', JSON.stringify(payload.id))
      }

      const storedData = getStoredStateData()

      const role = payload.role
      const organizationDistributorId: number | undefined =
        payload.distributorId
      const organization: ContextEntity = {
        id: payload.organizationId,
        name: payload.orgName,
        timezone: payload.orgTimezone,
      }

      let site: ContextEntity | undefined

      if (
        (role === UserRole.SiteAdmin || role === UserRole.SiteUser) &&
        payload.siteId !== undefined &&
        payload.siteName !== undefined
      ) {
        // `site` should be undefined for other roles
        site = {
          id: payload.siteId,
          name: payload.siteName,
          timezone: payload.siteTimezone,
        }
      }

      state.role = storedData.role ?? role
      state.organizationDistributorId =
        storedData.organizationDistributorId ?? organizationDistributorId
      state.organization = storedData.organization ?? organization
      state.site = storedData.site ?? site
      state.adminMode =
        (storedData.adminMode ?? false) && role === UserRole.HubletAdmin

      return state
    })

    builder.addCase(fetchCurrentUser.rejected, (state) => {
      state.currentUser = undefined

      state.fetchingCurrentUser = false
      state.fetchedCurrentUser = true
      state.fetchCurrentUserError = true

      return state
    })

    builder.addCase(fetchDistributors.fulfilled, (state, { payload }) => {
      state.distributors = payload
      return state
    })

    builder.addCase(fetchCustomers.fulfilled, (state, { payload }) => {
      state.customers = payload
      return state
    })

    builder.addCase(fetchSites.fulfilled, (state, { payload }) => {
      state.sites = payload
      return state
    })

    builder.addCase(fetchLanguages.fulfilled, (state, { payload }) => {
      state.languages = payload
      return state
    })
  },
})

export const {
  setOrganizationDistributorId,
  setOrganization,
  setSite,
  setRole,
  switchToAdmin,
  switchToNormal,
  setAccountSelectActivePage,
  setAccountSelectActiveDistributor,
  setAccountSelectActiveCustomer,
  setAccountSelectSearchQuery,
  setIsAccountSelectDone,
  invalidateCurrentUser,
  setLanguageDirection,
  setIpAddress,
  setCity,
  setCountry,
} = appSlice.actions

export default appSlice.reducer

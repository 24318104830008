import React, { useEffect, useMemo, useState } from 'react'
import { type SiteDataForAdminReview } from 'App/SuperAdmin/super-admin-types'
import FormField from 'common/components/FormField'
import DrawerForm from 'common/components/DrawerForm'
import { Stack } from '@mui/material'
import FormSelect from 'common/components/FormSelect'
import { useReviewSiteDataMutation } from 'App/SuperAdmin/super-admin-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import { timezoneOptions } from 'common/timezones'
import { useGetCustomerSectorsQuery } from 'App/CustomerSectors/customer-sector-rtk-api'
import { useCountryOptions } from 'common/countries'
import DataTagsField from 'common/components/DataTagsField'

interface DataReviewSiteDrawerProps {
  open: boolean
  onClose: () => void
  onSaved: (data: SiteDataForAdminReview) => void
  siteData: SiteDataForAdminReview
}

const DataReviewSiteDrawer = ({
  open,
  onClose,
  onSaved,
  siteData,
}: DataReviewSiteDrawerProps) => {
  const [name, setName] = useState(siteData.name)
  const [street1, setStreet1] = useState(siteData.addressStreet1)
  const [street2, setStreet2] = useState(siteData.addressStreet2)
  const [zip, setZip] = useState(siteData.addressZipcode)
  const [city, setCity] = useState(siteData.addressCity)
  const [region, setRegion] = useState(siteData.addressRegion)
  const [country, setCountry] = useState(siteData.addressCountry)
  const [timezone, setTimezone] = useState(siteData.tzdbTimezone)
  const [customerSectorId, setCustomerSectorId] = useState(
    siteData.customerSectorId
  )
  const [tags, setTags] = useState<string[]>(siteData.tags)

  const { data: customerSectors } = useGetCustomerSectorsQuery()
  const customerSectorOptions = useMemo(
    () =>
      customerSectors?.map((cs) => ({
        label: cs.customerSectorName,
        value: cs.id,
      })) ?? [],
    [customerSectors]
  )
  const countryOptions = useCountryOptions()

  const [
    reviewSiteData,
    {
      isLoading: isReviewingSiteData,
      error: reviewSiteDataError,
      isSuccess: isSiteDataReviewed,
      data: reviewedSiteData,
    },
  ] = useReviewSiteDataMutation()

  useEffect(() => {
    if (isSiteDataReviewed && reviewedSiteData) {
      onSaved(reviewedSiteData)
    }
  }, [isSiteDataReviewed, onSaved, reviewedSiteData])

  const [hasApiError, apiError] = useApiErrors([reviewSiteDataError])

  const handleSubmit = () => {
    const updatedSiteData: SiteDataForAdminReview = {
      id: siteData.id,
      name,
      addressStreet1: street1,
      addressStreet2: street2,
      addressZipcode: zip,
      addressCity: city,
      addressRegion: region,
      addressCountry: country,
      tzdbTimezone: timezone,
      customerSectorId,
      tags,
      isReviewed: true,
    }

    reviewSiteData(updatedSiteData)
  }

  return (
    <DrawerForm
      title="Review site data"
      open={open}
      onClose={onClose}
      submitText="Save"
      onSubmit={handleSubmit}
      disabledButton={isReviewingSiteData}
    >
      <Stack spacing={4}>
        {hasApiError && <ErrorBox>{apiError}</ErrorBox>}
        <FormField
          label="Distributor"
          value={siteData.distributorName}
          readOnly
          disabled={isReviewingSiteData}
        />
        <FormField
          label="Organization"
          value={siteData.organizationName}
          readOnly
          disabled={isReviewingSiteData}
        />
        <FormField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter name"
          disabled={isReviewingSiteData}
        />
        <FormField
          label="Street 1"
          value={street1}
          onChange={(e) => setStreet1(e.target.value)}
          placeholder="Enter street 1"
          disabled={isReviewingSiteData}
        />
        <FormField
          label="Street 2"
          value={street2}
          onChange={(e) => setStreet2(e.target.value)}
          placeholder="Enter street 2"
          disabled={isReviewingSiteData}
        />
        <FormField
          label="Zip"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          placeholder="Enter zip"
          disabled={isReviewingSiteData}
        />
        <FormField
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="Enter city"
          disabled={isReviewingSiteData}
        />
        <FormField
          label="Region"
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          placeholder="Enter region"
          disabled={isReviewingSiteData}
        />
        <FormSelect
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          options={countryOptions}
          placeholder="Select country"
          disabled={isReviewingSiteData}
        />
        <FormSelect
          label="Timezone"
          options={timezoneOptions}
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          placeholder="Select timezone"
          disabled={isReviewingSiteData}
        />
        <FormSelect
          label="Sector"
          value={customerSectorId}
          onChange={(e) => setCustomerSectorId(e.target.value)}
          options={customerSectorOptions}
          placeholder="Select sector"
          disabled={isReviewingSiteData}
        />
        <DataTagsField
          dataType="site"
          tags={tags}
          onChange={(tags) => setTags(tags)}
          disabled={isReviewingSiteData}
        />
      </Stack>
    </DrawerForm>
  )
}

export default DataReviewSiteDrawer

import React from 'react'
import { type Code } from 'App/Codes/codes-types'
import Drawer from 'common/components/Drawer'
import Panel from 'common/components/Panel'
import PanelField from 'common/components/PanelField'
import { useTranslation } from 'react-i18next'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import Table from 'common/components/TableNew'
import useRenderDate from 'common/hooks/useRenderDate'

interface ViewCodeDrawerProps {
  group: Code
  codes: Code[]
  open: boolean
  onClose: () => void
}

const ViewGroupDrawer = ({
  group,
  codes,
  open,
  onClose,
}: ViewCodeDrawerProps) => {
  const { t } = useTranslation()
  const renderDate = useRenderDate()

  const columns = [
    {
      title: t('codes.codeDetails.fields.code'),
      field: 'code',
    },
    {
      title: t('codes.codeDetails.fields.useCount'),
      field: 'useCount',
    },
  ]

  return (
    <Drawer title={t('codes.groupDetails.title')} open={open} onClose={onClose}>
      <Panel title={t('codes.groupDetails.title')}>
        <PanelField
          title={t('codes.codeDetails.fields.name')}
          value={group.name}
        />
        <PanelField
          title={t('codes.codeDetails.fields.code')}
          value={group.code}
        />
        <PanelField
          title={t('codes.codeDetails.fields.createdAt')}
          value={renderDate(group.createdAt)}
        />
        <PanelField
          title={t('codes.codeDetails.fields.profile')}
          value={
            group.profileName ??
            `${t('common.values.useDefault')} (${t(
              'codes.listCodes.values.invalid'
            )})`
          }
        />
        <PanelField
          title={t('codes.codeDetails.fields.permissionType')}
          value={t(
            `codes.addCode.permissionTypeField.options.${group.permissionType}`
          )}
        />
        <PanelField
          title={t('codes.codeDetails.fields.validUntil')}
          value={
            group.profileId === 0 ? (
              '-'
            ) : (
              <DateWithRelativeTime datetime={group.expiration} />
            )
          }
        />
      </Panel>
      <Table data={codes} columns={columns} />
    </Drawer>
  )
}

export default ViewGroupDrawer

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
} from '@mui/material'
import WizardDialog from 'common/components/WizardDialog'
import BotSVG from 'common/graphics/donebot.svg'
import Button from 'common/components/Button'

interface AllDoneDialogProps {
  title: string
  description?: string
  open: boolean
  onClose: () => void
}

const AllDoneDialog = ({
  title,
  description = '',
  open,
  onClose,
}: AllDoneDialogProps) => {
  const { t } = useTranslation()

  return (
    <WizardDialog open={open} onClose={onClose} narrow bottomBackground>
      <Stack sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
          <Box width="400px" mt={10}>
            <img src={BotSVG} alt="Badge" style={{ width: '100%' }} />
          </Box>
          <DialogTitle
            fontWeight="bold"
            textAlign="center"
            fontSize="2.2rem"
            py={2}
            sx={(theme) => ({ color: theme.palette.primary.main })}
          >
            {title}
          </DialogTitle>
          <Box px={4} py={1}>
            <Typography fontSize="1.2rem">{description}</Typography>
          </Box>
        </Stack>
        <DialogActions
          sx={{ pt: 4, pb: 6, display: 'flex', justifyContent: 'center' }}
        >
          <Button small onClick={onClose}>
            {t('installationWizard.done')}
          </Button>
        </DialogActions>
      </Stack>
    </WizardDialog>
  )
}

export default AllDoneDialog

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Box, Alert } from '@mui/material'
import Table from 'common/components/TableNew'
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import PowerIcon from '@mui/icons-material/PowerSettingsNewOutlined'
import RebootIcon from '@mui/icons-material/RestartAltOutlined'

import { type Dock } from 'App/Docks/docks-types'
import IconMenu from 'common/components/IconMenu'
import useDocks from 'common/hooks/useDocks'
import DockDetailsDrawer from 'App/Docks/components/DockDetailsDrawer'
import { useTranslation } from 'react-i18next'
import DockStatus, { isDockOnline } from 'App/Docks/components/DockStatus'
import DockSlotsInfo, {
  connectedTabletsCount,
  dockSlotsCount,
  unknownTabletsCount,
} from 'App/Docks/components/DockSlotsInfo'
import Toast from 'common/components/Toast'
import {
  fetchDocks,
  resetToastMessage,
  resetUpdatedDockLocation,
  setDockToDelete,
  setDockToUndelete,
  setToastMessage,
} from 'App/Docks/docks-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import DocksReactContext from 'App/Docks/docks-react-context'
import ActionDialog from 'common/components/ActionDialog'
import {
  useDeleteDockMutation,
  useRebootDocksMutation,
  useShutdownDocksMutation,
  useUndeleteDockMutation,
} from 'App/Docks/docks-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import useApp from 'common/hooks/useApp'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import DocksTableActionsBar from 'App/Docks/components/DocksTableActionsBar'
import useSearch, { type UseSearchOptions } from 'common/hooks/useSearch'
import MaybeDeletedRowCell from 'common/components/MaybeDeletedRowCell'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrashOutlined'
import useRenderDate from 'common/hooks/useRenderDate'
import { UserRole } from 'App/app-state'
import { useLoadStateQuery } from 'App/InstallationWizard/installation-wizard-rtk-api'
import { useAppDispatch, useAppSelector } from 'store'

interface DocksRowActionsProps {
  adminMode: boolean
  dock: Dock
  onClickViewDetails: (dock: Dock) => void
  onClickDelete: (dock: Dock) => void
  onClickUndelete: (dock: Dock) => void
  onClickShutdown: (dock: Dock) => void
  onClickReboot: (dock: Dock) => void
}

const DocksRowActions = ({
  adminMode,
  dock,
  onClickViewDetails,
  onClickDelete,
  onClickUndelete,
  onClickShutdown,
  onClickReboot,
}: DocksRowActionsProps) => {
  const actions = []

  if (dock.deletedAt === undefined) {
    actions.push({
      local: 'docks.listDocks.actions.viewDetails',
      fn: () => onClickViewDetails(dock),
      icon: EyeIcon,
    })
  }

  actions.push({
    local: 'docks.details.actions.fields.reboot.button',
    fn: () => onClickReboot(dock),
    icon: RebootIcon,
  })

  actions.push({
    local: 'docks.details.actions.fields.shutdown.button',
    fn: () => onClickShutdown(dock),
    icon: PowerIcon,
  })

  if (adminMode) {
    if (dock.deletedAt === undefined) {
      actions.push({
        local: 'docks.listDocks.actions.delete',
        fn: () => onClickDelete(dock),
        icon: DeleteIcon,
      })
    }

    if (dock.deletedAt !== undefined) {
      actions.push({
        local: 'common.actions.undelete',
        fn: () => onClickUndelete(dock),
        icon: RestoreFromTrashIcon,
      })
    }
  }

  return <IconMenu actions={actions} />
}

const DocksTable = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const renderDate = useRenderDate()

  const [deleteDock, { isSuccess: isDeleteSuccess, error: deleteDockError }] =
    useDeleteDockMutation()

  const [hasApiDeleteErrors, apiDeleteErrorMsg] = useApiErrors([
    deleteDockError,
  ])

  const [
    undeleteDock,
    { isSuccess: isUndeleteSuccess, error: undeleteDockError },
  ] = useUndeleteDockMutation()

  const [hasApiUndeleteErrors, apiUndeleteErrorMsg] = useApiErrors([
    undeleteDockError,
  ])

  const [rebootDocks, { isSuccess: rebootSuccess, isError: rebootError }] =
    useRebootDocksMutation()
  const [
    shutdownDocks,
    { isSuccess: shutdownSuccess, isError: shutdownError },
  ] = useShutdownDocksMutation()

  const showToast = useCallback(
    (key: string) => dispatch(setToastMessage(t(key))),
    [dispatch, t]
  )

  useEffect(() => {
    if (rebootSuccess) {
      showToast('docks.details.actions.fields.reboot.success')
    } else if (rebootError) {
      showToast('docks.details.actions.fields.reboot.error')
    }
  }, [showToast, rebootError, rebootSuccess])

  useEffect(() => {
    if (shutdownSuccess) {
      showToast('docks.details.actions.fields.shutdown.success')
    } else if (shutdownError) {
      showToast('docks.details.actions.fields.shutdown.error')
    }
  }, [showToast, shutdownError, shutdownSuccess])

  const context = useContext(DocksReactContext)
  const docks = useDocks()

  const fetchedDocks = useAppSelector((state) => state.docks.fetchedDocks)
  const updatedDockLocation = useAppSelector(
    (state) => state.docks.updatedDockLocation
  )
  const dockToDelete = useAppSelector((state) => state.docks.dockToDelete)
  const dockToUndelete = useAppSelector((state) => state.docks.dockToUndelete)

  const { toastMessage } = useAppSelector((state) => state.docks)

  const { organizationId, siteId } = useCurrentAccount()
  const { adminMode, role } = useApp()

  const [activeDockId, setActiveDockId] = useState<number | null>(null)
  const [dockDrawerOpen, setDockDrawerOpen] = useState(false)

  const [showDeleted, setShowDeleted] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const [selectedRows, setSelectedRows] = useState<Dock[]>([])
  const handleSelectionChange = useCallback((rows: any[]) => {
    setSelectedRows(rows as Dock[])
  }, [])

  useEffect(() => {
    if (updatedDockLocation) {
      setDockDrawerOpen(false)
      setActiveDockId(null)
      dispatch(fetchDocks({ organizationId, siteId, context }))
    }
  }, [dispatch, updatedDockLocation, organizationId, siteId, context])

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(fetchDocks({ organizationId, siteId, context }))
      dispatch(setDockToDelete(null))
    }
  }, [dispatch, isDeleteSuccess, organizationId, siteId, context])

  useEffect(() => {
    if (isUndeleteSuccess) {
      dispatch(fetchDocks({ organizationId, siteId, context }))
      dispatch(setDockToUndelete(null))
    }
  }, [dispatch, isUndeleteSuccess, organizationId, siteId, context])

  const activeDock = useMemo(() => {
    const matches = docks.filter(({ id }) => id === activeDockId)
    return matches.length === 1 ? matches[0] : null
  }, [docks, activeDockId])

  const handleCloseDockDrawer = () => {
    setDockDrawerOpen(false)
    setActiveDockId(null)
  }

  const handleClickViewDetails = (dock: Dock) => {
    setActiveDockId(dock.id)
    setDockDrawerOpen(true)
  }

  const handleClickDelete = useCallback(
    (dock: Dock) => {
      dispatch(setDockToDelete(dock))
    },
    [dispatch]
  )

  const handleClickUndelete = useCallback(
    (dock: Dock) => {
      dispatch(setDockToUndelete(dock))
    },
    [dispatch]
  )

  const handleActionDelete = () => {
    if (dockToDelete !== null) {
      deleteDock({ organizationId, siteId, context, dockId: dockToDelete.id })
    }
  }

  const handleActionUndelete = () => {
    if (dockToUndelete !== null) {
      undeleteDock({
        organizationId,
        siteId,
        context,
        dockId: dockToUndelete.id,
      })
    }
  }

  const handleClickShutdown = useCallback(
    (dock: Dock) => {
      shutdownDocks({
        context,
        organizationId,
        siteId,
        dockIds: [dock.id],
      })
    },
    [context, organizationId, shutdownDocks, siteId]
  )

  const handleClickReboot = useCallback(
    (dock: Dock) => {
      rebootDocks({
        context,
        organizationId,
        siteId,
        dockIds: [dock.id],
      })
    },
    [context, organizationId, rebootDocks, siteId]
  )

  const columns = useMemo(
    () => [
      {
        title: t('docks.listDocks.fields.name.label'),
        render: ({ name, deletedAt }: Dock) => (
          <MaybeDeletedRowCell deletedAt={deletedAt} content={name} />
        ),
      },
      {
        title: t('docks.listDocks.fields.serialNumber.label'),
        render: (dock: Dock) => dock.serialNumber ?? '-',
      },
      {
        title: t('docks.listDocks.fields.lastActive.label'),
        // eslint-disable-next-line react/display-name
        render: (dock: Dock) => {
          return dock.keepaliveLast === undefined ? (
            '-'
          ) : (
            <DateWithRelativeTime datetime={dock.keepaliveLast} />
          )
        },
      },
      {
        title: t('docks.listDocks.fields.connectedTablets.label'),
        // eslint-disable-next-line react/display-name
        render: (dock: Dock) => <DockSlotsInfo dock={dock} />,
      },
      {
        title: t('docks.listDocks.fields.status.label'),
        // eslint-disable-next-line react/display-name
        render: (dock: Dock) => <DockStatus dock={dock} />,
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (dock: Dock) => {
          return (
            <DocksRowActions
              adminMode={adminMode}
              dock={dock}
              onClickViewDetails={handleClickViewDetails}
              onClickDelete={handleClickDelete}
              onClickUndelete={handleClickUndelete}
              onClickShutdown={handleClickShutdown}
              onClickReboot={handleClickReboot}
            />
          )
        },
        style: { width: '24px' },
      },
    ],
    [
      t,
      adminMode,
      handleClickDelete,
      handleClickUndelete,
      handleClickShutdown,
      handleClickReboot,
    ]
  )

  const docksFilteredByShowDeleted = useMemo(() => {
    if (docks === undefined) return []
    if (showDeleted) return docks
    return docks.filter((user) => user.deletedAt === undefined)
  }, [docks, showDeleted])

  const searchOptions: UseSearchOptions<Dock> = useMemo(
    () => ({
      data: docksFilteredByShowDeleted,
      fields: ['name', 'keepaliveLast', 'serialNumber', 'boardInfo'],
      query: searchQuery,
      renderers: {
        keepaliveLast: (keepaliveLast?: Date) =>
          keepaliveLast != null ? renderDate(keepaliveLast) : '',
      },
      customFields: {
        connectedTablets: (dock: Dock) => {
          const connectedTablets = connectedTabletsCount(dock)
          const dockSlots = dockSlotsCount(dock)
          const unknownTablets = unknownTabletsCount(dock)

          let str = `${connectedTablets} / ${dockSlots}`

          if (unknownTablets > 0) {
            str += ` ${unknownTablets} ${t(
              'docks.listDocks.fields.connectedTablets.unknown'
            )}`
          }

          return str
        },
        status: (dock: Dock) =>
          isDockOnline(dock)
            ? t('docks.listDocks.fields.status.online')
            : t('docks.listDocks.fields.status.offline'),
      },
    }),
    [docksFilteredByShowDeleted, searchQuery, t, renderDate]
  )

  const docksFilteredBySearchQuery = useSearch(searchOptions)

  const docUrl =
    'https://hublet.zendesk.com/hc/en-us/articles/9310688739730-How-to-configure-a-new-Hublet-Dock-on-site'

  const {
    data: state,
    isSuccess: isLoadStateSuccess,
    error: loadStateError,
  } = useLoadStateQuery(
    {
      organizationId,
      siteId,
    },
    { skip: role !== UserRole.SiteAdmin && role !== UserRole.SiteUser }
  )

  const [hasLoadStateErrors, apiLoadStateErrorsMsg] = useApiErrors([
    loadStateError,
  ])

  if (!fetchedDocks) {
    return null
  }

  return (
    <>
      <DocksTableActionsBar
        selectedDocks={selectedRows}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        showDeleted={showDeleted}
        onChangeShowDeleted={setShowDeleted}
      />
      {isLoadStateSuccess && !state?.hasDocks && (
        <Box marginLeft="auto" my={4}>
          <Alert
            variant="filled"
            severity="info"
            sx={{ backgroundColor: '#FFFAD0', color: 'black' }}
            icon={false}
          >
            <p>{t('installationWizard.dock.tip.start')}</p>
            <p>{t('installationWizard.dock.tip.steps')}</p>
            <p>
              {t('installationWizard.dock.tip.read')}{' '}
              <a href={docUrl} target={'_blank'} rel="noreferrer">
                {t('installationWizard.dock.tip.doc')}
              </a>{' '}
              {t('installationWizard.dock.tip.about')}{' '}
              {t('installationWizard.dock.tip.complete')}
            </p>
          </Alert>
        </Box>
      )}
      <Table
        columns={columns}
        data={docksFilteredBySearchQuery}
        selectable={adminMode || selectedRows.length > 0}
        onSelectionChange={handleSelectionChange}
      />
      {activeDock != null && (
        <DockDetailsDrawer
          dock={activeDock}
          open={dockDrawerOpen}
          onClose={handleCloseDockDrawer}
        />
      )}
      <Toast
        open={updatedDockLocation}
        message={t('docks.toasts.updatedDockLocation')}
        onClose={() => dispatch(resetUpdatedDockLocation())}
      />
      <Toast open={isDeleteSuccess} message={t('docks.toasts.deletedDock')} />
      <Toast
        open={isUndeleteSuccess}
        message={t('docks.toasts.undeletedDock')}
      />
      <Toast
        open={toastMessage !== null}
        message={toastMessage ?? ''}
        onClose={() => dispatch(resetToastMessage())}
      />
      {dockToDelete != null && (
        <ActionDialog
          open={dockToDelete !== null}
          title={t('docks.deleteDock.title')}
          description={t('docks.deleteDock.description', {
            dock: dockToDelete.name,
          })}
          actionText={t('common.actions.delete')}
          onAction={handleActionDelete}
          onClose={() => dispatch(setDockToDelete(null))}
          closeOnAction={false}
        >
          {hasApiDeleteErrors && (
            <ErrorBox spacingBottom={2}>{apiDeleteErrorMsg}</ErrorBox>
          )}
        </ActionDialog>
      )}
      {dockToUndelete != null && (
        <ActionDialog
          open={dockToUndelete !== null}
          title={t('docks.undeleteDock.title')}
          description={t('docks.undeleteDock.description', {
            dock: dockToUndelete.name,
          })}
          actionText={t('common.actions.undelete')}
          onAction={handleActionUndelete}
          onClose={() => dispatch(setDockToUndelete(null))}
          closeOnAction={false}
        >
          {hasApiUndeleteErrors && (
            <ErrorBox spacingBottom={2}>{apiUndeleteErrorMsg}</ErrorBox>
          )}
        </ActionDialog>
      )}
      <Toast open={hasLoadStateErrors} message={apiLoadStateErrorsMsg} />
    </>
  )
}

export default DocksTable

import React from 'react'
import qs from 'qs'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface MicrosoftLoginProps {
  clientId: string
}

const MicrosoftLogin: React.FC<MicrosoftLoginProps> = ({ clientId }) => {
  const { t } = useTranslation()

  const handleLogin = () => {
    const loginUrl = `https://auth.${
      window.location.hostname
    }/oauth2/authorize?${qs.stringify({
      response_type: 'code',
      client_id: clientId,
      redirect_uri: `https://${window.location.hostname}/api/v2/web/aws/cognito/auth?from=sso`,
      identity_provider:
        window.location.hostname === 'manager.hublet.fi'
          ? 'MicrosoftEntraID-Vantaa'
          : 'MicrosoftEntraID-Hublet',
    })}`

    window.location.href = loginUrl
  }

  return (
    <Button variant="contained" color="primary" onClick={handleLogin}>
      {t('loginv2.signInWithEntraId')}
    </Button>
  )
}

export default MicrosoftLogin

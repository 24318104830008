import { type Tablet } from 'App/Tablets/tablets-types'

export enum StockState {
  NEW = 'New',
  ASSIGNED = 'Assigned',
  REMOVED = 'Removed',
  RETURNED = 'Returned',
}

export interface NewStockTablet {
  serialNumber: string
  model: string
  type: string
  stockStatus?: StockState
}

export interface StockTablet extends Tablet {
  type?: string
  state?: string
  location?: string
  site?: string
}

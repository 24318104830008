import React, { useState } from 'react'
import { Slider, type SliderProps, type Theme } from '@mui/material'

import { withStyles, makeStyles } from 'tss-react/mui'

const sliderStyles = {
  root: {
    height: 8,
  },
  thumb: {
    '&, &.Mui-disabled': {
      height: 20,
      width: 20,
    },
  },
  track: {
    height: 8,
    borderRadius: 7,
  },
  rail: {
    height: 8,
    borderRadius: 7,
  },
  mark: {
    display: 'none',
  },
  markLabel: {
    color: 'currentColor',
    opacity: 0.38,
  },
  markLabelActive: {
    opacity: 1,
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
}

const StyledSlider = withStyles(Slider, sliderStyles)

const useStyles = makeStyles()((theme: Theme) => ({
  HSlider: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  HSliderValue: {
    marginLeft: 16,
    color: theme.palette.primary.main,
  },
  HSliderDisabledValue: {
    color: '#bdbdbd',
  },
}))

interface HSliderProps extends Omit<SliderProps, 'defaultValue'> {
  showValue?: boolean
  defaultValue?: number | number[]
  renderValue?: (v?: number) => string
  virtualMin?: number // the UI could show e.g. range `0 - 10`, but you could be allowed to set only `3 - 10`
  virtualMax?: number
}

const HSlider = ({
  showValue = true,
  disabled,
  defaultValue,
  value,
  onChange,
  onChangeCommitted,
  renderValue,
  virtualMin,
  virtualMax,
  ...sliderProps
}: HSliderProps) => {
  const { classes, cx } = useStyles()

  const [sliderValue, setSliderValue] = useState(value ?? defaultValue)

  const handleChangeCommitted = (
    e: Event | React.SyntheticEvent,
    value: number | number[]
  ) => {
    if (virtualMin !== undefined) {
      value = Array.isArray(value)
        ? value.map((v) => Math.max(virtualMin, v))
        : Math.max(virtualMin, value)
    }

    if (virtualMax !== undefined) {
      value = Array.isArray(value)
        ? value.map((v) => Math.min(virtualMax, v))
        : Math.min(virtualMax, value)
    }

    if (onChangeCommitted !== undefined) {
      onChangeCommitted(e, value)
    }
  }

  const handleChange = (
    e: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    if (virtualMin !== undefined) {
      value = Array.isArray(value)
        ? value.map((v) => Math.max(virtualMin, v))
        : Math.max(virtualMin, value)
    }

    if (virtualMax !== undefined) {
      value = Array.isArray(value)
        ? value.map((v) => Math.min(virtualMax, v))
        : Math.min(virtualMax, value)
    }

    setSliderValue(value)
    if (onChange !== undefined) {
      onChange(e, value, activeThumb)
    }
  }
  const sliderComponent = (
    <StyledSlider
      {...sliderProps}
      value={sliderValue}
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={handleChange}
      onChangeCommitted={handleChangeCommitted}
    />
  )

  if (!showValue) {
    return sliderComponent
  }

  const hSliderValueClassNames = [classes.HSliderValue]

  if (disabled) {
    hSliderValueClassNames.push(classes.HSliderDisabledValue)
  }

  return (
    <div className={classes.HSlider}>
      {sliderComponent}
      <div className={cx(hSliderValueClassNames)}>
        {renderValue === undefined
          ? sliderValue
          : renderValue(sliderValue as number | undefined)}
      </div>
    </div>
  )
}

export default HSlider

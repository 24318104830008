import React, { type PropsWithChildren, useRef, useState } from 'react'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import DropdownMenu from 'common/components/DropdownMenu'
import Button from 'common/components/Button'
import DropdownMenuItem from 'common/components/DropdownMenuItem'
import { useTranslation } from 'react-i18next'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined'
import SearchIcon from '@mui/icons-material/SearchTwoTone'
import FormField from 'common/components/FormField'
import Checkbox from 'common/components/Checkbox'
import { type SvgIconComponent } from '@mui/icons-material'

const useStyles = makeStyles()((theme: Theme) => ({
  TableActionsBar: {
    marginBottom: theme.spacing(3),
  },
  Filters: {
    flexGrow: 1,
  },
  SelectedRowsText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  ActionsButton: {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: '#F8F4F8',
    },
  },
  ActionsButtonIcon: {
    marginTop: '-5px',
    marginRight: '-8px',
    marginLeft: theme.spacing(1),
    height: '19px',
  },
  SelectedRowsActions: {
    display: 'inline-block',
    marginLeft: theme.spacing(3),
  },
  TableSearchField: {
    maxWidth: 300,
    height: 39,
    verticalAlign: 'middle',
    '& .MuiInput-root': {
      height: '100%',
      borderColor: theme.palette.primary.light,
    },
    '& .MuiInputAdornment-root': {
      color: theme.palette.primary.main,
    },
    '& > label': {
      height: '100%',
    },
  },
  TableShowDeleted: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    '& > span': {
      verticalAlign: 'middle',
    },
  },
  TableShowDeletedText: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
}))

interface TableShowDeletedProps {
  checked: boolean
  onChange: (checked: boolean) => void
}

const TableShowDeleted = ({ checked, onChange }: TableShowDeletedProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <label className={classes.TableShowDeleted}>
      <Checkbox value={checked} onChange={(e) => onChange(e.target.checked)} />
      <span className={classes.TableShowDeletedText}>
        {t('tableActionsBar.showDeleted.label')}
      </span>
    </label>
  )
}

interface TableSearchFieldProps {
  query: string
  onChange: (query: string) => void
}

const TableSearchField = ({ query, onChange }: TableSearchFieldProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <FormField
      className={classes.TableSearchField}
      placeholder={t('tableActionsBar.search')}
      value={query}
      onChange={(e) => onChange(e.target.value)}
      endIcon={<SearchIcon />}
      rounded
      dense
    />
  )
}

interface SelectedRowsAction {
  local: string
  fn: () => void
  icon: SvgIconComponent
}

interface SelectedRowsActionsProps {
  actions: SelectedRowsAction[]
}

export const SelectedRowsActions = ({ actions }: SelectedRowsActionsProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const toggleRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleAction = (fn: () => void) => () => {
    setOpen(false)
    fn()
  }

  return (
    <div className={classes.SelectedRowsActions} ref={toggleRef}>
      <Button
        onClick={() => setOpen(true)}
        small
        className={classes.ActionsButton}
      >
        {t('tablets.actions.button')}
        <span className={classes.ActionsButtonIcon}>
          <ArrowDropDownIcon />
        </span>
      </Button>
      <DropdownMenu
        anchor={toggleRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        {actions.map((action) => (
          <DropdownMenuItem
            key={action.local}
            onClick={handleAction(action.fn)}
            Icon={action.icon}
          >
            {t(action.local)}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </div>
  )
}

interface TableActionsBarProps {
  showDeleted?: boolean
  onChangeShowDeleted?: (showDeleted: boolean) => void
  searchQuery?: string
  onSearch?: (query: string) => void
  selectedRows?: any[] // e.g. Tablet[]
  selectedRowsLabel?: string // e.g. `tablets`
  selectedRowsActions?: SelectedRowsAction[]
}

const TableActionsBar = ({
  showDeleted,
  onChangeShowDeleted,
  searchQuery,
  onSearch,
  selectedRows,
  selectedRowsLabel,
  selectedRowsActions,
  children,
}: PropsWithChildren<TableActionsBarProps>) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const isSearchable = searchQuery != null && onSearch != null
  const isShowDeleted = showDeleted != null && onChangeShowDeleted != null
  const hasSelectedRows = selectedRows != null && selectedRows.length > 0

  return (
    <Grid
      container
      className={classes.TableActionsBar}
      gap={4}
      alignItems="center"
    >
      {(isSearchable || isShowDeleted) && (
        <Grid item className={classes.Filters}>
          {isSearchable && (
            <TableSearchField query={searchQuery} onChange={onSearch} />
          )}
          {isShowDeleted && (
            <TableShowDeleted
              checked={showDeleted}
              onChange={onChangeShowDeleted}
            />
          )}
        </Grid>
      )}
      {children != null && (
        <Grid item flexGrow={1}>
          {children}
        </Grid>
      )}
      {hasSelectedRows && (
        <Grid item>
          <span className={classes.SelectedRowsText}>
            {selectedRows.length} {selectedRowsLabel ?? ''}{' '}
            {t('tableActionsBar.selected')}
          </span>
          {selectedRowsActions != null && selectedRowsActions.length > 0 && (
            <SelectedRowsActions actions={selectedRowsActions} />
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default TableActionsBar

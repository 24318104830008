export interface CategoryContext {
  organizationId: number
  siteId: number
  profileId: number
}

export interface Category {
  id: number
  name: string
  description: string
  numApps: number
  numLinks: number
  numFiles: number
  profileId?: number
}

export interface CategoryForm {
  name?: string
  description?: string
}

export interface ContentContext {
  organizationId: number
  siteId: number
  profileId: number
  categoryId: number
}

export interface Content {
  id?: number
  name: string
  description: string
  highlighted: boolean
  icon: string
}

export interface Website extends Content {
  url: string
  fullscreen: boolean
}

export interface App extends Content {
  packageName: string
  isLockTaskMode: boolean | null
  managedConfigId: string | null
  managedConfigName: string | null
  permissions: AppPermission[] | null
}

export interface File extends Content {
  path: string
}

export const isApp = (c: Content): c is App => {
  return (c as App).packageName !== undefined
}

export const isWebsite = (c: Content): c is Website => {
  return (c as Website).url !== undefined
}

export const isFile = (c: Content): c is File => {
  return (c as File).path !== undefined
}

export interface ContentForm {
  name: string
  description: string
}

export interface WebsiteForm extends ContentForm {
  url: string
  customIcon?: string
  fullscreen: boolean
}

export interface AppForm extends ContentForm {
  isLockTaskMode: boolean | null
  managedConfigId: string | null
  managedConfigName: string | null
  permissions: AppPermission[] | null
}

export interface FileForm extends ContentForm {
  path: string
  customIcon?: string
}

export interface AppPermission {
  name: string
  description: string
  state: string
}

export interface ManagedConfig {
  managedConfigId: string | null
  managedConfigName: string | null
}

import React from 'react'
import { type Dock } from 'App/Docks/docks-types'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
  ConnectedTabletsCount: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  UnknownTabletsCount: {
    display: 'inline-block',
    padding: '5px 8px',
    marginLeft: theme.spacing(2),
    borderRadius: '30px',
    backgroundColor: theme.palette.error.main,
    verticalAlign: 'middle',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#fff',
    whiteSpace: 'nowrap',
  },
}))

type DockKey = keyof Dock
const slots: DockKey[] = ['slot1', 'slot2', 'slot3', 'slot4', 'slot5', 'slot6']

const tabletInSlots: DockKey[] = [
  'tabletInSlot1',
  'tabletInSlot2',
  'tabletInSlot3',
  'tabletInSlot4',
  'tabletInSlot5',
  'tabletInSlot6',
]

export const connectedTabletsCount = (dock: Dock): number => {
  return slots.reduce((acc: number, key) => {
    if (dock[key] === null) {
      return acc
    }

    return acc + 1
  }, 0)
}

export const dockSlotsCount = (dock: Dock): number => {
  if (dock.model?.includes('HUBLET-2017-M3')) {
    return 3
  }

  return 6
}

export const unknownTabletsCount = (dock: Dock): number => {
  return tabletInSlots
    .slice(0, dockSlotsCount(dock))
    .reduce((acc: number, key, i) => {
      if (dock[key] === null && dock[slots[i]] !== null) {
        return acc + 1
      }

      return acc
    }, 0)
}

interface DockSlotsInfoProps {
  dock: Dock
}

const DockSlotsInfo = ({ dock }: DockSlotsInfoProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const unknownTablets = unknownTabletsCount(dock)

  return (
    <>
      <span className={classes.ConnectedTabletsCount}>
        {connectedTabletsCount(dock)} / {dockSlotsCount(dock)}
      </span>
      {unknownTablets > 0 && (
        <span className={classes.UnknownTabletsCount}>
          {unknownTablets}{' '}
          {t('docks.listDocks.fields.connectedTablets.unknown')}
        </span>
      )}
    </>
  )
}

export default DockSlotsInfo

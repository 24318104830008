import {
  type Integration,
  type DebugLog,
} from 'App/Integrations/integrations-types'
import { type InstallationWizardState as State } from 'App/InstallationWizard/installation-wizard-types'
import { type Network } from 'App/Networks/networks-types'
import { toModel as toNetworkModel } from 'api/networks-api'
import rtkApi from 'rtk-api'

interface IntegrationData {
  name: string
  url: string
  params?: Array<{ paramKey: string; paramValue: string }>
}

interface SiteReq {
  organizationId: number
  siteId: number
}

interface IntegrationReq extends SiteReq {
  data: IntegrationData
}

interface UpdateStateReq extends SiteReq {
  integrationState?: Record<string, any>
  hasAdmin?: boolean
  hasDock?: boolean
  hasSeenWelcome?: boolean
}

interface DebugLogsReq extends SiteReq {
  timestamp: number
}

interface TestCanBorrowReq extends DebugLogsReq {
  data?: any
}

const installationWizardRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    addIntegrationWithParams: builder.mutation<Integration, IntegrationReq>({
      query: ({ organizationId, siteId, data }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/installation-wizard/integration`,
        method: 'POST',
        body: data,
      }),
    }),
    loadState: builder.query<State | null, SiteReq>({
      query: ({ organizationId, siteId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/installation-wizard/state`,
      }),
    }),
    updateState: builder.mutation<void, UpdateStateReq>({
      query: ({ organizationId, siteId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/installation-wizard/cache`,
        method: 'PUT',
        body,
      }),
    }),
    testCanBorrow: builder.mutation<void, TestCanBorrowReq>({
      query: ({ organizationId, siteId, timestamp, data }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/installation-wizard/can-borrow/${timestamp}`,
        method: 'POST',
        body: { data },
      }),
    }),
    loadDebugLogs: builder.query<DebugLog[] | null, DebugLogsReq>({
      query: ({ organizationId, siteId, timestamp }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/installation-wizard/fetch-logs/${timestamp}`,
      }),
    }),
    loadSiteNetworks: builder.query<Network[], SiteReq>({
      query: ({ organizationId, siteId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/networks`,
      }),
      transformResponse: (data: any) => data.map(toNetworkModel),
    }),
  }),
})

export const {
  useAddIntegrationWithParamsMutation,
  useLoadStateQuery,
  useUpdateStateMutation,
  useTestCanBorrowMutation,
  useLoadDebugLogsQuery,
  useLoadSiteNetworksQuery,
} = installationWizardRtkApi

export default installationWizardRtkApi

import React from 'react'
import { useAppSelector } from 'store'

const PageHeader = ({ children }: { children: React.ReactNode }) => {
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )
  return <div dir={languageDirection}>{children}</div>
}

export default PageHeader

import axios from 'axios'
import useDeviceDetector from 'common/hooks/useDeviceDetector'
import { useFeature } from 'common/hooks/useFeature'
import { isFeatureEnabled } from 'common/utils/localStorage'
import { useAppSelector } from 'store'
import { TrackRequestProps } from 'common/hooks/useTracking.types'

const TRACKING_FEATURE_NAME = 'tracking'

// export this for using in non-React component (e.g. redux)
export const trackEvent = (requestBody: TrackRequestProps) => {
  // need to check feature flag again here for redux since it doesn't use useTracking hook
  if (isFeatureEnabled(TRACKING_FEATURE_NAME)) {
    // ignore await for tracking event to avoid slowing down the app
    void axios.post('/api/v2/tracking', requestBody)
  }
}

const useTracking = () => {
  const currentUser = useAppSelector((state) => state.app.currentUser)
  const ipAddress = useAppSelector((state) => state.app.ipAddress)
  const city = useAppSelector((state) => state.app.city)
  const country = useAppSelector((state) => state.app.country)
  const { role, id, organizationId } = currentUser ?? {}

  const { isEnabled } = useFeature(TRACKING_FEATURE_NAME)
  const { isMobile, browser, browserVersion } = useDeviceDetector()

  const track = ({ feature, action, payload }: TrackRequestProps) => {
    const requestBody = {
      feature,
      action,
      payload,
      isMobile,
      browser,
      browserVersion,
      userId: id,
      userRole: role,
      orgId: organizationId,
      ipAddress,
      city,
      country,
    }
    if (isEnabled) {
      trackEvent(requestBody as TrackRequestProps)
    }
  }

  return { track }
}

export default useTracking

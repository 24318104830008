import React from 'react'
import { type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  PageTitleText: {
    margin: 0,
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
}))

interface PageTitleProps {
  title: string
}

const PageTitle = ({ title }: PageTitleProps) => {
  const { classes } = useStyles()

  return <h1 className={classes.PageTitleText}>{title}</h1>
}

export default PageTitle

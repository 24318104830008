import React from 'react'
import { useGetUserPoolDataQuery } from 'App/CognitoLogin/cogonit-login-rtk-api'
import { Button, Typography, Box, Divider, styled } from '@mui/material'
import MicrosoftLogin from 'App/Login/components/MicrosoftLogin'
import LoginPageWrapper from 'App/Login/components/LoginPageWrapper'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATHS } from 'common/constants'

const SeparatorBox = styled(Box)(({ theme }) => ({
  width: '14.5rem',
  margin: theme.spacing(5, 0),
}))

const SSOLogin = () => {
  const { data: userPoolData } = useGetUserPoolDataQuery()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <LoginPageWrapper title={t('loginv2.signInTitle')}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Typography variant="body1" align="center">
          {t('loginv2.ssoInstruction1')}
        </Typography>
        <Typography variant="body1" marginBottom={5} align="center">
          {t('loginv2.ssoInstruction2')}
        </Typography>

        {userPoolData?.clientId && (
          <MicrosoftLogin clientId={userPoolData.clientId} />
        )}

        <SeparatorBox>
          <Divider />
        </SeparatorBox>
        <Button onClick={() => navigate(PATHS.login)} variant="outlined">
          {t('loginv2.goBack')}
        </Button>
      </Box>
    </LoginPageWrapper>
  )
}

export default SSOLogin

import { type CurrentUserContext } from 'App/app-state'
import { useDeepMemo } from 'common/hooks/useDeepMemo'
import { useAppSelector } from 'store'

export const useCurrentUserContext = (): CurrentUserContext | undefined => {
  const role = useAppSelector((state) => state.app.role)
  const adminMode = useAppSelector((state) => state.app.adminMode)
  const organizationDistributorId = useAppSelector(
    (state) => state.app.organizationDistributorId
  )
  const organization = useAppSelector((state) => state.app.organization)
  const site = useAppSelector((state) => state.app.site)

  const userContext = useDeepMemo(() => {
    if (role == null || organization == null) {
      return undefined
    }

    return {
      role,
      organizationDistributorId,
      organizationId: organization.id,
      siteId: site?.id,
      adminMode,
    }
  }, [adminMode, organization, role, site])

  return userContext
}

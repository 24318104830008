import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  Stack,
} from '@mui/material'
import WizardDialog from 'common/components/WizardDialog'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import StepDoneDialog from 'App/InstallationWizard/components/StepDoneDialog'
import { useUpdateStateMutation } from 'App/InstallationWizard/installation-wizard-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import useCurrentAccount from 'common/hooks/useCurrentAccount'

interface AddDockDialogProps {
  open: boolean
  hasDock: boolean
  onClose: () => void
}

const AddDockDialog = ({ open, hasDock, onClose }: AddDockDialogProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { organizationId, siteId } = useCurrentAccount()
  const [isDone, setIsDone] = useState(false)

  const [
    updateState,
    {
      isSuccess: isUpdateStateSuccess,
      error: updateStateError,
      reset: resetStateUpdate,
    },
  ] = useUpdateStateMutation()

  const [hasUpdateStateErrors, apiUpdateStateErrorsMsg] = useApiErrors([
    updateStateError,
  ])

  useEffect(() => {
    if (isUpdateStateSuccess) {
      setIsDone(true)
      resetStateUpdate()
    }
  }, [isUpdateStateSuccess, setIsDone, resetStateUpdate])

  const handleClose = () => {
    setIsDone(false)
    onClose()
  }

  const onInstall = () => {
    navigate('/docks')
    handleClose()
  }

  const onInstalled = () => {
    if (hasDock) {
      setIsDone(true)
    } else {
      updateState({ organizationId, siteId, hasDock: true })
    }
  }

  return (
    <>
      <WizardDialog
        open={open && !isDone}
        onClose={onClose}
        narrow
        bottomBackground
      >
        <Stack
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
            <DialogTitle
              mt={3}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontSize: '2.2rem',
                fontWeight: 'bold',
              })}
            >
              {t('installationWizard.dock.title')}
            </DialogTitle>
            <Typography
              width="519px"
              textAlign="center"
              fontSize="1.1rem"
              mt={1}
            >
              {t('installationWizard.dock.description')}
            </Typography>
            <Box mt={10}>
              <Button small onClick={onInstall}>
                {t('installationWizard.dock.install')}
              </Button>
            </Box>
            <Box mt={2}>
              <Button small outlined onClick={onInstalled}>
                {t('installationWizard.dock.installed')}
              </Button>
            </Box>
          </Stack>
          <DialogActions
            sx={{ pt: 4, pb: 8, display: 'flex', justifyContent: 'center' }}
          >
            <Button small onClick={onClose}>
              {t('installationWizard.close')}
            </Button>
          </DialogActions>
        </Stack>
      </WizardDialog>

      <StepDoneDialog
        title={t('installationWizard.dock.done.title')}
        description={t('installationWizard.dock.done.description')}
        open={isDone}
        onClose={handleClose}
      />

      <Toast
        open={hasUpdateStateErrors}
        message={apiUpdateStateErrorsMsg}
        onClose={resetStateUpdate}
      />
    </>
  )
}

export default AddDockDialog

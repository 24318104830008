import React, { useState, useEffect, useMemo } from 'react'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { LinearProgress, Stack, Typography } from '@mui/material'
import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import { type Product } from 'App/SuperAdmin/super-admin-types'
import Table, {
  type TableColumn,
  TableColumnFilter,
} from 'common/components/TableNew'
import {
  useGetProductsQuery,
  useDeleteProductMutation,
} from 'App/SuperAdmin/super-admin-rtk-api'
import IconMenu from 'common/components/IconMenu'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import AddProductDrawer from './components/AddProductDrawer'
import EditProductDrawer from './components/EditProductDrawer'
import ActionDialog from 'common/components/ActionDialog'
import Toast from 'common/components/Toast'
import { toDateFieldValue } from 'common/utils/date-utils'

const useStyles = makeStyles()((theme: Theme) => ({
  SkuActive: {
    fontWeight: 'bold',
    color: '#64AB43',
  },
  SkuInactive: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
}))

interface ProductRowActionsProps {
  product: Product
  onClickEdit: (productId: number) => void
  onClickDelete: (productId: number) => void
}

const ProductRowActions = ({
  product,
  onClickEdit,
  onClickDelete,
}: ProductRowActionsProps) => {
  const actions = [
    {
      local: 'Edit',
      fn: () => onClickEdit(product.id),
      icon: EditIcon,
    },
    {
      local: 'Delete',
      fn: () => onClickDelete(product.id),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

const ProductsTable = () => {
  const { classes } = useStyles()

  const { data: productsData, refetch: refetchProductData } =
    useGetProductsQuery()

  const [
    deleteProductData,
    { error: deleteProductError, isSuccess: isProductDeleted },
  ] = useDeleteProductMutation()

  useEffect(() => {
    if (isProductDeleted) {
      refetchProductData()
      setIsDeleteSaveSuccess(true)
    }
  }, [isProductDeleted, refetchProductData])

  useEffect(() => {
    if (deleteProductError) {
      setIsDeleteSaveError(true)
    }
  }, [deleteProductError])

  const [toOpenAddProduct, setToOpenAddProduct] = useState<boolean>(false)

  const [toEditProductId, setToEditProductId] = useState<number | null>(null)
  const toEditProductData = useMemo(() => {
    if (toEditProductId == null) {
      return null
    }

    return productsData?.find((product) => product.id === toEditProductId)
  }, [productsData, toEditProductId])

  const [toDeleteProductId, setToDeleteProductId] = useState<number | null>(
    null
  )
  const toDeleteProductData = useMemo(() => {
    if (toDeleteProductId == null) {
      return null
    }

    return productsData?.find((product) => product.id === toDeleteProductId)
  }, [productsData, toDeleteProductId])

  const [isAddSaveSuccess, setIsAddSaveSuccess] = useState(false)
  const [isEditSaveSuccess, setIsEditSaveSuccess] = useState(false)
  const [isDeleteSaveSuccess, setIsDeleteSaveSuccess] = useState(false)
  const [isDeleteSaveError, setIsDeleteSaveError] = useState(false)

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        title: 'SKU',
        field: 'sku',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Name',
        field: 'name',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Description',
        field: 'description',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Public URL',
        field: 'publicUrl',
        filter: TableColumnFilter.Text,
        canSort: true,
        render: ({ publicUrl }: Product) => {
          if (publicUrl === undefined) {
            return
          }

          const formattedUrl =
            publicUrl.startsWith('http://') || publicUrl.startsWith('https://')
              ? publicUrl
              : `http://${publicUrl}`
          return (
            <a href={formattedUrl} target="_blank" rel="noopener noreferrer">
              {publicUrl}
            </a>
          )
        },
      },
      {
        title: 'Is active',
        field: 'isActive',
        filter: TableColumnFilter.Boolean,
        canSort: true,
        render: ({ isActive }: Product) =>
          isActive ? (
            <span className={classes.SkuActive}>Active</span>
          ) : (
            <span className={classes.SkuInactive}>Inactive</span>
          ),
      },
      {
        title: 'End of life date',
        field: 'endOfLifeDate',
        filter: TableColumnFilter.DateRange,
        canSort: true,
        render: ({ endOfLifeDate }: Product) =>
          endOfLifeDate ? toDateFieldValue(endOfLifeDate) : '',
      },
      {
        title: 'Category',
        field: 'category',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Business model',
        field: 'businessModel',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (product: Product) => (
          <ProductRowActions
            product={product}
            onClickEdit={(productId: number) => setToEditProductId(productId)}
            onClickDelete={(productId: number) =>
              setToDeleteProductId(productId)
            }
          />
        ),
      },
    ]
  }, [classes])

  const handleAddsaved = () => {
    refetchProductData()
    setToOpenAddProduct(false)
    setIsAddSaveSuccess(true)
  }

  const handleEditSaved = () => {
    refetchProductData()
    setToEditProductId(null)
    setIsEditSaveSuccess(true)
  }

  if (productsData == null) {
    return <LinearProgress />
  }

  const onClickAddProduct = () => {
    setToOpenAddProduct(true)
  }

  const onActionDeleteDialog = () => {
    if (toDeleteProductId !== null) {
      deleteProductData(toDeleteProductId)
    }
  }

  const onCloseDeleteDialog = () => {
    setToDeleteProductId(null)
  }

  return (
    <>
      <PageActions>
        <Button onClick={onClickAddProduct} small>
          Add SKU
        </Button>
      </PageActions>
      <Table data={productsData} columns={columns} />
      {toOpenAddProduct && (
        <AddProductDrawer
          open={toOpenAddProduct}
          onClose={() => setToOpenAddProduct(false)}
          onSaved={handleAddsaved}
        />
      )}
      {toEditProductData != null && (
        <EditProductDrawer
          open={toEditProductData != null}
          onClose={() => setToEditProductId(null)}
          onSaved={handleEditSaved}
          productData={toEditProductData}
        />
      )}
      {setToDeleteProductId != null && (
        <ActionDialog
          open={toDeleteProductId !== null}
          title={'Delete product'}
          description={`Do you want to delete the product (name: ${
            toDeleteProductData?.name ?? 'Unknown Name'
          }, SKU: ${toDeleteProductData?.sku ?? 'Unknown SKU'})?`}
          actionText={'Delete'}
          onAction={onActionDeleteDialog}
          onClose={onCloseDeleteDialog}
        />
      )}
      <Toast
        message="Product data added"
        open={isAddSaveSuccess}
        onClose={() => setIsAddSaveSuccess(false)}
      />
      <Toast
        message="Product data saved"
        open={isEditSaveSuccess}
        onClose={() => setIsEditSaveSuccess(false)}
      />
      <Toast
        message="Product data deleted"
        open={isDeleteSaveSuccess}
        onClose={() => setIsDeleteSaveSuccess(false)}
      />
      <Toast
        message="Product data deletion failed"
        open={isDeleteSaveError}
        onClose={() => setIsDeleteSaveError(false)}
      />
    </>
  )
}

const Products = () => {
  return (
    <Page>
      <PageHeader>
        <Stack>
          <Typography
            sx={(theme) => ({
              fontWeight: 600,
              color: theme.palette.secondary.main,
            })}
          >
            Super-admin
          </Typography>
          <PageTitle title="Product Portfolio Management" />
        </Stack>
      </PageHeader>
      <PageBody>
        <Stack gap={2}>
          <Typography variant="h2">Products and Services</Typography>
          <ProductsTable />
        </Stack>
      </PageBody>
    </Page>
  )
}

export default Products

import React, { useState } from 'react'
import { Button, Menu, MenuItem, styled } from '@mui/material'
import { ArrowDropDown, Language } from '@mui/icons-material'
import { useLanguage } from 'locales'
import { useAppSelector } from 'store'
import { useTranslation } from 'react-i18next'

const LanguageButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  ...(theme.direction === 'rtl'
    ? { left: theme.spacing(2) }
    : { right: theme.spacing(2) }),
}))

// TODO: add props for SelectLanguage for positioning the options

const SelectLanguage = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [language, setLanguage] = useLanguage()
  const languages = useAppSelector((state) => state.app.languages)
  const { t } = useTranslation()

  const open = Boolean(anchorEl)

  const handleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectLanguage = (lang: string) => {
    setLanguage(lang)
    setAnchorEl(null)
  }

  return (
    <>
      <LanguageButton
        startIcon={<Language />}
        endIcon={<ArrowDropDown />}
        id="language-button"
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleBtnClick}
        size="small"
      >
        {t(`languages.${language}`)}
      </LanguageButton>
      <Menu
        id="language-menu"
        data-testid="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {languages.map((l: string) => (
          <MenuItem key={l} value={l} onClick={() => handleSelectLanguage(l)}>
            {t(`languages.${l}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SelectLanguage

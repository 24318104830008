import rtkApi from 'rtk-api'

interface PostRes {
  isSuccess: boolean
  error?: string
  data?: { session: string }
}

// Overlapping error codes
type OverlappingErrorCode =
  | 'ForbiddenException'
  | 'InternalErrorException'
  | 'InvalidLambdaResponseException'
  | 'InvalidParameterException'
  | 'LimitExceededException'
  | 'NotAuthorizedException'
  | 'ResourceNotFoundException'
  | 'TooManyRequestsException'
  | 'UnexpectedLambdaException'
  | 'UserLambdaValidationException'
  | 'UserNotFoundException'

// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html
type ForgotPasswordErrorCode =
  | OverlappingErrorCode
  | 'CodeDeliveryFailureException'
  | 'InvalidEmailRoleAccessPolicyException'
  | 'InvalidSmsRoleAccessPolicyException'
  | 'InvalidSmsRoleTrustRelationshipException'

// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmForgotPassword.html
type ConfirmForgotPasswordErrorCode =
  | OverlappingErrorCode
  | 'CodeMismatchException'
  | 'ExpiredCodeException'
  | 'InvalidPasswordException'
  | 'TooManyFailedAttemptsException'
  | 'UserNotConfirmedException'

// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
type RespondToAuthChallengeErrorCode =
  | OverlappingErrorCode
  | 'AliasExistsException'
  | 'CodeMismatchException'
  | 'ExpiredCodeException'
  | 'InvalidPasswordException'
  | 'InvalidSmsRoleAccessPolicyException'
  | 'InvalidSmsRoleTrustRelationshipException'
  | 'InvalidUserPoolConfigurationException'
  | 'MFAMethodNotFoundException'
  | 'PasswordHistoryPolicyViolationException'
  | 'PasswordResetRequiredException'
  | 'SoftwareTokenMFANotFoundException'
  | 'UserNotConfirmedException'

interface PostResForgotPassword {
  isSuccess: boolean
  error?: { code: ForgotPasswordErrorCode }
}

interface PostResConfirmForgotPassword {
  isSuccess: boolean
  error?: { code: ConfirmForgotPasswordErrorCode }
}

interface PostResForceChangePassword {
  isSuccess: boolean
  error?: { code: RespondToAuthChallengeErrorCode }
}

interface EmailPasswordApi {
  username: string
  password: string
}

interface UserPoolDate {
  clientId: string
}

interface ForgetPasswordApi {
  email: string
}

interface ConfirmPasswordApi {
  email: string
  code: string
  newPassword: string
}

interface ForceChangePasswordApi {
  email: string
  session: string
  newPassword: string
}

const cognitoLoginRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    emailPasswordLogin: builder.mutation<PostRes, EmailPasswordApi>({
      query: ({ username, password }) => ({
        url: `/user/login`,
        method: 'POST',
        body: { username, password },
      }),
    }),
    getUserPoolData: builder.query<UserPoolDate, void>({
      query: () => ({
        url: `/aws/cognito/user-pool`,
      }),
    }),
    forgetPassword: builder.mutation<PostResForgotPassword, ForgetPasswordApi>({
      query: ({ email }) => ({
        url: `/aws/cognito/forgot-password`,
        method: 'POST',
        body: { email },
      }),
    }),
    confirmPassword: builder.mutation<
      PostResConfirmForgotPassword,
      ConfirmPasswordApi
    >({
      query: ({ email, code, newPassword }) => ({
        url: `/aws/cognito/confirm-password`,
        method: 'POST',
        body: { email, code, newPassword },
      }),
    }),
    forceChangePassword: builder.mutation<
      PostResForceChangePassword,
      ForceChangePasswordApi
    >({
      query: ({ email, session, newPassword }) => ({
        url: `/aws/cognito/force-change-password`,
        method: 'POST',
        body: { email, session, newPassword },
      }),
    }),
  }),
})

export const {
  useEmailPasswordLoginMutation,
  useGetUserPoolDataQuery,
  useForgetPasswordMutation,
  useConfirmPasswordMutation,
  useForceChangePasswordMutation,
} = cognitoLoginRtkApi

export default cognitoLoginRtkApi

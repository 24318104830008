import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import IconTooltip from 'common/components/IconTooltip'
import Switch from 'common/components/Switch'
import TooltipInfo, {
  type TooltipInfoContent,
} from 'common/components/TooltipInfo'
import React from 'react'

const useStyles = makeStyles()((theme: Theme) => ({
  DrawerLabel: {
    marginBottom: theme.spacing(2),
  },
  LabelTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  LabelDescription: {
    marginTop: theme.spacing(1),
  },
  SwitchField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  DrawerLabelTitle: {
    color: '#000',
    textTransform: 'uppercase',
  },
  DrawerSwitchField: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  DrawerSwitch: {
    margin: -12,
  },
  DescriptionIcon: {
    display: 'inline-block',
    float: 'right',
    '& svg': {
      color: theme.palette.grey['300'],
      transition: 'color 0.15s ease',
    },
    '&:hover svg': {
      color: theme.palette.warning.main,
    },
  },
}))

interface FormSwitchProps {
  title: string
  description?: string
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  variant?: 'popup' | 'drawer'
  disabled?: boolean
  info?: TooltipInfoContent
  readOnly?: boolean
}

const FormSwitch = ({
  title,
  description,
  checked,
  onChange,
  variant,
  disabled = false,
  info,
  readOnly = false,
}: FormSwitchProps) => {
  const { classes, cx } = useStyles()

  const titleClasses = [classes.LabelTitle]
  const fieldClasses = [classes.SwitchField]
  const switchClasses = []
  const descriptionClasses = []
  const labelClasses = []

  if (variant === 'drawer') {
    titleClasses.push(classes.DrawerLabelTitle)
    fieldClasses.push(classes.DrawerSwitchField)
    switchClasses.push(classes.DrawerSwitch)
    labelClasses.push(classes.DrawerLabel)
  } else {
    descriptionClasses.push(classes.LabelDescription)
  }

  return (
    <Grid className={cx(fieldClasses)}>
      <Grid className={cx(labelClasses)} container direction="column">
        <Grid className={cx(titleClasses)} item>
          {title}
          {info != null && (
            <div className={classes.DescriptionIcon}>
              <IconTooltip title={<TooltipInfo content={info} />} />
            </div>
          )}
        </Grid>
        {description && (
          <Grid className={cx(descriptionClasses)} item>
            {description}
          </Grid>
        )}
      </Grid>
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={cx(switchClasses)}
        readOnly={readOnly}
      />
    </Grid>
  )
}

export default FormSwitch

import {
  DeleteRounded,
  PowerSettingsNewRounded,
  RemoveRedEyeRounded,
  RestartAltRounded,
} from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { GridRowId, GridToolbarContainer } from '@mui/x-data-grid'

interface ActionsToolbarProps {
  selectedRows: GridRowId[]
  onViewDetails: () => void
  onReboot: () => void
  onShutdown: () => void
  onDelete: () => void
}

const ActionsToolbar = ({
  selectedRows,
  onViewDetails,
  onReboot,
  onShutdown,
  onDelete,
}: ActionsToolbarProps) => {
  if (!selectedRows.length) {
    return null
  }

  const singleRowSelected = selectedRows.length === 1

  return (
    <GridToolbarContainer>
      <Typography variant="body1" mt={1} mb={1} fontWeight={600} pl="12px">
        Actions:{' '}
      </Typography>

      {singleRowSelected && (
        <Tooltip title="View details" placement="top">
          <IconButton onClick={onViewDetails} color="primary">
            <RemoveRedEyeRounded />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="Reboot" placement="top">
        <IconButton color="success" onClick={onReboot}>
          <RestartAltRounded />
        </IconButton>
      </Tooltip>

      <Tooltip title="Shutdown" placement="top">
        <IconButton color="warning" onClick={onShutdown}>
          <PowerSettingsNewRounded />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete" placement="top">
        <IconButton color="error" onClick={onDelete}>
          <DeleteRounded />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  )
}

export default ActionsToolbar

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Stack, Typography } from '@mui/material'
import WizardDialog from 'common/components/WizardDialog'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import android_logo from 'common/android-logo.svg'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useLocation } from 'react-router-dom'
import { API_BASE_URL } from 'common/constants'
import Axios from 'axios'

const AndroidLogo = () => (
  <img src={android_logo} alt="Android Logo" style={{ width: '159px' }} />
)

interface SetupGoogleDialogProps {
  open: boolean
  onClose: () => void
}

const SetupGoogleDialog = ({ open, onClose }: SetupGoogleDialogProps) => {
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()
  const { pathname } = useLocation()

  const [step, setStep] = useState<number>(0)
  const [googleAccoundAdded, setGoogleAccoundAdded] = useState<boolean>(false)
  const [enrollError, setEnrollError] = useState<string>('')

  const handleCreateAccount = () => {
    const googleCreateAccountUrl =
      'https://support.google.com/accounts/answer/27441'
    window.open(googleCreateAccountUrl, '_blank')
    onClose()
  }

  const handleClickNext = () => {
    setStep(1)
  }

  const handleClickEnroll = () => {
    Axios.post(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/enroll?redirectTo=${pathname}`
    )
      .then(({ data }) => {
        if (data) {
          window.location.href = data
        }
      })
      .catch(() => {
        setEnrollError(t('installationWizard.google.error.enroll'))
      })

    setStep(0)
    onClose()
  }

  const handelClose = () => {
    setGoogleAccoundAdded(false)
    onClose()
  }

  return (
    <>
      <WizardDialog
        open={open && step === 0 && !googleAccoundAdded}
        onClose={onClose}
        fullBackground
      >
        <Grid container height="100%">
          <Grid xs={5} container justifyContent="center" alignContent="center">
            <AndroidLogo />
          </Grid>
          <Grid xs={7}>
            <Stack
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <Stack
                mt={14}
                mr={6}
                sx={{ flexGrow: 1, alignItems: 'flex-start' }}
              >
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  })}
                >
                  {t('installationWizard.google.title')}
                </Typography>
                <Typography mt={2}>
                  {t('installationWizard.google.description')}
                </Typography>
                <Typography mt={2}>
                  {t('installationWizard.google.tip')}
                </Typography>
              </Stack>
              <Box
                my={6}
                mr={6}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  small
                  outlined
                  onClick={handleCreateAccount}
                  sx={{ mr: 1 }}
                >
                  {t('installationWizard.google.create')}
                </Button>
                <Button small onClick={handleClickNext}>
                  {t('installationWizard.google.exists')}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </WizardDialog>

      <WizardDialog
        open={open && step === 1 && !googleAccoundAdded}
        onClose={onClose}
        fullBackground
      >
        <Grid container height="100%">
          <Grid xs={5} container justifyContent="center" alignContent="center">
            <AndroidLogo />
          </Grid>
          <Grid xs={7}>
            <Stack
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <Stack
                mt={14}
                mr={6}
                sx={{ flexGrow: 1, alignItems: 'flex-start' }}
              >
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  })}
                >
                  {t('installationWizard.google.setup')}
                </Typography>
                <Typography mt={2}>
                  {t('installationWizard.google.setup.description')}
                </Typography>
              </Stack>
              <Box
                my={6}
                mr={6}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button small onClick={handleClickEnroll}>
                  {t('installationWizard.continue')}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </WizardDialog>

      <Toast
        open={enrollError.length > 0}
        message={enrollError}
        onClose={() => setEnrollError('')}
      />

      <WizardDialog
        open={googleAccoundAdded}
        onClose={handelClose}
        fullBackground
      >
        {t('installationWizard.google.added')}
      </WizardDialog>
    </>
  )
}

export default SetupGoogleDialog

import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import { useTranslation } from 'react-i18next'
import Panel from 'common/components/Panel'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from 'common/components/Button'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { fetchCustomer } from 'App/Customers/customers-state'
import CustomerSettingsGeneralDrawer from 'App/CustomerSettings/components/CustomerSettingsGeneralDrawer'
import useApp from 'common/hooks/useApp'
import { setOrganization } from 'App/app-state'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  PanelContainer: {
    borderRadius: '10px',
    border: 0,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    marginBottom: theme.spacing(4),
  },
  PanelTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  PanelContent: {
    padding: '0',
  },
  PanelItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: theme.spacing(3),

    borderBottom: '1px solid #e5e5e5',
    '&:last-child': {
      borderBottom: 0,
    },
  },
  EnrolledInfo: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  UnenrolledInfo: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: theme.palette.error.main,
  },
}))

const CustomerSettings = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()

  const customer = useAppSelector((state) => state.customers.customer)
  const { organizationDistributorId, organizationId } = useCurrentAccount()

  const [openGeneralDrawer, setOpenGeneralDrawer] = useState(false)

  const { organization: activeCustomer } = useApp()

  useEffect(() => {
    if (
      (customer === null || customer.id !== organizationId) &&
      organizationDistributorId !== undefined
    ) {
      dispatch(
        fetchCustomer({
          distributorId: organizationDistributorId,
          customerId: organizationId,
        })
      )
    }
  }, [dispatch, organizationId, customer, organizationDistributorId])

  useEffect(() => {
    if (activeCustomer !== undefined && activeCustomer.id === customer?.id) {
      // Update active customer data if customer data has changed
      if (
        activeCustomer.name !== customer.name ||
        activeCustomer.timezone !== customer.timezone
      ) {
        dispatch(
          setOrganization({
            id: customer.id,
            name: customer.name,
            timezone: customer.timezone,
          })
        )
      }
    }
  }, [customer, dispatch, activeCustomer])

  if (organizationDistributorId === undefined || customer === null) {
    return <Page />
  }

  return (
    <>
      <Page>
        <PageTitle title={t('customerSettings.title')} />
        <PageBody>
          <Panel
            title={
              <Grid className={classes.PanelTitle}>
                <Grid>{t('customerSettings.general.title')}</Grid>
                <Grid>
                  <Button onClick={() => setOpenGeneralDrawer(true)} small flat>
                    {t('customerSettings.general.editButton')}
                  </Button>
                </Grid>
              </Grid>
            }
            classes={{
              container: classes.PanelContainer,
              content: classes.PanelContent,
            }}
          >
            <Grid className={classes.PanelItem}>
              <Grid>{t('customerSettings.general.fields.name')}</Grid>
              <Grid>{customer.name}</Grid>
            </Grid>
            <Grid className={classes.PanelItem}>
              <Grid>{t('customerSettings.general.fields.timezone')}</Grid>
              <Grid>{customer.timezone ?? '-'}</Grid>
            </Grid>
            <Grid className={classes.PanelItem}>
              <Grid>{t('customerSettings.general.fields.customerId')}</Grid>
              <Grid>{customer.hubletId ?? '-'}</Grid>
            </Grid>
          </Panel>
        </PageBody>
      </Page>
      <CustomerSettingsGeneralDrawer
        distributorId={organizationDistributorId}
        customer={customer}
        open={openGeneralDrawer}
        onClose={() => setOpenGeneralDrawer(false)}
      />
    </>
  )
}

export default CustomerSettings

import { Dock } from 'App/Docks/docks-types'

export const dedup = (docks: Dock[]) => {
  const cleanedResult = []
  const existed: Record<number, boolean> = {}

  const duplicatedDockIds = []
  const existed2: Record<number, Array<number | undefined>> = {}

  for (const dock of docks) {
    // handling dedup to be shown in the DataGrid
    if (!dock.deletedAt && !existed[dock.id]) {
      // assuming all dockIds which haven't been deleted are unique
      existed[dock.id] = true
      cleanedResult.push(dock)
    } else if (dock.deletedAt) {
      // assuming all deleted docks are duplicated, re-write the ID with deletedAt string to avoid duplication for DataGrid
      const { id, deletedAt, ...rest } = dock
      cleanedResult.push({ id: `${id}#${deletedAt}`, deletedAt, ...rest })
    }

    // checking duplicated docks for manually resolving in database
    if (!existed2[dock.id]) {
      existed2[dock.id] = [dock.deletedAt]
    } else if (!existed2[dock.id].includes(dock.deletedAt)) {
      existed2[dock.id].push(dock.deletedAt)
    } else {
      // there is a duplicate
      duplicatedDockIds.push(dock.id)
    }
  }

  return { cleanedResult, duplicatedDockIds }
}

import { UserRole } from 'App/app-state'

export enum UserStatus {
  InvitationPending = 'invitation-pending',
  InvitationExpired = 'invitation-expired',
  Active = 'active',
  Locked = 'locked',
  Deleted = 'deleted',
}

export interface User {
  id: number
  organizationId: number
  siteId?: number
  email: string
  role: UserRole
  firstName: string
  lastName: string
  rcvExpLoansMail: boolean
  deletedAt?: number
  resetPasswordSentAt?: string
  invitationExpiresAt?: string
  loginSuccessLast?: string
  loginFailureLast?: string
  isLocked: boolean
  status?: UserStatus

  cognitoUserId?: string
  cognitoUserStatus?: string
  cognitoUserCreatedAt?: string
  cognitoUserUpdatedAt?: string
  cognitoInvitationSentAt?: string
  cognitoInvitationExpiresAt?: string
}

export interface UserEditable {
  role?: UserRole
  organizationId?: number
  siteId?: number
  firstName?: string
  lastName?: string
  rcvExpLoansMail?: boolean
  rcvExpLoansMailSiteId?: number
}

export interface InvitedUser {
  id: number
  email: string
  role: UserRole
}

import axios from 'axios'
import { useDeepEffect } from 'common/hooks/useDeepEffect'
import { TrackingDataType } from 'common/hooks/useTracking.types'
import { listToQueryParams } from 'common/utils/api-utils'
import { useState } from 'react'

export const useTrackingData = ({
  features,
  actions,
}: {
  features: string[]
  actions: string[]
}) => {
  const [rows, setRows] = useState<TrackingDataType[]>([])

  useDeepEffect(() => {
    // TODO: use rtk for api fetching?
    const fetchRowData = async () => {
      let url = '/api/v2/tracking'
      let hasFeature = false

      if (features.length > 0) {
        url += `?${listToQueryParams('features', features)}`
        hasFeature = true
      }

      if (actions.length > 0) {
        url += hasFeature ? '&' : '?'
        url += `${listToQueryParams('actions', actions)}`
      }
      try {
        const { data } = await axios.get(url)
        setRows(data)
      } catch (error) {
        console.log('Failed getting tracking data', error)
        setRows([])
      }
    }

    void fetchRowData()
  }, [features, actions])

  return { rows }
}

import React, { useMemo } from 'react'
import { Grid, Box } from '@mui/material'
import FormField from 'common/components/FormField'
import PasswordField from 'common/components/PasswordField'
import FormSelect, { type FormSelectOption } from 'common/components/FormSelect'
import FormSwitch from 'common/components/FormSwitch'
import { type TooltipInfoContent } from 'common/components/TooltipInfo'

export enum FormItemType {
  Field = 'field',
  Number = 'number',
  Password = 'password',
  Datetime = 'datetime',
  SelectOne = 'selectOne',
  SelectMulti = 'selectMulti',
  Switch = 'switch',
  URL = 'url',
}

export interface FormItemDef {
  type: FormItemType
  key: string
  label: string
  placeholder?: string
  options?: FormSelectOption[]
  nullable?: boolean
  info?: TooltipInfoContent
  disabled?: boolean
  emptyLabel?: string
}

interface FormItem extends FormItemDef {
  value: any
  onChange: (label: string, value: any) => void
  error: string
}

interface FormItemProps {
  item: FormItem
}

const Item = ({ item }: FormItemProps) => {
  switch (item.type) {
    case FormItemType.Field:
      return (
        <FormField
          label={item.label}
          type={'text'}
          placeholder={item?.placeholder ?? ''}
          value={item.value as string}
          onChange={(e) => item.onChange(item.key, e.target.value)}
          error={item.error}
          disabled={item.disabled}
        />
      )
    case FormItemType.Number:
      return (
        <FormField
          label={item.label}
          type={'number'}
          placeholder={item?.placeholder ?? ''}
          value={item.value as number}
          onChange={(e) => item.onChange(item.key, Number(e.target.value))}
          error={item.error}
          disabled={item.disabled}
        />
      )
    case FormItemType.Datetime:
      return (
        <FormField
          label={item.label}
          type={'datetime-local'}
          placeholder={item?.placeholder ?? ''}
          value={item.value}
          onChange={(e) => item.onChange(item.key, e.target.value)}
          error={item.error}
          disabled={item.disabled}
        />
      )
    case FormItemType.Password:
      return (
        <PasswordField
          label={item.label}
          placeholder={item?.placeholder ?? ''}
          value={item.value as string}
          onChange={(e) => item.onChange(item.key, e.target.value)}
          error={item.error}
          disabled={item.disabled}
        />
      )
    case FormItemType.SelectOne:
      return (
        item?.options && (
          <FormSelect
            label={item.label}
            placeholder={item?.placeholder ?? ''}
            options={item.options}
            value={item.value as string}
            onChange={(e) => item.onChange(item.key, e.target.value)}
            error={item.error}
            disabled={item.disabled}
            emptyLabel={item.emptyLabel}
            renderValue={() =>
              item.options?.find((opt) => opt.value === item.value)?.label ??
              item?.placeholder ??
              ''
            }
          />
        )
      )
    case FormItemType.SelectMulti:
      return (
        item?.options && (
          <FormSelect
            label={item.label}
            placeholder={item?.placeholder ?? ''}
            options={item.options}
            value={(item.value as string).split(',')}
            onChange={(e) => item.onChange(item.key, e.target.value.join(','))}
            multiple
            error={item.error}
            disabled={item.disabled}
          />
        )
      )
    case FormItemType.URL:
      return (
        <FormField
          label={item.label}
          type={'url'}
          placeholder={item?.placeholder ?? ''}
          value={item.value as string}
          onChange={(e) => item.onChange(item.key, e.target.value)}
          error={item.error}
          info={item?.info}
          disabled={item.disabled}
        />
      )
    case FormItemType.Switch:
      return (
        <FormSwitch
          title={item.label}
          checked={item.value === 'true'}
          onChange={(_e, checked) =>
            item.onChange(item.key, checked ? 'true' : 'false')
          }
          disabled={item.disabled}
          variant="drawer"
        />
      )
  }
}

interface DualItemFormRowProps {
  items: FormItem[]
}

const DualItemFormRow = ({ items }: DualItemFormRowProps) => {
  return (
    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
      <Grid item xs={6}>
        <Item item={items[0]} />
      </Grid>
      <Grid item xs={6}>
        {items.length === 2 && <Item item={items[1]} />}
      </Grid>
    </Grid>
  )
}

const splitIntoPairs = (arr: FormItem[]) => {
  const result = []

  for (let i = 0; i < arr.length; i += 2) {
    if (i === arr.length - 1) {
      result.push([arr[i]])
    } else {
      result.push([arr[i], arr[i + 1]])
    }
  }

  return result
}

interface WideFormProps {
  items: FormItemDef[]
  values: Record<string, any>
  errors?: Record<string, string>
  onChange: (key: string, value: any) => void
}

const WideForm = ({ items, values, errors = {}, onChange }: WideFormProps) => {
  const itemPairs = useMemo(
    () =>
      splitIntoPairs(
        items.map((item) => ({
          ...item,
          value: values[item.key],
          error: errors[item.key],
          onChange,
        }))
      ),
    [items, errors, values, onChange]
  )

  return (
    <Box sx={{ justifyContent: 'center' }}>
      {itemPairs.map((pair, index) => (
        <DualItemFormRow key={index} items={pair} />
      ))}
    </Box>
  )
}

export default WideForm

import { useMemo, useRef } from 'react'
import isDeepEqual from 'fast-deep-equal'

export const useDeepMemo = <T>(callback: () => T, deps: unknown[]): T => {
  const ref = useRef<unknown[]>()

  if (!ref.current || !isDeepEqual(deps, ref.current)) {
    ref.current = deps
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(callback, [ref.current])
}

import rtkApi from 'rtk-api'

export interface OrganizationDataForReview {
  name: string
  timezone: string
}

export interface SiteDataForReview {
  name: string
  addressStreet1: string
  addressStreet2: string
  addressZipcode: string
  addressCity: string
  addressCountry: string
  tzdbTimezone: string
  customerSectorId?: number
}

export interface UserDataForReview {
  firstName: string
  lastName: string
  isSubscribedToExpiredLoans?: boolean
  isSubscribedToReleaseNotes: boolean
}

export interface DataForReview {
  organizations: Record<string, OrganizationDataForReview>
  sites: Record<string, SiteDataForReview>
  users: Record<string, UserDataForReview>
}

const dataReviewRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataForReview: builder.query<DataForReview, void>({
      query: () => `/data-review`,
    }),
    postReviewData: builder.mutation<void, { data: DataForReview }>({
      query: ({ data }) => ({
        url: `/data-review`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const { useGetDataForReviewQuery, usePostReviewDataMutation } =
  dataReviewRtkApi

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import {
  useAddIntegrationMutation,
  useGetIntegrationTemplatesQuery,
} from 'App/Integrations/integrations-rtk-api'
import { type TooltipInfoContent } from 'common/components/TooltipInfo'
import { type AddIntegrationData } from 'App/Integrations/integrations-types'

import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import { addedIntegration } from 'App/Integrations/integrations-state'
import useAuthMethodOptions from 'App/Integrations/hooks/useAuthMethodOptions'
import useUrlStatus from 'common/hooks/useUrlStatus'
import { useAppDispatch } from 'store'

interface AddIntegrationDrawerProps {
  open: boolean
  onClose: () => void
}

export const fieldInfo: Record<string, TooltipInfoContent> = {
  url: {
    example: `https://${window.location.hostname}/integrations/http_json`,
  },
}

interface FormErrors {
  name?: string
  authMethod?: string
  url?: string
}

const AddIntegrationDrawer = ({ open, onClose }: AddIntegrationDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const [form, setForm] = useState<Partial<AddIntegrationData>>({})
  const [errors, setErrors] = useState<FormErrors>({})
  const [authMethod, setAuthMethod] = useState<string | undefined>()
  const urlStatus = useUrlStatus(form.url)

  const { data: integrationTemplates } = useGetIntegrationTemplatesQuery({
    organizationId,
    siteId,
  })

  const [
    addIntegration,
    {
      isLoading: isAdding,
      isSuccess: isAddSuccess,
      error: addIntegrationError,
    },
  ] = useAddIntegrationMutation()

  const [hasApiMutationErrors, apiMutationErrorMsg] = useApiErrors([
    addIntegrationError,
  ])

  useEffect(() => {
    if (isAddSuccess) {
      dispatch(addedIntegration())
      setForm({})
      setAuthMethod(undefined)
    }
  }, [dispatch, isAddSuccess])

  const authenticationMethodOptions = useAuthMethodOptions(integrationTemplates)

  const handleChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm((prevForm) => ({ ...prevForm, [key]: e.target.value }))
    }

  const handleSelectAuthMethod = (key: string) => {
    setAuthMethod(key)

    let newUrl = ''
    if (key !== 'custom' && integrationTemplates !== undefined) {
      newUrl = integrationTemplates.find((it) => it.key === key)?.url ?? ''
    }
    setForm((prevForm) => ({ ...prevForm, url: newUrl }))
  }

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()

    const { name, url } = form
    const newErrors: FormErrors = {}
    let hasError = false

    if (name === undefined || name.trim() === '') {
      newErrors.name = t('common.errors.emptyField')
      hasError = true
    }

    if (authMethod === undefined) {
      newErrors.authMethod = t('common.errors.emptyField')
      hasError = true
    }

    if (authMethod === 'custom') {
      if (urlStatus === 'default') {
        newErrors.url = t('common.errors.emptyField')
        hasError = true
      } else if (urlStatus === 'invalid') {
        newErrors.url = t('common.formField.url.status.invalid')
        hasError = true
      }
    }

    setErrors(newErrors)

    if (hasError) {
      return
    }

    addIntegration({
      organizationId,
      siteId,
      data: { name: name ?? '', url: url ?? '' },
    })
  }

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('integrations.addIntegration.title')}
      submitText={t('integrations.addIntegration.submitText')}
      autoMargins
      disabledButton={isAdding}
    >
      {hasApiMutationErrors && <ErrorBox>{apiMutationErrorMsg}</ErrorBox>}
      <FormField
        label={t('integrations.integrationForm.fields.label.label')}
        value={form.name}
        onChange={handleChangeField('name')}
        placeholder={t('integrations.integrationForm.fields.label.placeholder')}
        error={errors.name}
        autoFocus
        disabled={isAdding}
      />
      <FormSelect
        label={t('integrations.integrationForm.fields.authMode.label')}
        options={authenticationMethodOptions}
        placeholder={t(
          'integrations.integrationForm.fields.authMode.placeholder'
        )}
        value={authMethod}
        onChange={(_e, v) => handleSelectAuthMethod(v as string)}
        error={errors.authMethod}
        disabled={isAdding}
      />
      {authMethod === 'custom' && (
        <FormField
          type="url"
          label={t('integrations.integrationForm.fields.apiURL.label')}
          info={fieldInfo.url}
          value={form.url}
          onChange={handleChangeField('url')}
          placeholder={t(
            'integrations.integrationForm.fields.apiURL.placeholder'
          )}
          error={errors.url}
          disabled={isAdding}
        />
      )}
    </DrawerForm>
  )
}

export default AddIntegrationDrawer
